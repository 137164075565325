import styled from '@emotion/styled';

export const Tag = styled.span`
  background-color: #e1e7eb;
  color: #3d4e60;
  font-weight: 500;
  padding: 12px;
  border-radius: 6px;
  white-space: nowrap;
  box-shadow: rgb(0 0 0 / 24%) 0px 1px 8px;
`;

export const Label = styled.span`
  color: rgba(0, 0, 0, 0.38);
  font-size: 0.7rem;
  position: absolute;
  background-color: #fff;
  top: -8px;
  left: 15px;
  z-index: 1;
`;
