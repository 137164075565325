import fetch from '@payhop/shared-utils/fetch.util';

export default function getDocument(
  cnpj: string,
  type = 1,
  partnerIsIF = false
) {
  return fetch(`accounts/search-document`, {
    method: 'POST',
    ms: 'ACCOUNT',
    body: JSON.stringify({
      document: cnpj,
      type,
      creditor_type_if: partnerIsIF,
    }),
  });
}
