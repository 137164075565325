/* eslint-disable */

import fetch from '@payhop/shared-utils/fetch.util';

type dataRequest = {
  data: {
    deal_gcap: boolean;
    creditor_id: string;
    deal_type: string;
    deal_contract_type: string;
    file: any;
  };
};

export default async function postDealsSmokeUploadService({
  data,
}: dataRequest) {
  let formData = new FormData();

  formData.append('file', data.file);
  formData.append('creditor_id', data.creditor_id);
  formData.append('deal_type', data.deal_type);
  formData.append('deal_gcap', String(data.deal_gcap));
  formData.append('deal_contract_type', data.deal_contract_type);

  const response = await fetch(`deals/upload-contract-smoke`, {
    method: 'POST',
    ms: 'DEAL',
    defaultHeaders: false,
    auth: true,
    body: formData,
  });

  const result = await response.json();

  return [response.ok, result];
}
