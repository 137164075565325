import { useState } from 'react';
import flow from 'lodash/flow';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Box, IconButton, TextField, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import patchAccountsChangeEmailService from '@backoffice/private/services/account/postAccountsChangeEmail.service';

import * as dialogMerchantChangeEmailDuck from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantChangeEmail.duck';

const DialogChangeEmailComponent: React.FC<any> = () => {
  const handleNotification = useNotification();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();

  const dispatchRedux = {
    CLOSE: flow(dialogMerchantChangeEmailDuck.Dispatchs.close, dispatch),
  };

  const selectorRedux: any = {
    visible: useSelector(dialogMerchantChangeEmailDuck.Selectors.visible),
    payload: useSelector(dialogMerchantChangeEmailDuck.Selectors.payload),
  };

  const handleSubmit = async () => {
    setLoading(true);
    const response = await patchAccountsChangeEmailService({
      newEmail: email,
      userId: selectorRedux.payload.userId,
      phoneNumber: selectorRedux.payload.phoneNumber,
    });
    setLoading(false);

    if (response.ok) {
      handleNotification('Email alterado com sucesso!', 'success');

      dispatchRedux.CLOSE();

      setEmail('');

      window.location.reload();
    }

    if (!response.ok) {
      const result = await response.json();

      handleNotification(result?.errors.message[0], 'error');
    }
  };

  return (
    <Dialog
      open={selectorRedux.visible as any}
      onClose={() => dispatchRedux.CLOSE()}
      aria-labelledby="form-dialog-title"
      maxWidth={false}
    >
      <Box p={3} position="relative" pt={6}>
        <Box position="absolute" top={8} right={8}>
          <IconButton onClick={() => dispatchRedux.CLOSE()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="1fr"
          minWidth={300}
          gridGap={12}
          mt={3}
        >
          <TextField
            label="Novo email"
            required
            fullWidth
            variant="outlined"
            onChange={(ev) => setEmail(ev?.target?.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Atualizar
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogChangeEmailComponent;
