import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import fetch from '@payhop/shared-utils/fetch.util';

import CardSearch from '@backoffice/private/components/Card/CardSearch';

import useUserHook from '@backoffice/shared/hooks/useUser.hook';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useListCreditorsHook from '../../../importAppointments/hooks/useListCreditors.hook';

import ModalChangePasswordComponent from '../modals/ModalChangePassword.component';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/access/listCreditorAccess.duck';

import * as dialogCreditorChangePasswordDuck from '@backoffice/private/ducks/creditors/dialogs/dialogCreditorChangePassword.duck';

import TableCreditorUsersContainer from './TableUsersCreditors.component';

import schemaSearchFormik from '@backoffice/shared/utils/schemaSearchFormik.util';

const schemaSearch = [
  [
    {
      name: 'name',
      label: 'Nome',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'phone',
      label: 'Telefone',
    },
  ],
];

const creditorsService = () =>
  fetch(`creditors?limit=999999`, {
    method: 'GET',
    ms: 'CREDITOR',
    auth: true,
  });

const UsersContainer = ({ handleSetCreditorId }) => {
  const { isAdmin, user } = useUserHook();
  const creditors = useListCreditorsHook();

  const [creditorId, setCreditorId] = useState('');
  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const dispatchDialogRedux = {
    CLOSE: flow(dialogCreditorChangePasswordDuck.Dispatchs.close, dispatch),
  };

  const selectorDialogRedux = {
    visible: useSelector(dialogCreditorChangePasswordDuck.Selectors.visible),
    payload: useSelector(dialogCreditorChangePasswordDuck.Selectors.payload),
  };

  useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: false,
    },
  });

  useEffect(() => {
    if (!isAdmin) {
      const { personId } = user || {};

      dispatchRedux.UPDATE_FILTERS({}, personId);

      setCreditorId(personId);
    }
  }, []);

  useEffect(() => {
    handleSetCreditorId(creditorId);
  }, [creditorId]);

  useEffect(() => {
    return () => {
      dispatchRedux.RESET();
    };
  }, []);

  const handleSearch = (values) => {
    const newValues = { ...values, offset: 0 };

    if (newValues?.document) {
      newValues.document = newValues.document.replace(/\D/g, '');
    }

    return dispatchRedux.UPDATE_FILTERS({ ...newValues }, creditorId);
  };

  const handleSearchReset = () => (fn) => {
    dispatchRedux.RESET_FILTERS();
    dispatchRedux.GET(creditorId);

    return fn();
  };

  return (
    <UsersScreen
      creditors={creditors}
      handleUpdateFilters={dispatchRedux.UPDATE_FILTERS}
      visible={selectorDialogRedux.visible}
      handleClose={dispatchDialogRedux.CLOSE}
      creditorId={creditorId}
      setCreditorId={setCreditorId}
      handleSearch={handleSearch}
      handleSearchReset={handleSearchReset}
      email={selectorDialogRedux.payload?.email}
    />
  );
};

const UsersScreen = ({
  creditors,
  handleUpdateFilters,
  visible,
  handleClose,
  creditorId,
  setCreditorId,
  handleSearch,
  handleSearchReset,
  email,
}) => {
  const { isAdmin } = useUserHook();

  const initialValuesFormik: any = schemaSearchFormik(schemaSearch);

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" gridGap={32}>
        {isAdmin && (
          <Box>
            <TextField
              select
              SelectProps={{ native: true }}
              label="Fornecedor"
              variant="outlined"
              css={{ minWidth: 300 }}
              onChange={({ target: { value } }) => {
                setCreditorId(value);
                handleUpdateFilters({}, value);
              }}
            >
              <option value={''}></option>
              {creditors?.map((c) => (
                <option value={c.id} key={c.id}>
                  {c.trading_name}
                </option>
              ))}
            </TextField>
          </Box>
        )}

        {creditorId && (
          <Formik
            initialValues={initialValuesFormik}
            onSubmit={async (values) => {
              const valuesFormatted = {
                ...values,
                phone: values?.phone.replace(/\D/g, ''),
              };

              return handleSearch(valuesFormatted);
            }}
          >
            {({ submitForm, resetForm }) => (
              <CardSearch
                fields={schemaSearch}
                formik
                onSubmit={submitForm}
                onReset={() => handleSearchReset()(resetForm)}
              />
            )}
          </Formik>
        )}

        <TableCreditorUsersContainer creditorId={creditorId} />
      </Box>

      <ModalChangePasswordComponent
        open={visible}
        onClose={handleClose}
        email={email}
      />
    </Fragment>
  );
};

export default UsersContainer;
