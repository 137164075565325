import React, { useEffect, useMemo, useRef, useState } from 'react';
import { flow } from 'lodash';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import CardTable from '@backoffice/private/components/Card/CardTable';
import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';

import * as reports from '@backoffice/private/ducks/reports/listReports.duck';
import { STRATEGY_LABELS } from '@backoffice/private/constants/contract/strategy.constant';
import getExportReportService from '@backoffice/private/services/export/getExportReport.service';
import { TRANSACTION_LABELS } from '@backoffice/private/constants/contract/transaction.constant';

const TableContractomponent = () => {
  const { isAdmin, user, accountId } = useUserHook();
  const handleNotification = useNotification();

  const [loadingLocal, setLoadingLocal] = useState(false);
  const [orderFilter, setOrderFilter] = useState({});

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(reports.Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(reports.Dispatchs.resetFilters, dispatch),
    GET: flow(reports.Dispatchs.get, dispatch),
    RESET: flow(reports.Dispatchs.reset, dispatch),
    DELETE: flow(reports.Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(reports.Selectors.data),
    LOADING: useSelector(reports.Selectors.loading),
    DATA_RESULTS: useSelector(reports.Selectors.data_results),
    FILTERS: useSelector(reports.Selectors.filters),
    RESULTSET: useSelector(reports.Selectors.resultset),
    FETCHED: useSelector(reports.Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: false,
    },
  });

  useEffect(() => {
    if (!isAdmin) {
      const { personId } = user || {};

      dispatchRedux.UPDATE_FILTERS({
        creditor_id: personId,
        searchByGroup: false,
      });
    } else {
      dispatchRedux.GET();
    }
  }, []);

  const handleOnRefresh = () => {
    dispatchRedux.GET();
  };

  const handleOnExport = async () => {
    const filters = {
      ...selectorRedux.FILTERS,
    };

    delete filters.limit;
    delete filters.offset;
    await getExportReportService(accountId, filters);

    handleNotification(
      'Sua solicitação foi enviada, em alguns minutos você receberá uma notificação',
      'success'
    );
  };

  const handleOrder = async (filter) => {
    setLoadingLocal(true);
    await setOrderFilter((oldValue) => {
      if (!oldValue[filter]) {
        return { ...oldValue, [filter]: 'asc' };
      }
      if (oldValue[filter] === 'asc') {
        return { ...oldValue, [filter]: 'desc' };
      }
      if (oldValue[filter] === 'desc') {
        const orders = { ...oldValue };
        delete orders[filter];
        return { ...oldValue, [filter]: '' };
      }
    });
    setLoadingLocal(false);
  };

  useEffect(() => {
    dispatchRedux.UPDATE_FILTERS({
      ...orderFilter,
    });
  }, [orderFilter]);

  return (
    <TableContract
      loading={selectorRedux.LOADING || loadingLocal}
      results={selectorRedux.DATA_RESULTS}
      handleOnRefresh={handleOnRefresh}
      handleOnExport={handleOnExport}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleOrder={handleOrder}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
      orderFilter={orderFilter}
    />
  );
};

const TableContract = ({
  results,
  filters,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOnRefresh,
  handleOnExport,
  handleOrder,
  orderFilter,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'relatorios_liquidacao',
  });

  const { limit, count, offset } = filters || {};

  const columns = useMemo(
    () => [
      {
        title: 'Parceiro',
        dataIndex: 'creditor_name',
        key: 'creditor_name',
        tooltip: 'Responsável pela criação do compromisso',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('creditorNameOrder');
        },
        orderType: orderFilter.creditorNameOrder,
      },
      {
        title: 'Estabelecimento',
        dataIndex: 'merchant_name',
        key: 'merchant_name',
        tooltip: 'Estabelecimento Comercial selecionado para a operação',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('merchantNameOrder');
        },
        orderType: orderFilter.merchantNameOrder,
      },
      {
        title: 'CNPJ',
        dataIndex: 'merchant_document_number',
        key: 'merchant_document_number',
        tooltip: 'CNPJ do Estabelecimento Comercial selecionado para operação',
        render: ({ merchant_document_number }) => (
          <NumberFormat
            displayType="text"
            value={merchant_document_number}
            isNumericString
            format="##.###.###/####-##"
          />
        ),
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('merchantDocumentNumberOrder');
        },
        orderType: orderFilter.merchantDocumentNumberOrder,
      },
      {
        title: 'Credenciadora',
        dataIndex: 'acquirer_name',
        key: 'acquirer_name',
        tooltip: ' Credenciadora da Unidade de Recebível selecionada',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('acquirerNameOrder');
        },
        orderType: orderFilter.acquirerNameOrder,
      },
      {
        title: 'Arranjo',
        dataIndex: 'payment_arrangement',
        key: 'payment_arrangement',
        tooltip: 'Arranjo de Pagamento da Unidade de Recebível selecionada',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('paymentArrangementOrder');
        },
        orderType: orderFilter.paymentArrangementOrder,
      },
      {
        title: 'Prioridade',
        dataIndex: 'effect_order',
        key: 'effect_order',
        tooltip:
          'Ordem de prioridade do compromisso criado na agenda de recebíveis da Unidade de Recebível selecionada',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('effectOrder');
        },
        orderType: orderFilter.effectOrder,
      },
      {
        title: 'Valor Constítuido UR',
        dataIndex: 'amount_onerate',
        key: 'amount_onerate',
        tooltip:
          'Valor total transacionado entre o Estabelecimento e seu cliente final',
        render: ({ amount_onerate }) =>
          Number(amount_onerate ?? '0.00').toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }) ?? '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('amountOnerateOrder');
        },
        orderType: orderFilter.amountOnerateOrder,
      },
      {
        title: 'Valor de Efeito Inicial',
        dataIndex: 'effect_amount_initial',
        key: 'effect_amount_initial',
        tooltip:
          'Valor de efeito informado pela registradora no dia da criação do compromisso',
        render: ({ effect_amount_initial }) =>
          Number(effect_amount_initial ?? '0.00').toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }) ?? '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('effectAmountInitialOrder');
        },
        orderType: orderFilter.effectAmountInitialOrder,
      },
      {
        title: 'Valor de Efeito',
        dataIndex: 'effect_amount',
        key: 'effect_amount',
        tooltip:
          'Valor de efeito informado pela registradora no dia seguinte ao registro do compromisso',
        render: ({ effect_amount }) =>
          Number(effect_amount ?? '0.00').toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }) ?? '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('effectAmountOrder');
        },
        orderType: orderFilter.effectAmountOrder,
      },
      {
        title: 'Valor do Efeito Atualizado',
        dataIndex: 'effect_amount_updated',
        key: 'effect_amount_updated',
        tooltip:
          ' Valor de efeito informado pela registradora diariamente após o registro do compromisso',
        render: ({ effect_amount_updated }) =>
          Number(effect_amount_updated ?? '0.00').toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }) ?? '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('effectAmountUpdatedOrder');
        },
        orderType: orderFilter.effectAmountUpdatedOrder,
      },
      {
        title: 'Valor de Liquidação Efetivo',
        dataIndex: 'effective_settlement_amount',
        key: 'effective_settlement_amount',
        tooltip:
          'Valor informado pela instituição credenciadora ou subcredenciadora como valor efetivamente liquidado.',
        render: ({ effective_settlement_amount }) =>
          Number(effective_settlement_amount ?? '0.00').toLocaleString(
            'pt-br',
            {
              style: 'currency',
              currency: 'BRL',
            }
          ) ?? '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('effectiveSettlementAmountOrder');
        },
        orderType: orderFilter.effectiveSettlementAmountOrder,
      },
      {
        title: 'Data de Atualização',
        dataIndex: 'receivable_updated_at',
        key: 'receivable_updated_at',
        tooltip: 'Data da última atualização da UR pelas registradoras',
        render: ({ receivable_updated_at }) =>
          receivable_updated_at
            ? moment(receivable_updated_at).format('DD/MM/YYYY')
            : '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('receivableUpdatedAtOrder');
        },
        orderType: orderFilter.receivableUpdatedAtOrder,
      },
      {
        title: 'Data do Compromisso',
        dataIndex: 'contract_created_at',
        key: 'contract_created_at',
        tooltip: 'Data de Criação do Compromisso',
        render: ({ contract_created_at }) =>
          contract_created_at
            ? moment(contract_created_at).format('DD/MM/YYYY')
            : '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('contractCreatedAtOrder');
        },
        orderType: orderFilter.contractCreatedAtOrder,
      },
      {
        title: 'Data de liquidação',
        dataIndex: 'contract_execution_date',
        key: 'contract_execution_date',
        tooltip: 'Data de Liquidação prevista',
        render: ({ contract_execution_date }) =>
          contract_execution_date
            ? moment(contract_execution_date).format('DD/MM/YYYY')
            : '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('contractExecutionDateOrder');
        },
        orderType: orderFilter.contractExecutionDateOrder,
      },
      {
        title: 'Data de Liquidação Efetiva',
        dataIndex: 'effective_settlement_date',
        key: 'effective_settlement_date',
        tooltip:
          ' Data informada pela credenciadora como data de liquidação efetiva.',
        render: ({ effective_settlement_date }) =>
          effective_settlement_date
            ? moment(effective_settlement_date).format('DD/MM/YYYY')
            : '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('effectiveSettlementDateOrder');
        },
        orderType: orderFilter.effectiveSettlementDateOrder,
      },
      {
        title: 'Status do compromisso',
        dataIndex: 'status',
        key: 'status',
        tooltip: 'Status do compromisso ao qual a UR pertence',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('statusOrder');
        },
        orderType: orderFilter.statusOrder,
      },
      {
        title: 'Tipo de Transação',
        dataIndex: 'transaction_type',
        key: 'transaction_type',
        tooltip:
          'Tipo de transação selecionada na criação do contrato. Podendo ser Garantia, Cobrança, Antecipação e Pagamento',
        render: ({ transaction_type }) =>
          transaction_type ? TRANSACTION_LABELS[transaction_type] : '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('transactionTypeOrder');
        },
        orderType: orderFilter.transactionTypeOrder,
      },
      {
        title: 'Tipo de Contrato',
        dataIndex: 'contract_type',
        key: 'contract_type',
        tooltip:
          'Tipo de contrato selecionado na criação do compromisso (Troca de Titularidade ou Cessão)',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('contractTypeOrder');
        },
        orderType: orderFilter.contractTypeOrder,
      },
      {
        title: 'Modalidade',
        dataIndex: 'strategy',
        key: 'strategy',
        tooltip:
          'Modalidade de contrato selecionada na criação de compromisso. Podendo ser fumaça ou performado',
        render: ({ strategy }) => (strategy ? STRATEGY_LABELS[strategy] : '-'),
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('strategyOrder');
        },
        orderType: orderFilter.strategyOrder,
      },
      {
        title: 'Código Externo',
        dataIndex: 'code',
        key: 'code',
        tooltip:
          'Código obrigatório e personalizável informado na criação de compromisso',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('codeOrder');
        },
        orderType: orderFilter.codeOrder,
      },
      {
        title: 'ID do Compromisso',
        dataIndex: 'external_reference',
        key: 'external_reference',
        tooltip:
          ' Referência de contrato único gerado automaticamente a partir da criação de compromisso',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('externalReferenceOrder');
        },
        orderType: orderFilter.externalReferenceOrder,
      },
      {
        title: 'CNPJ do Parceiro',
        dataIndex: 'creditor_document_number',
        key: 'creditor_document_number',
        tooltip: 'CNPJ do parceiro que criou o compromisso',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('creditorDocumentNumberOrder');
        },
        orderType: orderFilter.creditorDocumentNumberOrder,
      },
      {
        title: 'CNPJ do Originador',
        dataIndex: 'originator_document_number',
        key: 'originator_document_number',
        tooltip: 'CNPJ do originador do crédito ofertado na operação',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('originatorDocumentNumberOrder');
        },
        orderType: orderFilter.originatorDocumentNumberOrder,
      },
      {
        title: 'Gestão de Colateral',
        dataIndex: 'gcap',
        key: 'gcap',
        tooltip:
          'Indica se a operação foi criada com gestão das URs para evitar a perda de lastro através da recomposição de URs.',
        render: ({ gcap }) => (gcap ? 'Sim' : 'Não'),
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('gcapOrder');
        },
        orderType: orderFilter.gcapOrder,
      },
    ],
    [permissions, orderFilter]
  );

  return (
    <>
      <CardTable
        count={count || 0}
        loading={loading}
        dataSource={results}
        rowsPerPage={limit}
        columns={columns}
        page={offset / limit}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onRefresh={handleOnRefresh}
        onExport={handleOnExport}
        exporttype="api"
        exportname="reports"
        tableProps={{
          style: {
            minWidth: 1700,
          },
        }}
      />
    </>
  );
};

export default TableContractomponent;
