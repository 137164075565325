import React, { Fragment } from 'react';
import { useLocation, useHistory } from 'react-router';

import { Grid, Box } from '@material-ui/core';
import { Breadcrumbs } from '@material-ui/core';

import LABELS_ROUTES from '@backoffice/shared/utils/labelRoutes.util';

import Typography from '@backoffice/shared/components/Typography.component';

interface TitlePageProps {
  title?: string;
  breadcrumbs?: boolean;
  handleClick?: (event: any) => void;
}

const TitlePageComponent: React.FC<TitlePageProps> = ({
  title,
  handleClick,
  breadcrumbs = true,
}) => {
  const { pathname } = useLocation();

  const pathnameSplitted = pathname.split('/');

  const linksBreadCrumb = pathnameSplitted
    .map((_, index) => {
      const currentRoute = [...Array(index + 1)]
        .map((_, idx) => pathnameSplitted[idx])
        .join('/');

      return {
        link: currentRoute,
        label: LABELS_ROUTES[currentRoute] || currentRoute,
      };
    })
    .filter((e) => e.link);

  return (
    <Fragment>
      <Grid container alignItems="center">
        <Grid item xs>
          <Grid container>
            {title && (
              <Grid item xs={12}>
                <Typography
                  customVariant="text__header--sm"
                  customColor="info-500"
                >
                  {title}
                </Typography>
              </Grid>
            )}

            {breadcrumbs && (
              <Grid item xs={12}>
                <Box>
                  <Breadcrumbs
                    separator="/"
                    aria-label="breadcrumb"
                    css={{
                      '.MuiBreadcrumbs-separator': {
                        fontSize: 16,
                      },
                    }}
                  >
                    {linksBreadCrumb?.map((link, index) =>
                      linksBreadCrumb.length === index + 1 ? (
                        <Typography
                          customColor="info-500"
                          customVariant="text__body--sm"
                          key={index}
                        >
                          {link.label}
                        </Typography>
                      ) : (
                        <Typography
                          customColor="gray-400"
                          customVariant="text__body--sm"
                          key={index}
                          onClick={() =>
                            handleClick ? handleClick(link?.link) : undefined
                          }
                          css={{
                            cursor: 'pointer',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          {link.label}
                        </Typography>
                      )
                    )}
                  </Breadcrumbs>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const TitlePageContainer: React.FC<TitlePageProps> = ({
  title,
  breadcrumbs,
}) => {
  const history = useHistory();

  const handleClick = (link: any): void => {
    history.push(link);
  };

  return (
    <TitlePageComponent
      title={title}
      handleClick={handleClick}
      breadcrumbs={breadcrumbs}
    />
  );
};

export default TitlePageContainer;
