import React, { useMemo, Fragment, useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { v4 } from 'uuid';

import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import ModalConfirmDelete from '@backoffice/private/components/Modal/ModalConfirmDelete.component';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardSearch from '@backoffice/private/components/Card/CardSearch';

import ROUTES from '@backoffice/shared/utils/routes.util';
import schemaSearchFormik from '@backoffice/shared/utils/schemaSearchFormik.util';

import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import postAccountsUnlockoutService from '@backoffice/private/services/account/postAccountsUnlockout.service';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/profiles/listProfiles.duck';
import { Dispatchs as DispatchsProfile } from '@backoffice/private/ducks/profiles/profile.duck';
import Typography from '@backoffice/shared/components/Typography.component';

const schemaSearch = [
  [
    {
      name: 'description',
      label: 'Nome do perfil',
    },
    {
      label: 'Tipo de perfil',
      name: 'type',
      type: 'select',
      multiple: false,
      options: [
        {
          label: 'Payhop',
          value: 'PAYHOP',
        },
        {
          label: 'Fornecedor',
          value: 'FORNECEDOR',
        },
      ],
    },
    {
      label: 'Ativo',
      name: 'active',
      type: 'select',
      multiple: false,
      options: [
        {
          label: 'Sim',
          value: 'true',
        },
        {
          label: 'Não',
          value: 'false',
        },
      ],
    },
  ],
];

const TableProfiles = () => {
  const handleNotification = useNotification();

  const [loadingLocal, setLoadingLocal] = useState(false);
  const [key, setKey] = useState(v4());

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };
  const dispatchProfileRedux = {
    UPDATE: flow(DispatchsProfile.update, dispatch),
    RESET: flow(DispatchsProfile.reset, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  useEffect(() => {
    setKey(v4());
  }, [selectorRedux.FILTERS]);

  const handleUnlock = async (email) => {
    setLoadingLocal(true);

    const response = await postAccountsUnlockoutService(email);

    if (response.ok) {
      handleNotification('Desbloqueado com sucesso', 'success');
    } else {
      const result = await response.json();

      handleNotification(result?.errors?.message?.[0], 'error');
    }

    setLoadingLocal(false);
  };

  const handleInactive = async (id, data, type) => {
    await dispatchProfileRedux.UPDATE(id, data, type);
    dispatchProfileRedux.RESET();

    handleNotification('Inativado com sucesso', 'success');
  };

  const handleOnRefresh = () => {
    dispatchRedux.GET();
  };

  const handleOnExport = async () => {
    const result = await dispatchRedux.GET({ isExport: true });

    return (result as any)?.results;
  };

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearch,
    handleSearchReset,
  } = useCrudRead({
    dispatchRedux,
    selectorRedux,
  });

  return (
    <UsersScreen
      loading={selectorRedux.LOADING || loadingLocal}
      results={selectorRedux.DATA_RESULTS}
      key={key}
      handleInactive={handleInactive}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleSearch={handleSearch}
      handleSearchReset={handleSearchReset}
      handleUnlock={handleUnlock}
      handleOnRefresh={handleOnRefresh}
      handleOnExport={handleOnExport}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const UsersScreen = ({
  loading,
  results,
  key,
  filters,
  handleInactive,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleSearchReset,
  handleUnlock,
  handleOnRefresh,
  handleOnExport,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_acesso_perfil',
  });
  const currentId = useRef('');
  const currentEmail = useRef('');

  const [modalInactive, setModalInactive] = useState(false);

  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        width: 100,
        render: ({ id, count, active }) => (
          <Box display="flex" gridGap={8}>
            <IconButton
              disabled={!permissions?.some((item) => item == 'edit')}
              onClick={() => {
                history.push(ROUTES.PRIVATE.ACCESS.EDIT(id, 'profile'));
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                history.push(ROUTES.PRIVATE.ACCESS.EDIT(id, 'visual'));
              }}
            >
              <VisibilityIcon />
            </IconButton>
            <Button
              size="small"
              variant="outlined"
              disabled={
                count > 0 ||
                !active ||
                !permissions?.some((item) => item == 'edit')
              }
              onClick={() => {
                currentId.current = id;

                setModalInactive(true);
              }}
            >
              Inativar
            </Button>
          </Box>
        ),
      },
      {
        title: 'Nome do perfil',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Tipo de Perfil',
        dataIndex: 'is_payhop',
        key: 'is_payhop',
        render: ({ is_payhop }) => (is_payhop ? 'PAYHOP' : 'FORNECEDOR'),
      },
      {
        title: 'Ativo',
        dataIndex: 'active',
        key: 'active',
        render: ({ active }) => (active ? 'SIM' : 'NÃO'),
      },
      {
        title: 'Data de criação',
        dataIndex: 'created_at',
        key: 'created_at',
        render: ({ created_at }) => moment(created_at).format('DD/MM/YYYY'),
      },
      {
        title: 'Data da última modificação',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: ({ updated_at }) =>
          updated_at ? moment(updated_at).format('DD/MM/YYYY') : '-',
      },
      {
        title: 'Quantidade de usuários',
        dataIndex: 'count',
        key: 'count',
      },
    ],
    [permissions]
  );

  const handleCloseModalInactive = () => setModalInactive(false);

  const initialValuesFormik: any = schemaSearchFormik(schemaSearch);

  const { limit, count, offset } = filters || {};

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" gridGap={32}>
        <Formik
          initialValues={{
            description: filters.description ?? '',
            type: filters.type ?? null,
            active: filters.active ?? null,
          }}
          onSubmit={async (values) => {
            const valuesFormatted = {
              ...values,
            };

            return handleSearch(valuesFormatted);
          }}
          key={key}
        >
          {({ submitForm, resetForm }) => (
            <CardSearch
              fields={schemaSearch}
              formik
              onSubmit={submitForm}
              onReset={() => handleSearchReset()(resetForm)}
            />
          )}
        </Formik>

        <Box>
          <Typography css={{ position: 'absolute', color: '#F44336' }}>
            *Obs: Perfis com usuários atrelados estarão com o botão inativar
            desabilitado
          </Typography>
          <CardTable
            count={count || 0}
            loading={loading}
            dataSource={results}
            rowsPerPage={limit}
            columns={columns}
            page={offset / limit}
            onExport={handleOnExport}
            onRefresh={handleOnRefresh}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            exporttype="xls"
            exportname="profiles"
          />
        </Box>
      </Box>

      <ModalConfirmDelete
        open={modalInactive}
        onCancel={handleCloseModalInactive}
        title="Você tem certeza que deseja inativar este Perfil?"
        description={
          'Esta ação não poderá ser desfeita, apenas prossiga se tiver certeza.'
        }
        onConfirm={() => {
          handleInactive(currentId.current, [], 'inactive');
          handleCloseModalInactive();
        }}
      />
    </Fragment>
  );
};

export default TableProfiles;
