import { useEffect } from 'react';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import useIsEdit from './useIsEdit.hook';

type useCrudTypes = {
  selectorRedux: {
    DATA: any;
    NOTIFICATION: any;
  };
  dispatchRedux: {
    RESET_NOTIFICATION: any;
  };
  messages?: {
    onCreate?: string;
    onEdit?: string;
  };
  opts?: any;
};

const useCrudNotification = ({
  selectorRedux,
  dispatchRedux,
  messages,
}: useCrudTypes) => {
  const handleNotification = useNotification();

  const { isEdit } = useIsEdit();

  const { successAction } = selectorRedux.DATA || {};

  useEffect(() => {
    if (!selectorRedux.NOTIFICATION) {
      return undefined;
    }

    if (successAction === true) {
      const messageCreate = messages?.onCreate ?? 'Criação feita com sucesso!';
      const messageEdit = messages?.onEdit ?? 'Atualização feita com sucesso!';

      handleNotification(
        isEdit ? messageEdit : messageCreate,
        'success',
        dispatchRedux.RESET_NOTIFICATION && {
          onClose: () => dispatchRedux.RESET_NOTIFICATION(),
        }
      );
    }

    if (successAction === false) {
      const errorMessage =
        selectorRedux?.DATA?.error ||
        'Aconteceu um erro, tente novamente mais tarde';

      handleNotification(
        errorMessage,
        'error',
        dispatchRedux.RESET_NOTIFICATION && {
          onClose: () => dispatchRedux.RESET_NOTIFICATION(),
        }
      );
    }
  }, [selectorRedux.NOTIFICATION, successAction]);

  return {
    handleNotification,
  };
};

export default useCrudNotification;
