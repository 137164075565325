import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import * as Styles from './PasswordValidation.styled';

const PasswordValidation = ({ senha }) => {
  const [passwordValidations, setPasswordValidations] = useState({
    characters: false,
    uppercase: false,
    number: false,
  });

  useEffect(() => {
    if (senha) {
      if (senha.length >= 8) {
        setPasswordValidations((prevState) => ({
          ...prevState,
          characters: true,
        }));
      } else {
        setPasswordValidations((prevState) => ({
          ...prevState,
          characters: false,
        }));
      }
      if (/[A-Z]/gm.test(senha)) {
        setPasswordValidations((prevState) => ({
          ...prevState,
          uppercase: true,
        }));
      } else {
        setPasswordValidations((prevState) => ({
          ...prevState,
          uppercase: false,
        }));
      }
      if (/[0-9]/gm.test(senha)) {
        setPasswordValidations((prevState) => ({
          ...prevState,
          number: true,
        }));
      } else {
        setPasswordValidations((prevState) => ({
          ...prevState,
          number: false,
        }));
      }
    } else {
      setPasswordValidations({
        characters: false,
        uppercase: false,
        number: false,
      });
    }
  }, [senha]);
  return (
    <Box display={'flex'} flexWrap={'wrap'}>
      <Styles.ValidationTag validation={passwordValidations.characters}>
        <Styles.ValidationIcon validation={passwordValidations.characters}>
          <CheckIcon />
        </Styles.ValidationIcon>{' '}
        8 caracteres
      </Styles.ValidationTag>
      <Styles.ValidationTag validation={passwordValidations.uppercase}>
        <Styles.ValidationIcon validation={passwordValidations.uppercase}>
          <CheckIcon />
        </Styles.ValidationIcon>{' '}
        Uma letra maíuscula
      </Styles.ValidationTag>
      <Styles.ValidationTag validation={passwordValidations.number}>
        <Styles.ValidationIcon validation={passwordValidations.number}>
          <CheckIcon />
        </Styles.ValidationIcon>{' '}
        Um número
      </Styles.ValidationTag>
    </Box>
  );
};

export default PasswordValidation;
