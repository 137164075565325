import React from 'react';

import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Props = {
  data: Array<string | number>;
};

const ReceivablesExport = ({ data }: Props) => {
  function handleFilterReceivablesData(data) {
    const filteredData: any = [];
    data.forEach((item) => {
      const newData = {
        document_acquirer: item.document_acquirer,
        arrangement_code: item.arrangement_code,
        appointment_title: '',
        appointment_code: '',
        appointment_date: '',
        external_reference: item.external_reference,
        reference_id: item.reference_id,
        wallet: 'CART-TBD',
        bank_account_document_holder: item.bank_account_document_holder,
        bank_account_type: item.bank_account_type,
        bank_account_ispb: item.bank_account_ispb,
        bank_account_agency: item.bank_account_agency,
        bank_account_account: item.bank_account_account,
        due_date: moment(item.due_date).format('DD/MM/YYYY'),
        total_constituted_value: item.total_constituted_value,
        effect_constituted_value: item.effect_constituted_value,
        division_rules: item.division_rules,
        encumbrance_indicator: item.encumbrance_indicator,
        document_type_final_user_receiver:
          item.document_type_final_user_receiver,
        document_final_user_receiver: item.document_final_user_receiver,
        holder_document: item.holder_document,
        constituition: item.constituition,
        amount_blocked: item.amount_blocked ? item.amount_blocked : '0',
        amount_encumbrance: item.amount_encumbrance,
      };
      filteredData.push(newData);
    });
    return filteredData;
  }
  return (
    <ExcelFile
      filename={'Recebiveis'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleFilterReceivablesData(data)} name="Recebíveis">
        <ExcelColumn label="TÍTULO" value="appointment_title" />
        <ExcelColumn label="CÓDIGO" value="appointment_code" />
        <ExcelColumn label="DATA COMPROMISSO" value="appointment_date" />
        <ExcelColumn label="REFERÊNCIA EXTERNA" value="external_reference" />
        <ExcelColumn label="IDENTIFICADOR CONTRATO" value="reference_id" />
        <ExcelColumn label="CARTEIRA" value="wallet" />
        <ExcelColumn
          label="CONTA PAGAMENTO_CNPJ"
          value="bank_account_document_holder"
        />
        <ExcelColumn label="TIPO CONTA PAGAMENTO" value="bank_account_type" />
        <ExcelColumn label="CONTA PAGAMENTO_ISPB" value="bank_account_ispb" />
        <ExcelColumn
          label="CONTA PAGAMENTO_AGÊNCIA"
          value="bank_account_agency"
        />
        <ExcelColumn
          label="CONTA PAGAMENTO_CONTA"
          value="bank_account_account"
        />
        <ExcelColumn label="CREDENCIADORA" value="document_acquirer" />
        <ExcelColumn label="ARRANJO" value="arrangement_code" />
        <ExcelColumn label="DATA LIQUIDAÇÃO" value="due_date" />
        <ExcelColumn
          label="VALOR CONSTITUIDO TOTAL"
          value="total_constituted_value"
        />
        <ExcelColumn
          label="VALOR CONSTITUIDO EFEITO"
          value="effect_constituted_value"
        />
        <ExcelColumn label="DIVISÃO" value="division_rules" />
        <ExcelColumn label="INDICADOR ONERAÇÃO" value="encumbrance_indicator" />
        <ExcelColumn
          label="TIPO DOCUMENTO USUÁRIO FINAL RECEBEDOR"
          value="document_type_final_user_receiver"
        />
        <ExcelColumn
          label="DOCUMENTO USUÁRIO FINAL RECEBEDOR"
          value="document_final_user_receiver"
        />
        <ExcelColumn label="DOCUMENTO TITULAR" value="holder_document" />
        <ExcelColumn label="CONSTITUIÇÃO" value="constituition" />
        <ExcelColumn label="VALOR BLOQUEADO" value="amount_blocked" />
        <ExcelColumn label="VALOR ONERADO" value="amount_encumbrance" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ReceivablesExport;
