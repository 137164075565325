import styled from '@emotion/styled';

type DetailsProps = {
  expanded: boolean;
};

type HeaderProps = {
  expanded: boolean;
};

export const AccordionContainer = styled.div`
  p {
    margin: 0;
  }
  box-sizing: border-box;
  margin-bottom: 16px;
`;

export const AccordionHeader = styled.header<HeaderProps>`
  font-size: 1.125rem;
  font-weight: 600;
  padding: 12px 14px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;

  svg {
    transform: ${(props) =>
      props.expanded ? 'rotateX(180deg)' : 'rotateX(0deg)'};
    transition: transform 0.8s;
  }
`;

export const AccordionDetails = styled.div<DetailsProps>`
  max-height: ${(props) => (props.expanded ? '9999px' : '0px')};
  overflow: hidden;
  transition: max-height 0.8s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-sizing: border-box;

  section {
    padding: 16px;
  }
`;
