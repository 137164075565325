import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import ModalConfirmDelete from '@backoffice/private/components/Modal/ModalConfirmDelete.component';
import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardSearch from '@backoffice/private/components/Card/CardSearch';

import ROUTES from '@backoffice/shared/utils/routes.util';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/partners/listPartners.duck';
import { v4 } from 'uuid';

const texts = {
  title: 'Parceiros',
  buttonAdd: 'Adicionar parceiro',
};

const schemaSearch = [
  [
    {
      name: 'name',
      label: 'Razão Social / Nome Fantasia',
    },
  ],
];

const PartnersContainer = () => {
  const handleNotification = useNotification();

  const [key, setKey] = useState(v4());
  const [loadingLocal, setLoadingLocal] = useState(false);

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const {
    handleDelete,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearch,
    handleSearchReset,
  } = useCrudRead({
    dispatchRedux,
    selectorRedux,
  });

  useEffect(() => {
    setKey(v4());
  }, [selectorRedux.FILTERS]);

  return (
    <PartnersScreen
      loading={selectorRedux.LOADING || loadingLocal}
      results={selectorRedux.DATA_RESULTS}
      key={key}
      handleDelete={handleDelete}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleSearch={handleSearch}
      handleSearchReset={handleSearchReset}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const PartnersScreen = (props) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_parceiros',
  });
  const [modalDelete, setModalDelete] = useState(false);

  const currentId = useRef('');

  const {
    loading,
    results,
    key,
    filters,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearch,
    handleSearchReset,
    handleDelete,
  } = props;

  const history = useHistory();

  const columns = [
    {
      width: 100,
      render: ({ id, email }) => (
        <Box display="flex" gridGap={8}>
          <IconButton
            onClick={() => {
              history.push(ROUTES.PRIVATE.PARTNERS.EDIT(id));
            }}
            disabled={!permissions?.some((item) => item == 'edit')}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              currentId.current = id;
              setModalDelete(true);
            }}
            disabled={!permissions?.some((item) => item == 'delete')}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      title: 'CNPJ',
      dataIndex: 'document_number',
      key: 'document_number',
      render: ({ document_number }) => (
        <NumberFormat
          displayType="text"
          value={document_number}
          isNumericString
          format={
            document_number.length === 11
              ? '###.###.###-##'
              : '##.###.###/####-##'
          }
        />
      ),
    },
    {
      title: 'Razão social',
      dataIndex: 'company_name',
    },
    {
      title: 'Nome Fantasia',
      dataIndex: 'trading_name',
    },
    {
      title: '% Receita',
      dataIndex: 'revenue_percentage',
      render: ({ revenue_percentage }) => (
        <NumberFormat
          displayType="text"
          value={revenue_percentage}
          prefix=""
          suffix="%"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale
          isNumericString
        />
      ),
    },
    {
      title: 'Data de Cadastro',
      dataIndex: 'created_at',
      render: ({ created_at }) => moment(created_at).format('DD/MM/YYYY'),
    },
  ];

  const { limit, count, offset } = filters || {};

  const handleCloseModalDelete = () => setModalDelete(false);

  return (
    <>
      <PageLayout
        headerTitle
        header
        sidebar
        title={texts.title}
        cta={
          <Button
            variant="outlined"
            color="primary"
            css={{ minWidth: 200 }}
            onClick={() => history.push(ROUTES.PRIVATE.PARTNERS.CREATE)}
            disabled={!permissions?.some((item) => item == 'create')}
          >
            {texts.buttonAdd}
          </Button>
        }
      >
        {permissions?.some((item) => item == 'read') && (
          <Box display="flex" flexDirection="column" gridGap={32}>
            <Formik
              initialValues={{ name: filters.name }}
              onSubmit={async (values) => {
                return handleSearch(values);
              }}
              key={key}
            >
              {({ submitForm, resetForm }) => (
                <CardSearch
                  fields={schemaSearch}
                  disabled={loading}
                  formik
                  onSubmit={submitForm}
                  onReset={() => handleSearchReset()(resetForm)}
                />
              )}
            </Formik>
            <CardTable
              count={count}
              loading={loading}
              dataSource={results}
              rowsPerPage={limit}
              columns={columns}
              page={offset / limit}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
        {!permissions ||
          (!permissions?.some((item) => item == 'read') && (
            <ModuleNotPermited />
          ))}
      </PageLayout>
      <ModalConfirmDelete
        open={modalDelete}
        onCancel={handleCloseModalDelete}
        title="Você tem certeza que deseja excluir este parceiro?"
        description={
          'Esta ação não poderá ser desfeita, apenas prossiga se tiver certeza.'
        }
        onConfirm={() =>
          handleDelete(currentId.current)(handleCloseModalDelete)
        }
      />
    </>
  );
};

export default PartnersContainer;
