import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import FormSchema, {
  FormSchemaProps,
} from '@backoffice/private/components/Form/FormSchema';

type cardFormProps = FormSchemaProps;

const CardFormComponent: React.FC<cardFormProps> = (props) => {
  return (
    <Card>
      <CardContent>
        <FormSchema {...props} />
      </CardContent>
    </Card>
  );
};

export default CardFormComponent;
