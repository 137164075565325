import React, { useEffect, Fragment, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Box, IconButton } from '@material-ui/core';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';
import CardTitle from '@backoffice/private/components/Card/CardTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useFormikContext, Formik, Field } from 'formik';
import { flow, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField as TextFieldFormik } from 'formik-material-ui';
import { useHistory } from 'react-router-dom';

import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';
import Accordion from '@backoffice/private/components/Accordion/Accordion.component';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useCrudNotification from '@backoffice/shared/hooks/useCrudNotification.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/parameters/creditorParameters.duck';

import * as types from '@backoffice/private/ducks/domains/types.duck';
import * as creditor from '@backoffice/private/ducks/creditors/creditor.duck';
import Loading from '@backoffice/private/components/Loading/Loading.component';

import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import ExportXLS from '@backoffice/private/components/Export/Export.component';

const validationSchema = Yup.object({
  parameters: Yup.array()
    .of(
      Yup.object({
        creditor_id: Yup.string().default(''),
        description: Yup.string().default(''),
        parameter_key: Yup.string().default(''),
        parameter_value: Yup.string().default(''),
      })
    )
    .default([]),
});

const inputGroups = [
  'Parâmetros de Faturamento',
  'Parâmetros de Contrato',
  'Parâmetros de Integração',
  'Parâmetros de Motor',
];

const FormCreditorParamContainer = () => {
  const [exportData, setExportData] = useState([]);
  const [fieldsPerGroup, setFieldsPerGroup] = useState<any>([]);
  const resultsRef = useRef([]);

  const history = useHistory();

  const dispatch = useDispatch();

  const { values, errors, setValues } = useFormikContext();

  const dispatchRedux = {
    CREATE: flow(Dispatchs.create, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET_NOTIFICATION: flow(Dispatchs.reset_notification, dispatch),
  };

  const dispatchTypesRedux = {
    GET: flow(types.Dispatchs.get, dispatch),
  };

  const selectorRedux = {
    LOADING: useSelector(Selectors.loading),
    DATA: useSelector(Selectors.data),
    FETCHED: useSelector(Selectors.fetched),
    NOTIFICATION: useSelector(Selectors.notification),
  };

  const selectorTypesRedux = {
    DATA: useSelector(types.Selectors.data),
    FETCHED: useSelector(types.Selectors.fetched),
  };

  const selectorCreditorRedux = {
    DATA: useSelector(creditor.Selectors.data),
  };

  const handleBack = () => history.goBack();

  useEffect(() => {
    dispatchRedux.GET(null);

    dispatchTypesRedux.GET();

    return () => {
      dispatchRedux.RESET();
    };
  }, []);

  useEffect(() => {
    if (!selectorRedux.FETCHED || !selectorTypesRedux.FETCHED) {
      return undefined;
    }

    const data = selectorRedux.DATA;

    const { id } = selectorCreditorRedux.DATA;

    const results = selectorTypesRedux?.DATA || [];

    const resultsManipuled = results.reduce(
      (acc, curr) => [
        ...acc,
        {
          creditor_id: id,
          description: '',
          parameter_key: curr.key,
          parameter_value: data?.results?.find(
            (r) => r.parameter_key === curr.key
          )?.parameter_value,
        },
      ],
      []
    );

    resultsRef.current = resultsManipuled;

    setValues({ parameters: resultsManipuled });
  }, [selectorRedux.FETCHED, selectorTypesRedux.FETCHED]);

  useEffect(() => {
    if (selectorTypesRedux?.DATA) handleInputGroups(selectorTypesRedux?.DATA);
  }, [selectorTypesRedux?.DATA]);

  useCrudNotification({
    selectorRedux,
    dispatchRedux,
  });

  const isValuesEquals = isEqual(
    resultsRef.current,
    (values as any).parameters
  );

  const handleExport = () => {
    const parameters = (values as any)?.parameters;

    const filteredObject = parameters.reduce((result, item) => {
      result[item.parameter_key] = item.parameter_value;
      return result;
    }, {});

    const newArray: any = [filteredObject];

    setExportData(newArray);

    setTimeout(() => {
      const exportButton = document.querySelector(
        '.button-xls-export'
      ) as HTMLElement;

      if (exportButton) {
        exportButton.click();
      }
    });
  };

  const handleInputGroups = (data) => {
    const groupData = data.reduce((acc, obj) => {
      const group = obj.group;
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(obj);
      return acc;
    }, {});

    const result = Object.values(groupData);

    setFieldsPerGroup(result);
  };
  return (
    <FormCreditorParamComponent
      fieldsPerGroup={fieldsPerGroup}
      values={(values as any)?.parameters || []}
      loading={selectorRedux.LOADING}
      handleExport={handleExport}
      errorsForm={errors}
      isValuesEquals={isValuesEquals}
      handleBack={handleBack}
      handleSubmit={() => dispatchRedux.CREATE(values)}
      exportData={exportData}
    />
  );
};

const FormCreditorParamComponent = ({
  loading,
  handleSubmit,
  errorsForm,
  handleBack,
  values,
  fieldsPerGroup,
  isValuesEquals,
  handleExport,
  exportData,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_fornecedor_parametros',
  });

  const renderInputByName = (inputs) => {
    return values.map((v, index) => {
      const input = inputs?.find((d) => d.key === v.parameter_key);

      const isSelectInput = input?.type === 'List';

      if (isSelectInput && input) {
        const selectInput = (
          <Field
            key={v.parameter_key}
            component={TextFieldFormik}
            name={`parameters.[${index}].parameter_value`}
            label={input?.label}
            fullWidth
            select
            variant="outlined"
            disabled={!permissions?.some((item) => item == 'edit')}
          >
            {input?.values?.map((va) => (
              <MenuItem value={va.value} key={va.value}>
                {va.description}
              </MenuItem>
            ))}
          </Field>
        );

        return selectInput;
      }
      if (input) {
        return (
          <Field
            key={v.parameter_key}
            component={TextFieldFormik}
            name={`parameters.[${index}].parameter_value`}
            label={input?.label}
            fullWidth
            variant="outlined"
            disabled={!permissions?.some((item) => item == 'edit')}
          />
        );
      }
    });
  };

  return (
    <>
      {permissions?.some((item) => item == 'read') && (
        <Box display="flex" flexDirection="column" gridGap={24}>
          <Box gridGap={8} display="flex" flexDirection="column">
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <CardTitle>Parâmetros do fornecedor</CardTitle>
              <Tooltip title="Baixar parâmetros">
                <IconButton onClick={handleExport} size="small">
                  <GetAppIcon css={{ width: 18, height: 18 }} />
                </IconButton>
              </Tooltip>
            </Box>

            <Card>
              <CardContent>
                {fieldsPerGroup.map((inputs, index) => {
                  return (
                    <Accordion
                      title={inputGroups[index]}
                      key={index}
                      isExpanded={false}
                    >
                      <Box
                        display="grid"
                        gridTemplateColumns={'1fr 1fr'}
                        gridGap={24}
                      >
                        {renderInputByName(inputs)}
                      </Box>
                    </Accordion>
                  );
                })}
              </CardContent>
            </Card>
          </Box>

          <CardFormFooter
            hasError={Object.keys(errorsForm).length > 0 || isValuesEquals}
            onSubmit={handleSubmit}
            onCancel={handleBack}
            disabled={
              !permissions?.some((item) => item == 'create') ||
              !permissions?.some((item) => item == 'edit')
            }
            loading={loading}
          />

          {loading && <Loading />}
          <ExportXLS filename={'parameters'} data={exportData} />
        </Box>
      )}
      {!permissions && <ModuleNotPermited />}
    </>
  );
};
export default function FormCreditorParamFormik() {
  return (
    <Formik
      initialValues={{ ...validationSchema.cast() }}
      onSubmit={() => {}}
      validateOnMount
      validationSchema={validationSchema}
    >
      <FormCreditorParamContainer />
    </Formik>
  );
}
