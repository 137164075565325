import React from 'react';

import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const normalizeOrigin = {
  1: 'Portal',
  2: 'Backoffice',
  3: 'Api',
  4: 'HotSite',
};

const normalizeOnboardingType = {
  1: 'Completo',
  2: 'Simplificado',
  3: 'Integrado',
};

type Props = {
  data: Array<string | number>;
};

const MerchantsExport = ({ data }: Props) => {
  function handleFilterActive(value) {
    if (value) {
      return 'Sim';
    } else {
      return 'Não';
    }
  }

  function handleFilterMerchantData(data) {
    const filteredData: any = [];
    data.forEach((item) => {
      const newData = {
        id: item.id,
        creditor: item.creditor?.company_name,
        partnership: item.partnership,
        type: item.type,
        company_name: item.company_name,
        trading_name: item.trading_name,
        document_number: item.document_number,
        address: item.address,
        address_number: item.address_number,
        address_complement: item.address_complement,
        address_neighborhood: item.address_neighborhood,
        address_city: item.address_city,
        address_state: item.address_state,
        address_zip_code: item.address_zip_code,
        active: handleFilterActive(item.active),
        created_at: moment(item.created_at).format('DD/MM/YYYY'),
        account_status: item.account.account_status.description,
        origin: normalizeOrigin[item.account.origin],
        onboarding_type: normalizeOnboardingType[item.account.onboarding_type],
        full_api: item.full_api,
        contact_name: item.contact_name,
        phone: item.phone_number,
        account_id: item.account_id,
        user_id: item.account.user_id,
      };

      filteredData.push(newData);
    });

    return filteredData;
  }
  return (
    <ExcelFile
      filename={'estabelecimentos'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleFilterMerchantData(data)} name="Estabelecimentos">
        <ExcelColumn label="CNPJ/CPF" value="document_number" />
        <ExcelColumn label="RAZÃO SOCIAL" value="company_name" />
        <ExcelColumn label="NOME FANTASIA" value="trading_name" />
        <ExcelColumn label="ID" value="id" />
        <ExcelColumn label="TIPO PESSOA" value="type" />
        <ExcelColumn label="NOME CONTATO" value="contact_name" />
        <ExcelColumn label="TELEFONE CELULAR" value="phone" />
        <ExcelColumn label="ENDEREÇO" value="address" />
        <ExcelColumn label="NÚMERO" value="address_number" />
        <ExcelColumn label="COMPLEMENTO" value="address_complement" />
        <ExcelColumn label="BAIRRO" value="address_neighborhood" />
        <ExcelColumn label="CIDADE" value="address_city" />
        <ExcelColumn label="ESTADO" value="address_state" />
        <ExcelColumn label="CEP" value="address_zip_code" />
        <ExcelColumn label="ATIVO?" value="active" />
        <ExcelColumn label="DATA DO CADASTRO" value="created_at" />
        <ExcelColumn label="STATUS DO CADASTRO" value="account_status" />
        <ExcelColumn label="ACCOUNT_ID" value="account_id" />
        <ExcelColumn label="USER_ID" value="user_id" />
        <ExcelColumn label="FORNECEDOR" value="creditor" />
        <ExcelColumn label="FULL_API" value="full_api" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default MerchantsExport;
