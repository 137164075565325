import React, { useState } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

import Button from '@backoffice/shared/components/Button.component';

import CardTable from '@backoffice/private/components/Card/CardTable';

const TableMerchantDocumentHistoricComponent = ({
  loading,
  results,
  handleSeeDocument,
  handleCompareDocuments,
}) => {
  const [documents, setDocuments] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const handleNotification = useNotification();

  const handleSetDocuments = (e, obj) => {
    const idOfClickedRow = e.target.attributes.id.value;
    if (selectedRows.includes(idOfClickedRow)) {
      const newDocuments = documents.filter(
        (item) => item.id !== idOfClickedRow
      );
      const newSelectedRows = selectedRows.filter(
        (item) => item !== idOfClickedRow
      );
      setSelectedRows(newSelectedRows);
      setDocuments(newDocuments);
    } else {
      setSelectedRows((prevState) => [...prevState, idOfClickedRow]);
      setDocuments((prevState) => [
        ...prevState,
        { id: obj.id, file: obj.file_path, date: obj.created_at },
      ]);
    }
  };

  const columns = [
    {
      width: 40,
      title: 'Comparar',
      render: (obj) => (
        <Box display="flex">
          <Checkbox
            id={obj.id}
            checked={selectedRows.includes(obj.id)}
            onChange={(e) => handleSetDocuments(e, obj)}
            disabled={
              selectedRows.includes(obj.id) || documents.length < 2
                ? false
                : true
            }
          />
        </Box>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'file_info',
      key: 'file_info',
    },
    {
      title: 'Status',
      dataIndex: 'status_description',
      key: 'status_description',
    },
    {
      title: 'Justificativa',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Data upload',
      dataIndex: 'created_at',
      key: 'created_at',
      render: ({ created_at }) =>
        created_at ? moment(created_at).format('DD/MM/YYYY') : '',
    },
    {
      title: 'Data alteração status',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: ({ updated_at }) =>
        updated_at ? moment(updated_at).format('DD/MM/YYYY') : '',
    },
    {
      width: 60,
      title: 'Visualizar Documento',
      render: (obj) => (
        <Box display="flex">
          <Button
            color="primary"
            variant="contained"
            aria-label="show document"
            loading={loading}
            onClick={() => {
              if (obj.file_path) {
                if (obj.file_path.includes('.pdf')) {
                  window.open(obj.file_path);
                } else {
                  handleSeeDocument(obj.file_path);
                }
              } else {
                handleNotification(
                  'Identificação do Representante Legal via Inovamind',
                  'error'
                );
              }
            }}
          >
            Visualizar
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box gridGap={8} display="flex" flexDirection="column" flex={1}>
      <CardTable
        count={results.length || 0}
        loading={loading}
        dataSource={results}
        rowsPerPage={5}
        columns={columns}
        page={0 / 5}
      />
      <Button
        color="primary"
        variant="contained"
        disabled={documents.length < 2}
        aria-label="compare"
        onClick={() => handleCompareDocuments(documents[0], documents[1])}
      >
        Comparar
      </Button>
    </Box>
  );
};

export default TableMerchantDocumentHistoricComponent;
