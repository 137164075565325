import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';

import {
  Dispatchs as DispatchsAcquirers,
  Selectors as SelectorsAcquirers,
} from '@backoffice/private/ducks/parameters/acquirers/listAcquirers.duck';

import {
  Dispatchs as DispatchsArrangements,
  Selectors as SelectorsArrangements,
} from '@backoffice/private/ducks/parameters/arrangements/listArrangements.duck';

import { Parameter } from '../@types/models/Parameter';
import { Result } from '../@types/models/Result';
import toFormatAlphabetic from '@backoffice/shared/utils/toFormatAlphabetic.util';

type GenericObject = { [key: string]: any };

type ResultParameters = Result & {
  results: Parameter[];
};

export default function useAcquirersAndArrangements() {
  const [arrangements, setArrangements] = useState<GenericObject>({});
  const [acquirers, setAcquirers] = useState<GenericObject>({});

  const dispatch = useDispatch();

  const dispatchAcquirers = {
    UPDATE_FILTERS: flow(DispatchsAcquirers.updateFilters, dispatch),
  };

  const dispatchArrangements = {
    UPDATE_FILTERS: flow(DispatchsArrangements.updateFilters, dispatch),
  };

  const handleGetArrangementsAndAcquirers = async () => {
    const resultAcquirers: any = await dispatchAcquirers.UPDATE_FILTERS({
      limit: 2000,
      active: true,
    });
    const resultArrangements: any = await dispatchArrangements.UPDATE_FILTERS({
      limit: 2000,
      active: true,
    });

    if (resultAcquirers) {
      const data = resultAcquirers?.results;

      const acquirersOptions = data
        ?.filter((b) => b.active)
        .map((b) => ({
          label: b?.acquirer_name,
          value: b?.document_number,
        }));

      const acquirersFormated = toFormatAlphabetic(acquirersOptions);

      setAcquirers(acquirersFormated);
    }

    if (resultArrangements) {
      const data = resultArrangements?.results;

      const arrangementsOptions = data
        ?.filter((b) => b.active)
        .map((b) => ({
          label: `${b?.payment_arrangement} - ${b?.brand_name} ${
            b?.type === 'credit' ? 'Crédito' : 'Débito'
          }`,
          value: b?.payment_arrangement,
        }));

      const arrangementsFormated = toFormatAlphabetic(arrangementsOptions);

      setArrangements(arrangementsFormated);
    }
  };

  return { acquirers, arrangements, handleGetArrangementsAndAcquirers };
}
