import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField as TextFieldMaterial } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { v4 } from 'uuid';
import { Autocomplete } from 'formik-material-ui-lab';

import toFormatAlphabetic from '@backoffice/shared/utils/toFormatAlphabetic.util';

import CardSearch from '@backoffice/private/components/Card/CardSearch';

export const SearchMerchantsComponent = ({
  creditors,
  isAdmin,
  handleSearchReset,
  loading,
}) => {
  const [key, setKey] = useState(v4());

  const { submitForm, resetForm }: any = useFormikContext();

  const arrToFormat = creditors;

  const arr = arrToFormat.reduce(
    (acc, curr) => [
      ...acc,
      {
        label: curr.trading_name,
        value: curr.id,
      },
    ],
    []
  );

  const arrFiltered = toFormatAlphabetic(arr);

  const schemaSearch = [
    [
      isAdmin &&
        arrFiltered.length > 0 && {
          type: 'custom',
          component: (
            <Field
              component={Autocomplete}
              name="creditor_id"
              options={arrFiltered.map((a: any) => a.value)}
              getOptionLabel={(option: any) => {
                const currentBank = arrFiltered?.find(
                  (b) => b.value === option
                );

                if (!currentBank?.label) {
                  return '';
                }

                return currentBank?.label;
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  label="Fornecedor"
                  variant="outlined"
                />
              )}
            />
          ),
        },
      {
        name: 'name',
        label: 'Nome Fantasia ou Razão Social',
      },
      {
        name: 'document',
        label: 'CNPJ/CPF',
      },
      {
        label: 'Visualizar ECs parceiros IF',
        name: 'view_full_api',
        type: 'custom',
        component: (
          <Field name="view_full_api">
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }: any) => (
              <FormControlLabel
                key={field.value}
                label={'Visualizar ECs parceiros IF'}
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={({ target: { checked } }) => {
                      setFieldValue(field.name, checked);
                    }}
                  />
                }
              />
            )}
          </Field>
        ),
      },
    ],
  ];

  return (
    <CardSearch
      formik
      key={key}
      fields={schemaSearch as any}
      disabled={loading}
      onSubmit={submitForm}
      onReset={() =>
        handleSearchReset()(() => {
          setKey(v4());

          resetForm();
        })
      }
    />
  );
};
