import { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import CSVReader from 'react-csv-reader';
import { v4 } from 'uuid';
import { CSVLink } from 'react-csv';

import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { TextField, Grid } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import fetch from '@payhop/shared-utils/fetch.util';

import Button from '@backoffice/shared/components/Button.component';
import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import CardTable from '@backoffice/private/components/Card/CardTable';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useListCreditorsHook from '../../importAppointments/hooks/useListCreditors.hook';

import patchDealDomicile from '@backoffice/private/services/deal/patchDealDomicile.service';

const papaparseOptions = {
  encoding: 'UTF-8',
  header: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

interface fileIF {
  cnpj___cpf: string;
  error?: string;
}

const ChangeDealsScreen = () => {
  const csvLinkEl = useRef(null);
  const { isAdmin, user } = useUserHook();
  const handleNotification = useNotification();

  const creditors = useListCreditorsHook();

  const [key, setKey] = useState(v4());
  const [loading, setLoading] = useState(false);
  const [dataFile, setDataFile] = useState<any>([]);
  const [file, setFile] = useState<any>(null);
  const [resultFile, setResultFile] = useState({});
  const [data, setData] = useState<any>([]);
  const [dataImport, setDataImport] = useState<any>([]);

  const handleForce = (data, fileInfo, originalFile) => {
    setDataImport(data);
    setDataFile(data);
    setFile(originalFile);
  };

  const handleClickSelectFile = () => {
    const obj = document.querySelector('.csv-input');

    (obj as any).click();
  };

  const handleReset = () => {
    setDataFile([]);
    setFile({});
    setKey(v4());
    setResultFile({});
  };

  const handleScrollTable = () => {
    const table = document.querySelector('.cardTable .MuiTableContainer-root');

    if (table) {
      (table as any).scrollLeft = 999999;
    }
  };

  const handleProcess = async () => {
    setLoading(true);
    const dataArr = {
      domicilios: dataFile,
    };

    const response = await patchDealDomicile(dataArr);

    const result = await response.json();

    setLoading(false);

    if (response.ok) {
      //Filtrando linhas com erros
      const errors = result
        .filter((obj) => !obj.success)
        .map((obj) => ({
          ...obj,
          error: obj.message,
          success: undefined,
          message: undefined,
        }));

      const shouldHaveErrors = errors.length > 0;

      if (!shouldHaveErrors) {
        handleNotification('Arquivo importado com sucesso!', 'success');
        handleReset();
      }

      if (shouldHaveErrors) {
        handleNotification(
          'Ocorreu um erro ao importar um ou mais registros. Verifique a coluna de erro.',
          'error'
        );

        setDataFile(errors);
        setFile(null);
        setKey(v4());
        handleScrollTable();
      }

      setResultFile(result);
    } else {
      handleNotification('Não foi possivel atualizar os contratos', 'error');
    }
  };

  const handleOnExport = () => {
    setData([
      {
        id_compromisso: '#########',
        id_domicilio: '#########',
      },
    ]);

    setTimeout(() => {
      (csvLinkEl as any).current.link.click();
    });
  };

  const columns = [
    {
      dataIndex: `id_compromisso`,
      key: 'id_compromisso',
      title: 'Id Compromisso',
    },
    {
      dataIndex: 'id_domicilio',
      key: 'id_domicilio',
      title: 'Id Domicílio',
    },
    {
      dataIndex: 'error',
      key: 'error',
      title: 'Erro',
      render: ({ error }) => (
        <HtmlTooltip
          title={
            <Typography
              style={{
                whiteSpace: 'pre-wrap',
              }}
            >
              {error}
            </Typography>
          }
        >
          <div>
            {error && error.slice(0, 35) + (error.length > 35 ? '...' : '')}
          </div>
        </HtmlTooltip>
      ),
    },
  ];

  const hasFile = !!file;

  const { permissions } = useAccountPermission({
    permission_slug: 'importacao_alterar_contrato',
  });

  return (
    <Fragment key={key}>
      <PageLayout headerTitle header sidebar title="Alterar Contratos">
        {permissions?.some((item) => item == 'create') && (
          <Box display="flex" flexDirection="column" gridGap={32}>
            <Card>
              <CardContent>
                <Box display="flex" gridGap={24} alignItems="center">
                  <Box flex={1} display="flex" gridColumnGap={10}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClickSelectFile}
                    >
                      Selecionar arquivo
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleOnExport}
                    >
                      Baixar Modelo
                    </Button>
                    <Box display="none">
                      <CSVReader
                        cssClass="react-csv-input"
                        label="Selecionar arquivo"
                        onFileLoaded={handleForce}
                        parserOptions={papaparseOptions}
                      />
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            <div className="cardTable">
              <CardTable
                dataSource={dataFile}
                columns={columns}
                footer={false}
                totallines={(resultFile as any)?.status?.totalLines}
                totalerror={(resultFile as any)?.status?.totalError}
                onExport={async () => dataFile}
              />
            </div>
            <Card>
              <Box component={CardActions} justifyContent="flex-end" mx={1}>
                <Button color="default" variant="text" onClick={handleReset}>
                  Limpar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  aria-label="show more"
                  onClick={handleProcess}
                  loading={loading}
                  disabled={loading || !hasFile}
                >
                  Processar dados
                </Button>
              </Box>
            </Card>
          </Box>
        )}

        {!permissions ||
          (!permissions?.some((item) => item == 'create') && (
            <ModuleNotPermited />
          ))}

        <CSVLink
          filename="modela_alteracao_contrato.csv"
          data={data}
          ref={csvLinkEl}
          separator={';'}
        />
      </PageLayout>
    </Fragment>
  );
};

export default ChangeDealsScreen;
