import { Dispatch } from 'redux';
import flow from 'lodash/flow';
import get from 'lodash/get';

import { setError } from '@payhop/shared-ducks/error.duck';

import fetch from '@payhop/shared-utils/fetch.util';

const context = `private/deals/deal`;
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
  error?: any;
  filters?: any;
};

export const Types = {
  INIT: `${context}/INIT`,
  SUCCESS: `${context}/SUCCESS`,
  RESET: `${context}/RESET`,
  ERROR: `${context}/ERROR`,
  RESET_NOTIFICATION: `${context}/RESET/NOTIFICATION`,
};

const initialState: any = {
  data: null,
  loading: false,
  error: null,
  fetched: false,
  notification: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, filters } = action;

  const reducers = {
    [Types.RESET]: {
      ...initialState,
    },
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
      error: false,
      data: payload,
      fetched: true,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error: true,
    },
    [Types.RESET_NOTIFICATION]: {
      ...state,
      notification: initialState.notification,
    },
  };

  return reducers[type] || state;
};

export const getDispatch =
  (idDeal) => async (dispatch: Dispatch<any>, getState) => {
    const url = ['deals', `/${idDeal}`].join('');

    try {
      dispatch({
        type: Types.INIT,
      });

      const response = await fetch(url, {
        method: 'GET',
        ms: 'DEAL',
        auth: true,
      });

      const result = await response.json();

      if (!response.ok) {
        throw result;
      }

      dispatch({
        type: Types.SUCCESS,
        payload: result,
      });

      return result;
    } catch (result: any) {
      dispatch({
        type: Types.ERROR,
        payload: {
          successAction: false,
          ...result,
        },
      });

      dispatch(
        setError({
          messages: result?.errors?.message,
          data: result,
          visible: false,
          context,
        })
      );

      return false;
    }
  };
export const createDispatch = (data) => async (dispatch) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(`deals`, {
      method: 'POST',
      ms: 'DEAL',
      auth: true,
      body: JSON.stringify(data),
    });
    const result = await response.json();

    if (!response.ok) {
      dispatch({
        type: Types.SUCCESS,
        payload: {
          successAction: false,
          ...result,
        },
      });
      return false;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: {
        successAction: true,
        ...result,
      },
    });
    return result;
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
      payload: {
        successAction: false,
        ...result,
      },
    });

    dispatch(
      setError({
        messages: result?.errors?.message,
        data: result,
        visible: false,
        context,
      })
    );

    return false;
  }
};
export const updateDispatch = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(`deals/${id}`, {
      method: 'PATCH',
      ms: 'DEAL',
      auth: true,
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: {
        successAction: true,
      },
    });

    return result;
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
      payload: {
        successAction: false,
        ...result,
      },
    });

    dispatch(
      setError({
        messages: result?.errors?.message,
        data: result,
        visible: false,
        context,
      })
    );

    return false;
  }
};

export const resetDispatch = () => ({
  type: Types.RESET,
});

export const resetNotificationDispatch = () => ({
  type: Types.RESET_NOTIFICATION,
});

const selectRoot = (state: any) => get(state, rootPathState);
const selectData = (state: any) => get(state, 'data');
const selectLoading = (state: any) => get(state, 'loading');
const selectFetched = (state: any) => get(state, 'fetched');
const selectError = (state: any) => get(state, 'error');
const selectNotification = (state: any) => get(state, 'notification');

export const Selectors = {
  data: flow(selectRoot, selectData),
  loading: flow(selectRoot, selectLoading),
  fetched: flow(selectRoot, selectFetched),
  error: flow(selectRoot, selectError),
  notification: flow(selectRoot, selectNotification),
};

export const Dispatchs = {
  get: getDispatch,
  reset: resetDispatch,
  create: createDispatch,
  update: updateDispatch,
  reset_notification: resetNotificationDispatch,
};
