import fetch from '@payhop/shared-utils/fetch.util';

type filters = {
  name?: string;
  document?: string;
  creditor_id?: string;
};

export default async function getExportMerchantsService(
  account_id: string,
  full_api: boolean,
  filters: filters
) {
  const queryString = Object.keys(filters)
    .map((key) => {
      if (filters[key] !== '' && filters[key]) {
        return `${key}=${filters[key]}`;
      }
    })
    .filter((item) => item !== undefined)
    .join('&');

  await fetch(
    `merchant?account_id=${account_id}&view_full_api=${full_api}&${queryString}`,
    {
      method: 'GET',
      ms: 'EXPORT',
      auth: true,
    }
  );

  return true;
}
