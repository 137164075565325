import { Dispatch } from 'redux';
import qs from 'qs';
import flow from 'lodash/flow';
import get from 'lodash/get';

import { setError } from '@payhop/shared-ducks/error.duck';

import fetch from '@payhop/shared-utils/fetch.util';

const context = `private/profiles/permissions`;
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
  error?: any;
  filters?: any;
};

export const Types = {
  INIT: `${context}/INIT`,
  SUCCESS: `${context}/SUCCESS`,
  RESET: `${context}/RESET`,
  ERROR: `${context}/ERROR`,
};

const initialState: any = {
  data: null,
  loading: false,
  error: null,
  fetched: false,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, filters } = action;

  const reducers = {
    [Types.RESET]: {
      ...initialState,
    },
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
      error: false,
      data: payload,
      fetched: true,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error: true,
    },
  };

  return reducers[type] || state;
};

export const getDispatch =
  ({ type }) =>
  async (dispatch: Dispatch<any>, getState) => {
    try {
      dispatch({
        type: Types.INIT,
      });

      const response = await fetch(
        `domains/user-resource?limit=200&type=${type}`,
        {
          method: 'GET',
          ms: 'DOMAIN',
          auth: true,
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw result;
      }

      dispatch({
        type: Types.SUCCESS,
        payload: result,
      });

      return result;
    } catch (result: any) {
      dispatch({
        type: Types.ERROR,
        payload: {
          successAction: false,
          ...result,
        },
      });

      dispatch(
        setError({
          messages: result?.errors?.message,
          data: result,
          visible: false,
          context,
        })
      );

      return false;
    }
  };

export const resetDispatch = () => ({
  type: Types.RESET,
});

const selectRoot = (state: any) => get(state, rootPathState);
const selectData = (state: any) => get(state, 'data');
const selectLoading = (state: any) => get(state, 'loading');
const selectFetched = (state: any) => get(state, 'fetched');
const selectError = (state: any) => get(state, 'error');

export const Selectors = {
  data: flow(selectRoot, selectData),
  loading: flow(selectRoot, selectLoading),
  fetched: flow(selectRoot, selectFetched),
  error: flow(selectRoot, selectError),
};

export const Dispatchs = {
  get: getDispatch,
  reset: resetDispatch,
};
