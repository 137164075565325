import { flatten } from 'lodash';

const schemaSearchFormik = (schema) =>
  flatten(schema).reduce(
    (acc: any, curr: any) => ({
      ...acc,
      [curr.name]: '',
    }),
    {}
  );

export default schemaSearchFormik;
