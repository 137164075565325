import { Dispatch } from 'redux';
import decode from 'jwt-decode';
import flow from 'lodash/flow';
import get from 'lodash/get';

type Actions = {
  type: string;
  payload?: {
    message?: string;
    variant: 'success' | 'error' | 'info' | 'warning' | 'default';
    configs?: {
      [key: string]: any;
    };
  };
};

export const Types = {
  SHOW: 'shared/toaster/SHOW',
  RESET: 'shared/toaster/RESET',
};

const initialState: any = {
  message: null,
  variant: null,
  configs: {},
};

export default (state = initialState, action: Actions) => {
  const { type, payload } = action;

  const reducers = {
    [Types.SHOW]: {
      message:
        payload?.message || 'Aconteceu um erro, tente novamente mais tarde',
      variant: payload?.variant,
      configs: payload?.configs,
    },
    [Types.RESET]: {
      ...initialState,
    },
  };

  return reducers[type] || state;
};

export const showDispatch = (payload: any) => ({
  type: Types.SHOW,
  payload,
});

export const resetDispatch = () => ({
  type: Types.RESET,
});

const selectRoot = (state: any) => get(state, 'shared.toaster');
const selectMessage = (state: any) => get(state, 'message');
const selectVariant = (state: any) => get(state, 'variant');
const selectConfigs = (state: any) => get(state, 'configs');

export const Selectors = {
  message: flow(selectRoot, selectMessage),
  variant: flow(selectRoot, selectVariant),
  configs: flow(selectRoot, selectConfigs),
};
