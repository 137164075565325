import React, { useEffect, useRef, useState } from 'react';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Box, IconButton } from '@material-ui/core';

import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';
import Button from '@backoffice/shared/components/Button.component';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import getMerchantAcquirers from '@backoffice/private/services/merchant/getMerchantAcquirers.service';
import getMerchantDiscovery from '@backoffice/private/services/merchant/getMerchantDiscovery.service';

import { Selectors } from '@backoffice/private/ducks/merchants/merchant.duck';

import * as dialogMerchantSeeDiscoveryArrangments from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantSeeDiscoveryArrangments.duck';
import NumberFormat from 'react-number-format';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DialogSeeArrangmentsDiscovery from '../Dialog/DialogMerchantArrangmentsDiscovery.component';
import TypographyComponent from '@backoffice/shared/components/Typography.component';
import moment from 'moment';

const TableMerchantAcquirerDiscoveryComponent = () => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_estabelecimento_credenciadoras',
  });
  const handleNotification = useNotification();
  const [discoveryAcquirers, setDiscoveryAcquirers] = useState([]);
  const [buttonDiscoveryDisabled, setButtonDiscoveryDisabled] =
    useState<boolean>(false);
  const dispatch = useDispatch();

  const handleGetDiscoveryAcquirers = async (merchantId) => {
    const { results } = await getMerchantAcquirers(merchantId);
    handleParseAcquirersAndArrangements(results);
  };

  const handleParseAcquirersAndArrangements = (data) => {
    const arrangments = data.map((item) => {
      return {
        name: `${item.arrangement_code} - ${item.arrangement_name}`,
        arrangement_code: item.arrangement_code,
        acquirer_name: item.acquirer_name,
        acquirer_document: item.acquirer_document,
        updated_at: item.updated_at,
      };
    });

    const dataParsed = arrangments.reduce((acc, curr) => {
      const {
        acquirer_name,
        acquirer_document,
        arrangement_code,
        name,
        updated_at,
      } = curr;

      const existingGroup = acc.find((group) => group.name === acquirer_name);

      if (existingGroup) {
        existingGroup.arrangements.push({ arrangement_code, name });
      } else {
        acc.push({
          name: acquirer_name,
          document_number: acquirer_document,
          updated_at,
          arrangements: [{ arrangement_code, name }],
        });
      }

      return acc;
    }, []);

    setDiscoveryAcquirers(dataParsed);
  };

  const handleGetMerchantDiscovery = async () => {
    if (!selectorRedux.DATA.optin_status) {
      handleNotification(
        'Discovery não permitido! Estabelecimento sem opt-in ativo!',
        'error'
      );
      return;
    }

    const documentNumberMerchant = selectorRedux.DATA.document_number;
    const creditorId = selectorRedux.DATA.creditor_id;
    const response = await getMerchantDiscovery(
      documentNumberMerchant,
      creditorId
    );

    if (response) {
      handleNotification(
        'Discovery esta sendo realizado, aguarde alguns minutos',
        'success'
      );
    }
    setButtonDiscoveryDisabled(true);
  };

  const dispatchRedux = {
    OPEN: flow(dialogMerchantSeeDiscoveryArrangments.Dispatchs.open, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
  };

  useEffect(() => {
    const documentNumberMerchant = selectorRedux.DATA.document_number;
    handleGetDiscoveryAcquirers(documentNumberMerchant);
  }, []);

  const columns = [
    {
      width: 10,
      title: '',
      render: (obj) => (
        <Box width={10} display="flex">
          <IconButton onClick={() => dispatchRedux.OPEN(obj)}>
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'CNPJ Credenciadora',
      dataIndex: 'document_number',
      key: 'document_number',
      render: ({ document_number }) => (
        <NumberFormat
          displayType="text"
          value={document_number}
          isNumericString
          format="##.###.###/####-##"
        />
      ),
    },
    {
      title: 'Data/Horário',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: ({ updated_at }) =>
        moment(updated_at).subtract(3, 'hours').format('DD/MM/YYYY HH:mm'),
    },
  ];

  return (
    <>
      {permissions?.some((item) => item == 'read') && (
        <Box gridGap={8} display="flex" flexDirection="column">
          <Box display={'flex'} justifyContent="space-between">
            <CardTitle>Listagem de vínculos</CardTitle>
            {permissions?.some((item) => item == 'create') && (
              <Button
                color="primary"
                variant="contained"
                aria-label="show more"
                disabled={buttonDiscoveryDisabled}
                onClick={() => handleGetMerchantDiscovery()}
              >
                Discovery
              </Button>
            )}
          </Box>
          {discoveryAcquirers.length > 0 && (
            <CardTable
              dataSource={discoveryAcquirers}
              rowsPerPage={10}
              columns={columns as any}
              footer={false}
            />
          )}

          {discoveryAcquirers.length == 0 && (
            <TypographyComponent
              customVariant="text__body--lg"
              customColor="info-500"
            >
              Nenhuma credenciadora encontrada
            </TypographyComponent>
          )}
        </Box>
      )}

      {(!permissions || !permissions?.some((item) => item == 'read')) && (
        <ModuleNotPermited />
      )}

      <DialogSeeArrangmentsDiscovery />
    </>
  );
};

export default TableMerchantAcquirerDiscoveryComponent;
