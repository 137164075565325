export const FONT_FAMILY = {
  OpenSans: "'Open Sans', sans-serif;",
};

export const FONT_STYLES = {
  TEXT_HEADER_LG: {
    fontFamily: FONT_FAMILY.OpenSans,
    fontSize: '62px',
    fontWeight: 700,
    lineHeight: '70px',
  },
  TEXT_HEADER: {
    fontFamily: FONT_FAMILY.OpenSans,
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '54px',
  },
  TEXT_HEADER_SM: {
    fontFamily: FONT_FAMILY.OpenSans,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
  },
  TEXT_BODY_LG: {
    fontFamily: FONT_FAMILY.OpenSans,
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
  },
  TEXT_BODY_MD: {
    fontFamily: FONT_FAMILY.OpenSans,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28px',
  },
  TEXT_BODY: {
    fontFamily: FONT_FAMILY.OpenSans,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  TEXT_BODY_SM: {
    fontFamily: FONT_FAMILY.OpenSans,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
};
