import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import * as listCreditorsDuck from '@backoffice/private/ducks/creditors/listCreditors.duck';

export default function useListCreditorsHook() {
  const [results, setResults] = useState([]);
  const [resultsFiltered, setResultsFiltered] = useState([]);

  const dispatch = useDispatch();

  const handleAlphabetic = (data) => {
    const arrayFiltered = data.sort((a: any, b: any) => {
      const aLabel = a.trading_name;
      const bLabel = b.trading_name;

      const aLabelFiltered = aLabel[0].toUpperCase() + aLabel.substring(1);
      const bLabelFiltered = bLabel[0].toUpperCase() + bLabel.substring(1);

      if (aLabelFiltered > bLabelFiltered) {
        return 1;
      }

      if (aLabelFiltered < bLabelFiltered) {
        return -1;
      }

      return 0;
    });

    setResultsFiltered(arrayFiltered);
  };

  const dispatchRedux = {
    GET: flow(listCreditorsDuck.Dispatchs.get, dispatch),
  };

  useEffect(() => {
    (async () => {
      const result: any = await dispatchRedux.GET({ isExport: true });

      setResults(result?.results || []);
    })();
  }, []);

  useEffect(() => {
    if (results) {
      handleAlphabetic(results);
    }
  }, [results]);

  return resultsFiltered;
}
