/* eslint-disable */

import fetch from '@payhop/shared-utils/fetch.util';

type dataRequest = {
  creditor_id: string;
  file: any;
};

export default async function postUsersUploadMerchantService({
  creditor_id,
  file,
}: dataRequest) {
  let formData = new FormData();

  formData.append('file', file);
  formData.append('creditor_id', creditor_id);

  const response = await fetch(`users/upload-merchant`, {
    method: 'POST',
    ms: 'ACCOUNT',
    defaultHeaders: false,
    auth: true,
    body: formData,
  });

  const result = await response.json();

  return [response.ok, result];
}
