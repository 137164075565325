import styled from '@emotion/styled';

type StepProps = {
  active: boolean;
  done: boolean;
  clickable: boolean;
};

export const StepsContainer = styled.div`
  width: 720px;
`;

export const StepsContent = styled.div`
  display: flex;

  column-gap: 20px;
`;

export const Step = styled.div<StepProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  :hover {
    cursor: ${(props) => (props.clickable ? 'pointer' : 'not-allowed')};
  }

  span {
    background: ${(props) =>
      props.done ? '#000E2B' : props.active ? '#3f51b5' : '#A6A6A6'};
    padding: 6px 10px 4px;
    border-radius: 100%;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 600;
  }

  p {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 2px;
    margin-bottom: 4px;
    color: ${(props) =>
      props.done ? '#000E2B' : props.active ? '#3f51b5' : '#A6A6A6'};
  }

  div {
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: ${(props) =>
      props.done ? '#000E2B' : props.active ? '#3f51b5' : '#A6A6A6'};
  }
`;
