import * as Yup from 'yup';

import validateCNPJ from '@payhop/shared-utils/validateCNPJ.util';
import validateCPF from '@payhop/shared-utils/validateCPF.util';
import messages from '@payhop/shared-utils/messagesSchema.util';

export const createDealSchema = Yup.object({
  document_number: Yup.string()
    .nullable()
    .required(messages.REQUIRED)
    .test('document_number', 'CNPJ ou CPF Inválido', (value) => {
      const documentLength = !!value && String(value).length;

      if (documentLength === 11 && validateCPF(value)) {
        return true;
      }

      if (documentLength === 14 && validateCNPJ(value)) {
        return true;
      }

      return false;
    }),
  name: Yup.string().required(messages.REQUIRED).default(''),
  title: Yup.string().required(messages.REQUIRED).default(''),
  code: Yup.string().required(messages.REQUIRED).default(''),
  description: Yup.string().required(messages.REQUIRED).default(''),
  type: Yup.string().required(messages.REQUIRED).default('1'),
  min_percentage: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .when('type', (type, schema) =>
      schema.test(
        'min_percentage',
        'Percentual não pode ser igual a 0,00, nem maior que 100,00%',
        (val) => {
          const percentual: any = !!val && parseFloat(val);
          if (percentual <= Number(0) || percentual >= Number(100.01))
            return false;
          return true;
        }
      )
    ),
  issue_date: Yup.string().required(messages.REQUIRED).nullable().default(null),
  expiration_date: Yup.date()
    .required(messages.REQUIRED)
    .nullable()
    .default(null),
});
