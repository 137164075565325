import { combineReducers } from 'redux';

type listOfReducersType = {
  name: string;
  path: string;
};

const reducers = {};

const listOfReducers: Array<listOfReducersType> = [
  {
    name: 'dialogUpdate',
    path: 'dialogCreditorUpdate.duck',
  },
  {
    name: 'dialogChangePassword',
    path: 'dialogCreditorChangePassword.duck',
  },
  {
    name: 'dialogCreateOriginator',
    path: 'dialogCreditorCreateOriginator.duck',
  },
  {
    name: 'dialogCreateDomicile',
    path: 'dialogCreditorCreateDomicile.duck',
  },
  {
    name: 'dialogSeeDomiciles',
    path: 'dialogCreditorSeeDomiciles.duck',
  },
];

listOfReducers.map((elm) =>
  Object.assign(reducers, {
    ...reducers,
    [elm.name]: require(`./${elm.path}`).default,
  })
);

export default combineReducers({
  ...reducers,
});
