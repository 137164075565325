import React from 'react';
import Typography from '@backoffice/shared/components/Typography.component';

const ProfilePermissionsItems = ({ title, items, itemType }) => {
  return (
    <div>
      <Typography
        customVariant="text__body--md"
        customColor="info-500"
        css={{ marginBottom: '23px' }}
      >
        {title}
      </Typography>
      {items.map((item: any, index) => {
        return (
          <>
            <Typography
              key={`${index}-${item[itemType]}`}
              css={{ marginBottom: '18px', fontSize: '0.875rem' }}
            >
              {item[itemType] ? item[itemType] : '-'}
            </Typography>
          </>
        );
      })}
    </div>
  );
};

export default ProfilePermissionsItems;
