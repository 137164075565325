import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box } from '@material-ui/core';

import useCreditorParameters from '@backoffice/private/hooks/useCreditorParameters.hook';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

import { PARAMETERS_VALUES } from '@backoffice/private/constants/parameters/parameters_values.contant';
import { PARAMETERS } from '@backoffice/private/constants/parameters/parameters.constant';

import { createDealSchema, createDealSchemaIF } from './CreateFormDeal.schema';
import getMerchantNew from '@backoffice/private/services/merchant/getMerchantNew.service';

import PartnerStep from './Steps/PartnerStep.component';
import ContractDataStep from './Steps/ContractDataStep.component';
import InstallmentsStep from './Steps/InstallmentsStep.component';

import CreateForm from '@backoffice/private/components/Form/CreateForm';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';

import getMerchantAcquirers from '@backoffice/private/services/merchant/getMerchantAcquirers.service';
import Loading from '@backoffice/private/components/Loading/Loading.component';
import createContractSmoke from '../../../services/createContractSmoke.service';

import createContractPerformed from '../../../services/createContractPerformed.service';
import ROUTES from '@backoffice/shared/utils/routes.util';
import { CONTRACT_TYPES_VALUES } from '@backoffice/private/constants/contract/type.constant';
import {
  TRANSACTION_TYPES,
  TRANSACTION_VALUES,
} from '@backoffice/private/constants/contract/transaction.constant';
import uploadTCD from '../../../services/uploadTcd.service';
import useCreditors from '@backoffice/private/hooks/useCreditors.hook';
import { Creditor } from '@backoffice/private/@types/models/Creditor';
import moment from 'moment';

const FormCreateDeal = () => {
  const [creditorId, setCreditorId] = useState<string | null>(null);
  const [selectedMerchant, setSelectedMerchant] = useState<any>();
  const [acquirers, setAcquirers] = useState<any>();
  const [arrangements, setArrangements] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [colateral, setColateral] = useState(false);
  const history = useHistory();
  const [optin, setOptin] = useState(false);
  const creditors: Creditor[] = useCreditors();
  const handleNotification = useNotification();
  const { parameters, handleSetParameters } = useCreditorParameters();

  const handleGetCreditorInfos = (id: string) => {
    setCreditorId(id);

    handleSetParameters(id, [
      PARAMETERS.TIPO_TRANSACAO,
      PARAMETERS.TIPO_CONTRATO,
      PARAMETERS.QUANTIDADE_PARCELAS_COMPROMISSO,
      PARAMETERS.PERFIL_PARCEIRO,
      PARAMETERS.GCAP,
    ]);
  };

  const handleGetMerchant = async (
    documentNumber: string,
    creditor_id?: string
  ) => {
    setIsLoading(true);

    if (documentNumber) {
      const documentCreditor = creditors.find(
        (cr) => cr.id === creditor_id
      )?.document_number;
      const { merchant, optin_status } = await getMerchantNew(
        documentNumber,
        documentCreditor
      );

      if (!optin_status) {
        handleNotification(
          'Compromisso não permitido! Estabelecimento sem opt-in ativo!',
          'error'
        );
        setOptin(false);
        setIsLoading(false);
        return;
      }

      setOptin(true);

      if (merchant) {
        const selectedMerchant = {
          id: merchant.id,
          name: merchant.company_name,
        };

        const { results } = await getMerchantAcquirers(documentNumber);
        if (results.length === 0) {
          handleNotification(
            'Não há credenciadoras vinculadas a este estabelecimento',
            'error'
          );
          setAcquirers([]);
          setArrangements([]);
        } else {
          handleParseAcquirersAndArrangementsPartnerCreditor(results);
        }

        setSelectedMerchant(selectedMerchant);
      } else {
        handleNotification(
          'Não foi encontrado nenhum estabelecimento com esse cnpj',
          'error'
        );
      }
    }
    setIsLoading(false);
  };

  const handleParseAcquirersAndArrangementsPartnerCreditor = (data) => {
    const acquirers = data.map((item) => {
      return {
        label: item.acquirer_name,
        value: item.acquirer_document,
      };
    });
    const arrangments = data.map((item) => {
      return {
        label: `${item.arrangement_code} - ${item.arrangement_name}`,
        value: item.arrangement_code,
      };
    });

    const filteredAcquirers = acquirers.filter(
      (item, index, self) =>
        self.findIndex((i) => JSON.stringify(i) === JSON.stringify(item)) ===
        index
    );
    const filteredArrangements = arrangments.filter(
      (item, index, self) =>
        self.findIndex((i) => JSON.stringify(i) === JSON.stringify(item)) ===
        index
    );

    setAcquirers(filteredAcquirers);
    setArrangements(filteredArrangements);
  };

  const handleParseInstallments = (values) => {
    const { installments, parcels_quantity } = values;

    const parcelsArray = [...Array(parcels_quantity)].map((_, index) => {
      const parcelIndex = index;
      const installment = {
        amount: installments?.[`amount_${parcelIndex}`],
        amount_onerate: values?.[`amount_onerate_${parcelIndex}`],
        division_rule: values?.[`division_rule_${parcelIndex}`],
        due_date: installments?.[`date_due_${parcelIndex}`],
        execution_date_final: moment(
          installments?.[`date_execution_final_${parcelIndex}`]
        ).startOf('day'),
        execution_date: moment(
          installments?.[`date_execution_initial_${parcelIndex}`]
        ).startOf('day'),
      };

      return installment;
    });

    return parcelsArray;
  };

  const handleSubmitContractPerformed = async (values, baseData) => {
    const { parcels_quantity, total_amount, installments } = values;
    let dealId;
    const parcelsArray = [...Array(parcels_quantity)].map((_, index) => {
      const parcelIndex = index;
      return {
        ...baseData,
        origin_gateway: isPartnerIF ? true : false,
        amount: installments[`amount_${parcelIndex}`],
        due_date: installments[`date_due_${parcelIndex}`],
        execution_date_final: moment(
          installments?.[`date_execution_final_${parcelIndex}`]
        ).startOf('day'),
        execution_date: moment(
          installments?.[`date_execution_initial_${parcelIndex}`]
        ).startOf('day'),
        type:
          tipo_transacao == 'ambos'
            ? values.type
            : TRANSACTION_VALUES[tipo_transacao],
        contract_type:
          tipo_contrato == 'todos'
            ? values.contract_type
            : CONTRACT_TYPES_VALUES[tipo_contrato],
      };
    });

    const sumAmount = parcelsArray.reduce(
      (partialSum, a) => partialSum + a.amount,
      0
    );

    if (Number(sumAmount.toFixed(2)) == Number(total_amount)) {
      try {
        setIsLoading(true);
        const id = await createContractPerformed(parcelsArray);
        dealId = id;
        handleNotification('Compromisso criado com sucesso', 'success');
        history.push(ROUTES.PRIVATE.APPOINTMENTS.ROOT);
        setIsLoading(false);
      } catch (e: any) {
        handleNotification(e[0], 'error');
        setIsLoading(false);
        return;
      }
    } else if (Number(sumAmount.toFixed(2)) > Number(total_amount)) {
      handleNotification(
        'A somatória das parcelas ultrapassa o valor total do compromisso',
        'error'
      );
      return null;
    } else {
      handleNotification(
        'A somatória das parcelas é menor que o valor total do compromisso',
        'error'
      );
      return null;
    }

    if (values.tcd && values.type == '4') {
      const dataUpload = {
        id: dealId,
        file: values.tcd,
      };
      await uploadTCD(dataUpload);
    }
  };

  const handleSubmitContractSmoke = async (values, baseData) => {
    const parsedInstallments = handleParseInstallments(values);
    const { tipo_transacao, tipo_contrato } = parameters;
    let dealId;

    const data = {
      ...baseData,
      type:
        tipo_transacao == 'ambos' || isColateral
          ? values.type
          : TRANSACTION_VALUES[tipo_transacao],
      contract_type:
        tipo_contrato == 'todos'
          ? values.contract_type
          : CONTRACT_TYPES_VALUES[tipo_contrato],
      total_amount: values.total_amount,
      installments: parsedInstallments,
    };

    try {
      setIsLoading(true);
      const id = await createContractSmoke(data);
      dealId = id;

      handleNotification('Compromisso Fumaça criado com sucesso', 'success');
      history.push(ROUTES.PRIVATE.APPOINTMENTS.ROOT);
      setIsLoading(false);
    } catch (e: any) {
      handleNotification(e[0], 'error');
      setIsLoading(false);

      return;
    }

    if (values.tcd && values.type == '4') {
      const dataUpload = {
        id: dealId,
        file: values.tcd,
      };
      await uploadTCD(dataUpload);
    }
  };

  const handleSubmit = async (values) => {
    const isContractPerformed = values.contract_modality == 'performado';
    const baseData = {
      is_gcap: values.deal_gcap,
      creditor_id: creditorId,
      creditor_originator_bank_account_id:
        values.creditor_originator_bank_account_id,
      title: values.title,
      description: values.description,
      merchant_id: selectedMerchant?.id,
      min_percentage: values.min_percentage,
      code: values.code,
      expiration_date: values.expiration_date,
      issue_date: values.issue_date,
      document_acquirers: values.document_acquirers,
      payment_arrangements: values.payment_arrangements,
      type: values.type,
    };

    if (isContractPerformed) {
      handleSubmitContractPerformed(values, baseData);
    } else {
      handleSubmitContractSmoke(values, baseData);
    }
  };

  const handleHasError = (values, errors) => {
    const isContractPerformed = values.contract_modality == 'performado';
    const transactionType = values?.type;
    const installments = values?.installments;

    if (!optin) {
      return true;
    }

    //Validate Basic Errors
    if (Object.keys(errors).length > 0) {
      return true;
    }

    //Validate CreditorId
    if (!creditorId) {
      return true;
    }

    //Validate aumount onerate parcels
    if (!isContractPerformed) {
      for (let index = 0; index < Number(values?.parcels_quantity); index++) {
        const propertyName = `amount_onerate_${index}`;
        if (!values?.[propertyName]) {
          return true;
        }
      }
      return false;
    }

    //Validate Installments Errors
    if (transactionType == '1' && installments) {
      return !Object.values(installments).every((value) => value !== null);
    } else if (installments) {
      return !Object.entries(installments).every(([key, value]) => {
        if (key.startsWith('date_due')) {
          return true;
        } else {
          return value !== null;
        }
      });
    }
  };

  //Parameters Values
  const isGcap = parameters?.gcap == '1';
  const tipo_parceiro = PARAMETERS_VALUES[parameters.perfil_parceiro];
  const isColateral = isGcap && colateral;
  const tipo_transacao: string = parameters.tipo_transacao;
  const tipo_contrato: string = parameters.tipo_contrato;
  const quantidade_parcelas = parameters.quantidade_parcelas_compromisso;

  const isPartnerIF = tipo_parceiro == 'Instituição financeira';

  return (
    <>
      <CreateForm schema={isPartnerIF ? createDealSchemaIF : createDealSchema}>
        {({ values, setFieldValue, errors }) => (
          <Box bgcolor={'#FFFFFF'}>
            <PartnerStep
              handleGetCreditorInfos={handleGetCreditorInfos}
              creditorId={creditorId}
              parameters={{ tipo_parceiro }}
              setFieldValue={setFieldValue}
            />

            {values.creditor_originator_bank_account_id && (
              <>
                <ContractDataStep
                  handleGetMerchant={handleGetMerchant}
                  partnerIF={isPartnerIF}
                  parameters={{ tipo_transacao, tipo_contrato, isGcap }}
                  values={values}
                  setFieldValue={setFieldValue}
                  merchantName={selectedMerchant?.name}
                  isColateral={isColateral}
                  errors={errors}
                  setColateral={setColateral}
                />
                <InstallmentsStep
                  values={values}
                  parameters={{
                    quantidade_parcelas,
                    tipo_contrato,
                    tipo_transacao,
                  }}
                  setFieldValue={setFieldValue}
                  acquirers={acquirers}
                  arrangements={arrangements}
                  isPartnerIF={isPartnerIF}
                  errors={errors}
                  isColateral={isColateral}
                />
              </>
            )}
            <CardFormFooter
              hasError={handleHasError(values, errors)}
              onSubmit={() => handleSubmit(values)}
              loading={false}
            />
          </Box>
        )}
      </CreateForm>
      {isLoading && <Loading title="Validando Informações" />}
    </>
  );
};

export default FormCreateDeal;
