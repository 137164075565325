import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'lodash';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import DialogDocumentHistoric from '../components/DialogDocumentHistoric.component';

import * as dialogMerchantDocumentsHistoricDuck from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantDocumentsHistoric.duck';
import getDocumentHistoricService from '@backoffice/private/services/checkid/getDocumentHistoric.service';
import * as merchantDuck from '@backoffice/private/ducks/merchants/merchant.duck';

const enumDocumentType = {
  1: 'Contrato_Social',
  2: 'Documento',
  3: 'Selfie',
};

const DialogDocumentHistoricContainer = () => {
  const handleNotification = useNotification();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const dispatchRedux = {
    OPEN: flow(dialogMerchantDocumentsHistoricDuck.Dispatchs.open, dispatch),
    CLOSE: flow(dialogMerchantDocumentsHistoricDuck.Dispatchs.close, dispatch),
  };

  const selectorRedux = {
    visible: useSelector(dialogMerchantDocumentsHistoricDuck.Selectors.visible),
    payload: useSelector(dialogMerchantDocumentsHistoricDuck.Selectors.payload),
  };

  const selectorMerchantRedux = {
    data: useSelector(merchantDuck.Selectors.data),
  };
  const [documentHistoric, setDocumentHistoric] = useState([]);

  const handleGetDocumentHistoric = async () => {
    setLoading(true);
    const { account_id } = selectorMerchantRedux.data;
    const filter = new URLSearchParams({
      offset: '0',
      limit: '10',
      accountId: account_id,
      typeDoc: enumDocumentType[selectorRedux?.payload?.file_type],
    });
    const response = await getDocumentHistoricService(filter);

    const { results } = await response.json();

    setDocumentHistoric(results);
    setLoading(false);
  };

  useEffect(() => {
    if (selectorRedux.visible) {
      handleGetDocumentHistoric();
    }
  }, [selectorRedux.visible]);

  return (
    <DialogDocumentHistoric
      open={selectorRedux.visible}
      onClose={dispatchRedux.CLOSE}
      documentNumberType={selectorRedux?.payload?.file_type}
      loading={loading}
      historic={documentHistoric}
      urlContract={selectorRedux.payload?.file_path}
    />
  );
};

export default DialogDocumentHistoricContainer;
