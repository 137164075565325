import React from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Field } from 'formik';

interface InputDocumentProps {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  getValueObject?: (id: string) => void;
}

const InputDocument = ({
  name,
  label,
  required,
  disabled,
  getValueObject,
}: InputDocumentProps) => {
  const handleFormatCNPJ = (value) => {
    let formatedCNPJ = value;
    formatedCNPJ = formatedCNPJ.replace(/\D/g, '');
    formatedCNPJ = formatedCNPJ.replace(/^(\d{2})(\d)/, '$1.$2');
    formatedCNPJ = formatedCNPJ.replace(/^(\d{2}).(\d{3})(\d)/, '$1.$2.$3');
    formatedCNPJ = formatedCNPJ.replace(/.(\d{3})(\d)/, '.$1/$2');
    formatedCNPJ = formatedCNPJ.replace(/(\d{4})(\d)/, '$1-$2');

    return formatedCNPJ;
  };

  const handleFormatCPF = (value) => {
    let formatedCPF = value;
    formatedCPF = formatedCPF.replace(/\D/g, '');
    formatedCPF = formatedCPF.replace(/(\d{3})(\d)/, '$1.$2');
    formatedCPF = formatedCPF.replace(/(\d{3})(\d)/, '$1.$2');
    formatedCPF = formatedCPF.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    return formatedCPF;
  };

  const handleFormatDocument = (value) => {
    if (value.length <= 14) {
      if (value.length > 12) {
        return handleFormatCNPJ(value);
      } else {
        return handleFormatCPF(value);
      }
    } else {
      return value;
    }
  };

  return (
    <Field name={name}>
      {({
        field,
        form: { setFieldValue, setFieldTouched },
        meta: { error, touched },
      }) => (
        <NumberFormat
          id={name}
          customInput={TextField}
          format={handleFormatDocument}
          fullWidth
          label={`${label}${required ? ` *` : ''}`}
          variant="outlined"
          value={field.value}
          disabled={disabled}
          max={14}
          error={touched && !!error}
          helperText={touched && !!error && error}
          onBlur={async ({ target: { value } }) => {
            setFieldTouched(field.name, true);

            if (getValueObject) {
              getValueObject(field.value);
            }
          }}
          onValueChange={(values: any) =>
            setFieldValue(field.name, values.value)
          }
        />
      )}
    </Field>
  );
};

export default InputDocument;
