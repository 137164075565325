import React, { useRef, useState, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import { Box, IconButton } from '@material-ui/core';
import CardTitle from '@backoffice/private/components/Card/CardTitle';
import CardTable from '@backoffice/private/components/Card/CardTable';
import SwitchActive from '../../SwitchActive.component';

import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';

import Button from '@backoffice/shared/components/Button.component';
import DialogCreateOriginator from '../../Dialogs/DialogCreateOriginator.component';
import DialogSeeDomiciles from '../../Dialogs/DialogSeeDomiciles.component';
import DialogCreateDomicile from '../../Dialogs/DialogCreateDomicile.component';

import CopyToClipboard from '@payhop/shared-components/CopyToClipboard/CopyToClipboard.component';

const normalizePixType = {
  1: 'CPF/CNPJ',
  2: 'E-mail',
  3: 'Telefone',
  4: 'Randômica',
};
const normalizePaymentType = {
  1: 'TED',
  2: 'PIX',
};

const TableCreditorOriginatorComponent = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOnExport,
  handleOnRefresh,
  handleOpenDialogCreateOriginator,
  handleOpenDialogCreateDomicile,
  handleSeeDomiciles,
  handleChangeOriginatorStatus,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_fornecedor_originadores',
  });
  const { limit, count, offset } = filters || {};

  const columns = useMemo(
    () => [
      {
        width: 10,
        title: '',
        render: (obj) => (
          <Box width={10} display="flex">
            <IconButton onClick={() => handleSeeDomiciles(obj)}>
              <VisibilityIcon />
            </IconButton>
          </Box>
        ),
      },
      {
        title: '',
        width: 10,
        render: (obj) => (
          <Box width={10} display="flex">
            <IconButton
              disabled={obj.status == 'Ativo' ? false : true}
              onClick={() => {
                handleOpenDialogCreateDomicile(obj);
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        ),
      },
      {
        title: 'Status',
        width: 40,
        render: (obj) => (
          <Box display="flex">
            <SwitchActive
              obj={obj}
              handleChangeStatus={handleChangeOriginatorStatus}
            />
          </Box>
        ),
      },
      {
        title: 'ID Originador',
        dataIndex: 'id',
        key: 'id',
        render: ({ id }) => <CopyToClipboard dataToCopy={id} />,
      },
      {
        title: 'CNPJ Originador',
        dataIndex: 'cnpj_originador',
        key: 'cnpj_originador',
        render: ({ cnpj_originador }) => (
          <NumberFormat
            displayType="text"
            value={cnpj_originador}
            isNumericString
            format="##.###.###/####-##"
          />
        ),
      },
      {
        title: 'Razão social',
        dataIndex: 'razao_social',
        key: 'razao_social',
      },
      {
        title: 'Domicílios',
        render: (obj) => (
          <Box display="flex">
            <p>{obj.domicilios.length}</p>
          </Box>
        ),
      },
    ],
    [permissions]
  );

  return (
    <>
      {permissions?.some((item) => item == 'read') && (
        <Box gridGap={8} display="flex" flexDirection="column">
          <Box display={'flex'} justifyContent="space-between">
            <CardTitle>Originadores</CardTitle>
            <Button
              color="primary"
              variant="contained"
              aria-label="show more"
              onClick={() => handleOpenDialogCreateOriginator()}
            >
              Cadastrar Originador
            </Button>
          </Box>

          <CardTable
            count={count || 0}
            loading={loading}
            dataSource={results}
            rowsPerPage={limit}
            columns={columns}
            page={offset / limit}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onRefresh={handleOnRefresh}
            onExport={handleOnExport}
            exporttype="xls"
            exportname="originators"
          />
        </Box>
      )}

      <DialogCreateOriginator />
      <DialogSeeDomiciles />
      <DialogCreateDomicile />
    </>
  );
};

export default TableCreditorOriginatorComponent;
