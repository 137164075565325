import { combineReducers } from 'redux';
import notifications from '@payhop/shared-ducks/notifications.duck';

type listOfReducersType = {
  name: string;
  path: string;
};

const reducers = {};

const listOfReducers: Array<listOfReducersType> = [
  {
    name: 'checkids',
    path: 'checkids',
  },
  {
    name: 'users',
    path: 'users',
  },
  {
    name: 'domains',
    path: 'domains',
  },
  {
    name: 'creditors',
    path: 'creditors',
  },
  {
    name: 'contracts',
    path: 'contracts',
  },
  {
    name: 'merchants',
    path: 'merchants',
  },
  {
    name: 'accounts',
    path: 'accounts',
  },
  {
    name: 'deals',
    path: 'deals',
  },
  {
    name: 'partners',
    path: 'partners',
  },
  {
    name: 'parameters',
    path: 'parameters',
  },
  {
    name: 'profiles',
    path: 'profiles',
  },
  {
    name: 'requests',
    path: 'requests',
  },
  {
    name: 'schedules',
    path: 'schedules',
  },
  {
    name: 'reports',
    path: 'reports',
  },
];

listOfReducers.map((elm) =>
  Object.assign(reducers, {
    ...reducers,
    [elm.name]: require(`./${elm.path}`).default,
  })
);

export default combineReducers({
  ...reducers,
  notifications,
});
