import fetch from '@payhop/shared-utils/fetch.util';

export default function patchDealDomicile(deal) {
  return fetch(`deals/update-domiciles`, {
    method: 'PATCH',
    ms: 'DEAL',
    auth: true,
    body: JSON.stringify(deal),
  });
}
