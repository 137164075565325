import React from 'react';

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ScheduleExport = ({ data }) => {
  function handleFilterScheduleAnalyticalData(data) {
    const newData = data.analytical;
    const filteredData: any = [];
    newData.forEach((item) => {
      const newData = {
        settlement_date: item.settlement_date,
        merchant_name: item.merchant_name,
        merchant_document_number: item.merchant_document_number,
        acquirer_name: item.acquirer_name,
        value_type: item.value_type,
        amount_total: item.amount_total,
        amount_available: item.amount_available,
      };
      filteredData.push(newData);
    });
    return filteredData;
  }

  function handleFilterScheduleSummaryData(data) {
    const newData = data.summary;
    const filteredData: any = [];

    newData.forEach((item) => {
      const newData = {
        merchant_name: item.merchant_name,
        merchant_document_number: item.merchant_document_number,
        acquirer_name: item.acquirer_name,
        value_type: item.arrangement_name,
        amount_total: item.amount_total,
        amount_available: item.amount_available,
      };
      filteredData.push(newData);
    });
    return filteredData;
  }
  return (
    <ExcelFile
      filename={`Agenda_${data?.document_number}`}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet
        data={() => handleFilterScheduleSummaryData(data)}
        name="Resumo"
      >
        <ExcelColumn label="ESTABELECIMENTO" value="merchant_name" />
        <ExcelColumn label="CNPJ" value="merchant_document_number" />
        <ExcelColumn label="CREDENCIADORA" value="acquirer_name" />
        <ExcelColumn label="ARRANJO / TIPO" value="value_type" />
        <ExcelColumn label="VALOR TOTAL" value="amount_total" />
        <ExcelColumn label="VALOR DISPONÍVEL" value="amount_available" />
      </ExcelSheet>
      <ExcelSheet
        data={() => handleFilterScheduleAnalyticalData(data)}
        name="Analítica"
      >
        <ExcelColumn label="DATA LIQUIDAÇÃO" value="settlement_date" />
        <ExcelColumn label="ESTABELECIMENTO" value="merchant_name" />
        <ExcelColumn label="CNPJ" value="merchant_document_number" />
        <ExcelColumn label="CREDENCIADORA" value="acquirer_name" />
        <ExcelColumn label="ARRANJO / TIPO" value="value_type" />
        <ExcelColumn label="VALOR TOTAL" value="amount_total" />
        <ExcelColumn label="VALOR DISPONÍVEL" value="amount_available" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ScheduleExport;
