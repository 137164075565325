import React, { useState, useEffect } from 'react';
import { Formik, Field, useFormikContext } from 'formik';
import { TextField as TextFieldMaterial } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';

import CardSearch from '@backoffice/private/components/Card/CardSearch';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import * as listAcquirerDuck from '@backoffice/private/ducks/parameters/acquirers/listAcquirers.duck';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

const SearchAcquirer = ({ handleSearchReset, loading }) => {
  const [key, setKey] = useState(v4());

  const { values, submitForm, resetForm }: any = useFormikContext();

  const schemaSearch = [
    [
      {
        label: 'Nome',
        name: 'name',
      },
      {
        type: 'custom',
        component: (
          <Field name="document">
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }: any) => (
              <>
                <NumberFormat
                  customInput={TextFieldMaterial}
                  format="##.###.###/####-##"
                  fullWidth
                  label="CNPJ"
                  required
                  variant="outlined"
                  value={field.value}
                  error={touched && !!error}
                  helperText={touched && !!error && error}
                  onBlur={async ({ target: { value } }) => {
                    setFieldTouched(field.name, true);
                  }}
                  onValueChange={(values: any) =>
                    setFieldValue(field.name, values.value)
                  }
                />
              </>
            )}
          </Field>
        ),
      },
    ],
  ];

  return (
    <>
      <CardTitle>Filtrar credenciadoras</CardTitle>

      <CardSearch
        formik
        key={key}
        disabled={loading}
        fields={schemaSearch as any}
        onSubmit={submitForm}
        onReset={() =>
          handleSearchReset()(() => {
            setKey(v4());

            resetForm();
          })
        }
      />
    </>
  );
};

export default function SearchAcquirerComponent() {
  const [key, setKey] = useState(v4());
  const { error } = useSelector((state: any) => state.shared);
  const handleNotification = useNotification();

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(listAcquirerDuck.Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(listAcquirerDuck.Dispatchs.resetFilters, dispatch),
    GET: flow(listAcquirerDuck.Dispatchs.get, dispatch),
    RESET: flow(listAcquirerDuck.Dispatchs.reset, dispatch),
    DELETE: flow(listAcquirerDuck.Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(listAcquirerDuck.Selectors.data),
    LOADING: useSelector(listAcquirerDuck.Selectors.loading),
    DATA_RESULTS: useSelector(listAcquirerDuck.Selectors.data_results),
    FILTERS: useSelector(listAcquirerDuck.Selectors.filters),
    RESULTSET: useSelector(listAcquirerDuck.Selectors.resultset),
    FETCHED: useSelector(listAcquirerDuck.Selectors.fetched),
  };

  const { handleSearch, handleSearchReset } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: true,
    },
  });

  useEffect(() => {
    if (error.messages?.[0] !== undefined) {
      handleNotification(error.messages?.[0], 'error');
    }
  }, [error.messages]);

  useEffect(() => {
    setKey(v4());
  }, [selectorRedux.FILTERS]);

  const handleSubmit = async (values) => {
    const newValues = {
      ...values,
      offset: 0,
    };

    await handleSearch(newValues);

    return true;
  };

  return (
    <Formik
      initialValues={{
        name: selectorRedux.FILTERS.name ?? '',
        document: selectorRedux.FILTERS.document ?? '',
      }}
      onSubmit={handleSubmit}
      key={key}
    >
      <SearchAcquirer
        handleSearchReset={handleSearchReset}
        loading={selectorRedux.LOADING}
      />
    </Formik>
  );
}
