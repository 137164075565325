export default (value: any) => {
  let formatedCNPJ = value;
  formatedCNPJ = formatedCNPJ.replace(/\D/g, '');
  formatedCNPJ = formatedCNPJ.replace(/^(\d{2})(\d)/, '$1.$2');
  formatedCNPJ = formatedCNPJ.replace(/^(\d{2}).(\d{3})(\d)/, '$1.$2.$3');
  formatedCNPJ = formatedCNPJ.replace(/.(\d{3})(\d)/, '.$1/$2');
  formatedCNPJ = formatedCNPJ.replace(/(\d{4})(\d)/, '$1-$2');

  return formatedCNPJ;
};
