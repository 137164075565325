import React, { useRef, useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import EditIcon from '@material-ui/icons/Edit';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/pricing/listCreditorPricing.duck';

import * as dialogCreditorUpdateDuck from '@backoffice/private/ducks/creditors/dialogs/dialogCreditorUpdate.duck';

import ModalConfirmDelete from '@backoffice/private/components/Modal/ModalConfirmDelete.component';

const TableCreditorTaxContainer = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const dispatchDialogCreditorUpdate = {
    OPEN: flow(dialogCreditorUpdateDuck.Dispatchs.open, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage, handleDelete } =
    useCrudRead({
      dispatchRedux,
      selectorRedux,
    });

  return (
    <TableCreditorTaxComponent
      loading={selectorRedux.LOADING}
      results={selectorRedux.DATA_RESULTS}
      handleDelete={handleDelete}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleClickRow={dispatchDialogCreditorUpdate.OPEN}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const TableCreditorTaxComponent = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  handleDelete,
  handleClickRow,
}) => {
  const currentId = useRef(null);
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_fornecedor_taxas',
  });

  const [modalDelete, setModalDelete]: any = useState(null);

  const { limit, count, offset } = filters || {};

  const columns = [
    {
      title: '',
      width: 40,
      render: (props) => (
        <Box display="flex">
          <IconButton
            onClick={() => {
              handleClickRow(props);
            }}
            disabled={!permissions?.some((item) => item == 'edit')}
          >
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      title: 'Faixa',
      dataIndex: 'price_range',
      key: 'price_range',
    },
    {
      title: 'Valor mínimo',
      dataIndex: 'min_value',
      key: 'min_value',
      render: ({ min_value }) =>
        Number(min_value).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
    {
      title: 'Valor máximo',
      dataIndex: 'max_value',
      key: 'max_value',
      render: ({ max_value }) =>
        Number(max_value).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
    {
      title: 'Taxa',
      dataIndex: 'fee',
      key: 'fee',
      render: ({ fee }) =>
        `${Number(fee).toLocaleString('pt-br', {
          style: 'decimal',
        })}%`,
    },
    {
      title: 'Data inicial',
      dataIndex: 'start_date',
      key: 'start_date',
      render: ({ start_date }) => moment(start_date).format('DD/MM/YYYY'),
    },
    {
      title: 'Data final',
      dataIndex: 'end_date',
      key: 'end_date',
      render: ({ end_date }) =>
        !end_date ? '-' : moment(end_date).format('DD/MM/YYYY'),
    },
    {
      title: 'Status',
      render: ({ active }) => (active ? 'Ativo' : 'Inativo'),
    },
  ];

  return (
    <>
      {permissions?.some((item) => item == 'read') && (
        <Box gridGap={8} display="flex" flexDirection="column">
          <CardTitle>Taxas acordadas com o fornecedor</CardTitle>

          <CardTable
            count={count || 0}
            loading={loading}
            dataSource={results}
            rowsPerPage={limit}
            columns={columns}
            page={offset / limit}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      )}

      <ModalConfirmDelete
        open={modalDelete || false}
        onConfirm={async () =>
          handleDelete(currentId.current)(() => {
            setModalDelete(false);

            currentId.current = null;
          })
        }
        onCancel={() => setModalDelete(false)}
      />
    </>
  );
};

export default TableCreditorTaxContainer;
