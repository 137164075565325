import React from 'react';

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Props = {
  data: Array<string | number>;
};

const BanksExport = ({ data }: Props) => {
  function handleFilterBankData(data) {
    const filteredData: any = [];
    data.forEach((item) => {
      const newData = {
        bank_code: item.bank_code,
        bank_ispb: item.bank_ispb,
        bank_name: item.bank_name,
      };
      filteredData.push(newData);
    });
    return filteredData;
  }
  return (
    <ExcelFile
      filename={'Bancos'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleFilterBankData(data)} name="Bancos">
        <ExcelColumn label="CÓDIGO" value="bank_code" />
        <ExcelColumn label="ISPB" value="bank_ispb" />
        <ExcelColumn label="NOME" value="bank_name" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default BanksExport;
