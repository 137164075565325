import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'lodash';
import Resizer from 'react-image-file-resizer';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import uploadDocument from '@payhop/shared-services/uploadDocument.service';
import patchCheckids from '@backoffice/private/services/checkid/patchCheckids.service';

import DialogDocument from '../components/DialogDocument.component';

import * as dialogMerchantDocumentsDuck from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantDocuments.duck';
import * as merchantDuck from '@backoffice/private/ducks/merchants/merchant.duck';
import * as checkidsDuck from '@backoffice/private/ducks/checkids/listCheckids.duck';
import * as dialogMerchantDocumentsReproveDuck from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantDocumentsReprove.duck';
import * as listMerchantDocumentDetailDuck from '@backoffice/private/ducks/merchants/documentDetail/listMerchantDocumentDetail.duck';

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1200,
      1200,
      'png',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });

const DialogDocumentContainer = () => {
  const handleNotification = useNotification();

  const prevVisible = useRef(null);

  const [document, setDocument] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const dispatchRedux = {
    OPEN: flow(dialogMerchantDocumentsDuck.Dispatchs.open, dispatch),
    CLOSE: flow(dialogMerchantDocumentsDuck.Dispatchs.close, dispatch),
  };

  const dispatchReproveRedux = {
    OPEN: flow(dialogMerchantDocumentsReproveDuck.Dispatchs.open, dispatch),
  };

  const dispatchCheckId = {
    GET: flow(checkidsDuck.Dispatchs.get, dispatch),
  };

  const dispatchDocumentDetails = {
    GET: flow(listMerchantDocumentDetailDuck.Dispatchs.get, dispatch),
  };

  const selectorRedux = {
    visible: useSelector(dialogMerchantDocumentsDuck.Selectors.visible),
    payload: useSelector(dialogMerchantDocumentsDuck.Selectors.payload),
  };

  const selectorMerchentRedux = {
    data: useSelector(merchantDuck.Selectors.data),
  };

  const handleUploadFile = async (i) => {
    const newDocument = [...document];

    newDocument[i.index].upload = 'uploading';

    setDocument([...newDocument]);

    const { account_id } = selectorMerchentRedux.data;

    const [success, result] = await uploadDocument({
      files: i.file,
      type: selectorRedux?.payload?.file_type,
      info: '',
      accountId: account_id,
    });

    if (!success) {
      const newDocument = [...document];

      handleNotification(result?.errors?.message?.[0], 'error');

      newDocument[i.index].upload = 'fail';

      setDocument([...newDocument]);

      return false;
    }

    if (success) {
      const newDocument = [...document];

      newDocument[i.index].upload = 'success';

      setDocument([...newDocument]);

      return true;
    }
  };

  const handleRetry = (file) => () => {
    const newDocument = [...document];

    newDocument[file.index].upload = 'pending';

    setDocument([...newDocument]);
  };

  const handleDelete = (index) => {
    setDocument(document.filter((_, i) => i !== index));
  };

  const handleAprove = async () => {
    const idDocument = selectorRedux.payload.id;

    setLoading(true);

    const response = await patchCheckids({
      id: idDocument,
      status: 2,
      comment: '',
    });

    setLoading(false);

    if (response.ok) {
      dispatchCheckId.GET();

      dispatchRedux.CLOSE();

      handleNotification('Documento aprovado com sucesso', 'success');
    }
  };

  const handleReprove = async () => {
    const idDocument = selectorRedux.payload.id;

    dispatchReproveRedux.OPEN({
      id: idDocument,
    });
  };

  const handleOnDrop = async (files) => {
    const filesFormated = await Promise.all(
      files.map(async (f: any, index) => {
        return {
          file: f.type.includes('image') ? await resizeFile(f) : f,
          upload: 'pending',
          index,
        };
      })
    );

    setDocument([...filesFormated]);
  };

  useEffect(() => {
    (async () => {
      const imagesToUpload = document.filter((d) => d.upload === 'pending');
      const hasImagesToUpload = imagesToUpload.length > 0;

      if (!hasImagesToUpload) {
        return undefined;
      }

      const results = await Promise.all(imagesToUpload.map(handleUploadFile));

      if (results.every((r) => !!r)) {
        handleNotification('Upload feito com sucesso!', 'success');
        dispatchRedux.CLOSE();
      }
    })();
  }, [document]);

  useEffect(() => {
    if (prevVisible.current === null) {
      prevVisible.current = selectorRedux.visible;

      return undefined;
    }

    setDocument([]);
    dispatchCheckId.GET();
  }, [selectorRedux.visible]);

  useEffect(() => {
    if (selectorRedux.payload?.file_path) {
      setDocument([
        {
          fileUrl: selectorRedux.payload?.file_path,
          index: 0,
          upload: 'success',
        },
      ]);
    }
  }, [selectorRedux.payload]);

  const documentType = selectorRedux?.payload?.file_info;

  return (
    <DialogDocument
      open={selectorRedux.visible}
      onClose={dispatchRedux.CLOSE}
      onAprove={handleAprove}
      onReprove={handleReprove}
      onDrop={handleOnDrop}
      onRetry={handleRetry}
      onDelete={handleDelete}
      documentType={documentType}
      documentNumberType={selectorRedux?.payload?.file_type}
      loading={loading}
      document={document}
      urlContract={selectorRedux.payload?.file_path}
    />
  );
};

export default DialogDocumentContainer;
