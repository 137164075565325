import { useMemo, Fragment, useRef, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Formik } from 'formik';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';

import ModalConfirmDelete from '@backoffice/private/components/Modal/ModalConfirmDelete.component';
import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import CardSearch from '@backoffice/private/components/Card/CardSearch';

import TableMerchant from '../../merchants/containers/TableMerchant.container';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import * as listPendingMerchantsDuck from '@backoffice/private/ducks/merchants/listPendingMerchants.duck';
import { v4 } from 'uuid';

const schemaSearch = [
  [
    {
      name: 'document',
      label: 'CNPJ',
    },
    {
      name: 'name',
      label: 'Nome Fantasia ou Razão Social',
    },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      multiple: true,
      options: [
        {
          label: 'Inativo com compromisso garantido',
          value: 'inativo',
        },
        {
          label: 'Pendente de Aprovação de Documentos',
          value: 'pendente',
        },
        {
          label: 'Todos',
          value: 'todos',
        },
      ],
    },
  ],
];

const DocsScreen = () => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_validacao_docs',
  });
  const dispatch = useDispatch();

  const currentId = useRef('');
  const [key, setKey] = useState(v4());
  const [modalDelete, setModalDelete] = useState(false);

  const dispatchRedux = {
    UPDATE_FILTERS: flow(
      listPendingMerchantsDuck.Dispatchs.updateFilters,
      dispatch
    ),
    RESET_FILTERS: flow(
      listPendingMerchantsDuck.Dispatchs.resetFilters,
      dispatch
    ),
    GET: flow(listPendingMerchantsDuck.Dispatchs.get, dispatch),
    RESET: flow(listPendingMerchantsDuck.Dispatchs.reset, dispatch),
    DELETE: flow(listPendingMerchantsDuck.Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(listPendingMerchantsDuck.Selectors.data),
    LOADING: useSelector(listPendingMerchantsDuck.Selectors.loading),
    DATA_RESULTS: useSelector(listPendingMerchantsDuck.Selectors.data_results),
    FILTERS: useSelector(listPendingMerchantsDuck.Selectors.filters),
    RESULTSET: useSelector(listPendingMerchantsDuck.Selectors.resultset),
    FETCHED: useSelector(listPendingMerchantsDuck.Selectors.fetched),
  };

  useEffect(() => {
    setKey(v4());
  }, [selectorRedux.FILTERS]);

  const { handleSearch, handleSearchReset } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: false,
    },
  });

  const columns = useMemo(
    () => [
      {
        title: 'CNPJ',
        dataIndex: 'document',
        key: 'document',
        render: ({ document }) => (
          <NumberFormat
            displayType="text"
            value={document}
            isNumericString
            format="##.###.###/####-##"
          />
        ),
      },
      {
        title: 'Nome Fantasia',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Razão Social',
        dataIndex: 'razao_social',
        key: 'razao_social',
      },
      {
        title: 'Ativo?',
        dataIndex: 'active',
        key: 'active',
        render: ({ active }) => (active ? 'Sim' : 'Não'),
      },
      {
        title: 'Data de Cadastro',
        dataIndex: 'data_cadastro',
        key: 'data_cadastro',
        render: ({ data_cadastro }) =>
          moment(data_cadastro).format('DD/MM/YYYY HH:mm'),
      },
    ],
    []
  );

  return (
    <Fragment>
      <PageLayout headerTitle header sidebar title="Documentação">
        {permissions?.some((item) => item == 'read') && (
          <Box display="flex" flexDirection="column" gridGap={32}>
            <Formik
              initialValues={{
                document: selectorRedux.FILTERS.document ?? '',
                name: selectorRedux.FILTERS.name ?? '',
                status: selectorRedux.FILTERS.status ?? [],
              }}
              onSubmit={handleSearch}
              key={key}
            >
              {({ submitForm, resetForm }) => (
                <CardSearch
                  fields={schemaSearch}
                  disabled={selectorRedux.LOADING}
                  formik
                  onSubmit={submitForm}
                  onReset={() => handleSearchReset()(resetForm)}
                />
              )}
            </Formik>

            <TableMerchant type="merchantPending" />
          </Box>
        )}
        {!permissions ||
          (!permissions?.some((item) => item == 'read') && (
            <ModuleNotPermited />
          ))}
      </PageLayout>

      <ModalConfirmDelete
        open={modalDelete}
        onCancel={() => setModalDelete(false)}
        onConfirm={() => {
          setModalDelete(false);
        }}
      />
    </Fragment>
  );
};

export default DocsScreen;
