import fetch from '@payhop/shared-utils/fetch.util';

export default function getTCD(id: any) {
  return fetch(`search?external_reference=${id}`, {
    method: 'GET',
    ms: 'UPLOAD',
    defaultHeaders: false,
    auth: true,
  }).then(async (response) => {
    if (!response.ok) {
      const result = await response.json();
      const errorMessage = result.message;

      throw errorMessage;
    } else {
      const data = await response.json();

      return data;
    }
  });
}
