import { Redirect, Route, Switch } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import LoginScreen from './screens/Login/Login.screen';

const PublicRoutes: any = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.PUBLIC.LOGIN}
      render={() => (
        <LoginScreen
          title={{
            main: 'Login',
            description: 'Faça login para acessar o backoffice',
          }}
        />
      )}
    />

    <Redirect to={ROUTES.PUBLIC.LOGIN} />
  </Switch>
);

export default PublicRoutes;
