import React from 'react';
import { useEffect } from 'react';
import { flow } from 'lodash';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import HomeModule from '@backoffice/private/modules/home/Home.module';
import AccessModule from '@backoffice/private/modules/access/Access.module';
import CreditorsModule from '@backoffice/private/modules/creditor/Creditor.module';
import SchedulesModule from '@backoffice/private/modules/schedules/Schedules.module';
import AppointimentsModule from '@backoffice/private/modules/appointments/Appointments.module';
import MerchantsModule from '@backoffice/private/modules/merchants/Merchants.module';
import DocsModule from '@backoffice/private/modules/docs/Docs.module';
import PartnersModule from '@backoffice/private/modules/partners/Partners.module';
import ImportMerchantModule from '@backoffice/private/modules/importMerchant/ImportMerchant.module';
import ImportAppointmentsModule from '@backoffice/private/modules/importAppointments/importAppointments.module';
import ImportSmokeAppointmentsModule from './modules/importSmokeAppointments/importSmokeAppointments.module';
import ChangeDealsModule from '@backoffice/private/modules/changeDeals/ChangeDeals.module';
import RegisteredContractsModule from '@backoffice/private/modules/registeredContracts/RegisteredContracts.module';
import ParametersModule from '@backoffice/private/modules/parameters/Parameters.module';
import MonitoringReportModule from '@backoffice/private/modules/monitoringReport/MonitoringReport.module';

import NotificationDrawer from './components/NotificationDrawer';
import { withNotificationDrawerContext } from './components/NotificationDrawer/NotificationDrawer.context';
import { withSidebarContext } from '@backoffice/private/components/Sidebar/Sidebar.context';

import ROUTES from '@backoffice/shared/utils/routes.util';

import useUserHook from '@backoffice/shared/hooks/useUser.hook';

const PrivateRoutes = ({ match: { path } }) => {
  const { signedIn } = useSelector(({ shared }: any) => shared.authentication);
  const { isAdmin } = useUserHook();

  const dispatch = useDispatch();

  if (!signedIn) {
    return <Redirect to={ROUTES.PUBLIC.LOGIN} />;
  }

  function CustomRoute({ adminRoute, ...rest }) {
    if (adminRoute && !isAdmin) {
      return <Redirect to="/app" />;
    }

    return <Route {...rest} />;
  }

  return (
    <>
      <Switch>
        <CustomRoute
          adminRoute={false}
          exact
          path={ROUTES.PRIVATE.HOME}
          component={HomeModule}
        />
        <CustomRoute
          adminRoute={false}
          path={ROUTES.PRIVATE.ACCESS.ROOT}
          component={AccessModule}
        />
        <CustomRoute
          adminRoute={true}
          path={ROUTES.PRIVATE.CREDITORS.ROOT}
          component={CreditorsModule}
        />
        <CustomRoute
          adminRoute={false}
          path={ROUTES.PRIVATE.SCHEDULES.ROOT}
          component={SchedulesModule}
        />
        <CustomRoute
          adminRoute={false}
          path={ROUTES.PRIVATE.APPOINTMENTS.ROOT}
          component={AppointimentsModule}
        />
        <CustomRoute
          adminRoute={false}
          path={ROUTES.PRIVATE.MERCHANTS.ROOT}
          component={MerchantsModule}
        />
        <CustomRoute
          adminRoute={true}
          path={ROUTES.PRIVATE.DOCS.ROOT}
          component={DocsModule}
        />
        <CustomRoute
          adminRoute={true}
          path={ROUTES.PRIVATE.PARTNERS.ROOT}
          component={PartnersModule}
        />
        <CustomRoute
          adminRoute={false}
          path={ROUTES.PRIVATE.IMPORT_MERCHANT.ROOT}
          component={ImportMerchantModule}
        />
        <CustomRoute
          adminRoute={false}
          path={ROUTES.PRIVATE.IMPORT_APPOINTMENTS.ROOT}
          component={ImportAppointmentsModule}
        />
        <CustomRoute
          adminRoute={false}
          path={ROUTES.PRIVATE.IMPORT_SMOKE_APPOINTMENTS.ROOT}
          component={ImportSmokeAppointmentsModule}
        />
        <CustomRoute
          adminRoute={false}
          path={ROUTES.PRIVATE.CHANGE_DEALS.ROOT}
          component={ChangeDealsModule}
        />
        <CustomRoute
          adminRoute={false}
          path={ROUTES.PRIVATE.PARAMETERS.ROOT}
          component={ParametersModule}
        />
        <CustomRoute
          adminRoute={false}
          path={ROUTES.PRIVATE.REGISTERED_CONTRACTS.ROOT}
          component={RegisteredContractsModule}
        />
        <CustomRoute
          adminRoute={true}
          path={ROUTES.PRIVATE.MONITORING_REPORT.ROOT}
          component={MonitoringReportModule}
        />

        <Redirect to={ROUTES.PRIVATE.HOME} />
      </Switch>
      <NotificationDrawer />
    </>
  );
};

export default withSidebarContext(withNotificationDrawerContext(PrivateRoutes));
