import React from 'react';

import Typography from '@material-ui/core/Typography';

const CardTitleComponent: React.FC = ({ children }) => (
  <Typography variant="subtitle1" color="textSecondary">
    {children}
  </Typography>
);

export default CardTitleComponent;
