import React from 'react';

import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Props = {
  data: Array<string | number>;
};

const AcquirersExport = ({ data }: Props) => {
  function handleFilterAcquirerData(data) {
    const filteredData: any = [];
    data.forEach((item) => {
      const newData = {
        acquirer_name: item.acquirer_name,
        document_number: item.document_number,
        register_name: item.register_name,
        created_at: moment(item.created_at).format('DD/MM/YYYY HH:mm'),
        inactivation_date: item.inactivation_date
          ? moment(item.inactivation_date).format('DD/MM/YYYY HH:mm')
          : '-',
      };
      filteredData.push(newData);
    });
    return filteredData;
  }
  return (
    <ExcelFile
      filename={'Credenciadoras'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleFilterAcquirerData(data)} name="Credenciadoras">
        <ExcelColumn label="NOME DA CREDENCIADORA" value="acquirer_name" />
        <ExcelColumn label="CNPJ" value="document_number" />
        <ExcelColumn label="REGISTRADORA" value="register_name" />
        <ExcelColumn label="DATA DE CRIAÇÃO" value="created_at" />
        <ExcelColumn label="DATA DE INATIVAÇÃO" value="inactivation_date" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default AcquirersExport;
