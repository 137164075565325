import { css } from '@emotion/react';

export const appBarCss = css({
  position: 'relative',
});

export const titleCss = css({
  flex: 1,
  left: 0,
  width: '100%',
});

export const containerCss = (fullScreen: boolean) =>
  css({
    maxWidth: fullScreen ? 800 : undefined,
    marginTop: 28,
    marginBottom: 28,
  });
