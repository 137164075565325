import React, { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import TableUsersPayhop from '../components/users/TableUsersPayhop.component';
import UsersCreditor from '../components/usersCreditors/UsersCreditors.component';
import TableProfiles from '../components/profiles/TableProfiles.component';
import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import CardTab from '@backoffice/private/components/Card/CardTab';

import ROUTES from '@backoffice/shared/utils/routes.util';

import PageLayout from '@backoffice/private/layouts/Page.layout';
import PageHeaderTitle from '@backoffice/private/layouts/PageHeaderTitle.layout';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

const texts = {
  titleEdit: 'Usuários',
  titleCreate: 'Usuários',
  buttonAddUser: 'Adicionar Usuário',
  buttonAddProfile: 'Adicionar Perfil',
};

const AccessScreen = () => {
  const permissionsUsers = useAccountPermission({
    permission_slug: 'cad_acesso_usuario_payhop',
  });
  const permissionsUsersCreditors = useAccountPermission({
    permission_slug: 'cad_acesso_usuario_fornecedor',
  });
  const permissionsProfiles = useAccountPermission({
    permission_slug: 'cad_acesso_perfil',
  });

  const [creditorId, setCreditorId] = useState();
  const { isAdmin, isPrimaryAccount } = useUserHook();

  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const { isEdit } = useIsEdit();

  const titlePage = useMemo(
    () => (isEdit ? texts.titleEdit : texts.titleCreate),
    [isEdit]
  );

  const handleTabActive = (value) => {
    const parameters = new URLSearchParams();
    if (value !== 0) {
      parameters.append('tab', value);
      history.replace({ search: parameters.toString() });
    } else {
      parameters.delete('tab');
      history.replace({ search: parameters.toString() });
    }
  };

  const handleSetCreditorId = (id) => {
    setCreditorId(id);
  };

  const tabs = {
    users_payhop: {
      label: 'Usuários - Payhop',
    },
    users_creditors: {
      label: 'Usuários - Fornecedores',
    },
    profiles: {
      label: 'Perfis',
    },
  };

  const tabsCreditor = {
    users_creditors: {
      label: 'Usuários - Fornecedores',
    },
  };

  const tabsFormatted: any = Object.keys(isAdmin ? tabs : tabsCreditor).reduce(
    (acc, curr, index) => ({
      ...acc,
      [curr]: {
        label: tabs[curr].label,
        name: curr,
        index,
      },
    }),
    {}
  );

  const tabsLabelsArr = Object.keys(tabsFormatted).map(
    (val) => tabsFormatted[val].label
  );

  return (
    <PageLayout header sidebar>
      <Box display="flex" gridGap={24} flexDirection="column">
        {params.get('tab') == null && isAdmin && (
          <Box display="flex" flexDirection="column" gridGap={24}>
            <PageHeaderTitle
              title={titlePage}
              cta={
                <Button
                  variant="outlined"
                  color="primary"
                  css={{ minWidth: 230 }}
                  onClick={() =>
                    history.push(ROUTES.PRIVATE.ACCESS.DYNAMIC_CREATE('user'))
                  }
                  disabled={
                    !permissionsUsers.permissions?.some(
                      (item) => item == 'create'
                    )
                  }
                >
                  {texts.buttonAddUser}
                </Button>
              }
            />
            <CardTab
              onChange={(_, value) => {
                handleTabActive(value);
              }}
              tabs={tabsLabelsArr}
              tabActive={Number(params.get('tab'))}
            />
            {permissionsUsers.permissions?.some((item) => item == 'read') && (
              <TableUsersPayhop />
            )}

            {permissionsUsers.permissions &&
              !permissionsUsers.permissions?.some((item) => item == 'read') && (
                <ModuleNotPermited />
              )}

            {!permissionsUsers.permissions && <ModuleNotPermited />}
          </Box>
        )}

        {params.get('tab') == (isAdmin ? '1' : null) && (
          <Box display="flex" flexDirection="column" gridGap={24}>
            <PageHeaderTitle
              title={titlePage}
              cta={
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    css={{ minWidth: 200 }}
                    disabled={
                      !creditorId ||
                      !permissionsUsersCreditors.permissions?.some(
                        (item) => item == 'create'
                      )
                    }
                    onClick={() =>
                      history.push(
                        `${ROUTES.PRIVATE.ACCESS.DYNAMIC_CREATE(
                          'user-creditor'
                        )}&creditor_id=${creditorId}`
                      )
                    }
                  >
                    {texts.buttonAddUser}
                  </Button>
                </>
              }
            />
            <CardTab
              onChange={(_, value) => {
                handleTabActive(value);
              }}
              tabs={tabsLabelsArr}
              tabActive={Number(params.get('tab'))}
            />
            {permissionsUsersCreditors.permissions?.some(
              (item) => item == 'read'
            ) && <UsersCreditor handleSetCreditorId={handleSetCreditorId} />}

            {permissionsUsersCreditors.permissions &&
              !permissionsUsersCreditors.permissions?.some(
                (item) => item == 'read'
              ) && <ModuleNotPermited />}

            {!permissionsUsersCreditors.permissions && <ModuleNotPermited />}
          </Box>
        )}
        {params.get('tab') == '2' && isAdmin && (
          <Box display="flex" flexDirection="column" gridGap={24}>
            <PageHeaderTitle
              title={'Perfis'}
              cta={
                <Button
                  variant="outlined"
                  color="primary"
                  css={{ minWidth: 230 }}
                  onClick={() =>
                    history.push(
                      ROUTES.PRIVATE.ACCESS.DYNAMIC_CREATE('profile')
                    )
                  }
                  disabled={
                    !permissionsProfiles.permissions?.some(
                      (item) => item == 'create'
                    )
                  }
                >
                  {texts.buttonAddProfile}
                </Button>
              }
            />
            <CardTab
              onChange={(_, value) => {
                handleTabActive(value);
              }}
              tabs={tabsLabelsArr}
              tabActive={Number(params.get('tab'))}
            />
            {permissionsProfiles.permissions?.some(
              (item) => item == 'read'
            ) && <TableProfiles />}

            {permissionsProfiles.permissions &&
              !permissionsProfiles.permissions?.some(
                (item) => item == 'read'
              ) && <ModuleNotPermited />}

            {!permissionsProfiles.permissions && <ModuleNotPermited />}
          </Box>
        )}
      </Box>
    </PageLayout>
  );
};

export default AccessScreen;
