import qs from 'qs';
import fetch from '@payhop/shared-utils/fetch.util';

export default async function getAccountsSearchService(filters = {}) {
  const filtersSerialize = qs.stringify(filters);

  const url = [
    'accounts/search',
    filtersSerialize && `?${filtersSerialize}`,
  ].join('');

  const response = await fetch(url, {
    method: 'GET',
    ms: 'ACCOUNT',
  });

  const result = await response.json();

  return [response.ok, result];
}
