import { useState, useRef } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

import Button from '@material-ui/core/Button';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EmailIcon from '@material-ui/icons/Email';

import ModalConfirmDelete from '@backoffice/private/components/Modal/ModalConfirmDelete.component';

import CardTable from '@backoffice/private/components/Card/CardTable';

import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';

import patchAccountsByIdService from '@backoffice/private/services/account/patchAccountsById.service';
import postAccountsUnlockoutService from '@backoffice/private/services/account/postAccountsUnlockout.service';
import postCreditorSecondaryResendInvite from '@backoffice/private/services/creditor/postCreditorsSecondaryResendInvite.service';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/access/listCreditorAccess.duck';
import * as dialogCreditorChangePasswordDuck from '@backoffice/private/ducks/creditors/dialogs/dialogCreditorChangePassword.duck';
import postAccountsSendTokenService from '@backoffice/private/services/account/postAccountsSendToken.service';

const TableCreditorUsersContainer = ({ creditorId = '' }) => {
  const handleNotification = useNotification();

  const [loadingLocal, setLoadingLocal] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const dispatchDialogCreditorUpdate = {
    OPEN: flow(dialogCreditorChangePasswordDuck.Dispatchs.open, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: false,
    },
  });

  const handleClickInactive = async ({ account_id }) => {
    setLoadingLocal(true);

    const response = await patchAccountsByIdService(account_id, {
      status: 'inativo',
    });

    setLoadingLocal(false);

    if (response.ok) {
      setModalConfirm(false);

      handleNotification('Inativado com sucesso', 'success');

      dispatchRedux.GET(creditorId);
    } else {
      const result = await response.json();

      handleNotification(result?.errors.message[0], 'error');
    }
  };

  const handleUnlock = async (props) => {
    setLoadingLocal(true);

    const response = await postAccountsUnlockoutService(props.email);

    if (response.ok) {
      handleNotification('Desbloqueado com sucesso', 'success');
    } else {
      const result = await response.json();

      handleNotification(result?.errors?.message?.[0], 'error');
    }

    setLoadingLocal(false);
  };

  const handleResendInvite = async (props) => {
    setLoadingLocal(true);
    let response;

    if (props.conta_principal) {
      response = await postAccountsSendTokenService({ email: props.email });
    } else {
      response = await postCreditorSecondaryResendInvite(props.email);
    }

    if (response.ok) {
      handleNotification('Convite re-enviado com sucesso', 'success');
    } else {
      const result = await response.json();

      handleNotification(result?.errors?.message?.[0], 'error');
    }

    setLoadingLocal(false);
  };

  return (
    <TableCreditorUsersComponent
      loading={selectorRedux.LOADING || loadingLocal}
      results={selectorRedux.DATA_RESULTS}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleClickRow={dispatchDialogCreditorUpdate.OPEN}
      handleClickInactive={handleClickInactive}
      handleResendInvite={handleResendInvite}
      modalConfirm={modalConfirm}
      setModalConfirm={setModalConfirm}
      handleUnlock={handleUnlock}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const TableCreditorUsersComponent = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  handleClickRow,
  handleClickInactive,
  handleResendInvite,
  modalConfirm,
  setModalConfirm,
  handleUnlock,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_acesso_usuario_fornecedor',
  });

  const currentUser = useRef({});

  const { limit, count, offset } = filters || {};

  const columns = [
    {
      title: '',
      width: 100,
      render: (props) => (
        <Box display="flex" gridGap={8}>
          <IconButton
            disabled={!permissions?.some((item) => item == 'edit')}
            size="small"
            onClick={() => {
              handleClickRow(props);
            }}
          >
            <VpnKeyIcon />
          </IconButton>

          <IconButton
            disabled={!permissions?.some((item) => item == 'edit')}
            size="small"
            onClick={() => {
              handleResendInvite(props);
            }}
          >
            <EmailIcon />
          </IconButton>

          <Button
            size="small"
            variant="outlined"
            disabled={!permissions?.some((item) => item == 'edit')}
            onClick={() => {
              currentUser.current = props;

              setModalConfirm(true);
            }}
          >
            Inativar
          </Button>
          <Button
            size="small"
            variant="outlined"
            disabled={!permissions?.some((item) => item == 'edit')}
            onClick={() => handleUnlock(props)}
          >
            Desbloquear
          </Button>
        </Box>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Perfil',
      dataIndex: 'profile_description',
      key: 'profile_description',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: ({ phone_number }) => (
        <NumberFormat
          displayType="text"
          value={phone_number}
          isNumericString
          format={
            phone_number.length === 11 ? '(##) #####-####' : '(##) ####-####'
          }
        />
      ),
    },
    {
      title: 'Ativo',
      dataIndex: 'status',
      key: 'status',
      render: ({ status }) => (status === 'Ativo' ? 'Sim' : 'Não'),
    },
  ];

  return (
    <Box gridGap={8} display="flex" flexDirection="column">
      <CardTable
        count={count || 0}
        loading={loading}
        dataSource={results}
        rowsPerPage={limit}
        columns={columns as any}
        page={offset / limit}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <ModalConfirmDelete
        title="Você tem certeza que deseja inativar este usuário?"
        description={
          'Esta ação não poderá ser desfeita, apenas prossiga se tiver certeza.'
        }
        open={modalConfirm || false}
        onCancel={() => setModalConfirm(false)}
        onConfirm={() => handleClickInactive(currentUser.current)}
      />
    </Box>
  );
};

export default TableCreditorUsersContainer;
