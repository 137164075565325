import React from 'react';

import { CardDetailContainer, CardDetailContent } from './CardDetail.styled';
import Typography from '@backoffice/shared/components/Typography.component';

const CardDetail = ({ data, title }) => {
  return (
    <CardDetailContainer>
      <Typography customVariant="text__body--md" customColor="secondary-darker">
        {title}
      </Typography>

      <CardDetailContent>
        <p>
          <span>CNPJ: </span>
          {title == 'Originador' ? data.cnpj_originador : data.cnpj_domicilio}
        </p>
        <p>
          <span>
            {title == 'Originador' ? 'Razão Social: ' : 'Título domicílio: '}{' '}
          </span>
          {title == 'Originador' ? data.razao_social : data.titulo_domicilio}
        </p>
        {title == 'Domicílio' && (
          <>
            <p>
              <span>Banco: </span>
              {data.banco}
            </p>
            <p>
              <span>Agência: </span>
              {data.agencia}
            </p>
            <p>
              <span>Conta: </span>
              {data.conta}
            </p>
            <p>
              <span>Tipo da conta: </span>
              {data.tipo_conta}
            </p>
          </>
        )}
      </CardDetailContent>
    </CardDetailContainer>
  );
};

export default CardDetail;
