import React, { useRef, useState } from 'react';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import ModalConfirmDelete from '@backoffice/private/components/Modal/ModalConfirmDelete.component';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import ROUTES from '@backoffice/shared/utils/routes.util';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import {
  Dispatchs,
  Selectors,
} from '../../../../ducks/parameters/banks/listBanks.duck';

const TableBankContainer = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: false,
    },
  });

  const handleDelete = (id) => {
    dispatchRedux.DELETE(id);
  };

  const handleOnRefresh = () => {
    dispatchRedux.GET();
  };

  const handleOnExport = async () => {
    const result = await dispatchRedux.GET({ isExport: true });

    return (result as any)?.results;
  };

  return (
    <TableBankComponent
      loading={selectorRedux.LOADING}
      results={selectorRedux.DATA_RESULTS}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleDelete={handleDelete}
      handleOnRefresh={handleOnRefresh}
      handleOnExport={handleOnExport}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const TableBankComponent = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  handleDelete,
  handleOnRefresh,
  handleOnExport,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_parametro_payhop_bancos',
  });
  const currentId: any = useRef();

  const [modalDelete, setModalDelete]: any = useState(null);

  const { limit, count, offset } = filters || {};

  const history = useHistory();

  const columns = [
    {
      width: 100,
      render: ({ id, bank_name }) => (
        <Box display="flex" gridGap={8}>
          <IconButton
            disabled={!permissions?.some((item) => item == 'edit')}
            onClick={() => {
              history.push(ROUTES.PRIVATE.PARAMETERS.EDIT(id, 'bank'));
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            disabled={!permissions?.some((item) => item == 'delete')}
            onClick={() => {
              currentId.current = {
                id,
                bank_name,
              };
              setModalDelete(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      title: 'Código',
      dataIndex: 'bank_code',
    },
    {
      title: 'ISPB',
      dataIndex: 'bank_ispb',
    },
    {
      title: 'Nome',
      dataIndex: 'bank_name',
    },
  ];

  const handleCloseModalDelete = () => setModalDelete(false);

  return (
    <Box gridGap={8} display="flex" flexDirection="column">
      <CardTitle>Listagem de Bancos</CardTitle>

      <CardTable
        count={count || 0}
        loading={loading}
        dataSource={results}
        rowsPerPage={limit}
        columns={columns}
        page={offset / limit}
        onRefresh={handleOnRefresh}
        onExport={handleOnExport}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        exporttype="xls"
        exportname="banks"
      />

      <ModalConfirmDelete
        open={modalDelete}
        onCancel={handleCloseModalDelete}
        title="Após a exclusão, o Banco não estará mais disponível para pagamento e/ou registro de Compromissos."
        description={`Deseja realmente excluir o cadastro do ${currentId.current?.bank_name} `}
        onConfirm={() => {
          handleDelete(currentId.current?.id);
          handleCloseModalDelete();
        }}
      />
    </Box>
  );
};

export default TableBankContainer;
