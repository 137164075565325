import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import CSVReader from 'react-csv-reader';
import { v4 } from 'uuid';
import { CSVLink } from 'react-csv';

import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { TextField, Grid } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import fetch from '@payhop/shared-utils/fetch.util';

import Button from '@backoffice/shared/components/Button.component';
import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import CardTable from '@backoffice/private/components/Card/CardTable';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useListCreditorsHook from '../../importAppointments/hooks/useListCreditors.hook';

import postUsersUploadMerchantService from '@backoffice/private/services/account/postUsersUploadMerchant.service';
import postUsersUploadMerchantCreditorIF from '@backoffice/private/services/account/postUsersUploadMerchantCreditorIF.service';
import postUsersUploadMerchantMaintenance from '@backoffice/private/services/account/postUsersUploadMerchantMaintenance.service';

const papaparseOptions = {
  encoding: 'UTF-8',
  header: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

interface fileIF {
  cnpj___cpf: string;
  error?: string;
}

const MerchantsScreen = () => {
  const csvLinkEl = useRef(null);
  const { isAdmin, user } = useUserHook();
  const handleNotification = useNotification();

  const creditors = useListCreditorsHook();

  const [key, setKey] = useState(v4());
  const [loading, setLoading] = useState(false);
  const [creditorId, setCreditorId] = useState('');
  const [partnerType, setPartnerType] = useState(null);
  const [dataFile, setDataFile] = useState<any>([]);
  const [file, setFile] = useState<any>(null);
  const [resultFile, setResultFile] = useState({});
  const [data, setData] = useState<any>([]);
  const [dataImport, setDataImport] = useState<any>([]);
  const [functype, setFunctype] = useState<number | null>(null);
  const [funcImport, setFuncImport] = useState<{
    active: boolean;
    value: string;
  }>({ active: false, value: '' });

  useEffect(() => {
    if (!isAdmin) {
      const { personId } = user || {};

      setCreditorId(personId);
      handleGetCreditorType(personId);
    }
  }, []);

  const handleForce = (data, fileInfo, originalFile) => {
    setDataImport(data);
    setDataFile(data);
    setFile(originalFile);
  };

  const handleClickSelectFile = () => {
    const obj = document.querySelector('.csv-input');

    (obj as any).click();
  };

  const handleReset = () => {
    setDataFile([]);
    setFile({});
    setKey(v4());
    setResultFile({});
    setPartnerType(null);
    setFuncImport({ active: false, value: '' });
  };

  const handleScrollTable = () => {
    const table = document.querySelector('.cardTable .MuiTableContainer-root');

    if (table) {
      (table as any).scrollLeft = 999999;
    }
  };

  const handleProcess = async () => {
    setLoading(true);

    let resultQ;

    if (functype === 0) {
      resultQ = await postUsersUploadMerchantMaintenance({
        creditor_id: creditorId,
        data: dataImport,
      });
    } else if (partnerType == 'if') {
      resultQ = await postUsersUploadMerchantCreditorIF({
        creditor_id: creditorId,
        data: dataImport,
      });
    } else {
      resultQ = await postUsersUploadMerchantService({
        creditor_id: creditorId,
        file,
      });
    }

    const [success, result] = resultQ;

    setLoading(false);

    if (success) {
      const { status } = result || {};
      const statusArr = status?.status || [];
      setCreditorId('');

      const shouldHaveErrors = statusArr.length > 0;
      let errorfound: any[] = [];
      if (Array.isArray(result)) {
        errorfound = result?.filter((obj) => obj?.erros?.length > 0);
      }

      if (!shouldHaveErrors && errorfound.length === 0) {
        handleNotification('Arquivo importado com sucesso!', 'success');
        handleReset();
        setResultFile(result);
      }

      if (functype === 0 && errorfound.length > 0) {
        const newData = dataImport;

        newData.forEach((oldData, i) => {
          if (result[i].erros.length > 0) {
            if (!oldData.error) {
              oldData.error = [];
            }
            oldData.error = result[i].erros;
            oldData.status = 'Erro';
          }
        });

        handleNotification(
          'Ocorreu um erro ao importar um ou mais registros. Verifique a coluna de erro.',
          'error'
        );

        setDataFile(newData);
        setFile(null);
        setKey(v4());
        handleScrollTable();
        return;
      }

      if (shouldHaveErrors) {
        let newDataFileArr: any = [];

        statusArr.map((s) => {
          const { item1, item2 } = s;
          const item1Split = item1.split(';');

          const newDataFile: any = columns.reduce(
            (acc, curr, idx) => ({
              ...acc,
              [curr.dataIndex]: item1Split[idx],
            }),
            {}
          );

          newDataFile.error = item2;

          newDataFileArr = [...newDataFileArr, newDataFile];
        });

        handleNotification(
          'Ocorreu um erro ao importar um ou mais registros. Verifique a coluna de erro.',
          'error'
        );

        setDataFile(newDataFileArr);
        setFile(null);
        setKey(v4());
        handleScrollTable();
      }

      setResultFile(result);
    } else {
      if (partnerType == 'if') {
        const lineErrors = Object.keys(result?.errors).map((item) => {
          return Number(item.substring(1, 2));
        });
        lineErrors.shift();

        const lineErrorsFiltered = lineErrors.map((item) => {
          return {
            line: item,
            message: result.errors[`[${item}].document_number`],
          };
        });

        const newData: fileIF[] = dataImport;

        newData.forEach((item) => {
          item.error = 'Estabelecimento não cadastrado';
        });

        lineErrorsFiltered.forEach((item) => {
          newData[item.line].error = item.message[0];
        });

        if (result?.errors?.length > 0) {
          handleNotification(result?.errors[0], 'error');
        } else {
          handleNotification(
            'Ocorreu um erro ao importar um ou mais registros. Verifique a coluna de erro.',
            'error'
          );
        }

        setDataFile(newData);
        setFile(null);
        setKey(v4());
        handleScrollTable();
      } else {
        handleNotification(result?.errors?.message[0], 'error');
      }
    }
  };

  const handleOnExport = () => {
    if (functype === 0) {
      setData([
        {
          'CNPJ ou ID Lote': '13916156000169',
          'Opt-in/Opt-out': 'Opt-in',
          'Recorrente?': 'Sim',
        },
      ]);
    } else if (partnerType == 'if') {
      setData([
        {
          'cnpj / cpf': '13916156000169',
        },
      ]);
    } else {
      setData([
        {
          'cnpj / cpf': '13916156000169',
          'Razao Social': 'T_IMPORTAÇÃO 4',
          'Nome Fantasia': 'T_IMPORTAÇÃO 4',
          'E-mail do Contato': 'payhop@payhop.com.br',
          'Nome do Contato': 'teste',
          'Telefone com DDD do Contato': '11999999999',
          endereco: 'RUA 3',
          numero: '3',
          Complemento: '143',
          Bairro: 'PAINEIRAS',
          Cidade: 'São Paulo',
          Estado: 'SP',
          CEP: '31700100',
        },
      ]);
    }

    setTimeout(() => {
      (csvLinkEl as any).current.link.click();
    });
  };

  const handleGetCreditorType = useCallback(async (id) => {
    const url = [`creditors/${id}/parameters?limit=9999`].join('');

    const creditorParametersService = () =>
      fetch(url, {
        method: 'GET',
        ms: 'CREDITOR',
        auth: true,
      });

    setFunctype(null);
    setFuncImport({ active: false, value: '' });
    const response = await creditorParametersService();
    const result = await response.json();

    result?.results.forEach((parameter) => {
      if (parameter.parameter_key === 'perfil_parceiro') {
        setPartnerType(parameter.parameter_value);
      }
      if (parameter.parameter_key === 'funcao_importacao_ec') {
        setFuncImport({ active: true, value: parameter.parameter_value });
      }
    });
  }, []);

  const columns = [
    {
      dataIndex: `cnpj___cpf`,
      key: 'cpf_cnpj',
      title: 'CNPJ / CPF',
    },
    {
      dataIndex: 'razao_social',
      key: 'razao_social',
      title: 'Razão Social',
    },
    {
      dataIndex: 'nome_fantasia',
      key: 'nome_fantasia',
      title: 'Nome Fantasia',
    },
    {
      dataIndex: 'e_mail_do_contato',
      key: 'e_mail',
      title: 'E-mail do Contato',
    },
    {
      dataIndex: 'nome_do_contato',
      key: 'nome_do_contato',
      title: 'Nome do Contato',
    },
    {
      dataIndex: 'telefone_com_ddd_do_contato',
      key: 'telefone_celular__com_ddd_',
      title: 'Telefone com DDD do Contato',
    },
    {
      dataIndex: 'endereco',
      key: 'endereco',
      title: 'Endereço',
    },
    {
      dataIndex: 'numero',
      key: 'numero',
      title: 'Número',
    },
    {
      dataIndex: 'complemento',
      key: 'complemento',
      title: 'Complemento',
    },
    {
      dataIndex: 'bairro',
      key: 'bairro',
      title: 'Bairro',
    },
    {
      dataIndex: 'cidade',
      key: 'cidade',
      title: 'Cidade',
    },
    {
      dataIndex: 'estado',
      key: 'estado',
      title: 'Estado',
    },
    {
      dataIndex: 'cep',
      key: 'cep',
      title: 'CEP',
    },
    {
      dataIndex: 'error',
      key: 'error',
      title: 'Erro',
      render: ({ error }) => (
        <HtmlTooltip
          title={
            <Fragment>
              <Typography
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {error}
              </Typography>
            </Fragment>
          }
        >
          <div>
            {error && error.slice(0, 35) + (error.length > 35 ? '...' : '')}
          </div>
        </HtmlTooltip>
      ),
    },
  ];

  const columnsIF = [
    {
      dataIndex: `cnpj___cpf`,
      key: 'cpf_cnpj',
      title: 'CNPJ / CPF',
    },
    {
      dataIndex: 'error',
      key: 'error',
      title: 'Erro',
      render: ({ error }) => (
        <HtmlTooltip
          title={
            <Fragment>
              <Typography
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {error}
              </Typography>
            </Fragment>
          }
        >
          <div>
            {error && error.slice(0, 35) + (error.length > 35 ? '...' : '')}
          </div>
        </HtmlTooltip>
      ),
    },
  ];

  const hasFile = !!file;

  const { permissions } = useAccountPermission({
    permission_slug: 'importacao_estabelecimento',
  });

  const func = [
    { id: 0, label: 'Manutenção' },
    { id: 1, label: 'Cadastro' },
  ];

  const columnsFunc = [
    {
      dataIndex: `cnpj_ou_id_lote`,
      key: 'document_number',
      title: 'CNPJ ou ID Lote',
    },
    {
      dataIndex: `opt_in_opt_out`,
      key: 'optin_optout',
      title: 'Opt-in/Opt-out',
    },
    {
      dataIndex: `recorrente_`,
      key: 'recorrente',
      title: 'Recorrente?',
    },
    {
      dataIndex: 'error',
      key: 'error',
      title: 'Erro',
      render: ({ error }) => (
        <HtmlTooltip
          title={
            <Fragment>
              <Typography
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {error}
              </Typography>
            </Fragment>
          }
        >
          <div>
            {error && error.slice(0, 35) + (error.length > 35 ? '...' : '')}
          </div>
        </HtmlTooltip>
      ),
    },
  ];

  const funcImportData = useMemo(() => {
    if (funcImport.value === 'todos') {
      return func;
    }

    if (funcImport.value === 'manutencao') {
      return [func[0]];
    }

    return [func[1]];
  }, [func, funcImport]);

  return (
    <Fragment key={key}>
      <PageLayout headerTitle header sidebar title="Estabelecimentos">
        {permissions?.some((item) => item == 'create') && (
          <Box display="flex" flexDirection="column" gridGap={32}>
            {isAdmin && (
              <Card>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <CardContent>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="select-provider">Fornecedor</InputLabel>
                        <Select
                          label="Fornecedor"
                          labelId="select-provider"
                          onChange={(_, child) => {
                            setCreditorId((child as any).props.value);
                            handleGetCreditorType((child as any).props.value);
                          }}
                        >
                          {creditors.map((c: any) => (
                            <MenuItem key={c.id} value={c.id}>
                              {c.company_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </CardContent>
                    <CardContent>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="func-provider">Função</InputLabel>
                        <Select
                          disabled={!funcImport.active}
                          value={functype}
                          label="Função"
                          labelId="functype"
                          onChange={(_, child) => {
                            setFunctype((child as any).props.value);
                          }}
                        >
                          {funcImportData.map((c: any) => (
                            <MenuItem key={c.id} value={c.id}>
                              {c.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </CardContent>
                  </Grid>
                  <Grid item xs={6}>
                    <CardContent>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          label="Tipo de Parceiro"
                          fullWidth
                          variant="outlined"
                          disabled={true}
                          name="consulta_agenda"
                          value={
                            partnerType
                              ? partnerType == 'if'
                                ? 'Instituição Financeira'
                                : 'Fornecedor'
                              : ''
                          }
                        />
                      </FormControl>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            )}
            <Card>
              <CardContent>
                <Box display="flex" gridGap={24} alignItems="center">
                  <Box flex={1} display="flex" gridColumnGap={10}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClickSelectFile}
                    >
                      Selecionar arquivo
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleOnExport}
                      disabled={!partnerType}
                    >
                      Baixar Modelo
                    </Button>
                    <Box display="none">
                      <CSVReader
                        cssClass="react-csv-input"
                        label="Selecionar arquivo"
                        onFileLoaded={handleForce}
                        parserOptions={papaparseOptions}
                      />
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            <div className="cardTable">
              <CardTable
                dataSource={dataFile}
                columns={
                  functype === 0
                    ? columnsFunc
                    : partnerType == 'if'
                    ? columnsIF
                    : columns
                }
                footer={false}
                totallines={(resultFile as any)?.status?.totalLines}
                totalerror={(resultFile as any)?.status?.totalError}
                exporttype={'xls'}
                exportname={
                  partnerType == 'if'
                    ? 'merchants-import-if'
                    : functype === 0
                    ? 'merchants-import-main'
                    : 'merchants-import-func'
                }
                onExport={async () => dataFile}
                tableProps={{
                  style: {
                    minWidth:
                      functype === 0 ? 300 : partnerType == 'if' ? 1000 : 3000,
                  },
                }}
              />
            </div>
            <Card>
              <Box component={CardActions} justifyContent="flex-end" mx={1}>
                <Button color="default" variant="text" onClick={handleReset}>
                  Limpar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  aria-label="show more"
                  onClick={handleProcess}
                  loading={loading}
                  disabled={loading || !hasFile || !creditorId}
                >
                  Processar dados
                </Button>
              </Box>
            </Card>
          </Box>
        )}

        {!permissions ||
          (!permissions?.some((item) => item == 'create') && (
            <ModuleNotPermited />
          ))}

        <CSVLink
          filename="modelo_estabelecimento.csv"
          data={data}
          ref={csvLinkEl}
          separator={';'}
        />
      </PageLayout>
    </Fragment>
  );
};

export default MerchantsScreen;
