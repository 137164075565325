import * as Yup from 'yup';

import validateCNPJ from '@payhop/shared-utils/validateCNPJ.util';
import validateCPF from '@payhop/shared-utils/validateCPF.util';
import messages from '@payhop/shared-utils/messagesSchema.util';

const handleValidateDocument = (document) => {
  const documentLength = !!document && String(document).length;

  if (documentLength === 11 && validateCPF(document)) {
    return true;
  }

  if (documentLength === 14 && validateCNPJ(document)) {
    return true;
  }

  return false;
};

export const validationSchemaCreate = Yup.object({
  document_number: Yup.string()
    .nullable()
    .required(messages.REQUIRED)
    .test('document_number', 'Documento inválido', (value) =>
      handleValidateDocument(value)
    ),
  name: Yup.string().default(''),
  status: Yup.string().default(''),
  creditor_id: Yup.string().default(''),
});

export const validationSchemaCreateIF = validationSchemaCreate.shape({
  initial_date: Yup.string().required(messages.REQUIRED).nullable(),
  end_date: Yup.date().required(messages.REQUIRED).nullable(),
  document_acquirers: Yup.array().required(messages.REQUIRED).default([]),
  payment_arrangements: Yup.array().required(messages.REQUIRED).default([]),
});
