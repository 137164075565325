const STATUS_DEAL = [
  {
    label: 'Todos',
    value: 'todos',
  },
  {
    label: 'A aprovar',
    value: 'a_aprovar',
  },
  {
    label: 'Aprovado',
    value: 'aprovado',
  },
  {
    label: 'Cancelado',
    value: 'cancelado',
  },
  {
    label: 'Liquidado',
    value: 'liquidado',
  },
  {
    label: 'Recusado',
    value: 'recusado',
  },
  {
    label: 'Em processamento',
    value: 'em_processamento',
  },
  {
    label: 'Insuficiente',
    value: 'insuficiente',
  },
  {
    label: 'Baixado',
    value: 'baixado',
  },
  {
    label: 'Expirado',
    value: 'expirado',
  },
  {
    label: 'Em contestação',
    value: 'em_contestacao',
  },
];

export default STATUS_DEAL;
