import * as Yup from 'yup';

import validateCNPJ from '@payhop/shared-utils/validateCNPJ.util';
import validateCPF from '@payhop/shared-utils/validateCPF.util';
import messages from '@backoffice/shared/utils/messagesSchema.util';

export const merchantValidationSchema = Yup.object({
  lead_account_id: Yup.string(),
  origin_account: Yup.string().default('2'),
  type_account: Yup.string().default('1'),
  type_person: Yup.string().default('2'),
  document_number: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .test('document_number', 'Documento Inválido', (value) => {
      const documentLength: any = !!value && String(value).length;

      if (documentLength < 11) {
        return false;
      }

      if (documentLength === 11 && validateCPF(value)) {
        return true;
      }

      if (documentLength === 14 && validateCNPJ(value)) {
        return true;
      }

      return false;
    }),
  creditor_id: Yup.string().required(messages.REQUIRED).default(''),
  trading_name: Yup.string().required(messages.REQUIRED).default(''),
  company_name: Yup.string().required(messages.REQUIRED).default(''),
  contact_name: Yup.string().required(messages.REQUIRED).default(''),
  contact_document_number: Yup.string().notRequired(),
  phone_number: Yup.string().required(messages.REQUIRED).default(''),
  address_zip_code: Yup.string().required(messages.REQUIRED).default(''),
  address: Yup.string().required(messages.REQUIRED).default(''),
  address_number: Yup.string().required(messages.REQUIRED).default(''),
  address_complement: Yup.string().notRequired().default(''),
  address_neighborhood: Yup.string().required(messages.REQUIRED).default(''),
  address_city: Yup.string().required(messages.REQUIRED).default(''),
  address_state: Yup.string().required(messages.REQUIRED).default(''),
  contact_email: Yup.string()
    .required(messages.REQUIRED)
    .email(messages.VALIDATION_EMAIL)
    .default(''),
  legal_nature_description: Yup.string().notRequired().default(''),
});
