import React, { useEffect } from 'react';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/originators/listCreditorOriginators.duck';
import { Dispatchs as DispatchsOriginators } from '@backoffice/private/ducks/creditors/originators/creditorOriginators.duck';

import * as dialogCreditorCreateOriginatorDuck from '@backoffice/private/ducks/creditors/dialogs/dialogCreditorCreateOriginator.duck';
import * as dialogCreditorSeeDomiciles from '@backoffice/private/ducks/creditors/dialogs/dialogCreditorSeeDomiciles.duck';
import * as dialogCreditorCreateDomicile from '@backoffice/private/ducks/creditors/dialogs/dialogCreditorCreateDomicile.duck';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import TableCreditorOriginatorComponent from './TableOriginator.component';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

const TableCreditorOriginatorContainer = () => {
  const dispatch = useDispatch();
  const handleNotification = useNotification();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const dispatchReduxOriginator = {
    UPDATE: flow(DispatchsOriginators.update, dispatch),
  };

  const dispatchDialogCreditorCreateOriginator = {
    OPEN: flow(dialogCreditorCreateOriginatorDuck.Dispatchs.open, dispatch),
  };

  const dispatchDialogCreditorSeeDomiciles = {
    OPEN: flow(dialogCreditorSeeDomiciles.Dispatchs.open, dispatch),
  };
  const dispatchDialogCreditorCreateDomicile = {
    OPEN: flow(dialogCreditorCreateDomicile.Dispatchs.open, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage } = useCrudRead({
    dispatchRedux,
    selectorRedux,
  });

  const handleOnRefresh = () => {
    dispatchRedux.GET();
  };

  const handleOnExport = async () => {
    const result = await dispatchRedux.GET({ isExport: true });

    return (result as any)?.results;
  };

  const handleChangeOriginatorStatus = (obj) => {
    const objData = {
      razao_social: obj.razao_social,
      status: obj.status,
      cnpj_originador: obj.cnpj_originador,
    };

    objData.status = obj.status === 'Ativo' ? 'Inativo' : 'Ativo';

    fetchData(objData);
  };

  const fetchData = async (obj) => {
    try {
      const response = await dispatchReduxOriginator.UPDATE({
        originadores: [obj],
      });

      dispatchRedux.GET();
    } catch (error) {
      handleNotification(`Erro ao alterar status: ${error}`, 'error');
      console.error('Erro na requisição:', error);
    }
  };

  return (
    <TableCreditorOriginatorComponent
      loading={selectorRedux.LOADING}
      results={selectorRedux.DATA_RESULTS}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleOpenDialogCreateOriginator={
        dispatchDialogCreditorCreateOriginator.OPEN
      }
      handleSeeDomiciles={dispatchDialogCreditorSeeDomiciles.OPEN}
      handleOpenDialogCreateDomicile={dispatchDialogCreditorCreateDomicile.OPEN}
      handleOnRefresh={handleOnRefresh}
      handleOnExport={handleOnExport}
      handleChangeOriginatorStatus={handleChangeOriginatorStatus}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

export default TableCreditorOriginatorContainer;
