import { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import { useFormikContext, Formik } from 'formik';
import { v4 } from 'uuid';

import messages from '@payhop/shared-utils/messagesSchema.util';
import PageLayout from '@backoffice/private/layouts/Page.layout';

import CardForm from '@backoffice/private/components/Card/CardForm';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/users/user.duck';

import {
  Dispatchs as DispatchsProfiles,
  Selectors as SelectorsProfiles,
} from '@backoffice/private/ducks/profiles/listProfiles.duck';

import useCrudCreateEdit from '@backoffice/shared/hooks/useCrudCreateEdit.hook';
import ROUTES from '@backoffice/shared/utils/routes.util';

const texts = {
  titleEdit: 'Editar usuário',
  titleCreate: 'Cadastro de usuário',
};

const validationSchemaCreate = Yup.object({
  profile_id: Yup.string().required(messages.REQUIRED).default(''),
  origin: Yup.string().default('2'),
  type: Yup.string().default('3'),
  name: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .min(8, 'O nome deve possuir mais de 8 caracteres'),
  email: Yup.string()
    .required(messages.REQUIRED)
    .email(messages.EMAIL)
    .default(''),
  password: Yup.string()
    .required(messages.REQUIRED)
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      messages.PASSWORD_SPECS
    )
    .default(''),
  phone_number: Yup.string()
    .required(messages.REQUIRED)
    .matches(
      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})?(\d{4}))$/,
      'O telefone é inválido'
    )
    .min(11, 'O telefone é inválido'),
});

const validationSchemaUpdate = Yup.object({
  profile_id: Yup.string().required(''),
  name: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .min(8, 'O nome deve possuir mais de 8 caracteres'),
  email: Yup.string()
    .required(messages.REQUIRED)
    .email(messages.EMAIL)
    .default(''),
  phone_number: Yup.string()
    .required(messages.REQUIRED)
    .matches(
      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})?(\d{4}))$/,
      'O telefone é inválido'
    )
    .min(11, 'O telefone é inválido'),
});

const CreateUserContainer = () => {
  const [key, setKey] = useState(v4());
  const history = useHistory();

  const { values, setValues, resetForm, errors } = useFormikContext();

  const dispatch = useDispatch();

  const dispatchRedux = {
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    CREATE: flow(Dispatchs.create, dispatch),
    UPDATE: flow(Dispatchs.update, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    FETCHED: useSelector(Selectors.fetched),
    ERROR: useSelector(Selectors.error),
  };

  const dispatchProfilesRedux = {
    GET: flow(DispatchsProfiles.get, dispatch),
    UPDATE_FILTERS: flow(DispatchsProfiles.updateFilters, dispatch),
  };

  const selectorProfilesRedux = {
    DATA: useSelector(SelectorsProfiles.data),
  };

  const { isEdit, handleCreate, handleUpdate } = useCrudCreateEdit({
    dispatchRedux,
    selectorRedux,
  });

  useEffect(() => {
    if (selectorRedux.FETCHED && isEdit) {
      setValues({
        name: selectorRedux.DATA.name,
        email: selectorRedux.DATA.email,
        phone_number: selectorRedux.DATA.phone_number,
        profile_id: selectorRedux.DATA.profile_id,
      });

      setTimeout(() => {
        setKey(v4());
      });
    }
  }, [selectorRedux.FETCHED, isEdit]);

  useEffect(() => {
    const { successAction } = selectorRedux.DATA || {};

    if (successAction === true && !isEdit) {
      resetForm();

      history.push(ROUTES.PRIVATE.ACCESS.ROOT);
    }
  }, [selectorRedux.DATA]);

  useEffect(() => {
    dispatchProfilesRedux.UPDATE_FILTERS({ type: 'PAYHOP', active: true });
    dispatchProfilesRedux.GET();
  }, []);

  return (
    <CreateUserScreen
      key={key}
      isEdit={isEdit}
      loading={selectorRedux.LOADING}
      fetched={selectorRedux.FETCHED}
      errorsForm={errors}
      profiles={selectorProfilesRedux.DATA}
      handleSubmit={() =>
        isEdit
          ? handleUpdate({
              user_name: (values as any).name,
              email: (values as any).email,
              phone_number: (values as any).phone_number,
              profile_id: (values as any).profile_id,
            })
          : handleCreate(values)
      }
    />
  );
};

const CreateUserScreen = ({
  isEdit,
  loading,
  fetched,
  handleSubmit,
  errorsForm,
  profiles,
}) => {
  const history = useHistory();

  const titlePage = useMemo(
    () => (isEdit ? texts.titleEdit : texts.titleCreate),
    [isEdit]
  );

  const formViewSchema = useMemo(
    () => [
      [
        {
          name: 'profile_id',
          label: 'Perfil',
          type: 'select',
          required: true,
          options: (profiles?.results || []).map((p) => ({
            value: p.id,
            label: p.description,
          })),
        },
      ],
      [
        {
          name: 'name',
          label: 'Nome Completo',
          required: true,
        },
      ],
      [
        {
          name: 'email',
          label: 'E-mail',
          required: true,
        },
        {
          name: 'phone_number',
          label: 'Telefone',
          required: true,
          mask: 'mobile',
        },
        !isEdit && {
          name: 'password',
          label: 'Senha',
          required: true,
          password: true,
        },
      ],
    ],
    [profiles]
  );

  const handleBack = () => history.goBack();

  return (
    <PageLayout headerTitle header sidebar title={titlePage}>
      <Box display="flex" gridGap={24} flexDirection="column">
        <CardForm formik fields={formViewSchema as any} />

        <CardFormFooter
          hasError={Object.keys(errorsForm).length > 0}
          onSubmit={handleSubmit}
          onCancel={handleBack}
          loading={isEdit ? loading && fetched : loading}
        />
      </Box>

      {isEdit && loading && !fetched && (
        <Box
          display="flex"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          width="100%"
          height="100%"
          zIndex={9999}
          alignItems="center"
          justifyContent="center"
          bgcolor="#ffffffd1"
        >
          <CircularProgress />
        </Box>
      )}
    </PageLayout>
  );
};

export default function CreateEditUserFormik() {
  const { isEdit } = useIsEdit();

  const validationSchema = isEdit
    ? validationSchemaUpdate
    : validationSchemaCreate;

  return (
    <Formik
      initialValues={{
        ...validationSchema.cast(),
      }}
      validateOnBlur
      validateOnMount
      onSubmit={() => {}}
      validationSchema={validationSchema}
    >
      <CreateUserContainer />
    </Formik>
  );
}
