import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Box, Checkbox } from '@material-ui/core';

import Accordion from '@backoffice/private/components/Accordion/Accordion.component';
import InputDocument from '@backoffice/private/components/Form/Inputs/InputDocument.component';
import InputText from '@backoffice/private/components/Form/Inputs/InputText.component';
import InputSelectNew from '@backoffice/private/components/Form/Inputs/InputSelectNew.component';

import {
  TRANSACTION_TYPES,
  TRANSACTION_VALUES,
} from '@backoffice/private/constants/contract/transaction.constant';
import InputTextWithMask from '@backoffice/private/components/Form/Inputs/InputTextWithMask.component';
import {
  CONTRACT_TYPES,
  CONTRACT_TYPES_VALUES,
} from '@backoffice/private/constants/contract/type.constant';
import { MODALITY_TYPES } from '@backoffice/private/constants/contract/modality.constant';
import InputUploadImage from '@backoffice/private/components/Form/Inputs/InputUploadImage.component';

type ContractDataStepProps = {
  handleGetMerchant: (
    documentNumber: string,
    creditor_id?: string
  ) => Promise<void>;
  parameters: {
    tipo_transacao: string;
    tipo_contrato: string;
    isGcap: boolean;
  };
  values: {
    creditor_id: string;
    title: string;
    description: string;
    document_number: string;
    code: string;
    type: string;
    contract_type: string;
    contract_modality: string;
    total_amount: number;
    deal_gcap: boolean;
  };
  partnerIF: boolean;
  isColateral: boolean;
  setFieldValue: any;
  merchantName?: string;
  errors: any;
  setColateral: Dispatch<SetStateAction<boolean>>;
};

const ContractDataStep = ({
  handleGetMerchant,
  parameters,
  values,
  merchantName,
  setFieldValue,
  errors,
  partnerIF,
  setColateral,
  isColateral,
}: ContractDataStepProps) => {
  const { tipo_transacao, tipo_contrato, isGcap } = parameters;

  const isContractAntecipation = values.type == '3';
  const isContractSmoke = values.contract_modality == 'fumaca';
  const isContractGaranteed = values.type == '1';
  const isContractCharge = values.type == '4';
  const contractModalityInputHasError = !!errors.contract_modality;

  //This const is deprecated because the rules of deal changes
  const canSelectContractType = isContractGaranteed
    ? values.contract_type
    : CONTRACT_TYPES_VALUES['troca_titularidade'];

  useEffect(() => {
    if (values.contract_modality === 'performado') {
      setFieldValue('deal_gcap', false);
      setColateral(false);
    }
  }, [values]);

  return (
    <Accordion title="Dados do Contrato">
      <Box
        marginTop={'24px'}
        display={'flex'}
        flexDirection={'column'}
        gridRowGap={16}
      >
        <Box display={'flex'} gridColumnGap={16}>
          <InputDocument
            name="document_number"
            label="CNPJ ou CPF"
            getValueObject={() =>
              handleGetMerchant(values.document_number, values?.creditor_id)
            }
          />
          <InputText
            label="Razão Social/Nome"
            name="name"
            value={merchantName}
            disabled
            required
          />
        </Box>
        <Box display={'flex'} gridColumnGap={16}>
          <InputText
            label="Título"
            name="title"
            value={values.title}
            required
          />
          <InputText label="Código" name="code" value={values.code} required />
          <InputText
            label="Descrição"
            name="description"
            value={values.description}
            required
          />
        </Box>
        <Box display="flex" alignItems={'center'} gridColumnGap={16}>
          <InputSelectNew
            name="contract_modality"
            label="Modalidade *"
            labelId="modality"
            hasError={contractModalityInputHasError}
            helperText="É necessario selecionar um tipo de contrato"
            options={MODALITY_TYPES}
            keyName="value"
            optionName="label"
            value={values.contract_modality}
            onChange={(_, option) => {
              setFieldValue('contract_modality', option.props.value);
            }}
            required
          />
          {isGcap && isContractSmoke && (
            <Box display={'flex'} width={480} alignItems={'center'}>
              Gestão de colateral?{' '}
              <Checkbox
                color="primary"
                onChange={(ev, checked) => {
                  setColateral(checked);
                  setFieldValue('deal_gcap', checked);
                  if (checked) {
                    setFieldValue('contract_type', '2');
                  }
                }}
                name={'colateral'}
                checked={values.deal_gcap}
              />
            </Box>
          )}
          <InputSelectNew
            name="type"
            label="Tipo de transação"
            labelId="type"
            disabled={tipo_transacao !== 'ambos' && !isColateral}
            options={
              isColateral
                ? TRANSACTION_TYPES['colateral']
                : TRANSACTION_TYPES[tipo_transacao]
            }
            keyName="value"
            optionName="label"
            value={
              tipo_transacao !== 'ambos' && !isColateral
                ? TRANSACTION_VALUES[tipo_transacao]
                : values.type
            }
            onChange={(_, option) => {
              setFieldValue('type', option.props.value);
              if (option.props.value == '4') {
                setFieldValue('expiration_date', '');
              }
            }}
            required
          />
        </Box>
        <Box display={'flex'} gridColumnGap={16}>
          <InputSelectNew
            name="contract_type"
            label="Tipo de contrato"
            labelId="contract_type"
            disabled={tipo_contrato !== 'todos' || isColateral}
            options={
              isColateral
                ? CONTRACT_TYPES['cessao']
                : CONTRACT_TYPES[tipo_contrato]
            }
            keyName="value"
            optionName="label"
            value={
              tipo_contrato !== 'todos' && !isColateral
                ? CONTRACT_TYPES_VALUES[tipo_contrato]
                : values.contract_type
            }
            onChange={(_, option) => {
              setFieldValue('contract_type', option.props.value);
            }}
            required
          />
          <InputTextWithMask
            name="total_amount"
            label="Valor solicitado"
            mask="currency"
            hasError={errors.total_amount}
            helperText={'Valor solicitado deve ser maior que 0'}
            required
            onChange={(value) => {
              setFieldValue('total_amount', value.floatValue);
            }}
          />
        </Box>
        {!partnerIF && isContractCharge && (
          <Box>
            <InputUploadImage
              name="tcd"
              importLabel="Termo de confissão de dívida"
            />
          </Box>
        )}
      </Box>
    </Accordion>
  );
};

export default ContractDataStep;
