import React, { useState } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core';

type InputSelectProps = {
  name: string;
  label?: string;
  labelId: string;
  options: Array<Record<string, unknown>>;
  keyName: string;
  optionName: string;
  required: boolean;
  value: string | null;
  disabled?: boolean;
  inputWidth?: string;
  onChange?: (e, value) => void;
  hasError?: boolean;
  helperText?: string;
  onBlur?: () => void;
  style?: any;
};

const InputSelectNew = ({
  name,
  label,
  labelId,
  options,
  keyName,
  optionName,
  required,
  value,
  disabled,
  inputWidth,
  onChange,
  hasError,
  helperText,
  onBlur,
  style,
}: InputSelectProps) => {
  const [haveError, setHaveError] = useState(false);

  const handleInputError = () => {
    if (hasError) {
      setHaveError(true);
    } else {
      setHaveError(false);
    }
  };
  return (
    <Box style={style} width={inputWidth ? inputWidth : '100%'}>
      <FormControl fullWidth variant="outlined" error={haveError}>
        {label && <InputLabel id={labelId}>{label}</InputLabel>}
        <Select
          id={name}
          label={label}
          labelId={labelId}
          value={value}
          name={name}
          onChange={onChange}
          disabled={disabled}
          required={required}
          onBlur={() => {
            handleInputError();
            onBlur && onBlur();
          }}
        >
          {(options ?? []).map((v: any) => (
            <MenuItem
              id={`select-${v.document_number ?? name}`}
              key={v[keyName]}
              value={v[keyName]}
            >
              {v[optionName]}
            </MenuItem>
          ))}
        </Select>
        {haveError && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default InputSelectNew;
