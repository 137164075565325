import React, { useEffect, useState } from 'react';

import {
  Box,
  Card,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from '@material-ui/core';
import CardTitle from '@backoffice/private/components/Card/CardTitle';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

import Button from '@material-ui/core/Button';
import TypographyComponent from '@backoffice/shared/components/Typography.component';
import getTCD from '../../../services/getTcd.service';

import CloseIcon from '@material-ui/icons/Close';
import InputUploadImage from '@backoffice/private/components/Form/Inputs/InputUploadImage.component';
import CreateForm from '@backoffice/private/components/Form/CreateForm';
import { Formik } from 'formik';
import uploadTCD from '../../../services/uploadTcd.service';

const DebtAknowledgment = ({ dealData }) => {
  const [file, setFile] = useState('');
  const [updateOpen, setUpdateOpen] = useState(false);
  useEffect(() => {
    handleGetTcd();
  }, []);

  const handleNotification = useNotification();

  const handleGetTcd = async () => {
    const tcd: any = await getTCD(dealData.id);

    setFile(tcd[0]?.fileUrl);
  };

  const handleClose = () => {
    setUpdateOpen(false);
  };

  const handleUpdateTCD = async (tcd) => {
    const dataUpload = {
      id: dealData.id,
      file: tcd,
    };
    await uploadTCD(dataUpload);

    handleNotification('Arquivo substituido com sucesso', 'success');

    setUpdateOpen(false);

    handleGetTcd();
  };

  return (
    <>
      <Box gridGap={8} display="flex" flexDirection="column">
        <Box
          display={'flex'}
          justifyContent="space-between"
          alignItems={'center'}
        >
          <CardTitle>Termo de confissão de dívida</CardTitle>
          <Button
            color="primary"
            variant="contained"
            aria-label="show more"
            size="small"
            css={{ width: 230 }}
            onClick={() => setUpdateOpen(true)}
          >
            Adicionar novo arquivo
          </Button>
        </Box>
        <Card css={{ padding: '8px' }}>
          {file ? (
            <embed src={file} width="100%" height="1000px" />
          ) : (
            <TypographyComponent customVariant="text__body--md">
              Não há termo anexado
            </TypographyComponent>
          )}
        </Card>
      </Box>

      {/* Dialog to update PDF */}
      <Dialog
        open={updateOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>
          Atualizar TCD
          <IconButton
            css={{ position: 'absolute', right: 0, top: '5px' }}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{ tcd: null }}
            onSubmit={(values) => handleUpdateTCD(values.tcd)}
          >
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <>
                  <InputUploadImage
                    name="tcd"
                    importLabel="Termo de confissão de dívida"
                  />
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    aria-label="show more"
                    type="submit"
                    css={{ marginTop: '16px' }}
                    disabled={!values.tcd}
                  >
                    Atualizar TCD
                  </Button>
                </>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DebtAknowledgment;
