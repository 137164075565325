import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Box } from '@material-ui/core';
import CardForm from '@backoffice/private/components/Card/CardForm';
import { useFormikContext, Formik } from 'formik';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import messages from '@payhop/shared-utils/messagesSchema.util';

import useCrudNotification from '@backoffice/shared/hooks/useCrudNotification.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/access/creditorAccess.duck';

import { Dispatchs as DispatchUnits } from '@backoffice/private/ducks/creditors/access/listCreditorAccess.duck';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';

const validationSchema = Yup.object({
  profile_id: Yup.string().required(messages.REQUIRED).default(''),
  origin: Yup.string().default('2'),
  type: Yup.string().default('2'),
  id: Yup.string().default(''),
  name: Yup.string().default('').required(messages.REQUIRED),
  email: Yup.string().default('').required(messages.REQUIRED),
  phone_number: Yup.string().default('').required(messages.REQUIRED),
});

const FormCreditorUsersContainer = ({ creditorId = '', profiles }) => {
  const { isAdmin } = useUserHook();
  const history = useHistory();

  const [activeProfiles, setActiveProfiles] = useState([]);

  const dispatch = useDispatch();

  const { values, errors, resetForm } = useFormikContext();

  const dispatchRedux = {
    CREATE: flow(Dispatchs.create, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    RESET_NOTIFICATION: flow(Dispatchs.reset_notification, dispatch),
  };

  const dispatchUnitsRedux = {
    GET: flow(DispatchUnits.get, dispatch),
  };

  const selectorRedux = {
    LOADING: useSelector(Selectors.loading),
    DATA: useSelector(Selectors.data),
    NOTIFICATION: useSelector(Selectors.notification),
  };

  useEffect(() => {
    const { successAction } = selectorRedux.DATA || {};

    if (successAction === true) {
      resetForm();

      dispatchUnitsRedux.GET(creditorId);

      dispatchRedux.RESET();
    }
  }, [selectorRedux.DATA]);

  useEffect(() => {
    if (!isAdmin) {
      setActiveProfiles(
        profiles.filter(
          (item) => item.description !== 'Administrador Fornecedor'
        )
      );
    }
  }, [profiles]);

  useCrudNotification({
    selectorRedux,
    dispatchRedux,
  });

  const handleBack = () => history.goBack();

  return (
    <FormCreditorUsersComponent
      loading={selectorRedux.LOADING}
      errorsForm={errors}
      handleBack={handleBack}
      profiles={isAdmin ? profiles : activeProfiles}
      handleSubmit={() => dispatchRedux.CREATE(values, creditorId)}
    />
  );
};

const FormCreditorUsersComponent = ({
  loading,
  handleSubmit,
  errorsForm,
  handleBack,
  profiles,
}) => {
  return (
    <Box gridGap={24} display="flex" flexDirection="column">
      <Box gridGap={8} display="flex" flexDirection="column">
        <CardForm
          formik
          fields={[
            [
              {
                name: 'profile_id',
                label: 'Perfil',
                type: 'select',
                required: true,
                options: (profiles || []).map((p) => ({
                  value: p.id,
                  label: p.description,
                })),
              },

              {
                name: 'name',
                label: 'Nome',
                required: true,
              },
              {
                name: 'email',
                label: 'Email',
                required: true,
              },
              {
                name: 'phone_number',
                label: 'Telefone',
                mask: 'mobile',
                required: true,
              },
            ],
          ]}
        />
      </Box>

      <CardFormFooter
        hasError={Object.keys(errorsForm).length > 0}
        onSubmit={handleSubmit}
        onCancel={handleBack}
        loading={loading}
      />
    </Box>
  );
};
export default function FormCreditorUsersFormik({ creditorId, profiles }) {
  return (
    <Formik
      initialValues={{ ...validationSchema.cast() }}
      onSubmit={() => {}}
      validateOnMount
      validationSchema={validationSchema}
    >
      <FormCreditorUsersContainer creditorId={creditorId} profiles={profiles} />
    </Formik>
  );
}
