import React from 'react';
import { Formik } from 'formik';
import { ObjectSchema } from 'yup';

interface CreateFormProps {
  schema: ObjectSchema;
  validateOnMount?: boolean;
  children: (formikProps: any) => React.ReactNode;
}

const CreateForm = ({ schema, children, validateOnMount }: CreateFormProps) => {
  return (
    <Formik
      initialValues={{ ...schema.cast() }}
      validateOnChange
      validateOnBlur
      validateOnMount={validateOnMount}
      onSubmit={() => {}}
      validationSchema={schema}
    >
      {(formikProps) => <>{children(formikProps)} </>}
    </Formik>
  );
};

export default CreateForm;
