import flow from 'lodash/flow';
import get from 'lodash/get';

const context = `private/creditors/dialogs/dialogCreateDomicile`;
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
  error?: any;
};

export const Types = {
  OPEN: `${context}/OPEN`,
  CLOSE: `${context}/CLOSE`,
  CHANGE_STEP: `${context}/STEP`,
};

const initialState: any = {
  visible: null,
  payload: null,
  step: '1',
};

export default (state = initialState, action: Actions) => {
  const { type, payload } = action;

  const reducers = {
    [Types.OPEN]: {
      visible: true,
      payload,
      step: '1',
    },
    [Types.CLOSE]: {
      ...initialState,
    },
    [Types.CHANGE_STEP]: {
      visible: true,
      step: payload,
    },
  };

  return reducers[type] || state;
};

export const openDispatch = (payload) => async (dispatch) => {
  dispatch({
    type: Types.OPEN,
    payload,
  });
};

export const closeDispatch = (func) => async (dispatch) => {
  func();
  dispatch({
    type: Types.CLOSE,
    step: '1',
  });
};

export const controlStep = (payload) => async (dispatch) => {
  dispatch({
    type: Types.CHANGE_STEP,
    payload,
  });
};

const selectRoot = (state: any) => get(state, rootPathState);
const selectVisible = (state: any) => get(state, 'visible');
const selectPayload = (state: any) => get(state, 'payload');
const selectStep = (state: any) => get(state, 'step');

export const Selectors = {
  visible: flow(selectRoot, selectVisible),
  payload: flow(selectRoot, selectPayload),
  step: flow(selectRoot, selectStep),
};

export const Dispatchs = {
  open: openDispatch,
  close: closeDispatch,
  controlStep: controlStep,
};
