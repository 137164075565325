import React from 'react';

import { Box } from '@material-ui/core';

import Typography from '@backoffice/shared/components/Typography.component';

const ModuleNotPermited = () => {
  return (
    <Box display="flex" flexDirection="column" gridGap={32}>
      <Typography>Você não tem acesso a visualização desse módulo</Typography>
    </Box>
  );
};

export default ModuleNotPermited;
