import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';

import * as listParametersDuck from '@backoffice/private/ducks/creditors/parameters/creditorParameters.duck';

import { Parameter } from '../@types/models/Parameter';
import { Result } from '../@types/models/Result';

type GenericObject = { [key: string]: any };

type ResultParameters = Result & {
  results: Parameter[];
};

export default function useCreditorParameters() {
  const [parameters, setParameters] = useState<GenericObject>({});

  const dispatch = useDispatch();

  const dispatchRedux = {
    GET: flow(listParametersDuck.Dispatchs.get, dispatch),
  };

  const handleSetParameters = async (id: string, parameters: Array<string>) => {
    const result: any = await dispatchRedux.GET(id);

    if (result) {
      const data = result?.results;
      const filteredParameters: Parameter[] = [];

      data.forEach((item, index) => {
        parameters.forEach((p) => {
          if (item.parameter_key == p) {
            filteredParameters.push(item);
          }
        });
      });

      const parametersObj: GenericObject = filteredParameters.reduce(
        (prev, curr) => {
          prev[curr.parameter_key] = curr.parameter_value;
          return prev;
        },
        {}
      );

      setParameters(parametersObj);
    }
  };

  return { parameters, handleSetParameters };
}
