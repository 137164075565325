import React, { useState, useEffect } from 'react';
import { Formik, Field, useFormikContext } from 'formik';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import CardSearch from '@backoffice/private/components/Card/CardSearch';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/contracts/contract.duck';
import { resetError } from '@payhop/shared-ducks/error.duck';

import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';

const SearchContract = () => {
  const [key, setKey] = useState(v4());
  const dispatch = useDispatch();

  const { values, submitForm, resetForm }: any = useFormikContext();

  const dispatchRedux = {
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
  };

  const schemaSearch = [
    [
      {
        label: 'Referência Externa ou Identificador Contrato',
        name: 'external_reference',
      },
    ],
  ];

  return (
    <>
      <CardTitle>Buscar Contrato</CardTitle>

      <CardSearch
        formik
        key={key}
        fields={schemaSearch as any}
        onSubmit={submitForm}
        disabled={values.external_reference == ''}
        onReset={() => {
          setKey(v4());
          resetForm();
          dispatchRedux.RESET();
        }}
      />
    </>
  );
};

export default function SearchContractComponent() {
  const { error } = useSelector((state: any) => state.shared);
  const handleNotification = useNotification();

  const dispatch = useDispatch();

  const dispatchRedux = {
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    FETCHED: useSelector(Selectors.fetched),
  };

  useEffect(() => {
    if (error.messages?.[0] !== undefined && error.error) {
      handleNotification(error.messages?.[0], 'error');
      dispatch(resetError());
    }
  }, [error.messages]);

  const handleSubmit = async (values) => {
    dispatchRedux.GET(values.external_reference);
    return true;
  };

  return (
    <Formik
      initialValues={{
        external_reference: '',
      }}
      onSubmit={handleSubmit}
    >
      <SearchContract />
    </Formik>
  );
}
