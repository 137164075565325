export default function alphabeticOrder(array) {
  const arrayFiltered = array.sort((a: any, b: any) => {
    const aLabel = a.label;
    const bLabel = b.label;

    const aLabelFiltered = aLabel[0].toUpperCase() + aLabel.substring(1);
    const bLabelFiltered = bLabel[0].toUpperCase() + bLabel.substring(1);

    if (aLabelFiltered > bLabelFiltered) {
      return 1;
    }

    if (aLabelFiltered < bLabelFiltered) {
      return -1;
    }

    return 0;
  });

  return arrayFiltered;
}
