import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/accounts/accountResources.duck';

interface UseAccountPermissionProps {
  permission_slug: string;
}

const useAccountPermission = ({
  permission_slug,
}: UseAccountPermissionProps) => {
  const [permissions, setPermissions] = useState<Array<string>>();
  const dispatch = useDispatch();
  const dispatchRedux = {
    GET: flow(Dispatchs.get, dispatch),
  };
  const selectorRedux = {
    DATA: useSelector(Selectors.data),
  };

  useEffect(() => {
    if (!selectorRedux.DATA) {
      dispatchRedux.GET();
    }
  }, []);

  useEffect(() => {
    if (selectorRedux.DATA) {
      selectorRedux.DATA.forEach((item) => {
        if (item.resource_slug == permission_slug) {
          setPermissions(item.permitions);
        }
      });
    }
  }, [selectorRedux.DATA]);

  return {
    permissions,
  };
};

export default useAccountPermission;
