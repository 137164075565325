import flow from 'lodash/flow';
import get from 'lodash/get';

const context = `private/users/dialogs/dialogCreatePayhopUser`;
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
  error?: any;
};

export const Types = {
  OPEN: `${context}/OPEN`,
  CLOSE: `${context}/CLOSE`,
};

const initialState: any = {
  visible: null,
  payload: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload } = action;

  const reducers = {
    [Types.OPEN]: {
      visible: true,
      payload,
    },
    [Types.CLOSE]: {
      ...initialState,
    },
  };

  return reducers[type] || state;
};

export const openDispatch = (payload) => async (dispatch) => {
  dispatch({
    type: Types.OPEN,
    payload,
  });
};

export const closeDispatch = () => async (dispatch) => {
  dispatch({
    type: Types.CLOSE,
  });
};

const selectRoot = (state: any) => get(state, rootPathState);
const selectVisible = (state: any) => get(state, 'visible');
const selectPayload = (state: any) => get(state, 'payload');

export const Selectors = {
  visible: flow(selectRoot, selectVisible),
  payload: flow(selectRoot, selectPayload),
};

export const Dispatchs = {
  open: openDispatch,
  close: closeDispatch,
};
