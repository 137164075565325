import flow from 'lodash/flow';
import get from 'lodash/get';

import { setError } from '@payhop/shared-ducks/error.duck';

import fetch from '@payhop/shared-utils/fetch.util';

const context = 'private/creditors/creditor';
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
  error?: any;
};

export const Types = {
  INIT: `${context}/INIT`,
  SUCCESS: `${context}/SUCCESS`,
  RESET: `${context}/RESET`,
  RESET_NOTIFICATION: `${context}/RESET/NOTIFICATION`,
  ERROR: `${context}/ERROR`,
};

const initialState: any = {
  data: null,
  loading: null,
  error: null,
  fetched: null,
  notification: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload } = action;

  const reducers = {
    [Types.RESET]: {
      ...initialState,
    },
    [Types.INIT]: {
      ...state,
      error: null,
      notification: null,
      loading: true,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
      error: false,
      notification: true,
      data: payload,
      fetched: true,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error: true,
      notification: true,
      data: payload,
    },
    [Types.RESET_NOTIFICATION]: {
      ...state,
      notification: initialState.notification,
    },
  };

  return reducers[type] || state;
};

export const fetchCreditorsData = (id) => async (dispatch) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(`creditors/${id}`, {
      method: 'GET',
      ms: 'CREDITOR',
      auth: true,
    });

    const result = await response.json();

    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: result,
    });
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
      payload: {},
    });

    dispatch(
      setError({
        messages: result?.errors?.message,
        data: result,
        visible: false,
        context,
      })
    );

    return false;
  }
};

export const createDispatch = (data) => async (dispatch) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(`creditors`, {
      method: 'POST',
      ms: 'CREDITOR',
      auth: true,
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: {
        successAction: true,
      },
    });

    return result;
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
      payload: {
        successAction: false,
      },
    });

    dispatch(
      setError({
        messages: result?.errors?.message,
        data: result,
        visible: false,
        context,
      })
    );

    return false;
  }
};

export const updateDispatch = (id, data) => async (dispatch, getState) => {
  const dt = flow(getState, Selectors.data)();

  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(`creditors/${id}`, {
      method: 'PATCH',
      ms: 'CREDITOR',
      auth: true,
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: {
        ...dt,
        successAction: true,
      },
    });

    return result;
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
      payload: {
        ...dt,
        successAction: false,
      },
    });

    dispatch(
      setError({
        messages: result?.errors?.message,
        data: result,
        visible: false,
        context,
      })
    );

    return false;
  }
};

export const resetDispatch = () => ({
  type: Types.RESET,
});

export const resetNotificationDispatch = () => ({
  type: Types.RESET_NOTIFICATION,
});

const selectRoot = (state: any) => get(state, rootPathState);
export const selectData = (state: any) => get(state, `${rootPathState}.data`);
const selectDataNew = (state: any) => get(state, 'data');
const selectLoading = (state: any) => get(state, 'loading');
const selectFetched = (state: any) => get(state, 'fetched');
const selectError = (state: any) => get(state, 'error');
const selectNotification = (state: any) => get(state, 'notification');

export const Selectors = {
  data: flow(selectRoot, selectDataNew),
  loading: flow(selectRoot, selectLoading),
  fetched: flow(selectRoot, selectFetched),
  error: flow(selectRoot, selectError),
  notification: flow(selectRoot, selectNotification),
};

export const Dispatchs = {
  get: fetchCreditorsData,
  reset: resetDispatch,
  reset_notification: resetNotificationDispatch,
  create: createDispatch,
  update: updateDispatch,
};
