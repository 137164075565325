import { flow } from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as checkidsDuck from '@backoffice/private/ducks/checkids/listCheckids.duck';
import * as listMerchantDocumentDetailDuck from '@backoffice/private/ducks/merchants/documentDetail/listMerchantDocumentDetail.duck';
import * as listPendingMerchantsDuck from '@backoffice/private/ducks/merchants/listPendingMerchants.duck';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';

import TableMerchantDocumentComponent from '../components/Table/TableMerchantDocument.component';
import TableMerchantDocumentDetailComponent from '../components/Table/TableMerchantDocumentDetail.component';
import TableMerchantPendingComponent from '../components/Table/TableMerchantPending.component';

type Props = {
  type: 'documents' | 'documentDetail' | 'merchantPending';
  [key: string]: any;
};

const TableMerchant = ({ type, ...restProps }: Props) => {
  const dispatch = useDispatch();

  const renderTableComponent = useMemo(
    () => ({
      documents: TableMerchantDocumentComponent,
      documentDetail: TableMerchantDocumentDetailComponent,
      merchantPending: TableMerchantPendingComponent,
    }),
    [type]
  );

  const moduleDuck = useMemo(
    () => ({
      documents: checkidsDuck,
      documentDetail: listMerchantDocumentDetailDuck,
      merchantPending: listPendingMerchantsDuck,
    }),
    [type]
  );

  const dispatchRedux = {
    UPDATE_FILTERS: flow(moduleDuck[type].Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(moduleDuck[type].Dispatchs.resetFilters, dispatch),
    GET: flow(moduleDuck[type].Dispatchs.get, dispatch),
    RESET: flow(moduleDuck[type].Dispatchs.reset, dispatch),
    DELETE: flow(moduleDuck[type].Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(moduleDuck[type].Selectors.data),
    LOADING: useSelector(moduleDuck[type].Selectors.loading),
    DATA_RESULTS: useSelector(moduleDuck[type].Selectors.data_results),
    FILTERS: useSelector(moduleDuck[type].Selectors.filters),
    RESULTSET: useSelector(moduleDuck[type].Selectors.resultset),
    FETCHED: useSelector(moduleDuck[type].Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage } = useCrudRead({
    dispatchRedux,
    selectorRedux,
  });

  const props = {
    loading: selectorRedux.LOADING,
    results: selectorRedux.DATA_RESULTS,
    handleChangePage,
    handleChangeRowsPerPage,
    filters: {
      ...selectorRedux.FILTERS,
      ...selectorRedux.RESULTSET,
    },
    ...restProps,
  };

  return React.createElement(renderTableComponent[type], props as any);
};

export default TableMerchant;
