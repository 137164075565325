import { Route, Switch, Redirect } from 'react-router-dom';

import DashboardScreen from './screens/Home.screen';

import ROUTES from '@backoffice/shared/utils/routes.util';

const RegisterRoutes: React.FC<any> = () => (
  <Switch>
    <Route exact path={ROUTES.PRIVATE.HOME} component={DashboardScreen} />

    <Redirect to={ROUTES.PRIVATE.HOME} />
  </Switch>
);

export default RegisterRoutes;
