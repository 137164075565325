import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Box } from '@material-ui/core';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardSearch from '@backoffice/private/components/Card/CardSearch';

import schemaSearchFormik from '@backoffice/shared/utils/schemaSearchFormik.util';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/profiles/profileAccounts.duck';

const schemaSearch = [
  [
    {
      name: 'name',
      label: 'Nome',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      label: 'Ativo',
      name: 'active',
      type: 'select',
      multiple: false,
      options: [
        {
          label: 'Sim',
          value: 'true',
        },
        {
          label: 'Não',
          value: 'false',
        },
      ],
    },
  ],
];

const TableProfileUsersContainer = ({ profileId }) => {
  const [loadingLocal, setLoadingLocal] = useState(false);

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const handleResetFilters = () => {
    dispatchRedux.RESET_FILTERS();
    dispatchRedux.UPDATE_FILTERS({ profile_id: profileId });
  };

  const handleChangePage = (_, value) => {
    dispatchRedux.UPDATE_FILTERS({
      profile_id: profileId,
      offset: value * selectorRedux.FILTERS?.limit,
    });
  };

  const { handleChangeRowsPerPage, handleSearch, handleSearchReset } =
    useCrudRead({
      dispatchRedux,
      selectorRedux,
      opts: {
        requestOnMount: false,
      },
    });

  useEffect(() => {
    dispatchRedux.GET({ profileId: profileId });
  }, []);

  return (
    <TableProfileUsersComponent
      loading={selectorRedux.LOADING || loadingLocal}
      results={selectorRedux.DATA_RESULTS}
      profileId={profileId}
      handleSearch={handleSearch}
      handleSearchReset={handleResetFilters}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const TableProfileUsersComponent = ({
  loading,
  results,
  profileId,
  handleSearch,
  handleSearchReset,
  handleChangePage,
  handleChangeRowsPerPage,
  filters,
}) => {
  const initialValuesFormik: any = schemaSearchFormik(schemaSearch);
  const { limit, count, offset } = filters || {};

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      render: ({ active }) => (active ? 'Sim' : 'Não'),
    },
  ];

  return (
    <Box gridGap={8} display="flex" flexDirection="column">
      {profileId !== undefined && (
        <>
          <Formik
            initialValues={{ ...initialValuesFormik, profile_id: profileId }}
            onSubmit={async (values) => {
              const valuesFormatted = {
                ...values,
              };

              return handleSearch({
                ...valuesFormatted,
                profile_id: profileId,
              });
            }}
          >
            {({ submitForm, resetForm }) => (
              <CardSearch
                fields={schemaSearch}
                formik
                onSubmit={submitForm}
                onReset={() => {
                  handleSearchReset();
                  resetForm;
                }}
              />
            )}
          </Formik>

          <CardTable
            count={count || 0}
            loading={loading}
            dataSource={results}
            rowsPerPage={limit}
            columns={columns as any}
            page={offset / limit}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

export default TableProfileUsersContainer;
