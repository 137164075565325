import React, { useEffect, useRef } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useDispatch } from 'react-redux';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import { WSS_NOTIFICATION_URL } from '@payhop/shared-configs/api.config';

import {
  addNewNotification,
  readAllNotifications,
} from '@payhop/shared-ducks/notifications.duck';

import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useUserAuthenticated from '@payhop/shared-hooks/useUserAuthenticated.hook';
import ListExportNotifications from '@backoffice/private/containers/ListExportNotifications.container';

import { useNotificationDrawer } from './NotificationDrawer.context';

import Component from './NotificationDrawer.component';

const NotificationDrawerEnhancer: React.FC<any> = (props) => {
  const { accountId } = useUserHook();
  const notification = useNotification();

  const ws: any = useRef(null);
  const isFirstRender = useRef(true);

  const dispatch = useDispatch();

  const { visible, toggleVisible: onClose }: any = useNotificationDrawer();

  const { data: signedIn } = useUserAuthenticated();

  useEffect(() => {
    if (!visible && !isFirstRender.current) {
      dispatch(readAllNotifications());
    }

    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [visible]);

  useEffect(() => {
    if (!signedIn) {
      return undefined;
    }

    ws.current = new HubConnectionBuilder()
      .withUrl(`${WSS_NOTIFICATION_URL}/notificationHub`)
      .build();

    ws.current.on(`/users/${accountId}`, (value: any) => {
      dispatch(addNewNotification(value));

      notification('Chegou uma nova notificação para você!', 'info');
    });

    ws.current.start();

    return () => ws.current.stop();
  }, [signedIn, accountId]);

  return (
    <ListExportNotifications>
      {({ data, loading, resultset, handleGetNotification }: any) => (
        <Component
          {...props}
          visible={props?.visible !== undefined ? props?.visible : visible}
          onClose={props?.onClose !== undefined ? props?.onClose : onClose}
          results={data || []}
          loadingNotification={loading}
          resultset={resultset}
          handleGetNotification={handleGetNotification}
        />
      )}
    </ListExportNotifications>
  );
};

export default NotificationDrawerEnhancer;
