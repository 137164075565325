import { Field, ErrorMessage } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';

import { Box, TextField, Typography } from '@material-ui/core';

type InputSelectAutocompleteProps = {
  name: string;
  limitTags?: number;
  disabled?: boolean;
  multiple?: boolean;
  label: string;
  optionsAutocomplete: Array<string> | [];
  getOptionLabel: (option: any) => any;
  inputWidth?: string;
  handleSelectAll?: () => void;
  hasError: boolean;
  errorMessage: string;
};

const InputSelectAutocomplete = ({
  name,
  limitTags,
  disabled,
  multiple,
  optionsAutocomplete,
  getOptionLabel,
  label,
  inputWidth,
  handleSelectAll,
  hasError,
  errorMessage,
}: InputSelectAutocompleteProps) => {
  return (
    <Box width={inputWidth ? inputWidth : '100%'}>
      <Field
        id={name}
        component={Autocomplete}
        name={name}
        limitTags={limitTags ?? 1}
        disabled={disabled}
        multiple={multiple ?? true}
        options={optionsAutocomplete}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            error={hasError}
            helperText={hasError && errorMessage}
          />
        )}
      />
      <ErrorMessage name={name} component="div" className="error-message" />
    </Box>
  );
};

export default InputSelectAutocomplete;
