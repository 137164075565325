/* eslint-disable */

import fetch from '@payhop/shared-utils/fetch.util';

type dataRequest = {
  creditor_id: string;
  file: any;
  deal_type: string;
  deal_contract_type: string;
};

export default async function postDealsUploadService({
  creditor_id,
  deal_type,
  deal_contract_type,
  file,
}: dataRequest) {
  let formData = new FormData();

  formData.append('file', file);
  formData.append('creditor_id', creditor_id);
  formData.append('deal_type', deal_type);
  formData.append('deal_contract_type', deal_contract_type);

  const response = await fetch(`deals/upload`, {
    method: 'POST',
    ms: 'DEAL',
    defaultHeaders: false,
    auth: true,
    body: formData,
  });

  const result = await response.json();

  return [response.ok, result];
}
