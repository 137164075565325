import React, { useMemo } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import EditIcon from '@material-ui/icons/Edit';

import CardTable from '@backoffice/private/components/Card/CardTable';

import ROUTES from '@backoffice/shared/utils/routes.util';

const TableMerchantPendingComponent = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const history = useHistory();

  const { limit, count, offset } = filters || {};

  const columns = useMemo(
    () => [
      {
        width: 100,
        render: ({ merchant_id }) => (
          <Box display="flex">
            <IconButton
              onClick={() =>
                history.push(ROUTES.PRIVATE.MERCHANTS.EDIT(merchant_id))
              }
            >
              <EditIcon />
            </IconButton>
          </Box>
        ),
      },
      {
        title: 'Tipo Pessoa',
        dataIndex: 'merchant_type',
        key: 'merchant_type',
      },
      {
        title: 'CNPJ/CPF',
        dataIndex: 'merchant_document',
        key: 'merchant_document',
      },
      {
        title: 'Nome Fantasia',
        dataIndex: 'trading_name',
        key: 'trading_name',
      },
      {
        title: 'Razão Social',
        dataIndex: 'company_name',
        key: 'company_name',
      },
      {
        title: 'Fornecedor',
        dataIndex: 'creditor_trading',
        key: 'creditor_trading',
      },
      {
        title: 'Inativo com compromisso garantido',
        dataIndex: 'first_deal_guaranteed',
        key: 'first_deal_guaranteed',
        render: ({ first_deal_guaranteed }) =>
          first_deal_guaranteed ? 'Sim' : 'Não',
      },
      {
        title: 'Data do compromisso garantido',
        dataIndex: 'first_deal_guaranteed_date',
        key: 'first_deal_guaranteed_date',
      },
      {
        title: 'Status',
        dataIndex: 'status_description',
        key: 'status_description',
      },
    ],
    []
  );

  return (
    <Box gridGap={8} display="flex" flexDirection="column" flex={1}>
      <CardTable
        count={count || 0}
        loading={loading}
        dataSource={results?.filter((r) => r.campo !== 'Grau Face Match')}
        rowsPerPage={limit}
        columns={columns}
        page={offset / limit}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        tableProps={{
          style: {
            minWidth: 1700,
          },
        }}
      />
    </Box>
  );
};

export default TableMerchantPendingComponent;
