import { Route, Switch, Redirect } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import RegisteredContracts from './screens/RegisteredContracts.screen';

const RegisteredContractsModule: React.FC<any> = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.PRIVATE.REGISTERED_CONTRACTS.ROOT}
      component={RegisteredContracts}
    />
    <Redirect to={ROUTES.PRIVATE.HOME} />
  </Switch>
);

export default RegisteredContractsModule;
