import fetch from '@payhop/shared-utils/fetch.util';

export default function getAddressByZip(zipCode: string) {
  return fetch(`accounts/search-zipcode`, {
    method: 'POST',
    ms: 'ACCOUNT',
    body: JSON.stringify({
      zipCode,
    }),
  });
}
