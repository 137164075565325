import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { useFormikContext, Formik } from 'formik';
import { flow, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CardForm from '@backoffice/private/components/Card/CardForm';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import useCrudNotification from '@backoffice/shared/hooks/useCrudNotification.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/pricing/creditorPricing.duck';
import { Dispatchs as DispatchUnits } from '@backoffice/private/ducks/creditors/pricing/listCreditorPricing.duck';

const validationSchema = Yup.object({
  price_range: Yup.string().required().default(''),
  min_value: Yup.string().required().default(''),
  max_value: Yup.string()
    .required()
    .default('')
    .when('min_value', (min_value, schema) =>
      schema.test(
        'minhighmax',
        'Valor máximo não pode ser menor que o valor mínimo',
        (val) => {
          return Number(val) > Number(min_value);
        }
      )
    ),
  fee: Yup.string().required().default(''),
  start_date: Yup.string().required().nullable().default(null),
  end_date: Yup.string()
    .nullable()
    .default(null)
    .when('start_date', (start_date, schema) =>
      schema.test(
        'start_datemin',
        'A data final precisa ser maior que a inicial',
        (end_date) => {
          return moment(end_date).diff(start_date) >= 0;
        }
      )
    ),
});

const FormCreditorTaxContainer = ({ isEdit, data }) => {
  const initial = useRef({});

  const history = useHistory();

  const dispatch = useDispatch();

  const { values, errors, resetForm, setValues } = useFormikContext();

  const dispatchRedux = {
    CREATE: flow(Dispatchs.create, dispatch),
    UPDATE: flow(Dispatchs.update, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    RESET_NOTIFICATION: flow(Dispatchs.reset_notification, dispatch),
  };

  const dispatchUnitsRedux = {
    GET: flow(DispatchUnits.get, dispatch),
  };

  const selectorRedux = {
    LOADING: useSelector(Selectors.loading),
    DATA: useSelector(Selectors.data),
    NOTIFICATION: useSelector(Selectors.notification),
    ERROR: useSelector(Selectors.error),
  };

  const { handleNotification } = useCrudNotification({
    selectorRedux,
    dispatchRedux,
  });

  useEffect(() => {
    const { successAction, successActionUpdate, error } =
      selectorRedux.DATA || {};

    if (successAction === true && !isEdit) {
      dispatchUnitsRedux.GET();

      resetForm();

      dispatchRedux.RESET();
    }

    if (isEdit) {
      if (successActionUpdate === true && selectorRedux.NOTIFICATION === true) {
        dispatchUnitsRedux.GET();

        handleNotification('Atualizado com sucesso!', 'success');

        dispatchRedux.RESET_NOTIFICATION();
      }

      if (
        successActionUpdate === false &&
        selectorRedux.NOTIFICATION === true
      ) {
        handleNotification(error, 'error');

        dispatchRedux.RESET_NOTIFICATION();
      }
    }
  }, [selectorRedux.DATA, isEdit, selectorRedux.NOTIFICATION]);

  const handleBack = () => history.goBack();

  useEffect(() => {
    if (!isEdit || !data) {
      return undefined;
    }

    const obj = {
      id: data?.id,
      price_range: data?.price_range,
      min_value: data?.min_value,
      max_value: data?.max_value,
      fee: data?.fee,
      start_date: data?.start_date,
      end_date: data?.end_date,
    };

    const objFormatted = Object.keys(obj).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: ['fee', 'max_value', 'min_value'].includes(curr)
          ? String(obj[curr].toFixed(2))
          : String(obj[curr]),
      }),
      {}
    );

    setValues(objFormatted);

    initial.current = objFormatted;
  }, [isEdit, data]);

  const isEqualsValues = isEdit && isEqual(values, initial.current);

  return (
    <FormCreditorTaxComponent
      loading={selectorRedux.LOADING}
      errorsForm={errors}
      isEdit={isEdit}
      handleBack={handleBack}
      isEqualsValues={isEqualsValues}
      handleSubmit={() =>
        isEdit ? dispatchRedux.UPDATE(values) : dispatchRedux.CREATE(values)
      }
    />
  );
};

const FormCreditorTaxComponent = ({
  loading,
  handleSubmit,
  errorsForm,
  handleBack,
  isEdit,
  isEqualsValues,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_fornecedor_taxas',
  });
  const schema = [
    [
      {
        name: 'price_range',
        label: 'Faixa',
        required: true,
      },
      {
        name: 'min_value',
        label: 'Valor mínimo',
        required: true,
        mask: 'currency',
      },
      {
        name: 'max_value',
        label: 'Valor máximo',
        required: true,
        mask: 'currency',
      },
      {
        name: 'fee',
        label: 'Taxa',
        required: true,
        mask: 'tax',
      },
    ],
    [
      {
        name: 'start_date',
        label: 'Data inicial',
        required: true,
        type: 'date',
      },
      {
        name: 'end_date',
        label: 'Data final',
        required: true,
        type: 'date',
      },
    ],
  ];

  return (
    <>
      {permissions?.some((item) => item == 'create') && (
        <Box display="flex" flexDirection="column" gridGap={24}>
          <Box gridGap={8} display="flex" flexDirection="column">
            <CardTitle>
              {isEdit ? 'Editando faixa' : 'Cadastrar nova faixa'}
            </CardTitle>

            <CardForm formik fields={schema as any} />
          </Box>

          <CardFormFooter
            hasError={Object.keys(errorsForm).length > 0 || isEqualsValues}
            onSubmit={handleSubmit}
            onCancel={handleBack}
            loading={loading}
          />
        </Box>
      )}
      {!permissions && <ModuleNotPermited />}
    </>
  );
};
export default function FormCreditorTaxFormik({ isEdit = false, data = {} }) {
  return (
    <Formik
      initialValues={{ ...validationSchema.cast() }}
      onSubmit={() => {}}
      validateOnMount
      validationSchema={validationSchema}
    >
      <FormCreditorTaxContainer isEdit={isEdit} data={data} />
    </Formik>
  );
}
