import styled from '@emotion/styled';

export const ValidationTag = styled.span<{ validation: boolean }>(
  {
    borderRadius: '10px',
    margin: '3px',
    padding: '5px 10px',
    color: '#a1a6b5',
    width: '48%',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    fontSize: '14px',
  },
  ({ validation }) => ({
    color: validation ? '#07926f' : '#a1a6b5',
  })
);

export const ValidationIcon = styled.div<{ validation: boolean }>(
  {
    fontSize: '8px',
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '2px',
  },
  ({ validation }) => ({
    color: validation ? '#07926f' : '#cecece',
    background: validation ? '#cff7ec' : '#f2f5f4',
  })
);
