import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import useQuery from '@payhop/shared-hooks/useQuery.hook';

const useIsEdit = () => {
  const { pathname } = useLocation();
  const query = useQuery();
  const id = query.get('id');
  const type = query.get('type');

  const isEdit = useMemo(
    () => pathname.split('/').splice(-1)[0] === 'edit',
    [pathname]
  );

  return {
    isEdit,
    id,
    type,
  };
};

export default useIsEdit;
