import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import FormSchema, {
  FormSchemaProps,
} from '@backoffice/private/components/Form/FormSchema';
import { Form } from 'formik';

type cardSearchProps = FormSchemaProps & {
  onSubmit?: any;
  onReset?: any;
  disabled?: boolean;
};

const CardSearchComponent: React.FC<cardSearchProps> = ({
  fields,
  formik,
  onSubmit,
  onReset,
  disabled,
}) => {
  return (
    <Form>
      <Card>
        <CardContent>
          <FormSchema fields={fields} formik={formik} />
        </CardContent>
        <Box component={CardActions} justifyContent="flex-end" mt={1} mx={1}>
          <Button color="default" variant="text" onClick={onReset}>
            Limpar
          </Button>
          <Button
            color="primary"
            variant="contained"
            aria-label="show more"
            onClick={onSubmit}
            disabled={disabled}
            type={'submit'}
          >
            Buscar
          </Button>
        </Box>
      </Card>
    </Form>
  );
};

export default CardSearchComponent;
