import fetch from '@payhop/shared-utils/fetch.util';

export default function createContractPerformed(data: any) {
  return fetch(`deals`, {
    method: 'POST',
    ms: 'DEAL',
    auth: true,
    body: JSON.stringify(data),
  }).then(async (response) => {
    if (!response.ok) {
      const { errors } = await response.json();
      const errorMessage = errors.message;

      throw errorMessage;
    } else {
      const data = await response.json();

      const id = data[0].id;

      return id;
    }
  });
}
