import React, { useEffect, useRef, useState } from 'react';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Box, IconButton, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import ModalConfirmDelete from '@backoffice/private/components/Modal/ModalConfirmDelete.component';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import ROUTES from '@backoffice/shared/utils/routes.util';

import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import {
  Dispatchs,
  Selectors,
} from '../../../../ducks/parameters/acquirers/listAcquirers.duck';
import {
  Dispatchs as DispatchsAcquirer,
  Selectors as SelectorsAcquirer,
} from '../../../../ducks/parameters/acquirers/acquirer.duck';

const TableAcquirerContainer = () => {
  const dispatch = useDispatch();
  const handleNotification = useNotification();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const dispatchAcquirerRedux = {
    UPDATE: flow(DispatchsAcquirer.update, dispatch),
    RESET_NOTIFICATION: flow(DispatchsAcquirer.reset_notification, dispatch),
  };

  const selectorAcquirerRedux = {
    DATA: useSelector(SelectorsAcquirer.data),
    NOTIFICATION: useSelector(SelectorsAcquirer.notification),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: false,
    },
  });

  useEffect(() => {
    const { successActionUpdate, error } = selectorAcquirerRedux.DATA || {};

    if (
      successActionUpdate === true &&
      selectorAcquirerRedux.NOTIFICATION === true
    ) {
      handleNotification('Credenciadora atualizada com sucesso!', 'success');

      dispatchAcquirerRedux.RESET_NOTIFICATION();
      dispatchRedux.GET();
    }

    if (
      successActionUpdate === false &&
      selectorAcquirerRedux.NOTIFICATION === true
    ) {
      handleNotification(error, 'error');

      dispatchAcquirerRedux.RESET_NOTIFICATION();
    }
  }, [selectorAcquirerRedux.DATA]);

  const handleDelete = (id) => {
    dispatchRedux.DELETE(id);
  };

  const handleInactive = (acquirer) => {
    dispatchAcquirerRedux.UPDATE(acquirer.id, acquirer.data);
  };

  const handleOnRefresh = () => {
    dispatchRedux.GET();
  };

  const handleOnExport = async () => {
    const result = await dispatchRedux.GET({ isExport: true });

    return (result as any)?.results;
  };

  return (
    <TableAcquirerComponent
      loading={selectorRedux.LOADING}
      results={selectorRedux.DATA_RESULTS}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleDelete={handleDelete}
      handleInactive={handleInactive}
      handleOnRefresh={handleOnRefresh}
      handleOnExport={handleOnExport}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const TableAcquirerComponent = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  handleDelete,
  handleInactive,
  handleOnRefresh,
  handleOnExport,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_parametro_payhop_credenciadoras',
  });

  const currentId: any = useRef();

  const [modalDelete, setModalDelete]: any = useState(null);
  const [modalInactive, setModalInactive]: any = useState(null);

  const { limit, count, offset } = filters || {};

  const history = useHistory();

  const columns = [
    {
      width: 100,
      render: ({ id, acquirer_name, register_name, active }) => (
        <Box display="flex" gridGap={8}>
          <IconButton
            disabled={!permissions?.some((item) => item == 'edit')}
            onClick={() => {
              history.push(ROUTES.PRIVATE.PARAMETERS.EDIT(id, 'acquirer'));
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            disabled={!permissions?.some((item) => item == 'delete')}
            onClick={() => {
              currentId.current = id;
              setModalDelete(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
          <Button
            size="small"
            variant="outlined"
            disabled={!permissions?.some((item) => item == 'edit')}
            onClick={() => {
              currentId.current = {
                id,
                data: {
                  name: acquirer_name,
                  registradora: register_name,
                  active: !active,
                },
              };

              setModalInactive(true);
            }}
          >
            {active ? 'Inativar' : 'Ativar'}
          </Button>
        </Box>
      ),
    },
    {
      title: 'Logo',
      dataIndex: 'path',
      key: 'path',
      render: ({ path }) => (path ? <img width="30px" src={path} /> : '-'),
    },
    {
      title: 'CNPJ',
      dataIndex: 'document_number',
    },
    {
      title: 'Nome',
      dataIndex: 'acquirer_name',
    },
    {
      title: 'Registradora',
      dataIndex: 'register_name',
    },
    {
      title: 'Data cadastro',
      dataIndex: 'created_at',
      key: 'created_at',
      render: ({ created_at }) => moment(created_at).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Data da inativação',
      dataIndex: 'inactivation_date',
      key: 'inactivation_date',
      render: ({ inactivation_date }) =>
        inactivation_date
          ? moment(inactivation_date).format('DD/MM/YYYY HH:mm')
          : '-',
    },
  ];

  const acquirer = results?.find((r) => r.id === currentId?.current) || {};
  const handleCloseModalInactive = () => setModalInactive(false);
  const handleCloseModalDelete = () => setModalDelete(false);

  return (
    <Box gridGap={8} display="flex" flexDirection="column">
      <CardTitle>Listagem de credenciadoras</CardTitle>

      <CardTable
        count={count || 0}
        loading={loading}
        dataSource={results}
        rowsPerPage={limit}
        columns={columns as any}
        page={offset / limit}
        onRefresh={handleOnRefresh}
        onExport={handleOnExport}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        exporttype="xls"
        exportname="acquirers"
      />
      <ModalConfirmDelete
        open={modalDelete}
        onCancel={handleCloseModalDelete}
        title={`Após a exclusão não será mais possível realizar pesquisas de agendas para essa credenciadora.
        `}
        description={`Deseja realmente excluir o cadastro da credenciadora ${acquirer.acquirer_name}`}
        onConfirm={() => {
          handleDelete(currentId.current);
          handleCloseModalDelete();
        }}
      />
      <ModalConfirmDelete
        open={modalInactive}
        onCancel={handleCloseModalInactive}
        title={`Você tem certeza que deseja ${
          currentId.current?.data?.active ? 'ativar' : 'inativar'
        } esta credenciadora?`}
        description={`A credenciadora poderá ser ${
          currentId.current?.data?.active ? 'desativada' : 'ativada'
        } novamente mais tarde`}
        onConfirm={() => {
          handleInactive(currentId.current);
          handleCloseModalInactive();
        }}
      />
    </Box>
  );
};

export default TableAcquirerContainer;
