import { combineReducers } from 'redux';

type listOfReducersType = {
  name: string;
  path: string;
};

const reducers = {};

const listOfReducers: Array<listOfReducersType> = [
  {
    name: 'list',
    path: 'listCreditorOriginators.duck',
  },
  {
    name: 'originator',
    path: 'creditorOriginators.duck',
  },
];

listOfReducers.map((elm) =>
  Object.assign(reducers, {
    ...reducers,
    [elm.name]: require(`./${elm.path}`).default,
  })
);

export default combineReducers({
  ...reducers,
});
