import React, { useState } from 'react';

import {
  AccordionContainer,
  AccordionHeader,
  AccordionDetails,
} from './Accordion.styled';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type AccordionProps = {
  title: string;
  children: React.ReactNode;
  isExpanded?: boolean;
};

const Accordion = ({ title, children, isExpanded = true }: AccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  return (
    <AccordionContainer>
      <AccordionHeader
        onClick={() => setExpanded(!expanded)}
        expanded={expanded}
      >
        <p>{title}</p>
        <ExpandMoreIcon />
      </AccordionHeader>
      <AccordionDetails expanded={expanded}>
        <section>{children}</section>
      </AccordionDetails>
    </AccordionContainer>
  );
};

export default Accordion;
