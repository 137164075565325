import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import ROUTES from '@backoffice/shared/utils/routes.util';

import useNotification from './shared/hooks/useNotification.hook';

import PublicRoutes from './public/Public.routes';
import PrivateRoutes from './private/Private.routes';

if (process.env.REACT_APP_LOGROCKET === 'true') {
  setupLogRocketReact(LogRocket);

  LogRocket.init('28cefh/payhop', {
    network: {
      requestSanitizer: (request) => {
        if (request.url.toLowerCase().includes('/sign-in')) {
          return null;
        }
        return request;
      },
    },
  });
}

type RoutesProps = {
  match: {
    path: string;
  };
};

const Routes: React.FC<RoutesProps> = () => {
  useNotification();

  return (
    <Switch>
      <Route path={ROUTES.PRIVATE_NAMESPACE_ROUTE} component={PrivateRoutes} />

      <Route path="*" component={PublicRoutes} />
    </Switch>
  );
};

export default Routes;
