import fetch from '@payhop/shared-utils/fetch.util';

export default function patchCheckids(obj) {
  return fetch(`checkids`, {
    method: 'PATCH',
    ms: 'CHECKID',
    auth: true,
    body: JSON.stringify({
      id: obj.id,
      status: obj.status,
      comment: obj.comment,
    }),
  });
}
