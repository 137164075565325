import React from 'react';

import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Props = {
  data: Array<string | number>;
};

const OriginatorsExport = ({ data }: Props) => {
  function handleFilterOriginatorData(data) {
    const filteredData: any = [];
    data.forEach((originador) => {
      originador.domicilios.forEach((domicilio) => {
        const novoDomicilio = {
          ...domicilio,
          cnpj_originador: originador.cnpj_originador,
          razao_social: originador.razao_social,
          status_originador: originador.status,
          data_cadastro_originador: originador.data_cadastro,
          data_atualizacao_originador: originador.data_atualizacao,
          id_originador: originador.id,
        };
        filteredData.push(novoDomicilio);
      });
    });

    return filteredData;
  }
  return (
    <ExcelFile
      filename={'originadores_e_domicílios'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleFilterOriginatorData(data)} name="originadores">
        <ExcelColumn label="ID ORIGINADOR" value="id_originador" />
        <ExcelColumn label="CNPJ ORIGINADOR" value="cnpj_originador" />
        <ExcelColumn label="RAZÃO SOCIAL" value="razao_social" />
        <ExcelColumn label="STATUS ORIGINADOR" value="status_originador" />
        <ExcelColumn
          label="DATA DE CADASTRO"
          value="data_cadastro_originador"
        />
        <ExcelColumn label="ID DOMICÍLIO" value="id" />
        <ExcelColumn label="CNPJ DOMICÍLIO" value="cnpj_domicilio" />
        <ExcelColumn label="TÍTULO DOMICÍLIO" value="titulo_domicilio" />
        <ExcelColumn label="AGÊNCIA" value="agencia" />
        <ExcelColumn label="CONTA" value="conta" />
        <ExcelColumn label="BANCO" value="banco" />
        <ExcelColumn label="TIPO DA CONTA" value="tipo_conta" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default OriginatorsExport;
