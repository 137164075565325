import fetch from '@payhop/shared-utils/fetch.util';

export default function uploadTCD(data: any) {
  const formData = new FormData();

  const { id, file } = data;

  formData.append('file', file);
  formData.append('type', 'contrato');
  formData.append('external_reference', id);

  return fetch('upload', {
    method: 'POST',
    ms: 'UPLOAD',
    defaultHeaders: false,
    auth: true,
    body: formData,
  }).then(async (response) => {
    if (!response.ok) {
      const { errors } = await response.json();
      const errorMessage = errors.message;

      throw errorMessage;
    } else {
      return response;
    }
  });
}
