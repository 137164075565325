import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { flow } from 'lodash';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import HomeIcon from '@material-ui/icons/Home';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/parameters/creditorParameters.duck';

import menuConstant from '@backoffice/private/constants/menu.constant';

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    active: {
      color: theme.palette.primary.main,
    },
  })
);

const SidebarComponent: React.FC<any> = ({ toggleSideBar, isOpen }) => {
  const { isAdmin, user } = useUserHook();
  const history = useHistory();
  const location = useLocation();
  const urlScreen: string[] = location.pathname
    .split('/')
    .filter((item) => item != '');
  const classes = useStyles();

  const currentModule = urlScreen[1];

  const dispatch = useDispatch();

  const dispatchRedux = {
    GET: flow(Dispatchs.get, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    FETCHED: useSelector(Selectors.fetched),
    ERROR: useSelector(Selectors.error),
    NOTIFICATION: useSelector(Selectors.notification),
  };

  useEffect(() => {
    if (!isAdmin && !selectorRedux.DATA?.results) {
      const { personId } = user || {};
      dispatchRedux.GET(personId);
    }
  }, []);

  const handleMenuItems = (item) => {
    const permissionsArray: any = [];
    item.profileModuleSlug.forEach((item) => {
      const { permissions } = useAccountPermission({
        permission_slug: item,
      });

      if (permissions) {
        permissionsArray.push(permissions);
      }
    });

    if (item.profileModule && permissionsArray.length == 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleTitleMenu = (titleName, isMenuAdmin) => {
    if (isMenuAdmin == 'admin' && isAdmin) {
      return titleName;
    } else if (isMenuAdmin == 'admin' && !isAdmin) {
      return ' ';
    } else {
      return titleName;
    }
  };

  return (
    <Paper
      elevation={1}
      square
      component="aside"
      css={{ height: 'calc(100vh - 64px)' }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      })}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          overflow={isOpen ? 'auto' : 'hidden'}
          paddingTop={2}
          display="flex"
          flexDirection="column"
          gridGap={16}
          flex={1}
        >
          <List className={classes.root}>
            <ListItem button onClick={() => toggleSideBar()}>
              {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </ListItem>
          </List>
          <List className={classes.root}>
            <ListItem button onClick={() => history.push('/app')}>
              <ListItemIcon>
                <HomeIcon
                  className={location.pathname === '/app' ? classes.active : ''}
                />
              </ListItemIcon>
              <ListItemText
                className={location.pathname === '/app' ? classes.active : ''}
                primary="Início"
              />
            </ListItem>
          </List>

          {menuConstant.map(([titleMenu, typeMenu, linksObj], index) => (
            <List
              subheader={
                (linksObj as any).filter((l) => handleMenuItems(l)).length >
                0 ? (
                  <ListSubheader>
                    {handleTitleMenu(titleMenu, typeMenu)}
                  </ListSubheader>
                ) : (
                  <Fragment />
                )
              }
              className={classes.root}
              key={`${index}-menu`}
            >
              <>
                {(linksObj as any)
                  .filter((l) => handleMenuItems(l))
                  .map((l: any, i: any) => (
                    <ListItem
                      button
                      id={`sidebar-${l?.id}`}
                      onClick={() => history.push(l.link)}
                      key={`${index}-${i}-menu`}
                    >
                      <ListItemIcon>
                        {React.createElement(l.icon, {
                          className:
                            currentModule === l?.module ? classes.active : '',
                        })}
                      </ListItemIcon>
                      <ListItemText
                        className={
                          currentModule === l?.module ? classes.active : ''
                        }
                        primary={l?.label}
                      />
                    </ListItem>
                  ))}
              </>
            </List>
          ))}
        </Box>
        {/* <Button
          disableElevation
          css={{ padding: 0 }}
          onClick={() => history.push('/app/profile')}
        >
          <Box
            padding={2}
            display="flex"
            alignItems="center"
            borderTop="1px solid rgba(0, 0, 0, 0.08)"
            gridGap={12}
            width="100%"
          >
            <Avatar
              className={urlScreen[1] === 'profile' ? classes.active : ''}
              css={{ width: 36, height: 36, fontSize: 16 }}
            >
              {currentUser?.name?.split(' ')[0][0]}
              {currentUser?.name?.split(' ')[1][0]}
            </Avatar>
            {isOpen ? (
              <Typography
                component="span"
                className={urlScreen[1] === 'profile' ? classes.active : ''}
                css={{ textTransform: 'none' }}
              >
                {currentUser?.name}
              </Typography>
            ) : (
              ''
            )}
          </Box>
        </Button> */}
      </Box>
    </Paper>
  );
};

export default SidebarComponent;
