import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import Accordion from '@backoffice/private/components/Accordion/Accordion.component';
import InputSelectNew from '@backoffice/private/components/Form/Inputs/InputSelectNew.component';
import InputData from '@backoffice/private/components/Form/Inputs/InputDataNew.component';
import ParcelsForm from '../../../ParcelsForm.component';
import InputSelectAutocomplete from '@backoffice/private/components/Form/Inputs/InputSelectAutocomplete.component';

type InstallmentsStepProps = {
  parameters: {
    quantidade_parcelas: string;
    tipo_transacao: string;
    tipo_contrato: string;
  };
  values: {
    total_amount: number;
    parcels_quantity: string;
    solicitated_value: number;
    division_rule: string;
    contract_modality: 'performado' | 'fumaca';
    type: string;
    expiration_date: Date;
  };
  setFieldValue: any;
  acquirers: [
    {
      label: string;
      value: string;
    }
  ];
  arrangements: [
    {
      label: string;
      value: string;
    }
  ];
  isPartnerIF: boolean;
  errors: any;
  isColateral: boolean;
};

const InstallmentsStep = ({
  values,
  parameters,
  setFieldValue,
  acquirers,
  arrangements,
  isPartnerIF,
  errors,
  isColateral,
}: InstallmentsStepProps) => {
  const { quantidade_parcelas, tipo_contrato, tipo_transacao } = parameters;

  const [parcelsQuantityArray, setParcelsQuantityArray] = useState<any>([]);
  const [parcelsDateNull, setParcelsDateNull] = useState<boolean>(false);
  const [haveAcquirers, setHaveAcquirers] = useState<boolean>(false);
  const [haveArrangements, setHaveArrangements] = useState<boolean>(false);

  const acquirerInputHasError = !!errors.document_acquirers;
  const arrangementInputHasError = !!errors.payment_arrangements;
  const isContractPerformed = values.contract_modality == 'performado';
  const isTransactionCharge = values.type == '4';
  const expirationDateValidation =
    (!!values.expiration_date && !isPartnerIF) || !isTransactionCharge;
  const expirationDateErrorMessage =
    errors.expiration_date || 'Campo obrigatório';

  const handleCreateParcelsQuantityArray = (parcels: string) => {
    const parcelsQuantityArray = [...Array(+parcels)].map((item, index) => {
      const isFirst = index === 0;
      const value = index + 1;

      return {
        label: `${isFirst ? 'Única' : value}`,
        value,
      };
    });
    setParcelsQuantityArray(parcelsQuantityArray);
  };

  const handleParcelsDate = (date) => {
    const arrFiltered = date.map((obj) => {
      return Object.keys(obj).map(function (key) {
        return obj[key];
      });
    });

    const arr = arrFiltered[0];

    setParcelsDateNull(arr.every((item) => item !== null));
  };

  //Transform quantity of parcels in array to use the input select
  useEffect(() => {
    if (quantidade_parcelas) {
      handleCreateParcelsQuantityArray(quantidade_parcelas);
    }
  }, [quantidade_parcelas]);

  useEffect(() => {
    acquirers?.length > 0 ? setHaveAcquirers(true) : setHaveAcquirers(false);
    arrangements?.length > 0
      ? setHaveArrangements(true)
      : setHaveArrangements(false);
  }, [acquirers, arrangements]);

  return (
    <Accordion title="Dados da(s) Parcela(s)">
      <Box
        marginTop={'24px'}
        display={'flex'}
        flexDirection={'column'}
        gridRowGap={16}
      >
        <Box display={'flex'} gridColumnGap={16}>
          <InputSelectNew
            name="parcels_quantity"
            label="Quantidade de Parcelas"
            labelId="parcels_quantity"
            disabled={!values.total_amount}
            options={parcelsQuantityArray}
            keyName="value"
            optionName="label"
            value={values.parcels_quantity}
            onChange={(_, option) => {
              setFieldValue('parcels_quantity', option.props.value);
            }}
            required
          />
          <InputData
            name="issue_date"
            hasError={errors.issue_date}
            helperText={errors.issue_date}
            label="Data de emissão"
            required
          />
          <InputData
            name="expiration_date"
            label="Data de expiração"
            hasError={expirationDateValidation ? errors.expiration_date : false}
            helperText={
              expirationDateValidation ? expirationDateErrorMessage : ''
            }
            required={!isPartnerIF && !isTransactionCharge}
            disabled={isPartnerIF || isTransactionCharge}
            reloadError={!expirationDateValidation}
          />
        </Box>
        {haveAcquirers && haveArrangements && (
          <Box display={'flex'} gridColumnGap={16}>
            <InputSelectAutocomplete
              label="Credenciadoras"
              name="document_acquirers"
              hasError={acquirerInputHasError}
              errorMessage="É necessário selecionar uma ou mais credenciadoras"
              optionsAutocomplete={acquirers?.map((a: any) => a.value || [])}
              getOptionLabel={(option: any) => {
                const currentAcquirer = acquirers?.find(
                  (b) => b.value === option
                );

                return currentAcquirer?.label;
              }}
            />
            <InputSelectAutocomplete
              label="Arranjos"
              name="payment_arrangements"
              hasError={arrangementInputHasError}
              errorMessage="É necessário selecionar um ou mais arranjos"
              optionsAutocomplete={arrangements?.map((a: any) => a.value || [])}
              getOptionLabel={(option: any) => {
                const currentArrangement = arrangements?.find(
                  (b) => b.value === option
                );

                return currentArrangement?.label;
              }}
            />
          </Box>
        )}

        <Box display={'flex'} gridColumnGap={16}>
          <ParcelsForm
            name="installments"
            parcelsSelect={[...Array(values.parcels_quantity)]}
            dealValue={values.total_amount}
            handleDates={handleParcelsDate}
            values={values}
            parameters={{ tipo_transacao, tipo_contrato }}
            isColateral={isColateral}
          />
        </Box>
      </Box>
    </Accordion>
  );
};

export default InstallmentsStep;
