import React, { useState } from 'react';
import { TextField as TextFieldF } from '@backoffice/shared/components/TextField';
import { Field } from 'formik';

interface InputTextWithMaskProps {
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  value?: number;
  mask:
    | 'cpf'
    | 'cnpj'
    | 'currency'
    | 'mobile'
    | 'zipcode'
    | 'date_of_birth'
    | 'tax';
  onChange?: (e) => void;
  onBlur?: (e) => void;
  hasError?: any;
  helperText?: string;
}

const InputTextWithMask = ({
  name,
  label,
  required,
  disabled,
  mask,
  value,
  onChange,
  onBlur,
  hasError,
  helperText,
}: InputTextWithMaskProps) => {
  const [haveError, setHaveError] = useState(false);

  const handleInputError = () => {
    if (hasError) {
      setHaveError(true);
    } else {
      setHaveError(false);
    }
  };

  return (
    <Field name={name}>
      {({
        field,
        form: { setFieldValue, setFieldTouched },
        meta: { error, touched },
      }) => (
        <TextFieldF
          id={name}
          mask={mask}
          label={`${label}${required ? ` *` : ''}`}
          fullWidth
          variant="outlined"
          value={value ? value : field.value}
          disabled={disabled}
          error={haveError}
          helperText={haveError && helperText}
          onBlur={handleInputError}
          onValueChange={onChange}
          onBlurCapture={onBlur}
        />
      )}
    </Field>
  );
};

export default InputTextWithMask;
