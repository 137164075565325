import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import useCrudNotification from '@backoffice/shared/hooks/useCrudNotification.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/merchants/merchant.duck';

type DialogSimplifiedProps = {
  link: string;
  open: boolean;
  onCancel: any;
};

const DialogSimplifiedRegistration: React.FC<DialogSimplifiedProps> = ({
  link,
  open,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    RESET_NOTIFICATION: flow(Dispatchs.reset_notification, dispatch),
  };
  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    NOTIFICATION: useSelector(Selectors.notification),
  };

  const { handleNotification } = useCrudNotification({
    selectorRedux,
    dispatchRedux,
  });

  const handleCopyText = (link) => {
    navigator.clipboard.writeText(link);

    handleNotification('Link copiado!', 'success');
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        Copie o link abaixo e envie para o estabelecimento completar o cadastro:
        <Box position="absolute" top={8} right={8}>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          px={2}
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            columnGap: 5,
            background: '#D7DEDB',
            borderRadius: 3,
            cursor: 'pointer',
          }}
          onClick={() => {
            handleCopyText(link);
          }}
        >
          <p>{link}</p>
          <FileCopyIcon color="disabled" />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSimplifiedRegistration;
