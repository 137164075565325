import React from 'react';
import classNames from 'classnames';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

import {
  SYSTEM_COLORS,
  BRAND_COLORS,
} from '@backoffice/shared/configs/colors.config';
import { FONT_STYLES } from '@backoffice/shared/configs/typography.config';

type Props = TypographyProps & {
  customColor?:
    | 'info-500'
    | 'gray-300'
    | 'gray-400'
    | 'secondary-darker'
    | 'white';
  customVariant?:
    | 'text__header--lg'
    | 'text__header'
    | 'text__header--sm'
    | 'text__body--lg'
    | 'text__body--md'
    | 'text__body'
    | 'text__body--sm';
};

const useStyles = makeStyles(() => {
  return createStyles({
    info500: {
      color: SYSTEM_COLORS.info[500],
    },
    gray400: {
      color: BRAND_COLORS.gray[400],
    },
    gray300: {
      color: BRAND_COLORS.gray[300],
    },
    colorSecondaryDarker: {
      color: BRAND_COLORS.secondary[500],
    },
    white: {
      color: 'white',
    },
  });
});

const TypographyComponent: React.FC<Props> = ({
  customVariant,
  customColor,
  className,
  ...typographyProps
}) => {
  const classes = useStyles();
  const hasColor = !!typographyProps?.color;

  const customVariantCss = {
    'text__header--lg': FONT_STYLES.TEXT_HEADER_LG,
    text__header: FONT_STYLES.TEXT_HEADER,
    'text__header--sm': FONT_STYLES.TEXT_HEADER_SM,
    'text__body--lg': FONT_STYLES.TEXT_BODY_LG,
    'text__body--md': FONT_STYLES.TEXT_BODY_MD,
    text__body: FONT_STYLES.TEXT_BODY,
    'text__body--sm': FONT_STYLES.TEXT_BODY_SM,
  };

  return (
    <Typography
      {...typographyProps}
      css={[!!customVariant && customVariantCss[customVariant]]}
      className={classNames([
        className,
        !hasColor && customColor === 'white' && classes.white,
        !hasColor && customColor === 'info-500' && classes.info500,
        !hasColor && customColor === 'gray-300' && classes.gray300,
        !hasColor && customColor === 'gray-400' && classes.gray400,
        !hasColor &&
          customColor === 'secondary-darker' &&
          classes.colorSecondaryDarker,
      ])}
    />
  );
};

export default TypographyComponent;
