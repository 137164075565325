import React, { useRef, useState } from 'react';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ModalConfirmDelete from '@backoffice/private/components/Modal/ModalConfirmDelete.component';
import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';
import Loading from '@backoffice/private/components/Loading/Loading.component';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';

import {
  Dispatchs,
  Selectors,
} from '../../../../ducks/merchants/acquirers/listMerchantAcquirers.duck';

const TableMerchantAcquirerContainer = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
    REMOVE_ACQUIRER: flow(Dispatchs.removeAcquirer, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage } = useCrudRead({
    dispatchRedux,
    selectorRedux,
  });

  const handleDelete = (id) => {
    dispatchRedux.REMOVE_ACQUIRER(id);
  };

  return (
    <TableMerchantAcquirerComponent
      loading={selectorRedux.LOADING}
      results={selectorRedux.DATA_RESULTS}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleDelete={handleDelete}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const TableMerchantAcquirerComponent = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  handleDelete,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_estabelecimento_credenciadoras',
  });
  const { isAdmin } = useUserHook();

  const currentId = useRef(null);

  const [modalDelete, setModalDelete]: any = useState(null);

  const { limit, count, offset } = filters || {};

  const columns = [
    isAdmin && {
      width: 40,
      render: ({ id }) => (
        <Box display="flex">
          <IconButton
            onClick={() => {
              setModalDelete(true);

              currentId.current = id;
            }}
            disabled={!permissions?.some((item) => item == 'delete')}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      width: 40,
      render: ({ path }) => <img width={24} src={path} />,
    },
    {
      title: 'Nome',
      dataIndex: 'acquirer_name',
    },
    {
      title: 'Ativo',
      render: ({ active }) => (active ? 'Sim' : 'Não'),
    },
    {
      title: 'Antecipação',
      dataIndex: 'advancement_description',
    },
  ];

  const acquirer = results?.find((r) => r.id === currentId?.current) || {};

  return (
    <>
      {permissions?.some((item) => item == 'read') && (
        <Box gridGap={8} display="flex" flexDirection="column">
          <CardTitle>Listagem de credenciadoras</CardTitle>

          <CardTable
            count={count || 0}
            loading={loading}
            dataSource={results}
            rowsPerPage={limit}
            columns={columns as any}
            page={offset / limit}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <ModalConfirmDelete
            open={modalDelete || false}
            description={`Deseja excluir a credenciadora ${acquirer.acquirer_name}?`}
            onConfirm={async () => {
              handleDelete(currentId.current);

              await new Promise((res) =>
                setTimeout(() => {
                  setModalDelete(false);

                  currentId.current = null;

                  return res;
                }, 200)
              );
            }}
            onCancel={() => setModalDelete(false)}
          />
        </Box>
      )}

      {(!permissions || !permissions?.some((item) => item == 'read')) && (
        <ModuleNotPermited />
      )}
    </>
  );
};

export default TableMerchantAcquirerContainer;
