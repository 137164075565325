import React from 'react';
import SideBarComponent from './Sidebar.component';
import { useSidebar } from './Sidebar.context';

const SideBarContainer = () => {
  const { toggleSideBar, isOpen }: any = useSidebar();

  return <SideBarComponent isOpen={isOpen} toggleSideBar={toggleSideBar} />;
};

export default SideBarContainer;
