import React, { useState, useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';

import fetch from '@payhop/shared-utils/fetch.util';

import * as listMerchantsDuck from '@backoffice/private/ducks/merchants/listMerchants.duck';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import { SearchMerchantsComponent } from './SearchMerchant.component';

const creditorsService = () =>
  fetch(`creditors?limit=999999`, {
    method: 'GET',
    ms: 'CREDITOR',
    auth: true,
  });

const SearchMerchantsContainer = () => {
  const { isAdmin } = useUserHook();

  const [key, setKey] = useState(v4());
  const [creditors, setCreditors] = useState([]);

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(listMerchantsDuck.Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(listMerchantsDuck.Dispatchs.resetFilters, dispatch),
    GET: flow(listMerchantsDuck.Dispatchs.get, dispatch),
    RESET: flow(listMerchantsDuck.Dispatchs.reset, dispatch),
    DELETE: flow(listMerchantsDuck.Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(listMerchantsDuck.Selectors.data),
    LOADING: useSelector(listMerchantsDuck.Selectors.loading),
    DATA_RESULTS: useSelector(listMerchantsDuck.Selectors.data_results),
    FILTERS: useSelector(listMerchantsDuck.Selectors.filters),
    RESULTSET: useSelector(listMerchantsDuck.Selectors.resultset),
    FETCHED: useSelector(listMerchantsDuck.Selectors.fetched),
  };

  const { handleSearch, handleSearchReset } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: false,
    },
  });

  const handleGetCreditors = useCallback(async () => {
    const response = await creditorsService();
    const result = await response.json();

    const { results } = result || {};

    setCreditors(results);
  }, []);

  const handleSubmit = async (values) => {
    const newValues = { ...values, offset: 0 };

    if (newValues?.document) {
      newValues.document = newValues.document.replace(/\D/g, '');
    }

    await handleSearch(newValues);
  };

  useEffect(() => {
    if (isAdmin) {
      handleGetCreditors();
    }
  }, [handleGetCreditors]);

  useEffect(() => {
    setKey(v4());
  }, [selectorRedux.FILTERS]);

  return (
    <Formik
      initialValues={{
        creditor_id: selectorRedux.FILTERS.creditor_id ?? null,
        document: selectorRedux.FILTERS.document ?? '',
        name: selectorRedux.FILTERS.name ?? '',
        view_full_api: selectorRedux.FILTERS.view_full_api ?? false,
      }}
      onSubmit={handleSubmit}
      key={key}
    >
      <SearchMerchantsComponent
        creditors={creditors}
        isAdmin={isAdmin}
        loading={selectorRedux.LOADING}
        handleSearchReset={handleSearchReset}
      />
    </Formik>
  );
};

export default SearchMerchantsContainer;
