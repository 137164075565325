import React, { useMemo, Fragment, useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

import ModalConfirmDelete from '@backoffice/private/components/Modal/ModalConfirmDelete.component';
import ModalChangePasswordComponent from '../modals/ModalChangePassword.component';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardSearch from '@backoffice/private/components/Card/CardSearch';

import ROUTES from '@backoffice/shared/utils/routes.util';

import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';

import postAccountsUnlockoutService from '@backoffice/private/services/account/postAccountsUnlockout.service';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/users/listUsers.duck';

const texts = {
  title: 'Usuários',
};

const TableUsersPayhop = () => {
  const handleNotification = useNotification();

  const [loadingLocal, setLoadingLocal] = useState(false);
  const [key, setKey] = useState(v4());

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const handleUnlock = async (email) => {
    setLoadingLocal(true);

    const response = await postAccountsUnlockoutService(email);

    if (response.ok) {
      handleNotification('Desbloqueado com sucesso', 'success');
    } else {
      const result = await response.json();

      handleNotification(result?.errors?.message?.[0], 'error');
    }

    setLoadingLocal(false);
  };

  const {
    handleDelete,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearch,
    handleSearchReset,
  } = useCrudRead({
    dispatchRedux,
    selectorRedux,
  });

  useEffect(() => {
    setKey(v4());
  }, [selectorRedux.FILTERS]);

  return (
    <UsersScreen
      loading={selectorRedux.LOADING || loadingLocal}
      results={selectorRedux.DATA_RESULTS}
      key={key}
      handleDelete={handleDelete}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleSearch={handleSearch}
      handleSearchReset={handleSearchReset}
      handleUnlock={handleUnlock}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const UsersScreen = ({
  loading,
  results,
  key,
  filters,
  handleDelete,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleSearchReset,
  handleUnlock,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_acesso_usuario_payhop',
  });
  const currentId = useRef('');
  const currentEmail = useRef('');

  const [modalDelete, setModalDelete] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);

  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        width: 100,
        render: ({ id, email }) => (
          <Box display="flex" gridGap={8}>
            <IconButton
              disabled={!permissions?.some((item) => item == 'edit')}
              onClick={() => {
                history.push(ROUTES.PRIVATE.ACCESS.EDIT(id, 'user'));
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              disabled={!permissions?.some((item) => item == 'edit')}
              onClick={() => {
                currentId.current = id;
                currentEmail.current = email;
                setModalPassword(true);
              }}
            >
              <VpnKeyIcon />
            </IconButton>
            <IconButton
              disabled={!permissions?.some((item) => item == 'delete')}
              onClick={() => {
                currentId.current = id;
                setModalDelete(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <Button
              disabled={!permissions?.some((item) => item == 'edit')}
              size="small"
              variant="outlined"
              onClick={() => handleUnlock(email)}
            >
              Desbloquear
            </Button>
          </Box>
        ),
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Perfil',
        dataIndex: 'profile_description',
        key: 'profile_description',
      },
      {
        title: 'Telefone',
        dataIndex: 'phone_number',
        key: 'phone_number',
        render: ({ phone_number }) => (
          <NumberFormat
            displayType="text"
            value={phone_number}
            format="(##) #####-####"
          />
        ),
      },
    ],
    [permissions]
  );

  const handleCloseModalDelete = () => setModalDelete(false);

  const { limit, count, offset } = filters || {};

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" gridGap={32}>
        <Formik
          initialValues={{
            name: filters.name ?? '',
            email: filters.email ?? '',
            phone: filters.phone ?? '',
          }}
          onSubmit={async (values) => {
            const valuesFormatted = {
              ...values,
              phone: values?.phone.replace(/\D/g, ''),
            };

            return handleSearch(valuesFormatted);
          }}
          key={key}
        >
          {({ submitForm, resetForm }) => (
            <CardSearch
              fields={[
                [
                  {
                    name: 'name',
                    label: 'Nome',
                  },
                  {
                    name: 'email',
                    label: 'Email',
                  },
                  {
                    name: 'phone',
                    label: 'Telefone',
                    mask: 'mobile',
                  },
                ],
              ]}
              formik
              onSubmit={submitForm}
              onReset={() => handleSearchReset()(resetForm)}
            />
          )}
        </Formik>

        <CardTable
          count={count}
          loading={loading}
          dataSource={results}
          rowsPerPage={limit}
          columns={columns}
          page={offset / limit}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>

      <ModalConfirmDelete
        open={modalDelete}
        onCancel={handleCloseModalDelete}
        title="Você tem certeza que deseja inativar este usuário?"
        description={
          'Esta ação não poderá ser desfeita, apenas prossiga se tiver certeza.'
        }
        onConfirm={() =>
          handleDelete(currentId.current)(handleCloseModalDelete)
        }
      />

      <ModalChangePasswordComponent
        open={modalPassword}
        onClose={() => setModalPassword(false)}
        email={currentEmail.current}
      />
    </Fragment>
  );
};

export default TableUsersPayhop;
