import React, { useState } from 'react';
import { v4 } from 'uuid';
import { Field, useFormikContext } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import NumberFormat from 'react-number-format';
import { TextField as TextFieldMaterial } from '@material-ui/core';
import toFormatAlphabetic from '@backoffice/shared/utils/toFormatAlphabetic.util';

import CardSearch from '@backoffice/private/components/Card/CardSearch';

import STATUS_DEAL from '../../../constants/statusDeal.constant';

export const SearchSolicitationComponent = ({
  isAdmin,
  merchants,
  creditors,
  handleSearchReset,
  loading,
}) => {
  const [key, setKey] = useState(v4());

  const { values, submitForm, resetForm }: any = useFormikContext();

  const arrToFormat =
    values?.consulta_por === 'fornecedor' ? creditors : merchants;

  const arr = arrToFormat.reduce(
    (acc, curr) => [
      ...acc,
      {
        label: curr.trading_name,
        value: curr.id,
      },
    ],
    []
  );

  const arrFiltered = toFormatAlphabetic(arr);

  const schemaSearch = [
    [
      {
        label: 'Consulta por',
        name: 'consulta_por',
        disabled: !isAdmin ? true : false,
        type: 'select',
        options: [
          {
            label: 'Fornecedor',
            value: 'fornecedor',
          },
          {
            label: 'Estabelecimento',
            value: 'estabelecimento',
          },
        ],
      },
      arrFiltered.length > 0 && {
        type: 'custom',
        component: (
          <Field
            component={Autocomplete}
            name="id"
            css={{ minWidth: '250px' }}
            options={arrFiltered.map((a: any) => a.value)}
            getOptionLabel={(option: any) => {
              const currentBank = arrFiltered?.find((b) => b.value === option);

              if (!currentBank?.label) {
                return '';
              }

              return currentBank?.label;
            }}
            renderInput={(params) => (
              <TextFieldMaterial
                {...params}
                label="Fornecedor/Estabelecimento"
                variant="outlined"
              />
            )}
          />
        ),
      },
      !(!isAdmin && values?.consulta_por === 'fornecedor') && {
        type: 'custom',
        component: (
          <Field name="documentNumber">
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }: any) => (
              <>
                <NumberFormat
                  customInput={TextFieldMaterial}
                  format="##.###.###/####-##"
                  fullWidth
                  label="CNPJ"
                  required
                  variant="outlined"
                  value={field.value}
                  error={touched && !!error}
                  helperText={touched && !!error && error}
                  onBlur={async ({ target: { value } }) => {
                    setFieldTouched(field.name, true);
                  }}
                  onValueChange={(values: any) =>
                    setFieldValue(field.name, values.value)
                  }
                />
              </>
            )}
          </Field>
        ),
      },
      {
        label: 'ID',
        name: 'referenceId',
      },
    ],
    [
      {
        label: 'Status',
        name: 'status',
        type: 'select',
        multiple: true,
        options: STATUS_DEAL,
      },
      {
        label: 'Data Execução Inicial',
        name: 'startDate',
        type: 'date',
      },
      {
        label: 'Data Execução Final',
        name: 'endDate',
        type: 'date',
      },
    ],
  ];

  return (
    <CardSearch
      formik
      key={key}
      fields={schemaSearch as any}
      disabled={loading}
      onSubmit={submitForm}
      onReset={() =>
        handleSearchReset()(() => {
          setKey(v4());

          resetForm();
        })
      }
    />
  );
};
