import React, { useEffect, useState } from 'react';

import { Field, useFormikContext } from 'formik';
import { Box } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import useNotification from '@payhop/shared-hooks/useNotification.hook';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@backoffice/shared/components/Typography.component';

type InputUploadImageProps = {
  inputWidth?: string;
  onDrop?: any;
  name: string;
  importLabel: string;
};

const InputUploadImage = ({
  inputWidth,
  onDrop: onDropProp,
  name,
  importLabel,
}: InputUploadImageProps) => {
  const { setFieldValue } = useFormikContext();
  const handleNotification = useNotification();
  const [file, setFile] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');

  const dropzone = useDropzone({
    onDrop: onDropProp,
    accept: '.pdf',
    maxSize: 5242880,
  });

  useEffect(() => {
    if (dropzone.acceptedFiles.length > 0) {
      handleFiles(dropzone.acceptedFiles[0]);
      setFieldValue(name, dropzone.acceptedFiles[0]);
      setUploadedFileName(dropzone.acceptedFiles[0].name);
    }
  }, [dropzone.acceptedFiles]);

  useEffect(() => {
    if (dropzone.fileRejections.length > 0) {
      const firstRejection = dropzone.fileRejections[0];
      const maxSizeError = firstRejection.errors.find(
        (error) => error.code === 'file-too-large'
      );
      if (maxSizeError) {
        handleNotification(
          'Uau, esse arquivo é muito grande! Para anexar um arquivo, ele precisa ser menor que 5 MB. Tente reduzir o tamanho do arquivo ou selecione outro arquivo menor.',
          'error'
        );
      } else {
        handleNotification(
          'Ops! O arquivo que você selecionou não está em um formato válido. Selecione um arquivo no formato PDF.',
          'error'
        );
      }
    }
  }, [dropzone.fileRejections]);

  const handleFiles = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
        const base64 = reader.result;
        setFile(base64 as string);
      };
      reader.onerror = (error) => reject(error);
    });

  return (
    <>
      <Box
        {...dropzone.getRootProps()}
        width={inputWidth ? inputWidth : '100%'}
        border="1px solid #A8A8A8"
        borderRadius={4}
        css={{ cursor: 'pointer', padding: '0 8px' }}
        height={55}
      >
        {!file && (
          <Box
            height={55}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography css={{ color: '#A8A8A8' }}>{importLabel}</Typography>
            <CloudUploadIcon
              css={{ width: 40, height: 40, color: '#A8A8A8' }}
            />
          </Box>
        )}

        {file && (
          <Box height="100%" display="flex" alignItems="center">
            <Typography customColor="info-500">{uploadedFileName}</Typography>
          </Box>
        )}

        <input
          {...dropzone.getInputProps()}
          type="file"
          style={{ display: 'none' }}
        />
      </Box>
    </>
  );
};

export default InputUploadImage;
