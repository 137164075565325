import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

type useCrudTypes = {
  dispatchRedux: {
    GET: any;
    RESET: any;
    RESET_FILTERS?: any;
    DELETE?: any;
    UPDATE_FILTERS?: any;
  };
  selectorRedux: {
    FILTERS: any;
  };
  opts?: {
    requestOnMount?: boolean;
    resetOnUnmount?: boolean;
  };
};

const useCrudRead = ({
  dispatchRedux,
  selectorRedux,
  opts = {},
}: useCrudTypes) => {
  const { requestOnMount = true } = opts;

  useEffect(() => {
    if (requestOnMount) {
      dispatchRedux.GET();
    }
  }, []);

  const { limit } = selectorRedux.FILTERS || {};

  const handleDelete = (id) => async (fn) => {
    const result = await dispatchRedux.DELETE(id);

    if (!result) {
      return false;
    }

    dispatchRedux.GET();

    return fn();
  };

  const handleChangePage = (_, value) =>
    dispatchRedux.UPDATE_FILTERS({
      offset: value * limit,
    });

  const handleChangeRowsPerPage = (_, { props: { value } }) =>
    dispatchRedux.UPDATE_FILTERS({ limit: value, offset: 0 });

  const handleSearch = (values) => {
    const newValues = { ...values };

    if (newValues?.document) {
      newValues.document = newValues.document.replace(/\D/g, '');
    }

    return dispatchRedux.UPDATE_FILTERS({ ...newValues });
  };

  const handleSearchForce = (values) => {
    const newValues = { ...values };

    if (newValues?.document) {
      newValues.document = newValues.document.replace(/\D/g, '');
    }

    return dispatchRedux.UPDATE_FILTERS({ ...newValues }, { force: true });
  };

  const handleSearchReset = () => (fn) => {
    dispatchRedux.RESET_FILTERS();
    dispatchRedux.GET();

    return fn();
  };

  return {
    handleDelete,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearch,
    handleSearchReset,
    handleSearchForce,
  };
};

export default useCrudRead;
