import React from 'react';

import { Box } from '@material-ui/core';

import * as Styles from './Tags.styled';

interface Cnae {
  description: string;
  number: number;
}

interface TagsComponentProps {
  cnaes: Cnae[];
}

const TagsComponent = ({ cnaes }: TagsComponentProps) => {
  return (
    <Box position="relative">
      <Styles.Label>CNAES</Styles.Label>
      <Box
        css={{
          border: '1px solid rgba(0, 0, 0, 0.24)',
          borderRadius: '4px',
          padding: '8px',
          maxHeight: '180px',
          overflowY: 'scroll',
          columnGap: '8px',
          rowGap: '8px',
          display: 'flex',
          minHeight: '50px',
        }}
        flexWrap={'wrap'}
      >
        {cnaes &&
          cnaes.map((cnae) => {
            return (
              <Styles.Tag key={cnae.number}>
                {cnae.number} - {cnae.description}
              </Styles.Tag>
            );
          })}
      </Box>
    </Box>
  );
};

export default TagsComponent;
