import React, { useState, useEffect, useMemo } from 'react';
import { flow } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import * as dialogCreditorSeeDomiciles from '@backoffice/private/ducks/creditors/dialogs/dialogCreditorSeeDomiciles.duck';
import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/originators/creditorOriginators.duck';

import {
  Dispatchs as DispatchsOriginators,
  Selectors as SelectorsOriginators,
} from '@backoffice/private/ducks/creditors/originators/listCreditorOriginators.duck';

import { TIPO_CONTA } from '@backoffice/private/constants/tipo_conta.constant';

import {
  Dialog,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CardTable from '@backoffice/private/components/Card/CardTable';
import NumberFormat from 'react-number-format';
import SwitchActive from '../SwitchActive.component';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

import CopyToClipboard from '@payhop/shared-components/CopyToClipboard/CopyToClipboard.component';

const DialogSeeDomiciles = () => {
  const handleNotification = useNotification();
  const [domiciles, setDomiciles] = useState<any>([]);
  const [originatorObject, setOriginatorObject] = useState<any>([]);
  const [dataForm, setDataForm] = useState<any>();

  const dispatch = useDispatch();

  const dispatchRedux = {
    CLOSE: flow(dialogCreditorSeeDomiciles.Dispatchs.close, dispatch),
  };

  const dispatchReduxOriginators = {
    UPDATE: flow(Dispatchs.update, dispatch),
  };

  const dispatchListOriginators = {
    GET: flow(DispatchsOriginators.get, dispatch),
  };

  const selectorRedux = {
    visible: useSelector(dialogCreditorSeeDomiciles.Selectors.visible),
    payload: useSelector(dialogCreditorSeeDomiciles.Selectors.payload),
  };

  useEffect(() => {
    if (selectorRedux.payload) {
      setDomiciles(selectorRedux?.payload?.domicilios);
      setOriginatorObject(selectorRedux.payload);
    }
  }, [selectorRedux.payload]);

  const handleChangeDomicileStatus = (obj) => {
    let teste = originatorObject;
    delete teste.data_cadastro;
    delete teste.data_atualizacao;
    delete teste.status;
    delete teste.id;

    const newDataDomiciles = domiciles?.map((item) => {
      if (item == obj) {
        delete item.mensagem;
        delete item.data_cadastro;
        delete item.data_atualizacao;
        return {
          ...item,
          status: item.status == 'Ativo' ? 'Inativo' : 'Ativo',
          banco: item.banco.split(' - ')[0].trim(),
        };
      } else {
        delete item.mensagem;
        delete item.data_cadastro;
        delete item.data_atualizacao;
        return {
          ...item,
          banco: item.banco.split(' - ')[0].trim(),
        };
      }
    });
    teste = { originadores: [{ ...teste, domicilios: newDataDomiciles }] };

    fetchData(teste, obj);
  };

  const fetchData = async (obj, domicile) => {
    try {
      const response = await dispatchReduxOriginators.UPDATE(obj);
      if (response[0].domicilios[0].status == 'Erro') {
        handleNotification(response[0].domicilios[0].mensagem, 'error');
      } else {
        setDomiciles(response[0].domicilios);
        handleNotification('Status alterado com sucesso!', 'success');
      }
      // Faça o que precisa com a resposta
    } catch (error) {
      handleNotification(`Erro ao alterar status: ${error}`, 'error');
      console.error('Erro na requisição:', error);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: 'Status',
        width: 40,
        render: (obj) => (
          <Box display="flex">
            <SwitchActive
              obj={obj}
              handleChangeStatus={handleChangeDomicileStatus}
            />
          </Box>
        ),
      },
      {
        title: 'CNPJ Domicílio',
        dataIndex: 'cnpj_domicilio',
        key: 'cnpj_domicilio',
        render: ({ cnpj_domicilio }) => (
          <NumberFormat
            displayType="text"
            value={cnpj_domicilio}
            isNumericString
            format="##.###.###/####-##"
          />
        ),
      },
      {
        title: 'ID Domicílio',
        dataIndex: 'id',
        key: 'id',
        render: ({ id }) => <CopyToClipboard dataToCopy={id} />,
      },
      {
        title: 'Título',
        dataIndex: 'titulo_domicilio',
        key: 'titulo_domicilio',
      },
      {
        title: 'Banco',
        dataIndex: 'banco',
        key: 'banco',
      },
      {
        title: 'Agência',
        dataIndex: 'agencia',
        key: 'agencia',
      },
      {
        title: 'Conta',
        dataIndex: 'conta',
        key: 'conta',
      },
      {
        title: 'Tipo da conta',
        dataIndex: 'tipo_conta',
        key: 'tipo_conta',
        render: ({ tipo_conta }) => (
          <p>
            {TIPO_CONTA[tipo_conta]} - {tipo_conta}
          </p>
        ),
      },
    ],
    [domiciles, originatorObject]
  );

  return (
    <Dialog
      open={selectorRedux.visible}
      onClose={() => dispatchRedux.CLOSE(dispatchListOriginators.GET)}
      aria-labelledby="form-dialog-title"
      maxWidth={'lg'}
      fullWidth={true}
    >
      <DialogTitle id="customized-dialog-title">
        Domicilíos
        <IconButton
          css={{ position: 'absolute', right: 0, top: '5px' }}
          aria-label="close"
          onClick={() => dispatchRedux.CLOSE(dispatchListOriginators.GET)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {domiciles && domiciles.length > 0 ? (
          <CardTable dataSource={domiciles} columns={columns} footer={false} />
        ) : (
          <p>Nenhum domicilio cadastrado para esse originador</p>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DialogSeeDomiciles;
