import React, { useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import ExportXLS from '../../Export/Export.component';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table, { TableProps } from '@backoffice/private/components/Table';
import { Typography } from '@material-ui/core';

type Props = TableProps & {
  onRefresh?: any;
  onExport?: any;
  totalLines?: string;
  totalError?: string;
  exporttype?: string;
  exportname?: string;
};

const CardTableComponent: React.FC<Props> = (props) => {
  const csvLinkEl = useRef(null);
  const [exporting, setExporting] = useState(false);
  const [data, setData] = useState([]);

  const handleOnExport = async () => {
    setExporting(true);

    if (props.exporttype == 'api') {
      await props.onExport();
    } else {
      const data = await props.onExport();

      setData(data);
      setTimeout(() => {
        if (props.exporttype == 'xls') {
          const exportButton = document.querySelector(
            '.button-xls-export'
          ) as HTMLElement;

          if (exportButton) {
            exportButton.click();
          }
          setExporting(false);
        } else {
          (csvLinkEl as any).current.link.click();
          setExporting(false);
        }
      });
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        {(props.onExport ||
          props.onRefresh ||
          props.totalLines ||
          props.totalError) && (
          <Box alignSelf="flex-end" mb={1} display="flex" gridGap={8}>
            {props.onExport && (
              <Tooltip title="Baixar tabela">
                <IconButton
                  onClick={handleOnExport}
                  size="small"
                  disabled={exporting}
                >
                  <GetAppIcon css={{ width: 18, height: 18 }} />
                </IconButton>
              </Tooltip>
            )}
            {props.onRefresh && (
              <Tooltip title="Atualizar tabela">
                <IconButton onClick={props.onRefresh} size="small">
                  <RefreshIcon css={{ width: 18, height: 18 }} />
                </IconButton>
              </Tooltip>
            )}
            {props.totallines && (
              <Typography>Total de linhas: {props.totallines}</Typography>
            )}
            {props.totalerror && (
              <Typography>Total de erros: {props.totalerror}</Typography>
            )}
          </Box>
        )}
        <Card>
          <Table {...props} />
        </Card>
      </Box>

      {exporting && props.exporttype !== 'api' && (
        <div
          css={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: '#ffffffe0',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Dialog open={exporting}>
            <DialogTitle id="alert-dialog-title">
              Arquivo em processamento
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Os dados estão sendo exportados
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      )}

      <CSVLink filename="exported.csv" data={data} ref={csvLinkEl} />
      {props.exporttype == 'xls' && props.exportname && (
        <ExportXLS filename={props.exportname} data={data} />
      )}
    </>
  );
};

export default CardTableComponent;
