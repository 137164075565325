export const getColumnsTable = () => {
  return [
    {
      title: 'Campo',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Formato',
      dataIndex: 'format',
      key: 'format',
    },
    {
      title: 'Obrigatório',
      dataIndex: 'required',
      key: 'required',
    },
    {
      title: 'Qtd. Máxima de Caractéres',
      dataIndex: 'qtd_max_characteres',
      key: 'qtd_max_characteres',
    },
    {
      title: 'Regras',
      dataIndex: 'rules',
      key: 'rules',
    },
    {
      title: 'Exemplo de Preenchimento',
      dataIndex: 'example_fill',
      key: 'example_fill',
    },
  ];
};

export const getColumnsData = () => {
  return [
    {
      field: 'cnpj_estabelecimento',
      format: 'Texto',
      required: 'SIM',
      qtd_max_characteres: 'CPF: 11 / CNPJ: 14',
      rules: '1. Não incluir caractéres especiais',
      example_fill: 'CPF: 44235417470 / CNPJ: 35195156000132',
    },
    {
      field: 'título',
      format: 'Texto',
      required: 'SIM',
      qtd_max_characteres: 'Sem limite de caracteres',
      rules: '1. Obrigatório 2. Campo Personalizável',
      example_fill: 'Compromisso Parcelado',
    },
    {
      field: 'descrição',
      format: 'Texto',
      required: 'SIM',
      qtd_max_characteres: 'Sem limite de caracteres',
      rules: '1. Obrigatório 2. Campo Personalizável',
      example_fill: 'Compromisso 2 Parcelas de R$500,00',
    },
    {
      field: 'código',
      format: 'Texto',
      required: 'SIM',
      qtd_max_characteres: 'Sem limite de caracteres',
      rules: '1. Obrigatório 2. Campo Personalizável',
      example_fill: 'CP52582023',
    },
    {
      field: 'emissão',
      format: 'Data',
      required: 'SIM PARA PARCEIRO IF',
      qtd_max_characteres: '10',
      rules:
        '1. Campo de Data, referente a data de emissão do boleto do parceiro, ou da emissão do documento relacionado ao crédito.',
      example_fill: '02/15/2024',
    },
    {
      field: 'expiração',
      format: 'Data',
      required: 'SIM',
      qtd_max_characteres: '10',
      rules:
        '1. Campo de Data, referente ao período que o estabelecimento terá para aprovar o compromisso. 2 Não pode ser menor que a data de criação.',
      example_fill: '02/15/2024',
    },
    {
      field: 'cnpj_credenciadora',
      format: 'Texto',
      required: 'SIM',
      qtd_max_characteres: '14 por credenciadora',
      rules:
        '1. A credenciadora deve estar cadastrada. 2. Deve separar as credenciadoras com virgula (,)',
      example_fill: '85045168000135,57035168000150',
    },
    {
      field: 'arranjos',
      format: 'Texto',
      required: 'SIM',
      qtd_max_characteres: 'Sem limite de caracteres',
      rules:
        '1. A credenciadora deve estar cadastrada. 2. Deve separar os arranjos  com virgula (,)',
      example_fill: 'VCC,MCC',
    },
    {
      field: 'regra_divisão',
      format: 'Texto',
      required: 'SIM',
      qtd_max_characteres: '1',
      rules: 'Somente uma regra de divisão por linha',
      example_fill: '1',
    },
    {
      field: 'valor_onerar',
      format: 'Texto',
      required: 'SIM',
      qtd_max_characteres: 'Sem limite de caracteres',
      rules: 'Até dois numeros depois do ponto',
      example_fill: '50.00',
    },
    {
      field: 'valor_solicitado',
      format: 'Texto',
      required: 'SIM',
      qtd_max_characteres: 'Sem limite de caracteres',
      rules: 'Até dois numeros depois do ponto',
      example_fill: '500.00',
    },
    {
      field: 'data_vencimento',
      format: 'Data',
      required: 'SIM PARA GARANTIA',
      qtd_max_characteres: '10',
      rules:
        '1. Data de vencimento deve ser igual ou anterior a data de execução inicial; 2. Data de vencimento é obrigatória somente para tipo de Transação Garantia, e opcional para os demais tipos de transação.',
      example_fill: '02/15/2024',
    },
    {
      field: 'execução_início',
      format: 'Data',
      required: 'SIM',
      qtd_max_characteres: '10',
      rules:
        '1. Data de execução_inicio não pode ser maior que a data de execução_fim; 2. Data de execução_início não pode ser maior que a data de execução_fim.',
      example_fill: '02/15/2024',
    },
    {
      field: 'execução_fim',
      format: 'Data',
      required: 'SIM',
      qtd_max_characteres: '10',
      rules:
        '1. Data de execução_fim não pode ser menor que a data de criação. 3. Data de execução _fim não pode ser menor que a data de execução_início.',
      example_fill: '02/15/2024',
    },
    {
      field: 'id_domicilio',
      format: 'Texto',
      required: 'SIM',
      qtd_max_characteres: 'Sem limite de caracteres',
      rules:
        '1. Deve incluir os caracteres especiais constantes no ID domicílio.',
      example_fill: '1ce8d500-5f99-11ee-b924-69a6b0323aae',
    },
  ];
};
