import React, { useState, useEffect, useCallback } from 'react';
import { Formik, useFormikContext } from 'formik';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';

import CardSearch from '@backoffice/private/components/Card/CardSearch';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import * as listArrangementDuck from '@backoffice/private/ducks/parameters/arrangements/listArrangements.duck';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

const SearchArrangement = ({ handleSearchReset, loading }) => {
  const [key, setKey] = useState(v4());

  const { values, submitForm, resetForm }: any = useFormikContext();

  const schemaSearch = [
    [
      {
        label: 'Nome',
        name: 'name',
        required: true,
      },
      {
        label: 'Código',
        name: 'code',
      },
      {
        label: 'Arranjo',
        name: 'arrangement',
        required: true,
      },
    ],
  ];

  return (
    <>
      <CardTitle>Filtrar arranjos</CardTitle>

      <CardSearch
        formik
        key={key}
        disabled={loading}
        fields={schemaSearch as any}
        onSubmit={submitForm}
        onReset={() =>
          handleSearchReset()(() => {
            setKey(v4());

            resetForm();
          })
        }
      />
    </>
  );
};

export default function SearchArrangementComponent() {
  const [key, setKey] = useState(v4());
  const { error } = useSelector((state: any) => state.shared);
  const handleNotification = useNotification();

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(listArrangementDuck.Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(listArrangementDuck.Dispatchs.resetFilters, dispatch),
    GET: flow(listArrangementDuck.Dispatchs.get, dispatch),
    RESET: flow(listArrangementDuck.Dispatchs.reset, dispatch),
    DELETE: flow(listArrangementDuck.Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(listArrangementDuck.Selectors.data),
    LOADING: useSelector(listArrangementDuck.Selectors.loading),
    DATA_RESULTS: useSelector(listArrangementDuck.Selectors.data_results),
    FILTERS: useSelector(listArrangementDuck.Selectors.filters),
    RESULTSET: useSelector(listArrangementDuck.Selectors.resultset),
    FETCHED: useSelector(listArrangementDuck.Selectors.fetched),
  };

  const { handleSearch, handleSearchReset } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: true,
    },
  });

  useEffect(() => {
    if (error.messages?.[0] !== undefined) {
      handleNotification(error.messages?.[0], 'error');
    }
  }, [error.messages]);

  useEffect(() => {
    setKey(v4());
  }, [selectorRedux.FILTERS]);

  const handleSubmit = async (values) => {
    const newValues = {
      ...values,
      offset: 0,
    };

    await handleSearch(newValues);

    return true;
  };

  return (
    <Formik
      initialValues={{
        name: selectorRedux.FILTERS.name ?? '',
        code: selectorRedux.FILTERS.code ?? '',
        arrangement: selectorRedux.FILTERS.arrangement ?? '',
      }}
      onSubmit={handleSubmit}
      key={key}
    >
      <SearchArrangement
        handleSearchReset={handleSearchReset}
        loading={selectorRedux.LOADING}
      />
    </Formik>
  );
}
