import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

import { login } from '@payhop/shared-ducks/authentication.duck';

import { Card, CardContent, Grid, Box, Typography } from '@material-ui/core';

import Logo from '@payhop/shared-components/Logo';

import ROUTES from '@backoffice/shared/utils/routes.util';

import loginSchema from '@backoffice/shared/schemas/login.schema';

import Button from '@backoffice/shared/components/Button.component';

const CSS = {
  container: css({
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flex: 1,
  }),
  box: css({
    minWidth: '100%',
    padding: '24px',
    borderRadius: '16px',
  }),
  width: css({
    width: '100%',
  }),
};

interface ITitle {
  main: string;
  description?: string;
}

interface LoginProps {
  title: ITitle;
  labelButton?: string;
  history: any;
  handleLogin: (values: any) => void;
}

const LoginScreen: React.FC<Pick<LoginProps, 'title'>> = ({ title }) => {
  const { authentication } = useSelector((state: any) => state.shared || {});
  const setNotification = useNotification();
  const dispatch = useDispatch();

  const { error } = useSelector((state: any) => state.shared);

  useEffect(() => {
    const { error: errorBoolean, context } = error;

    if (errorBoolean && context === 'login') {
      setNotification(error.messages[0], 'error');
    }
  }, [error, setNotification]);

  const { signedIn } = authentication || {};

  const handleSubmit = async (values) => dispatch(login(values, 'ec', 2));

  if (signedIn) {
    return <Redirect to={ROUTES.PRIVATE.HOME} />;
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ ...loginSchema.cast(undefined) }}
      validateOnMount
      validationSchema={loginSchema}
    >
      {({ errors, submitForm, isSubmitting }) => (
        <Form>
          <Grid container>
            <Grid item xs={12} css={CSS.container}>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={4}>
                  <Card variant="outlined" css={CSS.box}>
                    <CardContent>
                      <Grid container spacing={3} direction="column">
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={8}>
                              <Typography variant="h3" color="textPrimary">
                                {title.main}
                              </Typography>

                              {title.description && (
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                >
                                  {title.description}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              <Box
                                display="flex"
                                height="100%"
                                justifyContent="flex-end"
                                alignItems="center"
                              >
                                <Logo css={{ maxWidth: 140 }} />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            id="login-email"
                            component={TextField}
                            variant="outlined"
                            type="input"
                            name="email"
                            label="E-mail"
                            css={CSS.width}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            id="login-password"
                            component={TextField}
                            variant="outlined"
                            type="password"
                            name="password"
                            label="Senha"
                            css={CSS.width}
                          />
                          {/* <Box mt={1}>
                            <Button color="primary" onClick={() => undefined}>
                              Esqueci a senha
                            </Button>
                          </Box> */}
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            id="login-button"
                            type="submit"
                            variant="contained"
                            color="primary"
                            css={CSS.width}
                            onClick={submitForm}
                            disabled={
                              Object.keys(errors).length > 0 || isSubmitting
                            }
                            loading={isSubmitting}
                            loadingSize="24px"
                          >
                            Entrar
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginScreen;
