import React, { useState, useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';

import getMerchants from '@backoffice/private/services/merchant/getMerchants.service';
import getCreditors from '@backoffice/private/services/creditor/getCreditors.service';

import * as listDealsDuck from '@backoffice/private/ducks/deals/listDeals.duck';
import { SearchDealComponent } from './SearchDeal.component';
import { v4 } from 'uuid';
import moment from 'moment';

const SearchDealContainer = () => {
  const { isAdmin, user } = useUserHook();

  const [creditors, setCreditors] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [key, setKey] = useState('');

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(listDealsDuck.Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(listDealsDuck.Dispatchs.resetFilters, dispatch),
    GET: flow(listDealsDuck.Dispatchs.get, dispatch),
    RESET: flow(listDealsDuck.Dispatchs.reset, dispatch),
  };

  const selectorRedux = {
    LOADING: useSelector(listDealsDuck.Selectors.loading),
    FILTERS: useSelector(listDealsDuck.Selectors.filters),
  };

  const { handleSearch, handleSearchReset } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: false,
    },
  });

  const handleGetCreditors = useCallback(async () => {
    const response = await getCreditors();
    const result = await response.json();

    const { results } = result || {};

    setCreditors(results);
  }, []);

  const handleGetMerchants = useCallback(async () => {
    const response = await getMerchants();
    const result = await response.json();

    const { results } = result || {};

    setMerchants(results);
  }, []);

  useEffect(() => {
    if (isAdmin) {
      handleGetCreditors();
    }

    handleGetMerchants();
  }, [handleGetCreditors, handleGetMerchants]);

  useEffect(() => {
    setKey(v4());
  }, [selectorRedux.FILTERS]);

  const handleSubmit = async (values) => {
    const newValues = {
      ...values,
      offset: 0,
    };

    if (newValues?.codes) {
      newValues.searchByGroup = undefined;
    }

    if (newValues?.searchByGroup) {
      newValues.codes = undefined;
    }

    if (isAdmin) {
      if (newValues?.consulta_por === 'fornecedor') {
        newValues.creditor_id = newValues.id;
        newValues.searchByCreditor = true;
        newValues.merchant_id = undefined;
      } else {
        newValues.creditor_id = undefined;
        newValues.searchByCreditor = false;
        newValues.merchant_id = newValues.id;
      }
    }

    if (!isAdmin) {
      const { personId } = user || {};

      if (newValues?.consulta_por === 'fornecedor') {
        newValues.creditor_id = personId;
        newValues.merchant_id = undefined;
      } else {
        newValues.creditor_id = personId;
        newValues.merchant_id = newValues.id;
      }
    }

    if (newValues?.startDate) {
      newValues.startDate = moment(newValues.startDate)
        .toISOString()
        .split('T')[0];
    } else {
      newValues.startDate = undefined;
    }

    if (newValues?.endDate) {
      newValues.endDate = moment(newValues.endDate).toISOString().split('T')[0];
    } else {
      newValues.endDate = undefined;
    }
    if (newValues?.createdAt) {
      newValues.createdAt = moment(newValues.createdAt)
        .toISOString()
        .split('T')[0];
    } else {
      newValues.createdAt = undefined;
    }

    if (newValues?.codes) {
      newValues.codes = [newValues.codes];
    }

    delete newValues.id;
    delete newValues.consulta_por;

    await handleSearch(newValues);

    return true;
  };

  return (
    <Formik
      initialValues={{
        consulta_por: selectorRedux.FILTERS.searchByCreditor
          ? 'fornecedor'
          : 'estabelecimento',
        startDate: selectorRedux.FILTERS.startDate ?? null,
        endDate: selectorRedux.FILTERS.endDate ?? null,
        document_number: selectorRedux.FILTERS.document_number ?? '',
        codes: selectorRedux.FILTERS?.codes,
        id: selectorRedux.FILTERS.searchByCreditor
          ? selectorRedux.FILTERS.creditor_id
          : selectorRedux.FILTERS.merchant_id,
        external_reference: selectorRedux.FILTERS.external_reference,
        status: selectorRedux.FILTERS.status,
        searchByGroup: selectorRedux.FILTERS.searchByGroup ?? false,
        strategy: selectorRedux.FILTERS.strategy,
        createdAt: selectorRedux.FILTERS.createdAt ?? null,
        searchByGcap: selectorRedux.FILTERS.searchByGcap ?? false,
      }}
      onSubmit={handleSubmit}
      key={key}
    >
      <SearchDealComponent
        isAdmin={isAdmin}
        creditors={creditors}
        merchants={merchants}
        loading={selectorRedux.LOADING}
        handleSearchReset={handleSearchReset}
      />
    </Formik>
  );
};

export default SearchDealContainer;
