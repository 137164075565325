import { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import { withFormik, useFormikContext } from 'formik';

import messages from '@payhop/shared-utils/messagesSchema.util';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useQuery from '@payhop/shared-hooks/useQuery.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/users/user.duck';

import {
  Dispatchs as DispatchsProfiles,
  Selectors as SelectorsProfiles,
} from '@backoffice/private/ducks/profiles/listProfiles.duck';

import useCrudCreateEdit from '@backoffice/shared/hooks/useCrudCreateEdit.hook';
import ROUTES from '@backoffice/shared/utils/routes.util';

import FormCreditorUsersFormik from '../components/usersCreditors/FormCreditorUsers.component';

const texts = {
  titleEdit: 'Editar usuário',
  titleCreate: 'Cadastro de usuário',
};

const validationSchema = Yup.object({
  profile_id: Yup.string().required(messages.REQUIRED).default(''),
  origin: Yup.string().default('2'),
  type: Yup.string().default('3'),
  name: Yup.string().required(messages.REQUIRED).default(''),
  email: Yup.string()
    .required(messages.REQUIRED)
    .email(messages.EMAIL)
    .default(''),
  phone_number: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .min(10, 'O telefone é inválido'),
});

const CreateUserContainer = () => {
  const history = useHistory();

  const { values, setValues, resetForm, errors } = useFormikContext();

  const dispatch = useDispatch();

  const dispatchRedux = {
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    CREATE: flow(Dispatchs.create, dispatch),
    UPDATE: flow(Dispatchs.update, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    FETCHED: useSelector(Selectors.fetched),
    ERROR: useSelector(Selectors.error),
  };

  const dispatchProfilesRedux = {
    GET: flow(DispatchsProfiles.get, dispatch),
    UPDATE_FILTERS: flow(DispatchsProfiles.updateFilters, dispatch),
  };

  const selectorProfilesRedux = {
    DATA: useSelector(SelectorsProfiles.data),
  };

  const { isEdit, handleCreate, handleUpdate } = useCrudCreateEdit({
    dispatchRedux,
    selectorRedux,
  });

  useEffect(() => {
    if (selectorRedux.FETCHED && isEdit) {
      setValues({
        origin: selectorRedux.DATA.origin,
        type: selectorRedux.DATA.type,
        name: selectorRedux.DATA.name,
        email: selectorRedux.DATA.email,
        phone_number: selectorRedux.DATA.phone_number,
        profile_id: selectorRedux.DATA.profile_id,
      });
    }
  }, [selectorRedux.FETCHED, isEdit]);

  useEffect(() => {
    const { successAction } = selectorRedux.DATA || {};

    if (successAction === true && !isEdit) {
      resetForm();

      history.push(ROUTES.PRIVATE.ACCESS.ROOT);
    }
  }, [selectorRedux.DATA]);

  useEffect(() => {
    dispatchProfilesRedux.UPDATE_FILTERS({ type: 'FORNECEDOR', active: true });
    dispatchProfilesRedux.GET();
  }, []);

  return (
    <CreateUserScreen
      isEdit={isEdit}
      loading={selectorRedux.LOADING}
      fetched={selectorRedux.FETCHED}
      errorsForm={errors}
      profiles={selectorProfilesRedux.DATA}
      handleSubmit={() =>
        isEdit ? handleUpdate(values) : handleCreate(values)
      }
    />
  );
};

const CreateUserScreen = ({
  isEdit,
  loading,
  fetched,
  handleSubmit,
  errorsForm,
  profiles,
}) => {
  const query = useQuery();

  const history = useHistory();

  const titlePage = useMemo(
    () => (isEdit ? texts.titleEdit : texts.titleCreate),
    [isEdit]
  );

  return (
    <PageLayout headerTitle header sidebar title={titlePage} ctaBack>
      <Box display="flex" gridGap={24} flexDirection="column">
        {profiles && (
          <FormCreditorUsersFormik
            creditorId={query.get('creditor_id')}
            profiles={profiles?.results}
          />
        )}
      </Box>

      {isEdit && loading && !fetched && (
        <Box
          display="flex"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          width="100%"
          height="100%"
          zIndex={9999}
          alignItems="center"
          justifyContent="center"
          bgcolor="#ffffffd1"
        >
          <CircularProgress />
        </Box>
      )}
    </PageLayout>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    ...validationSchema.cast(),
  }),
  validateOnChange: false,
  validateOnBlur: true,
  validateOnMount: true,
  validationSchema,
  handleSubmit: () => {},
})(CreateUserContainer);
