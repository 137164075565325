import fetch from '@payhop/shared-utils/fetch.util';

export default function putDealsChangeStatusService({
  deal_id,
  deal_status,
  creditor_id,
}) {
  return fetch(`deals/change-status`, {
    method: 'PUT',
    ms: 'DEAL',
    auth: true,
    body: JSON.stringify({
      deal_id,
      deal_status,
      creditor_id,
    }),
  });
}
