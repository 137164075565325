import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import qs from 'qs';

import { setError } from '@payhop/shared-ducks/error.duck';

import fetch from '@payhop/shared-utils/fetch.util';

type Actions = {
  type: string;
  payload?: any;
  error?: any;
};

export const Types = {
  INIT: 'private/notifications/INIT',
  SUCCESS: 'private/notifications/SUCCESS',
  ERROR: 'private/notifications/ERROR',
  RESET: 'private/notifications/RESET',
};

const initialState: any = {
  data: null,
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload } = action;

  const reducers = {
    [Types.INIT]: {
      ...state,
      loading: true,
    },
    [Types.SUCCESS]: {
      loading: false,
      data: payload,
      error: false,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error: true,
    },
    [Types.RESET]: {
      ...initialState,
    },
  };

  return reducers[type] || state;
};

export const addNewNotification =
  (notification: any) => async (dispatch: any, getState: any) => {
    const notifications =
      getState()?.private?.notifications?.data?.results || [];

    dispatch({
      type: Types.SUCCESS,
      payload: {
        ...getState()?.private?.notifications?.data,
        results: [notification, ...notifications],
      },
    });
  };

export const readAllNotifications =
  () => async (dispatch: any, getState: any) => {
    const notifications =
      getState()?.private?.notifications?.data?.results || [];

    const unreadNotifications = notifications.filter(
      (r: any) => r.status === 1
    );

    if (unreadNotifications.length > 0) {
      fetch(`notifications`, {
        method: 'PATCH',
        ms: 'NOTIFICATION',
        auth: true,
        body: JSON.stringify({
          notifications_user_id: unreadNotifications.map((r: any) => r.id),
        }),
      });
    }

    dispatch({
      type: Types.SUCCESS,
      payload: {
        ...getState()?.private?.notifications?.data,
        results: notifications.reduce(
          (acc: any, curr: any) => [
            ...acc,
            {
              ...curr,
              status: 0,
            },
          ],
          []
        ),
      },
    });
  };

export const getNotifications =
  (filters: any = {}, opts = {}) =>
  async (dispatch: Dispatch<any>) => {
    const { concat }: any = opts;

    const queryString = qs.stringify(filters, { skipNulls: true });

    try {
      dispatch({
        type: Types.INIT,
      });

      const response = await fetch(
        `notifications${queryString ? `?${queryString}` : ''}`,
        {
          method: 'GET',
          ms: 'NOTIFICATION',
          auth: true,
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw result;
      }

      dispatch({
        type: Types.SUCCESS,
        payload: {
          ...result,
          results: [...(concat ? concat : []), ...result.results],
        },
      });

      return result;
    } catch (result: any) {
      dispatch(
        setError({
          messages: result?.errors?.message,
          data: result,
          visible: false,
          context: 'private/notifications',
        })
      );

      return false;
    }
  };

export const useEcNotificationsDuck = () =>
  useSelector((state: any) => state.private.notifications);
