import React, { useState } from 'react';

import TextFieldMaterial from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

import { TextFieldProps } from './TextField.type';

const TextFieldCNPJ = (props) => (
  <NumberFormat
    customInput={TextFieldMaterial}
    format="##.###.###/####-##"
    {...props}
  />
);

const TextFieldCPF = (props) => (
  <NumberFormat
    customInput={TextFieldMaterial}
    format="###.###.###-##"
    {...props}
  />
);

const TextFieldBirth = (props) => (
  <NumberFormat
    customInput={TextFieldMaterial}
    format="##/##/####"
    {...props}
  />
);

const TextFieldCurrency = (props) => (
  <NumberFormat
    customInput={TextFieldMaterial}
    prefix="R$ "
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale
    isNumericString
    {...props}
  />
);

const TextFieldTax = (props) => (
  <NumberFormat
    customInput={TextFieldMaterial}
    prefix=""
    suffix=" %"
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale
    isNumericString
    {...props}
  />
);

const TextFieldZipCode = (props) => (
  <NumberFormat customInput={TextFieldMaterial} format="#####-###" {...props} />
);

const TextFieldMobile = (props) => {
  return (
    <NumberFormat
      customInput={TextFieldMaterial}
      format={
        props?.value?.length === 11 ? '(##) #####-####' : '(##) ####-#####'
      }
      {...props}
    />
  );
};

const ComponentToRender = {
  cnpj: TextFieldCNPJ,
  cpf: TextFieldCPF,
  currency: TextFieldCurrency,
  zipcode: TextFieldZipCode,
  mobile: TextFieldMobile,
  date_of_birth: TextFieldBirth,
  tax: TextFieldTax,
  default: TextFieldMaterial,
};

export const TextField: React.FC<TextFieldProps> = ({ mask, ...restProps }) => {
  return React.createElement(
    ComponentToRender[mask || 'default'],
    restProps as any
  );
};
