export const MODALITY_TYPES = [
  {
    label: 'Contrato Performado',
    value: 'performado',
  },
  {
    label: 'Contrato Fumaça',
    value: 'fumaca',
  },
];
export const MODALITY_LABELS = {
  [0]: 'Performado',
  [1]: 'Fumaça',
};
