import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import flow from 'lodash/flow';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import CardTitle from '@backoffice/private/components/Card/CardTitle';
import CardForm from '@backoffice/private/components/Card/CardForm';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';

import messages from '@backoffice/shared/utils/messagesSchema.util';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/merchants/partners/merchantPartner.duck';
import { Dispatchs as DispatchPartners } from '@backoffice/private/ducks/merchants/partners/listMerchantPartners.duck';

const partnerSchema = [
  [
    {
      label: 'CPF',
      name: 'document_number',
      required: true,
      mask: 'cpf',
    },
    {
      label: 'Nome Completo',
      name: 'full_name',
      required: true,
    },
    {
      label: 'Status',
      name: 'active',
      type: 'select',
      required: true,
      options: [
        {
          label: 'Ativo',
          value: true,
        },
        {
          label: 'Inativo',
          value: false,
        },
      ],
    },
  ],
];

const validationSchema = Yup.object({
  document_number: Yup.string().required(messages.REQUIRED).default(''),
  full_name: Yup.string().required(messages.REQUIRED).default(''),
  active: Yup.boolean(),
});

const FormMerchantPartnerContainer = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { values, errors, resetForm } = useFormikContext();

  const dispatchRedux = {
    CREATE: flow(Dispatchs.create, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
  };

  const dispatchPartnersRedux = {
    GET: flow(DispatchPartners.get, dispatch),
  };

  const selectorRedux = {
    LOADING: useSelector(Selectors.loading),
    DATA: useSelector(Selectors.data),
  };

  useEffect(() => {
    const { successAction } = selectorRedux.DATA || {};

    if (successAction === true) {
      resetForm();

      dispatchPartnersRedux.GET();

      dispatchRedux.RESET();
    }
  }, [selectorRedux.DATA]);

  const handleBack = () => history.goBack();

  return (
    <FormMerchantPartnerComponent
      loading={selectorRedux.LOADING}
      errorsForm={errors}
      handleBack={handleBack}
      handleSubmit={() => dispatchRedux.CREATE(values)}
    />
  );
};

const FormMerchantPartnerComponent = ({
  loading,
  handleSubmit,
  errorsForm,
  handleBack,
}) => {
  const { isAdmin } = useUserHook();
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_estabelecimento_dados_socios',
  });

  if (!isAdmin) {
    return <></>;
  }

  return (
    <>
      {permissions?.some((item) => item == 'create') && (
        <Box gridGap={8} display="flex" flexDirection="column">
          <CardTitle>Cadastrar novo sócio</CardTitle>

          <Box display="flex" flexDirection="column" gridGap={24}>
            <CardForm formik fields={partnerSchema as any} />

            <CardFormFooter
              hasError={Object.keys(errorsForm).length > 0}
              onSubmit={handleSubmit}
              onCancel={handleBack}
              loading={loading}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default function FormMerchantPartnerFormik() {
  return (
    <Formik
      initialValues={{ ...validationSchema.cast() }}
      onSubmit={() => {}}
      validateOnMount
      validationSchema={validationSchema}
    >
      <FormMerchantPartnerContainer />
    </Formik>
  );
}
