import * as Yup from 'yup';
import validateCNPJ from '@payhop/shared-utils/validateCNPJ.util';

export const validationSchema = Yup.object({
  cnpj_originador: Yup.string()
    .required('CNPJ/CPF é obrigatório')
    .default('')
    .test('cnpj_originador', 'CNPJ inválido', (value) => {
      const documentLength = value ? String(value).length : 0;

      if (documentLength < 14 || !validateCNPJ(value)) {
        return false;
      }

      return true;
    }),
  razao_social: Yup.string().required('Razão social é obrigatório'),
});
