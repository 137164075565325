import React from 'react';

import PageLayout from '@backoffice/private/layouts/Page.layout';

const HomeScreen = () => {
  return (
    <PageLayout
      headerTitle
      header
      sidebar
      title="Início"
      breadcrumbs={false}
    ></PageLayout>
  );
};

export default HomeScreen;
