import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

import { DialogTitle, DialogContent } from '@material-ui/core';
import TableMerchantDocumentHistoricComponent from './Table/TableMerchantDocumentHistoric.component';
import moment from 'moment';

const enumDocumentType = {
  1: 'Contrato Social',
  2: 'Documento',
  3: 'Selfie',
};

interface documentFile {
  date: string;
  file: string;
}

const DialogDocumentHistoricComponent = ({
  open,
  onClose,
  loading,
  documentNumberType,
  urlContract,
  historic,
}) => {
  const [documentFile, setDocumentFile] = useState<string>('');
  const [visualizationOpen, setVisualizationOpen] = useState(false);
  const [comparationOpen, setComparationOpen] = useState(false);
  const [documentsFile, setDocumentsFile] = useState<documentFile[]>([]);

  const handleSeeDocument = (file) => {
    setDocumentFile(file);
    setVisualizationOpen(true);
  };

  const handleCloseVisualization = () => {
    setVisualizationOpen(false);
  };
  const handleCloseComparation = () => {
    setComparationOpen(false);
  };

  const handleCompareDocuments = (file1, file2) => {
    const date1 = new Date(file1.date);
    const date2 = new Date(file2.date);

    if (date1.getTime() < date2.getTime()) {
      setDocumentsFile([
        { file: file1.file, date: file1.date },
        { file: file2.file, date: file2.date },
      ]);
    } else {
      setDocumentsFile([
        { file: file2.file, date: file2.date },
        { file: file1.file, date: file1.date },
      ]);
    }

    setComparationOpen(true);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title">
          Detalhamento de {enumDocumentType[documentNumberType]}{' '}
          <IconButton
            css={{ position: 'absolute', right: 0, top: '5px' }}
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TableMerchantDocumentHistoricComponent
            loading={loading}
            results={historic}
            handleSeeDocument={handleSeeDocument}
            handleCompareDocuments={handleCompareDocuments}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleCloseVisualization}
        aria-labelledby="customized-dialog-title"
        open={visualizationOpen}
        maxWidth={'xl'}
      >
        <DialogTitle id="customized-dialog-title">
          Visualização de documento
          <IconButton
            css={{ position: 'absolute', right: 0, top: '5px' }}
            aria-label="close"
            onClick={handleCloseVisualization}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <img src={documentFile} alt="documento" />
        </DialogContent>
      </Dialog>

      {documentsFile.length == 2 && (
        <Dialog
          onClose={handleCloseComparation}
          aria-labelledby="customized-dialog-title"
          open={comparationOpen}
          maxWidth={'xl'}
        >
          <DialogTitle id="customized-dialog-title">
            Comparação de documento
            <IconButton
              aria-label="close"
              css={{ position: 'absolute', right: 0, top: '5px' }}
              onClick={handleCloseComparation}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            dividers
            css={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}
          >
            <Box>
              <Typography>
                Documento do dia{' '}
                {moment(documentsFile[1].date).format('DD/MM/YYYY')}
              </Typography>
              <img
                css={{ width: '500px', height: '500px' }}
                src={documentsFile[1].file}
                alt="documento"
              />
            </Box>
            <Typography>-</Typography>
            <Box>
              <Typography>
                Documento do dia{' '}
                {moment(documentsFile[0].date).format('DD/MM/YYYY')}
              </Typography>
              <img
                css={{ width: '500px', height: '500px' }}
                src={documentsFile[0].file}
                alt="documento"
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default DialogDocumentHistoricComponent;
