import fetch from '@payhop/shared-utils/fetch.util';

export default function postDealsResendApprove({ deal_id }) {
  return fetch(`deals/approved`, {
    method: 'POST',
    ms: 'DEAL',
    auth: true,
    body: JSON.stringify({
      deal_id,
    }),
  });
}
