import crudRoutes from './crudRoutes.util';

const NAMESPACE = '/app';

const NAMESPACE_ACCESS = `${NAMESPACE}/access`;
const NAMESPACE_CREDITOR = `${NAMESPACE}/creditors`;
const NAMESPACE_SCHEDULES = `${NAMESPACE}/schedules`;
const NAMESPACE_APPOINTMENTS = `${NAMESPACE}/appointments`;
const NAMESPACE_MERCHANTS = `${NAMESPACE}/merchants`;
const NAMESPACE_IMPORT_MERCHANTS = `${NAMESPACE}/import-merchants`;
const NAMESPACE_IMPORT_APPOINTMENTS = `${NAMESPACE}/import-appointments`;
const NAMESPACE_IMPORT_SMOKE_APPOINTMENTS = `${NAMESPACE}/import-smoke-appointments`;
const NAMESPACE_CHANGE_DEALS = `${NAMESPACE}/change-deals`;
const NAMESPACE_DOCS = `${NAMESPACE}/docs`;
const NAMESPACE_PARTNERS = `${NAMESPACE}/partners`;
const NAMESPACE_PARAMETERS = `${NAMESPACE}/parameters`;
const NAMESPACE_CONCILIATION_SCHEDULE = `${NAMESPACE}/conciliation-schedule`;
const NAMESPACE_CONCILIATION_EXTRACT = `${NAMESPACE}/conciliation-extract`;
const NAMESPACE_CONCILIATION_BANK = `${NAMESPACE}/conciliation-bank`;
const NAMESPACE_CONCILIATION_DIVERGENCE = `${NAMESPACE}/conciliation-consult`;
const NAMESPACE_CONCILIATION_COMPANY = `${NAMESPACE}/conciliation-company`;
const NAMESPACE_REGISTERED_CONTRACTS = `${NAMESPACE}/registered-contracts`;
const NAMESPACE_MONITORING = `${NAMESPACE}/conciliation-monitoring`;
const NAMESPACE_MONITORING_REPORT = `${NAMESPACE}/monitoring-report`;

const ACCESS_ROUTES = crudRoutes(NAMESPACE_ACCESS);
const CREDITORS_ROUTES = crudRoutes(NAMESPACE_CREDITOR);
const SCHEDULES_ROUTES = crudRoutes(NAMESPACE_SCHEDULES);
const APPOINTMENTS_ROUTES = crudRoutes(NAMESPACE_APPOINTMENTS);
const MERCHANTS_ROUTES = crudRoutes(NAMESPACE_MERCHANTS);
const DOCS_ROUTES = crudRoutes(NAMESPACE_DOCS);
const PARTNERS_ROUTES = crudRoutes(NAMESPACE_PARTNERS);
const IMPORT_MERCHANT_ROUTES = crudRoutes(NAMESPACE_IMPORT_MERCHANTS);
const IMPORT_APPOINTMENTS_ROUTES = crudRoutes(NAMESPACE_IMPORT_APPOINTMENTS);
const IMPORT_SMOKE_APPOINTMENTS_ROUTES = crudRoutes(
  NAMESPACE_IMPORT_SMOKE_APPOINTMENTS
);
const CHANGE_DEALS_ROUTES = crudRoutes(NAMESPACE_CHANGE_DEALS);
const PARAMETERS_ROUTES = crudRoutes(NAMESPACE_PARAMETERS);
const REGISTERED_CONTRACTS_ROUTES = crudRoutes(NAMESPACE_REGISTERED_CONTRACTS);
const CONCILIATION_EXTRACT_ROUTES = crudRoutes(NAMESPACE_CONCILIATION_EXTRACT);
const CONCILIATION_BANK_ROUTES = crudRoutes(NAMESPACE_CONCILIATION_BANK);
const CONCILIATION_COMPANY_ROUTES = crudRoutes(NAMESPACE_CONCILIATION_COMPANY);
const CONCILIATION_MONITORING_ROUTES = crudRoutes(NAMESPACE_MONITORING);
const CONCILIATION_SCHEDULE_ROUTES = crudRoutes(
  NAMESPACE_CONCILIATION_SCHEDULE
);
const CONCILIATION_DIVERGENCE_ROUTES = crudRoutes(
  NAMESPACE_CONCILIATION_DIVERGENCE
);
const MONITORING_REPORT_ROUTES = crudRoutes(NAMESPACE_MONITORING_REPORT);

const PRIVATE_ROUTES = {
  HOME: NAMESPACE,
  ACCESS: ACCESS_ROUTES,
  CREDITORS: CREDITORS_ROUTES,
  SCHEDULES: SCHEDULES_ROUTES,
  APPOINTMENTS: APPOINTMENTS_ROUTES,
  MERCHANTS: MERCHANTS_ROUTES,
  DOCS: DOCS_ROUTES,
  PARTNERS: PARTNERS_ROUTES,
  IMPORT_MERCHANT: IMPORT_MERCHANT_ROUTES,
  IMPORT_APPOINTMENTS: IMPORT_APPOINTMENTS_ROUTES,
  IMPORT_SMOKE_APPOINTMENTS: IMPORT_SMOKE_APPOINTMENTS_ROUTES,
  CHANGE_DEALS: CHANGE_DEALS_ROUTES,
  PARAMETERS: PARAMETERS_ROUTES,
  REGISTERED_CONTRACTS: REGISTERED_CONTRACTS_ROUTES,
  CONCILIATION_SCHEDULE: CONCILIATION_SCHEDULE_ROUTES,
  CONCILIATION_EXTRACT: CONCILIATION_EXTRACT_ROUTES,
  CONCILIATION_BANK: CONCILIATION_BANK_ROUTES,
  CONCILIATION_DIVERGENCE: CONCILIATION_DIVERGENCE_ROUTES,
  CONCILIATION_COMPANY: CONCILIATION_COMPANY_ROUTES,
  CONCILIATION_MONITORING: CONCILIATION_MONITORING_ROUTES,
  MONITORING_REPORT: MONITORING_REPORT_ROUTES,
};

const PUBLIC_ROUTES = {
  LOGIN: '/login',
};

const ROUTES = {
  PRIVATE: PRIVATE_ROUTES,
  PUBLIC: PUBLIC_ROUTES,
  PRIVATE_NAMESPACE_ROUTE: NAMESPACE,
};

export default ROUTES;
