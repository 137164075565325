import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Box } from '@material-ui/core';

import CardTab from '@backoffice/private/components/Card/CardTab/CardTab.component';
import TableDealComponent from '../components/TableDeal.component';
import TableSolicitationComponent from '../components/TableSolicitation.component';
import SearchDeal from '../components/Search/Deal/index';
import SearchSolicitation from '../components/Search/Solicitation/index';
import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import ROUTES from '@backoffice/shared/utils/routes.util';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import TableDeal from '../components/TableDeal.component';

const texts = {
  title: 'Compromissos',
  buttonAdd: 'Adicionar compromisso',
};

const AppointmentsScreen = () => {
  const permissionsData = useAccountPermission({
    permission_slug: 'cad_compromisso_dados_compromissos',
  });
  const permissionsSolicitation = useAccountPermission({
    permission_slug: 'cad_compromisso_dados_solicitacoes',
  });
  const [tabActive, setTabActive] = useState(0);

  const history = useHistory();

  return (
    <Fragment>
      <PageLayout
        headerTitle
        header
        sidebar
        title={texts.title}
        cta={
          <Button
            variant="outlined"
            color="primary"
            css={{ minWidth: 240 }}
            onClick={() => history.push(ROUTES.PRIVATE.APPOINTMENTS.CREATE)}
            disabled={
              !permissionsData.permissions?.some((item) => item == 'create')
            }
          >
            {texts.buttonAdd}
          </Button>
        }
      >
        <Box display="flex" flexDirection="column" gridGap={32}>
          <CardTab
            onChange={(_, value) => setTabActive(value)}
            tabs={['COMPROMISSOS', 'SOLICITAÇÕES']}
            tabActive={tabActive}
          />

          {tabActive === 0 && (
            <Fragment>
              {permissionsData.permissions?.some((item) => item == 'read') && (
                <>
                  <SearchDeal />
                  <TableDeal />
                </>
              )}
              {!permissionsData.permissions ||
                (!permissionsData.permissions?.some(
                  (item) => item == 'read'
                ) && <ModuleNotPermited />)}
            </Fragment>
          )}

          {tabActive === 1 && (
            <Fragment>
              {permissionsSolicitation.permissions?.some(
                (item) => item == 'read'
              ) && (
                <>
                  <SearchSolicitation />
                  <TableSolicitationComponent />
                </>
              )}
              {!permissionsSolicitation.permissions ||
                (!permissionsSolicitation.permissions?.some(
                  (item) => item == 'read'
                ) && <ModuleNotPermited />)}
            </Fragment>
          )}
        </Box>
      </PageLayout>
    </Fragment>
  );
};

export default AppointmentsScreen;
