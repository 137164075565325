import { useMemo, Fragment, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import ModalConfirmDelete from '@backoffice/private/components/Modal/ModalConfirmDelete.component';
import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import CardTable from '@backoffice/private/components/Card/CardTable';

import ROUTES from '@backoffice/shared/utils/routes.util';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import patchAccountsByIdService from '@backoffice/private/services/account/patchAccountsById.service';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/merchants/listMerchants.duck';

import { Selectors as SelectorsCreditor } from '@backoffice/private/ducks/creditors/parameters/creditorParameters.duck';

import { Dispatchs as DispatchsDialogsDocument } from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantDocuments.duck';
import SearchMerchant from '../components/Search/Merchants/index';
import useNotification from '@payhop/shared-hooks/useNotification.hook';
import getExportMerchantsService from '@backoffice/private/services/export/getExportMerchants.service';

const normalizeOrigin = {
  1: 'Portal',
  2: 'Backoffice',
  3: 'Api',
  4: 'HotSite',
};
const normalizeOnboardingType = {
  1: 'Completo',
  2: 'Simplificado',
  3: 'Integrado',
};

const texts = {
  title: 'Estabelecimentos',
  buttonAdd: 'Adicionar estabelecimento',
};

const MerchantsContainer = () => {
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [partnerType, setPartnerType] = useState('');

  const { accountId, isAdmin, user } = useUserHook();
  const handleNotification = useNotification();

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const DispatchsDialogsDocumentRedux = {
    CLOSE: flow(DispatchsDialogsDocument.close, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const selectorCreditorRedux = {
    DATA: useSelector(SelectorsCreditor.data),
  };

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearchReset,
    handleDelete,
  } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: isAdmin,
    },
  });

  useEffect(() => {
    DispatchsDialogsDocumentRedux.CLOSE();
  }, []);

  useEffect(() => {
    if (selectorCreditorRedux.DATA) {
      const { results } = selectorCreditorRedux.DATA;

      results.forEach((parameter) => {
        if (parameter.parameter_key === 'perfil_parceiro') {
          setPartnerType(parameter.parameter_value);
        }
      });
    }
  }, [selectorCreditorRedux.DATA]);

  useEffect(() => {
    if (partnerType && partnerType == 'if') {
      dispatchRedux.UPDATE_FILTERS({ view_full_api: true });
    } else if (partnerType) {
      dispatchRedux.GET();
    }
  }, [partnerType]);

  const handleOnActive = async (id) => {
    setLoadingLocal(true);

    const response = await patchAccountsByIdService(id, {
      status: 'inativo',
    });

    setLoadingLocal(false);

    dispatchRedux.GET();
  };

  const handleOnInative = (id) => async () => {
    setLoadingLocal(true);

    const response = await patchAccountsByIdService(id, {
      status: 'ativo',
    });

    setLoadingLocal(false);

    dispatchRedux.GET();
  };

  const handleOnRefresh = () => {
    dispatchRedux.GET();
  };

  const handleOnExport = async () => {
    const { document, name, creditor_id, view_full_api } =
      selectorRedux.FILTERS;

    await getExportMerchantsService(accountId, view_full_api, {
      document,
      name,
      creditor_id,
    });

    handleNotification(
      'Sua solicitação foi enviada, em alguns minutos você receberá uma notificação',
      'success'
    );
  };

  return (
    <MerchantsScreen
      loading={selectorRedux.LOADING || loadingLocal}
      results={selectorRedux.DATA_RESULTS}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleSearchReset={handleSearchReset}
      handleOnActive={handleOnActive}
      handleOnInative={handleOnInative}
      handleOnRefresh={handleOnRefresh}
      handleOnExport={handleOnExport}
      handleDelete={handleDelete}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const MerchantsScreen = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearchReset,
  handleOnActive,
  handleOnInative,
  handleDelete,
  handleOnRefresh,
  handleOnExport,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_estabelecimento',
  });
  const { isAdmin } = useUserHook();

  const [modalDelete, setModalDelete]: any = useState(null);

  const currentId = useRef(null);

  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        width: 100,
        render: ({ id, active, account }) => (
          <Box display="flex">
            <IconButton
              onClick={() => history.push(ROUTES.PRIVATE.MERCHANTS.EDIT(id))}
              disabled={!permissions?.some((item) => item == 'edit')}
            >
              <EditIcon />
            </IconButton>
          </Box>
        ),
      },
      {
        title: 'Tipo Pessoa',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'CNPJ/CPF',
        dataIndex: 'document_number',
        key: 'document_number',
        render: ({ document_number }) => (
          <NumberFormat
            displayType="text"
            value={document_number}
            isNumericString
            format={
              document_number.length === 11
                ? '###.###.###-##'
                : '##.###.###/####-##'
            }
          />
        ),
      },
      {
        title: 'Nome Fantasia',
        dataIndex: 'trading_name',
        key: 'trading_name',
      },
      {
        title: 'Razão Social',
        dataIndex: 'company_name',
        key: 'company_name',
      },
      isAdmin && {
        title: 'Fornecedor',
        dataIndex: 'creditor.company_name',
        render: ({ creditor }) => creditor?.company_name,
      },
      isAdmin && {
        title: 'Parceiro',
        dataIndex: 'partnership',
        key: 'partnership',
      },
      {
        title: 'Status do cadastro',
        render: ({ account }) => account?.account_status?.description,
      },
      {
        title: 'Origem cadastro',
        render: ({ account }) => normalizeOrigin[account.origin],
      },
      {
        title: 'Data do cadastro',
        render: ({ created_at }) =>
          moment(created_at).format('DD/MM/YYYY HH:mm'),
      },
      {
        title: 'Ativo',
        dataIndex: 'active',
        key: 'active',
        render: ({ active }) => (active ? 'Sim' : 'Não'),
      },
      {
        title: 'Tipo de onboarding',
        dataIndex: 'onboarding_type',
        render: ({ account }) =>
          normalizeOnboardingType[account?.onboarding_type],
      },
      {
        title: 'Opt-in',
        dataIndex: 'optin_status',
        key: 'optin_status',
        render: ({ optin_status }) => (optin_status ? 'Ativo' : 'Inativo'),
      },
    ],
    [permissions]
  );

  const { limit, count, offset } = filters || {};

  return (
    <Fragment>
      <PageLayout
        headerTitle
        header
        sidebar
        title={texts.title}
        cta={
          <Button
            variant="outlined"
            color="primary"
            css={{ minWidth: 260 }}
            onClick={() => history.push(ROUTES.PRIVATE.MERCHANTS.CREATE)}
            disabled={!permissions?.some((item) => item == 'create')}
          >
            {texts.buttonAdd}
          </Button>
        }
      >
        {permissions?.some((item) => item == 'read') && (
          <Box display="flex" flexDirection="column" gridGap={32}>
            <SearchMerchant />
            <CardTable
              count={count || 0}
              loading={loading}
              dataSource={results}
              rowsPerPage={limit}
              columns={columns as any}
              page={offset / limit}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onExport={handleOnExport}
              onRefresh={handleOnRefresh}
              exporttype="api"
              exportname="merchants"
            />
          </Box>
        )}

        {!permissions ||
          (!permissions?.some((item) => item == 'read') && (
            <ModuleNotPermited />
          ))}
      </PageLayout>

      <ModalConfirmDelete
        open={modalDelete || false}
        onConfirm={async () => {
          await handleOnActive(currentId.current);
          setModalDelete(false);
        }}
        onCancel={() => setModalDelete(false)}
      />
    </Fragment>
  );
};

export default MerchantsContainer;
