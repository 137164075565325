import { Fragment, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import flow from 'lodash/flow';
import Box from '@material-ui/core/Box';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import CardTab from '@backoffice/private/components/Card/CardTab';
import ModalChangePassword from '@backoffice/private/components/Modal/ModalChangePassword.component';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';

import TableMerchantDocumentContainer from '../containers/TableMerchantDocument.container';
import DialogDocument from '../containers/DialogDocument.container';
import DialogDocumentHistoric from '../containers/DialogDocumentHistoric.container';
import ActionsDocument from '../containers/ActionsDocument.container';

import TableMerchantPartner from '../components/Table/TableMerchantPartner.component';
import TableMerchantPartnerExternal from '../components/Table/TableMerchantPartnerExternal.component';
import TableMerchantAccess from '../components/Table/TableMerchantAccess.component';
import TableMerchantAcquirer from '../components/Table/TableMerchantAcquirer.component';
import FormMerchant from '../components/Form/FormMerchant.component';
import FormMerchantPartner from '../components/Form/FormMerchantPartner.component';
import FormMerchantAcquirer from '../components/Form/FormMerchantAcquirer.component';

import { Dispatchs } from '@backoffice/private/ducks/merchants/merchant.duck';
import * as dialogMerchantChangePasswordDuck from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantChangePassword.duck';
import { Dispatchs as DispatchsAccounts } from '@backoffice/private/ducks/accounts/account.duck';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import TableMerchantAcquirerDiscoveryComponent from '../components/Table/TableMerchantAcquirerDiscovery.component';

const texts = {
  titleEdit: 'Editar estabelecimento',
  titleCreate: 'Cadastro de estabelecimento',
};

const tabs = {
  dados_cadastrais: {
    label: 'Dados Cadastrais',
  },
  dados_dos_socios: {
    label: 'Dados dos Sócios',
  },
  credenciadores: {
    label: 'Credenciadoras',
  },
  dados_de_acesso: {
    label: 'Dados de acesso',
  },
  documentos: {
    label: 'Documentos',
  },
};

const tabsFormatted: any = Object.keys(tabs).reduce(
  (acc, curr, index) => ({
    ...acc,
    [curr]: {
      label: tabs[curr].label,
      name: curr,
      index,
    },
  }),
  {}
);

const tabsLabelsArr = Object.keys(tabsFormatted).map(
  (val) => tabsFormatted[val].label
);

const CreateMerchantContainer = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    RESET: flow(Dispatchs.reset, dispatch),
  };

  const dispatchAccountsRedux = {
    RESET: flow(DispatchsAccounts.reset, dispatch),
  };

  useEffect(() => {
    return () => {
      dispatchAccountsRedux.RESET();
      dispatchRedux.RESET();
    };
  }, []);

  return <CreateMerchantScreen />;
};

const CreateMerchantScreen = () => {
  const { isAdmin } = useUserHook();

  const [tabActive, setTabActive] = useState(0);

  const { isEdit, id } = useIsEdit();

  const titlePage = useMemo(
    () => (isEdit ? texts.titleEdit : texts.titleCreate),
    [isEdit]
  );

  return (
    <PageLayout headerTitle header sidebar title={titlePage} ctaBack>
      <Box display="flex" gridGap={24} flexDirection="column">
        {isEdit && (
          <CardTab
            onChange={(_, value) => setTabActive(value)}
            tabs={tabsLabelsArr.filter((t) =>
              isAdmin ? t : t !== 'Documentos'
            )}
            tabActive={tabActive}
          />
        )}

        {tabsFormatted.dados_cadastrais.index === tabActive && <FormMerchant />}

        {tabsFormatted.dados_dos_socios.index === tabActive && (
          <Box display="flex" flexDirection="column" gridGap={24}>
            <TableMerchantPartner />
            <TableMerchantPartnerExternal />

            <FormMerchantPartner />
          </Box>
        )}

        {tabsFormatted.dados_de_acesso.index === tabActive && (
          <TableMerchantAccess />
        )}

        {tabsFormatted.credenciadores.index === tabActive && (
          <Box display="flex" flexDirection="column" gridGap={24}>
            <TableMerchantAcquirer />

            <FormMerchantAcquirer />

            <TableMerchantAcquirerDiscoveryComponent />
          </Box>
        )}

        {tabsFormatted.documentos.index === tabActive && (
          <Box display="flex" flexDirection="column" gridGap={24}>
            <TableMerchantDocumentContainer />

            <Box
              mt={4}
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <ActionsDocument />
            </Box>
          </Box>
        )}
      </Box>

      <ModalChangePassword duck={dialogMerchantChangePasswordDuck} />
      <DialogDocument />
      <DialogDocumentHistoric />
    </PageLayout>
  );
};

export default CreateMerchantContainer;
