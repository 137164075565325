import React from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';

type CardTabProps = {
  tabs: Array<string>;
  onChange: any;
  tabActive: number;
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CardTabComponent: React.FC<CardTabProps> = ({
  tabs,
  onChange,
  tabActive,
}) => {
  return (
    <Card>
      <Tabs
        onChange={onChange}
        indicatorColor="primary"
        textColor="primary"
        value={tabActive}
      >
        {tabs.map((t, index) => (
          <Tab key={`tab-${index}`} label={t} {...a11yProps(index)} />
        ))}
      </Tabs>
    </Card>
  );
};

export default CardTabComponent;
