import React, { useRef, useState } from 'react';
import flow from 'lodash/flow';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';
import ModalConfirmDelete from '@backoffice/private/components/Modal/ModalConfirmDelete.component';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/merchants/partners/listMerchantPartners.duck';

const TableMerchantPartnerContainer = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage, handleDelete } =
    useCrudRead({
      dispatchRedux,
      selectorRedux,
    });

  return (
    <TableMerchantPartnerComponent
      loading={selectorRedux.LOADING}
      results={selectorRedux.DATA_RESULTS}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
      handleDelete={handleDelete}
    />
  );
};

const TableMerchantPartnerComponent = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  handleDelete,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_estabelecimento_dados_socios',
  });
  const { isAdmin } = useUserHook();

  const currentId = useRef(null);

  const [modalDelete, setModalDelete]: any = useState(null);

  const { limit, count, offset } = filters || {};

  const columns = [
    isAdmin && {
      title: '',
      width: 40,
      render: (props) => (
        <Box display="flex">
          <IconButton
            onClick={() => {
              setModalDelete(true);

              currentId.current = props.id;
            }}
            disabled={!permissions?.some((item) => item == 'delete')}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Cargo',
      dataIndex: 'company_position',
      key: 'company_position',
      render: ({ company_position }) =>
        company_position ? company_position : '-',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: ({ phone_number }) => (
        <NumberFormat
          displayType="text"
          value={phone_number}
          isNumericString
          format="(##) #####-####"
        />
      ),
    },
    {
      title: 'Documento',
      dataIndex: 'document_number',
      key: 'document_number',
      render: ({ document_number }) =>
        document_number ? (
          <NumberFormat
            displayType="text"
            value={document_number}
            isNumericString
            format="###.###.###-##"
          />
        ) : (
          '-'
        ),
    },
    {
      title: 'Ativo?',
      dataIndex: 'active',
      key: 'active',
      render: ({ active }) => (active ? 'Sim' : 'Não'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const partner = results?.find((r) => r.id === currentId?.current) || {};

  return (
    <>
      {permissions?.some((item) => item == 'read') && (
        <Box gridGap={8} display="flex" flexDirection="column">
          <CardTitle>Dados do cadastro</CardTitle>

          <CardTable
            count={count || 0}
            loading={loading}
            dataSource={results}
            rowsPerPage={limit}
            columns={columns as any}
            page={offset / limit}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <ModalConfirmDelete
            open={modalDelete || false}
            description={`Deseja excluir o sócio ${partner.full_name}?`}
            onConfirm={async () =>
              handleDelete(currentId.current)(() => {
                setModalDelete(false);

                currentId.current = null;
              })
            }
            onCancel={() => setModalDelete(false)}
          />
        </Box>
      )}
      {(!permissions || !permissions?.some((item) => item == 'read')) && (
        <ModuleNotPermited />
      )}
    </>
  );
};

export default TableMerchantPartnerContainer;
