export const BRAND_COLORS = {
  primary: {
    main: '#0E97F7',
    100: '#E0F2FE',
    200: '#C1E4FD',
    300: '#5DC5FF',
    400: '#055E9B',
    500: '#003357',
  },
  secondary: {
    main: '#7450F0',
    400: '#3E50D8',
    500: '#1A1859',
  },
  gray: {
    main: '#00A85A',
    100: '#F2F5F6',
    200: '#DBE1E5',
    300: '#9FAAB1',
    400: '#6C7A84',
    500: '#32414C',
  },
  gradient: {
    main: 'linear-gradient(90deg, #20BAF6 0%, #7450F0 100%)',
  },
};

export const SYSTEM_COLORS = {
  warning: {
    main: '#FFAB00',
    100: '#FFF7E5',
    200: '#FFE7B8',
    300: '#FFD070',
    400: '#BF840B',
    500: '#664400',
  },
  success: {
    main: '#34BE84',
    100: '#DDF8ED',
    200: '#C0F1DC',
    300: '#83DDB7',
    400: '#229464',
    500: '#114B32',
  },
  error: {
    main: '#F44336',
    100: '#FEE0DE',
    200: '#FCC4C0',
    300: '#F99790',
    400: '#CB180B',
    500: '#790E07',
  },
  info: {
    main: '#0362F0',
    100: '#E6F0FF',
    200: '#B8D4FF',
    300: '#3385FF',
    400: '#003E99',
    500: '#002152',
  },
};
