import React from 'react';
import get from 'lodash.get';
import { v4 } from 'uuid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

export type TableProps = {
  tableProps?: any;
  onChangePage?: any;
  onChangeRowsPerPage?: any;
  onRowClick?: any;
  count?: any;
  rowsPerPage?: any;
  page?: any;
  loading?: boolean;
  footer?: boolean;
  collapse?: any;
  rowSelected?: any;
  expandRowSelected?: any;
  onExpand?: any;
  dataSource: Array<{
    [key: string]: any;
  }>;
  columns: Array<{
    title?: string;
    tooltip?: string;
    dataIndex?: string;
    key?: string;
    render?: any;
    width?: number;
    hasOrdenation?: boolean;
    handleOrder?: () => void;
    orderType?: 'asc' | 'desc' | null;
  }>;
  [key: string]: any;
};

const TableComponent: React.FC<TableProps> = ({
  columns,
  dataSource,
  tableProps,
  onChangePage,
  onChangeRowsPerPage,
  count,
  rowsPerPage,
  page,
  loading,
  footer = true,
  onRowClick,
  collapse = undefined,
  rowSelected,
  expandRowSelected = undefined,
  onExpand,
  ...restProps
}) => {
  const [collapsedItems, setCollapsedItems] = React.useState<any>([]);

  const hasInArrCollapsed = (idx) => collapsedItems.includes(idx);

  const handleToggleCollapse = (idx) => {
    const hasIndexOnArr = hasInArrCollapsed(idx);

    if (hasIndexOnArr) {
      return setCollapsedItems(collapsedItems.filter((id) => id !== idx));
    }

    return setCollapsedItems([...collapsedItems, idx]);
  };

  const handleGetTitleTooltip = (
    title: string | undefined,
    tooltip: string | undefined
  ) => {
    const titleComponent = <strong>{title}</strong>;
    if (tooltip && tooltip?.length > 0) {
      return (
        <Tooltip title={tooltip}>
          <div css={{ cursor: 'help', display: 'flex', gap: 2 }}>
            {titleComponent}
            <InfoIcon style={{ width: 10, height: 10 }} color="primary" />
          </div>
        </Tooltip>
      );
    }
    return titleComponent;
  };

  return (
    <div css={{ position: 'relative' }}>
      {loading && (
        <div
          css={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: '#ffffffe0',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <TableContainer component={Paper} {...restProps}>
        <Table {...tableProps}>
          <TableHead>
            <TableRow>
              {collapse && (
                <TableCell width={50} key={v4()} variant="head"></TableCell>
              )}
              {columns?.map((c) => (
                <>
                  {c?.hasOrdenation ? (
                    <TableCell
                      key={v4()}
                      width={c?.width}
                      variant="head"
                      onClick={c?.handleOrder}
                      css={{ cursor: 'pointer' }}
                    >
                      {handleGetTitleTooltip(c.title, c?.tooltip)}
                      {c.orderType ? (
                        <span>{c.orderType === 'asc' ? '⬆️' : '⬇️'}</span>
                      ) : (
                        <span css={{ marginLeft: '4px', color: '#9ca3af' }}>
                          ⬆⬇
                        </span>
                      )}
                    </TableCell>
                  ) : (
                    <TableCell key={v4()} width={c?.width} variant="head">
                      {handleGetTitleTooltip(c.title, c?.tooltip)}
                    </TableCell>
                  )}
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource?.map((row: any, id) => (
              <React.Fragment key={v4()}>
                <TableRow
                  onClick={(e) => onRowClick && onRowClick(row, e)}
                  hover
                  selected={rowSelected && rowSelected(row)}
                  css={[
                    onRowClick && {
                      cursor: 'pointer',
                    },
                  ]}
                >
                  {collapse && (
                    <TableCell width={50}>
                      <Button
                        size="small"
                        style={{ minWidth: 0 }}
                        onClick={(ev) => {
                          ev.stopPropagation();

                          handleToggleCollapse(row.id);
                          onExpand(hasInArrCollapsed(row.id), row);
                        }}
                      >
                        {hasInArrCollapsed(row.id) ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </Button>
                    </TableCell>
                  )}

                  {columns?.map((c, idx) => (
                    <TableCell width={c?.width} key={`${c?.dataIndex}-${idx}`}>
                      {(c?.render && c?.render(row)) ||
                        (c?.dataIndex && get(row, c.dataIndex))}
                    </TableCell>
                  ))}
                </TableRow>

                {expandRowSelected && !!expandRowSelected(row) && (
                  <TableRow>
                    <TableCell colSpan={Array.from(columns).length + 1}>
                      {expandRowSelected(row)}
                    </TableCell>
                  </TableRow>
                )}

                {hasInArrCollapsed(row.id) && (
                  <TableRow>
                    <TableCell colSpan={Array.from(columns).length + 1}>
                      {collapse(row)}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>

          {footer && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  count={count || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableComponent;
