import fetch from '@payhop/shared-utils/fetch.util';

export default async function getDealOriginatorHistoryService(id) {
  const response = await fetch(`deals/${id}/history-domicile`, {
    method: 'GET',
    ms: 'DEAL',
    auth: true,
  });

  const result = await response.json();

  return result;
}
