import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'lodash';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import * as dialogMerchantDocumentsReproveDuck from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantDocumentsReprove.duck';
import * as checkidsDuck from '@backoffice/private/ducks/checkids/listCheckids.duck';
import * as dialogMerchantDocumentsDuck from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantDocuments.duck';

import patchCheckids from '@backoffice/private/services/checkid/patchCheckids.service';

import DialogDocumentReproveComponent from '../components/DialogDocumentReprove.component';

const DialogDocumentReproveContainer = () => {
  const preComment = useRef('');

  const [comment, setComment] = useState('');

  const handleNotification = useNotification();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const dispatchRedux = {
    OPEN: flow(dialogMerchantDocumentsReproveDuck.Dispatchs.open, dispatch),
    CLOSE: flow(dialogMerchantDocumentsReproveDuck.Dispatchs.close, dispatch),
  };

  const dispatchDocumentRedux = {
    CLOSE: flow(dialogMerchantDocumentsDuck.Dispatchs.close, dispatch),
  };

  const dispatchCheckId = {
    GET: flow(checkidsDuck.Dispatchs.get, dispatch),
  };

  const selectorRedux = {
    visible: useSelector(dialogMerchantDocumentsReproveDuck.Selectors.visible),
    payload: useSelector(dialogMerchantDocumentsReproveDuck.Selectors.payload),
  };

  const handleReprove = async () => {
    const idDocument = selectorRedux.payload.id;

    setLoading(true);

    const response = await patchCheckids({
      id: idDocument,
      status: 3,
      comment,
    });

    setLoading(false);

    if (response.ok) {
      dispatchCheckId.GET();

      dispatchRedux.CLOSE();
      dispatchDocumentRedux.CLOSE();

      handleNotification('Documento reprovado com sucesso', 'success');
    }
  };

  const handleSetPreMessages = () => {
    const messages = {
      dados_invalido: 'Dados inválidos',
      arquivo_invalido: 'Arquivo inválido',
      solicitacao_doc: 'Solicitação do envio de documento societário',
    };

    return setComment(messages[preComment.current]);
  };

  return (
    <DialogDocumentReproveComponent
      open={selectorRedux.visible}
      handleClose={dispatchRedux.CLOSE}
      comment={comment}
      setComment={setComment}
      preComment={preComment}
      handleSetPreMessages={handleSetPreMessages}
      handleReprove={handleReprove}
      loading={loading}
    />
  );
};

export default DialogDocumentReproveContainer;
