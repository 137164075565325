import React, { createContext, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

const NotificationDrawerContext = createContext({});

const NotificationDrawerContextProvider: React.FC = ({ children }) => {
  const { data } = useSelector((state: any) => state.private.notifications);

  const [visible, setVisible] = useState<boolean>(false);

  const toggleVisible = () => {
    setVisible((prev) => !prev);
  };

  const value = {
    visible,
    setVisible,
    toggleVisible,
    unreads: (data?.results || []).filter((el: any) => el.status === 1).length,
  };

  return (
    <NotificationDrawerContext.Provider value={value}>
      {children}
    </NotificationDrawerContext.Provider>
  );
};

const withNotificationDrawerContext = (Component: any) => (props: any) =>
  (
    <NotificationDrawerContextProvider>
      <Component {...props} />
    </NotificationDrawerContextProvider>
  );

const useNotificationDrawer = () => useContext(NotificationDrawerContext);

export {
  NotificationDrawerContextProvider as default,
  NotificationDrawerContext,
  withNotificationDrawerContext,
  useNotificationDrawer,
};
