import React, { useState, useEffect } from 'react';
import { Field, ErrorMessage } from 'formik';
import { Grid, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { TextField as TextFieldF } from '@backoffice/shared/components/TextField';
import InputTextWithMask from '@backoffice/private/components/Form/Inputs/InputTextWithMask.component';
import InputSelectNew from '@backoffice/private/components/Form/Inputs/InputSelectNew.component';

import InputData from '@backoffice/private/components/Form/Inputs/InputData.component';
import Typography from '@backoffice/shared/components/Typography.component';
import {
  DIVISON_GCAP_RULE_TYPES,
  DIVISON_RULE_TYPES,
} from '@backoffice/private/constants/contract/divison_rule.constant';
import { TRANSACTION_VALUES } from '@backoffice/private/constants/transacao.constant';

interface ParcelsFormProps {
  name: string;
  parcelsSelect: Array<string>;
  dealValue: number;
  handleDates: (Array) => void;
  parameters: {
    tipo_transacao: string;
    tipo_contrato: string;
  };
  values: {
    total_amount: number;
    parcels_quantity: string;
    solicitated_value: number;
    division_rule: string;
    contract_modality: 'performado' | 'fumaca';
    type: string;
  };
  isColateral: boolean;
}

const ParcelsForm = ({
  name,
  parcelsSelect,
  dealValue,
  handleDates,
  parameters,
  values,
  isColateral,
}: ParcelsFormProps) => {
  const Component = ({
    field,
    form: { setFieldValue, setFieldTouched },
  }: any) => {
    const { tipo_transacao, tipo_contrato } = parameters;
    const isContractPerformed = values.contract_modality == 'performado';
    const isContractTransactionTypeGuarantee =
      tipo_transacao == 'ambos'
        ? values.type == '1'
        : tipo_transacao == 'garantia';

    const [amountValues, setAmountValues] = useState<Array<number>>([]);
    const [amountTotalValue, setAmountTotalValue] = useState<string>('');
    const [postArrayInfos, setPostArrayInfos] = useState({});
    const [parcelSelected, setParcelSelected] = useState<number>(0);
    const [dealValueSelected, setDealValueSelected] = useState<number>(0);
    const [onerateErrors, setOnerateErrors] = useState<any>({});
    const [dates, setDates] = useState({});

    useEffect(() => {
      setDealValueSelected(dealValue);
      setParcelSelected(parcelsSelect.length);
      setPostArrayInfos({});
      handleParcelValues();
      handleSetDates();
    }, []);

    useEffect(() => {
      if (
        parcelsSelect.length > 0 &&
        (parcelsSelect.length !== parcelSelected ||
          dealValueSelected !== dealValue)
      ) {
        setDealValueSelected(dealValue);
        setParcelSelected(parcelsSelect.length);

        setPostArrayInfos({});
        handleParcelValues();
        handleSetDates();
        handleDates([dates]);
      }
    }, [dealValue, parcelsSelect]);

    useEffect(() => {
      if (amountValues.length > 0) {
        handleSetParcelsTotalValue();

        const amountObject = {};

        amountValues.forEach((item, index) => {
          amountObject[`amount_${index}`] = Number(item.toFixed(2));
        });

        setPostArrayInfos({ ...postArrayInfos, ...amountObject });
      }
    }, [amountValues]);

    useEffect(() => {
      if (Object.keys(postArrayInfos).length) {
        setFieldValue(field.name, postArrayInfos);
      }
    }, [postArrayInfos]);

    useEffect(() => {
      setPostArrayInfos({ ...postArrayInfos, ...dates });
      handleDates([dates]);
    }, [dates]);

    const handleSetDates = () => {
      const dueDateObject = {};
      const executionDateFinalObject = {};
      const executionDateInitialObject = {};

      parcelsSelect.forEach((item, index) => {
        dueDateObject[`date_due_${index}`] = null;
        executionDateInitialObject[`date_execution_initial_${index}`] = null;
        executionDateFinalObject[`date_execution_final_${index}`] = null;
      });

      setDates({
        ...dueDateObject,
        ...executionDateFinalObject,
        ...executionDateInitialObject,
      });
    };

    const handleSetInputDate = (value, index) => {
      setDates({ ...dates, [index]: value });
    };

    const handleParcelValues = () => {
      const valuesArray = parcelsSelect.map(() => {
        return dealValue / parcelsSelect.length;
      });

      const lastIndex = valuesArray.length - 1;

      let valuesFiltered = 0;

      valuesArray.forEach((item) => {
        valuesFiltered += Number(item.toFixed(2));
      });

      if (valuesFiltered > dealValue) {
        const diff = valuesFiltered % dealValue;
        valuesArray[lastIndex] = Number(
          (valuesArray[lastIndex] - diff).toFixed(2)
        );
      } else if (valuesFiltered < dealValue) {
        const diff = dealValue % valuesFiltered;
        valuesArray[lastIndex] = Number(
          (valuesArray[lastIndex] + diff).toFixed(2)
        );
      }

      const valuesArrayJSON = JSON.stringify(
        valuesArray.map((item) => {
          return Number(item.toFixed(2));
        })
      );

      const amountValuesJSON = JSON.stringify(amountValues);

      if (valuesArrayJSON !== amountValuesJSON) setAmountValues(valuesArray);
    };

    const handleSetParcelsTotalValue = () => {
      let parcelsTotal = 0;

      amountValues.forEach((item) => {
        parcelsTotal += Number(item.toFixed(2));
      });

      setAmountTotalValue(
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }).format(parcelsTotal)
      );
    };

    const handleSetAmountItem = (value, index) => {
      const valuesArrayInput: Array<number> = amountValues;
      valuesArrayInput[index] = Number(value);

      setAmountValues([...valuesArrayInput]);
    };

    const handleErrorOnerate = (
      value,
      index = 0,
      divisionRuleSelected = null
    ) => {
      if (value) {
        if (divisionRuleSelected == 2 && value > 100.0) {
          setOnerateErrors((oldValue) => ({
            ...oldValue,
            [`onerate_error${index}`]: 'Valor não pode ser maior que 100%',
          }));
        } else if (divisionRuleSelected == 1 && value > amountValues[index]) {
          setOnerateErrors((oldValue) => ({
            ...oldValue,
            [`onerate_error${index}`]:
              'Valor não pode ser maior que valor da parcela',
          }));
        } else {
          setOnerateErrors((oldValue) => ({
            ...oldValue,
            [`onerate_error${index}`]: false,
          }));
        }
      } else {
        setOnerateErrors((oldValue) => ({
          ...oldValue,
          [`onerate_error${index}`]: 'O campo é obrigatório',
        }));
      }
    };

    useEffect(() => {
      if (isColateral && parcelsSelect?.length > 0) {
        parcelsSelect.map((_, index) => {
          setFieldValue(`division_rule_${index}`, '2');
        });
      }
    }, [isColateral, parcelSelected]);

    return (
      <>
        {parcelsSelect.length > 0 && (
          <Box>
            {parcelsSelect.map((_, index) => {
              return (
                <Box
                  key={index}
                  css={{
                    display: 'flex',
                    columnGap: '6px',
                    marginBottom: '24px',
                  }}
                >
                  <Grid item css={{ flex: '1' }}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gridRowGap={12}
                    >
                      <Typography
                        customVariant="text__body--sm"
                        customColor="info-500"
                        css={{
                          height: '30px',
                        }}
                      >
                        Parcela
                      </Typography>
                      <TextFieldF
                        key={index}
                        name={'parcels_index'}
                        fullWidth
                        variant="outlined"
                        disabled={true}
                        value={index + 1}
                      />
                    </Box>
                  </Grid>
                  <Grid item css={{ flex: '1' }}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gridRowGap={12}
                    >
                      <Typography
                        customVariant="text__body--sm"
                        customColor={
                          isContractPerformed ? 'gray-400' : 'info-500'
                        }
                        css={{
                          height: '30px',
                        }}
                      >
                        Regra de divisão {!isContractPerformed && '*'}
                      </Typography>
                      {isColateral && (
                        <InputSelectNew
                          disabled
                          name={`division_rule_${index}`}
                          labelId={`division_rule_${index}`}
                          options={DIVISON_GCAP_RULE_TYPES}
                          keyName={'value'}
                          optionName={'label'}
                          required={false}
                          value={'2'}
                        />
                      )}
                      <InputSelectNew
                        style={{
                          display: isColateral ? 'none' : 'unset',
                        }}
                        key={index}
                        name={`division_rule_${index}`}
                        labelId={`division_rule_${index}`}
                        hasError={
                          !isContractPerformed &&
                          !values[`division_rule_${index}`]
                        }
                        options={
                          isColateral
                            ? DIVISON_GCAP_RULE_TYPES
                            : DIVISON_RULE_TYPES
                        }
                        disabled={isContractPerformed || isColateral}
                        keyName="value"
                        optionName="label"
                        helperText="Regra de divisão é obrigatória"
                        value={values[`division_rule_${index}`]}
                        onChange={(_, option) => {
                          setFieldValue(
                            `division_rule_${index}`,
                            option.props.value
                          );
                          handleErrorOnerate(
                            values[`amount_onerate_${index}`],
                            index,
                            option.props.value
                          );
                        }}
                        required={!isContractPerformed}
                      />
                    </Box>
                  </Grid>
                  <Grid item css={{ flex: '2' }}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gridRowGap={12}
                    >
                      <Typography
                        customVariant="text__body--sm"
                        customColor={
                          isContractPerformed ? 'gray-400' : 'info-500'
                        }
                        css={{
                          height: '30px',
                        }}
                      >
                        Valor a onerar {!isContractPerformed && '*'}
                      </Typography>
                      <InputTextWithMask
                        key={index}
                        name={`amount_onerate_${index}`}
                        hasError={
                          !isContractPerformed &&
                          onerateErrors[`onerate_error${index}`]
                        }
                        helperText={onerateErrors[`onerate_error${index}`]}
                        label="Valor a onerar"
                        mask={
                          values[`division_rule_${index}`] == '2'
                            ? 'tax'
                            : 'currency'
                        }
                        required={!isContractPerformed}
                        disabled={
                          isContractPerformed ||
                          !values[`division_rule_${index}`]
                        }
                        onChange={(value) => {
                          setFieldValue(
                            `amount_onerate_${index}`,
                            value.floatValue
                          );
                          handleErrorOnerate(
                            value.floatValue,
                            index,
                            values[`division_rule_${index}`]
                          );
                        }}
                        onBlur={(e) => {
                          const value = e.target.value;
                          const valueParsed = parseFloat(
                            value.replace('R$', '').replace(',', '.')
                          );

                          if (isNaN(valueParsed)) {
                            handleErrorOnerate(valueParsed, index);
                          }
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item css={{ flex: '2' }}>
                    <Tooltip
                      title={`Valor total das parcelas: ${amountTotalValue}`}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gridRowGap={12}
                      >
                        <Typography
                          customVariant="text__body--sm"
                          customColor="info-500"
                          css={{
                            height: '30px',
                          }}
                        >
                          Valor da Parcela *
                        </Typography>
                        <TextFieldF
                          key={index}
                          name={`amount_parcel_${index + 1}`}
                          mask={'currency'}
                          value={amountValues[index]}
                          required
                          fullWidth
                          variant="outlined"
                          onValueChange={(value: any) => {
                            handleSetAmountItem(value.value, index);
                            handleErrorOnerate(
                              values[`amount_onerate_${index}`],
                              index,
                              values[`division_rule_${index}`]
                            );
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Grid>
                  <Grid item css={{ flex: '2' }}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gridRowGap={12}
                    >
                      <Typography
                        customVariant="text__body--sm"
                        customColor="info-500"
                        css={{
                          height: '30px',
                        }}
                      >
                        Data de Vencimento{' '}
                        {isContractTransactionTypeGuarantee && '*'}
                      </Typography>
                      <InputData
                        name="date_expiration"
                        valueDate={dates[`date_due_${index}`]}
                        hasError={
                          !dates[`date_due_${index}`] &&
                          isContractTransactionTypeGuarantee
                        }
                        helperText="A data de vencimento é obrigatória"
                        key={index}
                        handleDate={handleSetInputDate}
                        dateParam={`date_due_${index}`}
                      />
                    </Box>
                  </Grid>
                  <Grid item css={{ flex: '2' }}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gridRowGap={12}
                    >
                      <Typography
                        customVariant="text__body--sm"
                        customColor="info-500"
                        css={{
                          height: '30px',
                        }}
                      >
                        Data Execução Inicial *
                      </Typography>
                      <InputData
                        name="date_execution_initial"
                        valueDate={dates[`date_execution_initial_${index}`]}
                        hasError={!dates[`date_execution_initial_${index}`]}
                        helperText="A data de Execução inicial é obrigatória"
                        key={index}
                        handleDate={handleSetInputDate}
                        dateParam={`date_execution_initial_${index}`}
                      />
                    </Box>
                  </Grid>
                  <Grid item css={{ flex: '2' }}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gridRowGap={12}
                    >
                      <Typography
                        customVariant="text__body--sm"
                        customColor="info-500"
                        css={{
                          height: '30px',
                        }}
                      >
                        Data Execução Final *
                      </Typography>
                      <InputData
                        name="date_execution_final"
                        valueDate={dates[`date_execution_final_${index}`]}
                        hasError={!dates[`date_execution_final_${index}`]}
                        helperText="A data de Execução Final é obrigatória"
                        key={index}
                        handleDate={handleSetInputDate}
                        dateParam={`date_execution_final_${index}`}
                      />
                    </Box>
                  </Grid>
                </Box>
              );
            })}
          </Box>
        )}
      </>
    );
  };

  return <Field name={name}>{Component}</Field>;
};

export default ParcelsForm;
