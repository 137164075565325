import { Fragment, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import flow from 'lodash/flow';
import Box from '@material-ui/core/Box';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';

import { Dispatchs as DispatchsAcquirers } from '@backoffice/private/ducks/parameters/acquirers/acquirer.duck';
import { Dispatchs as DispatchsArrangements } from '@backoffice/private/ducks/parameters/arrangements/arrangement.duck';
import { Dispatchs as DispatchsBanks } from '@backoffice/private/ducks/parameters/banks/bank.duck';
import FormAcquirersComponent from '../components/Form/FormAcquirers.component';
import FormArrangementComponent from '../components/Form/FormArrangement.component';
import FormBanksComponent from '../components/Form/FormBanks.component';

const title = {
  acquirer: 'Credenciadora',
  arrangement: 'Arranjo',
  bank: 'Banco',
};

const EditParameterContainer = () => {
  const dispatch = useDispatch();

  const dispatchAcquirersRedux = {
    RESET: flow(DispatchsAcquirers.reset, dispatch),
  };

  const dispatchArrangementsRedux = {
    RESET: flow(DispatchsArrangements.reset, dispatch),
  };

  const dispatchBanksRedux = {
    RESET: flow(DispatchsBanks.reset, dispatch),
  };

  useEffect(() => {
    return () => {
      dispatchAcquirersRedux.RESET();
      dispatchArrangementsRedux.RESET();
      dispatchBanksRedux.RESET();
    };
  }, []);

  return <EditParameterScreen />;
};

const EditParameterScreen = () => {
  const { type }: any = useIsEdit();
  return (
    <PageLayout
      headerTitle
      header
      sidebar
      title={`Editar ${title[type]}`}
      ctaBack
    >
      <Box display="flex" gridGap={24} flexDirection="column">
        {type == 'bank' && <FormBanksComponent />}
        {type == 'acquirer' && <FormAcquirersComponent />}
        {type == 'arrangement' && <FormArrangementComponent />}
      </Box>
    </PageLayout>
  );
};

export default EditParameterContainer;
