import React from 'react';
import { flow } from 'lodash';
import { Dialog, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';

import * as dialogUpdateDuck from '@backoffice/private/ducks/creditors/dialogs/dialogCreditorUpdate.duck';

import FormCreditorTax from '../Form/FormCreditorTax.component';

const DialogsUpdateComponent = ({ currentTab, ...props }) => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    CLOSE: flow(dialogUpdateDuck.Dispatchs.close, dispatch),
  };

  const selectorRedux = {
    visible: useSelector(dialogUpdateDuck.Selectors.visible),
    payload: useSelector(dialogUpdateDuck.Selectors.payload),
  };

  const variants = {
    taxas: FormCreditorTax,
  };

  return (
    <Dialog
      open={selectorRedux.visible}
      onClose={() => dispatchRedux.CLOSE()}
      aria-labelledby="form-dialog-title"
      maxWidth={false}
      {...props}
    >
      <Box p={3} position="relative" pt={6}>
        <Box position="absolute" top={8} right={8}>
          <IconButton onClick={() => dispatchRedux.CLOSE()}>
            <CloseIcon />
          </IconButton>
        </Box>
        {variants[currentTab] &&
          React.createElement(variants[currentTab], {
            isEdit: true,
            data: selectorRedux?.payload,
          })}
      </Box>
    </Dialog>
  );
};

export default DialogsUpdateComponent;
