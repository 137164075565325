import React from 'react';

import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const normalizeOrigin = {
  1: 'Portal',
  2: 'Backoffice',
  3: 'Api',
  4: 'HotSite',
};

const normalizeOnboardingType = {
  1: 'Completo',
  2: 'Simplificado',
  3: 'Integrado',
};

type Props = {
  data: Array<string | number>;
};

const MerchantsImportMain = ({ data }: Props) => {
  function handleFilterActive(value) {
    if (value) {
      return 'Sim';
    } else {
      return 'Não';
    }
  }

  function handleFilterMerchantData(data) {
    const filteredData: any = [];
    data.forEach((item) => {
      const newData = {
        cnpj_ou_id_lote: item.cnpj_ou_id_lote,
        opt_in_opt_out: item.opt_in_opt_out,
        recorrente_: item.recorrente_,
        error: item?.error?.length > 0 ? item?.error[0] : '',
      };

      filteredData.push(newData);
    });

    return filteredData;
  }
  return (
    <ExcelFile
      filename={'estabelecimentos'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleFilterMerchantData(data)} name="Estabelecimentos">
        <ExcelColumn label="CNPJ ou ID Lote" value="cnpj_ou_id_lote" />
        <ExcelColumn label="Opt-in/Opt-out" value="opt_in_opt_out" />
        <ExcelColumn label="Recorrente?" value="recorrente_" />
        <ExcelColumn label="" value="error" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default MerchantsImportMain;
