import React from 'react';
import { Box } from '@material-ui/core';

import CardTable from '@backoffice/private/components/Card/CardTable';

const TableMerchantDocumentDetailComponent = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const { limit, count, offset } = filters || {};

  const columns = [
    {
      title: 'Campo',
      dataIndex: 'campo',
      key: 'campo',
    },
    {
      title: 'Cadastro',
      dataIndex: 'cadastro',
      key: 'cadastro',
    },
    {
      title: 'OCR',
      dataIndex: 'ocr',
      key: 'ocr',
      render: ({ ocr }) => ocr.join(', '),
    },
  ];

  return (
    <Box gridGap={8} display="flex" flexDirection="column" flex={1}>
      <CardTable
        count={count || 0}
        loading={loading}
        dataSource={results?.filter((r) => r.campo !== 'Grau Face Match')}
        rowsPerPage={limit}
        columns={columns}
        page={offset / limit}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default TableMerchantDocumentDetailComponent;
