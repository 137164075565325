import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CloseIcon from '@material-ui/icons/Close';

import { Creditor } from '@backoffice/private/@types/models/Creditor';

import {
  validationSchemaCreate,
  validationSchemaCreateIF,
} from './CreateSchedule.schema';

import { PARAMETERS_VALUES } from '@backoffice/private/constants/parameters/parameters_values.contant';
import { PARAMETERS } from '@backoffice/private/constants/parameters/parameters.constant';

import InputSelectNew from '@backoffice/private/components/Form/Inputs/InputSelectNew.component';
import InputText from '@backoffice/private/components/Form/Inputs/InputText.component';
import InputDocument from '@backoffice/private/components/Form/Inputs/InputDocument.component';
import InputData from '@backoffice/private/components/Form/Inputs/InputDataNew.component';
import InputSelectAutocomplete from '@backoffice/private/components/Form/Inputs/InputSelectAutocomplete.component';

import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';
import CreateForm from '@backoffice/private/components/Form/CreateForm';

import useCreditors from '@backoffice/private/hooks/useCreditors.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useCreditorParameters from '@backoffice/private/hooks/useCreditorParameters.hook';
import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useAcquirersAndArrangements from '@backoffice/private/hooks/useAcquirersAndArrangements.hook';

import getMerchantNew from '@backoffice/private/services/merchant/getMerchantNew.service';
import getMerchantAcquirers from '@backoffice/private/services/merchant/getMerchantAcquirers.service';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/schedules/schedule.duck';
import TypographyComponent from '@backoffice/shared/components/Typography.component';
import Loading from '@backoffice/private/components/Loading/Loading.component';

const CreateSchedule = () => {
  const [loadingLocal, setLoadingLocal] = useState<boolean>(false);
  const [loadingAcquirers, setLoadingAcquirers] = useState<boolean>(false);
  const [creditorId, setCreditorId] = useState<string | null>(null);
  const [selectedMerchant, setSelectedMerchant] = useState<any>();
  const [selectableAcquirers, setSelectableAcquirers] = useState<any>();
  const [selectableArrangements, setSelectableArrangements] = useState<any>();
  const [haveAcquirers, setHaveAcquirers] = useState<boolean>(false);
  const [haveArrangements, setHaveArrangements] = useState<boolean>(false);
  const [dialogRules, setDialogRules] = useState({} as any);
  const [visualizationOpen, setVisualizationOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [searchAcquirersType, setSearchAcquirersType] =
    useState<string>('vinculos');
  const [discoveryHaveAcquirers, setDiscoveryHaveAcquirers] =
    useState<boolean>(false);

  const [discoveryAcquirers, setDiscoveryAcquirers] = useState([]);
  const [discoveryArrangements, setDiscoveryArrangements] = useState([]);

  const creditors: Creditor[] = useCreditors();
  const { isAdmin, user } = useUserHook();
  const { parameters, handleSetParameters } = useCreditorParameters();
  const { arrangements, acquirers, handleGetArrangementsAndAcquirers } =
    useAcquirersAndArrangements();
  const handleNotification = useNotification();
  const dispatch = useDispatch();

  const dispatchRedux = {
    CREATE: flow(Dispatchs.create, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
  };

  const handleGetCreditorInfos = (id: string) => {
    setCreditorId(id);

    handleSetParameters(id, [PARAMETERS.PERFIL_PARCEIRO]);
  };

  const handleGetMerchant = async (
    documentNumber: string,
    creditor_id: string
  ) => {
    if (documentNumber) {
      const creditor = creditor_id ? creditor_id : creditorId;

      const documentCreditor = creditors.find(
        (cr) => cr.id === creditor
      )?.document_number;

      const { merchant, account_status, optin_status } = await getMerchantNew(
        documentNumber,
        documentCreditor
      );

      setSelectableAcquirers([]);
      setSelectableArrangements([]);

      if (!optin_status) {
        handleNotification(
          'Consulta de Agenda não permitida! Estabelecimento sem opt-in ativo!',
          'error'
        );
        return;
      }

      if (merchant) {
        const selectedMerchant = {
          id: merchant.id,
          name: merchant.company_name,
          status: account_status,
        };
        setLoadingAcquirers(true);
        const { results } = await getMerchantAcquirers(documentNumber);
        if (results.length === 0) {
          handleNotification(
            'Não há credenciadoras vinculadas a este estabelecimento',
            'error'
          );
          setSearchAcquirersType('params');
          setSelectableAcquirers([]);
          setSelectableArrangements([]);
          setDiscoveryHaveAcquirers(false);

          await handleGetArrangementsAndAcquirers();
          setLoadingAcquirers(false);
        } else {
          setDiscoveryHaveAcquirers(true);
          handleParseAcquirersAndArrangementsPartnerCreditor(results);
          setLoadingAcquirers(false);
        }

        setSelectedMerchant(selectedMerchant);
      } else {
        handleNotification(
          'Não foi encontrado nenhum estabelecimento com esse cnpj',
          'error'
        );
      }
    }
  };

  const handleParseAcquirersAndArrangementsPartnerCreditor = (data) => {
    const acquirers = data.map((item) => {
      return {
        label: item.acquirer_name,
        value: item.acquirer_document,
      };
    });
    const arrangments = data.map((item) => {
      return {
        label: `${item.arrangement_code} - ${item.arrangement_name}`,
        value: item.arrangement_code,
      };
    });

    const filteredAcquirers = acquirers.filter(
      (item, index, self) =>
        self.findIndex((i) => JSON.stringify(i) === JSON.stringify(item)) ===
        index
    );
    const filteredArrangements = arrangments.filter(
      (item, index, self) =>
        self.findIndex((i) => JSON.stringify(i) === JSON.stringify(item)) ===
        index
    );

    setSelectableAcquirers(filteredAcquirers);
    setDiscoveryAcquirers(filteredAcquirers);
    setSelectableArrangements(filteredArrangements);
    setDiscoveryArrangements(filteredArrangements);
  };

  const handleChangeSwitch = async () => {
    setLoadingAcquirers(true);
    setSelectableAcquirers([]);
    setSelectableArrangements([]);

    if (searchAcquirersType == 'vinculos') {
      await handleGetArrangementsAndAcquirers();
    } else {
      setSelectableAcquirers(discoveryAcquirers);
      setSelectableArrangements(discoveryArrangements);
    }
    setSearchAcquirersType((oldValue) =>
      oldValue == 'vinculos' ? 'params' : 'vinculos'
    );
    setLoadingAcquirers(false);
  };

  const handleSubmit = async (values) => {
    setLoadingLocal(true);
    const data = {
      partnerType: isPartnerIF ? 'if' : 'fornecedor',
      creditor_id: isAdmin ? values.creditor_id : creditorId,
      merchant_id: selectedMerchant.id,
      protocol: uuidv4(),
      acquirers: values.document_acquirers,
      arrangements: values.payment_arrangements,
      initial_date: values.initial_date,
      end_date: values.end_date,
    };

    const response = await dispatchRedux.CREATE(data);

    handleValidationSubmit(response);
  };

  const handleValidationSubmit = (response) => {
    if (!response) {
      setDialogRules({
        dialogType: 'error',
        dialogTitle: 'Solicitação não executada',
      });
      setVisualizationOpen(true);
      setLoadingLocal(false);
    } else {
      setDialogRules({
        dialogType: 'success',
        dialogTitle: 'Solicitação em processamento',
        dialogText:
          'Acompanhe o status da solicitação na tela "Agenda de recebíveis"',
      });

      if (tipo_parceiro == 'if') {
        handleNotification(
          'Sua solicitação foi enviada, em alguns minutos você receberá uma notificação',
          'success'
        );
      }
      setVisualizationOpen(true);
      setLoadingLocal(false);
      history.back();
    }
  };

  const handleCloseVisualization = () => {
    setVisualizationOpen(false);
    if (dialogRules.dialogType == 'success') {
      history.back();
    }
  };

  useEffect(() => {
    if (!isAdmin) {
      const { personId } = user || {};

      handleGetCreditorInfos(personId);
      setCreditorId(personId);
    }
  }, []);

  useEffect(() => {
    if (acquirers && arrangements) {
      setSelectableAcquirers(acquirers);
      setSelectableArrangements(arrangements);
    }
  }, [acquirers, arrangements]);

  useEffect(() => {
    selectableAcquirers?.length > 0
      ? setHaveAcquirers(true)
      : setHaveAcquirers(false);
    selectableArrangements?.length > 0
      ? setHaveArrangements(true)
      : setHaveArrangements(false);
  }, [selectableAcquirers, selectableArrangements]);

  useEffect(() => {
    const { successAction, errors } = selectorRedux.DATA || {};

    if (!successAction) {
      setErrorMessage(errors?.message[0]);
    }
  }, [selectorRedux.DATA]);

  //Parameters Values
  const tipo_parceiro = PARAMETERS_VALUES[parameters.perfil_parceiro];
  const isPartnerIF = tipo_parceiro == 'Instituição financeira';

  return (
    <>
      {loadingLocal && <Loading title={'Validando informações'} />}
      <CreateForm
        validateOnMount
        schema={isPartnerIF ? validationSchemaCreateIF : validationSchemaCreate}
      >
        {({ values, setFieldValue, errors }) => (
          <>
            <Card css={{ padding: '16px', marginBottom: '16px' }}>
              <Box display={'flex'} gridColumnGap={16}>
                <InputSelectNew
                  inputWidth="50%"
                  name="creditor_id"
                  label="Fornecedor"
                  labelId="fornecedor"
                  options={creditors}
                  disabled={!isAdmin}
                  keyName="id"
                  optionName="trading_name"
                  value={creditorId || ''}
                  onChange={(e, option) => {
                    const value = option.props.value;

                    handleGetCreditorInfos(value);
                    setFieldValue('creditor_id', value);
                  }}
                  required={isAdmin}
                />
                <InputText
                  inputWidth="50%"
                  name="tipo_parceiro"
                  label="Tipo de parceiro"
                  disabled
                  value={tipo_parceiro}
                />
              </Box>
              <Box display={'flex'} gridColumnGap={16} marginTop={'16px'}>
                <InputDocument
                  name="document_number"
                  label="CNPJ ou CPF"
                  getValueObject={() =>
                    handleGetMerchant(
                      values.document_number,
                      values?.creditor_id
                    )
                  }
                />
                <InputText
                  label="Razão Social/Nome"
                  name="name"
                  value={selectedMerchant?.name}
                  disabled
                  required
                />
                <InputText
                  label="Status"
                  name="status"
                  value={selectedMerchant?.status}
                  disabled
                  required
                />
              </Box>
              {isPartnerIF && (
                <Box display={'flex'} gridColumnGap={16} marginTop={'16px'}>
                  <InputData
                    name="initial_date"
                    hasError={errors.initial_date}
                    helperText={errors.initial_date}
                    label="Data Inicial"
                    required
                  />
                  <InputData
                    name="end_date"
                    label="Data Final"
                    hasError={errors.end_Date}
                    helperText={errors.end_date}
                    required
                  />
                </Box>
              )}
              {loadingAcquirers && (
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  padding={'16px 0'}
                >
                  <CircularProgress />{' '}
                </Box>
              )}
              {haveAcquirers && haveArrangements && (
                <>
                  <Box display={'flex'} gridColumnGap={16} marginTop={'16px'}>
                    <InputSelectAutocomplete
                      label="Credenciadoras"
                      name="document_acquirers"
                      hasError={!!errors.document_acquirers}
                      errorMessage="É necessário selecionar uma ou mais credenciadoras"
                      optionsAutocomplete={selectableAcquirers?.map(
                        (a: any) => a.value || []
                      )}
                      getOptionLabel={(option: any) => {
                        const currentAcquirer = selectableAcquirers?.find(
                          (b) => b.value === option
                        );

                        return currentAcquirer?.label;
                      }}
                    />
                    <InputSelectAutocomplete
                      label="Arranjos"
                      name="payment_arrangements"
                      hasError={!!errors.payment_arrangements}
                      errorMessage="É necessário selecionar um ou mais arranjos"
                      optionsAutocomplete={selectableArrangements?.map(
                        (a: any) => a.value || []
                      )}
                      getOptionLabel={(option: any) => {
                        const currentArrangement = selectableArrangements?.find(
                          (b) => b.value === option
                        );

                        return currentArrangement?.label;
                      }}
                    />
                  </Box>
                  <Switch
                    checked={searchAcquirersType == 'vinculos'}
                    onChange={() => {
                      handleChangeSwitch();
                      setFieldValue('payment_arrangements', []);
                      setFieldValue('document_acquirers', []);
                    }}
                    color="primary"
                    disabled={!discoveryHaveAcquirers}
                    name="active"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <TypographyComponent
                    customVariant="text__body--sm"
                    customColor="secondary-darker"
                    css={{ display: 'inline', fontWeight: 600 }}
                  >
                    {searchAcquirersType == 'params'
                      ? 'Todas as Credenciadoras'
                      : 'Credenciadoras Vinculadas'}
                  </TypographyComponent>
                </>
              )}
            </Card>

            <CardFormFooter
              hasError={Object.keys(errors).length > 0 || !creditorId}
              onSubmit={() => handleSubmit(values)}
              loading={false}
            />
          </>
        )}
      </CreateForm>

      <Dialog
        onClose={handleCloseVisualization}
        aria-labelledby="customized-dialog-title"
        open={visualizationOpen}
        maxWidth={'xl'}
      >
        <DialogTitle
          css={{
            color: dialogRules.dialogType == 'error' ? '#F44336' : '#34BE84',
            borderTop: '2px',
            borderLeft: '2px',
            borderRight: '2px',
            borderBottom: '0',
            borderStyle: 'solid',
            borderColor:
              dialogRules.dialogType == 'error' ? '#F44336' : '#34BE84',
          }}
          id="customized-dialog-title"
        >
          {dialogRules.dialogTitle}
          <IconButton
            css={{ position: 'absolute', right: 0, top: '9px' }}
            aria-label="close"
            onClick={handleCloseVisualization}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          css={{
            color: '#0A2764',
            fontSize: '18px',
            borderTop: '0',
            borderLeft: '2px',
            borderRight: '2px',
            borderBottom: '2px',
            borderStyle: 'solid',
            borderColor:
              dialogRules.dialogType == 'error' ? '#F44336' : '#34BE84',
          }}
          dividers
        >
          {dialogRules.dialogType == 'error'
            ? errorMessage
            : dialogRules.dialogText}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateSchedule;
