import React from 'react';
import {
  Dialog,
  Box,
  Typography,
  MenuItem,
  TextField,
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import Button from '@backoffice/shared/components/Button.component';

const DialogDocumentReproveComponent = ({
  open,
  handleClose,
  preComment,
  handleSetPreMessages,
  setComment,
  comment,
  handleReprove,
  loading,
}) => {
  return (
    <Dialog open={open} maxWidth={false} onClose={handleClose}>
      <Box position="relative" p={3} minWidth={800}>
        <Box position="absolute" top={16} right={16}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="grid" gridTemplateColumns="1fr">
          <Typography variant="h6">Recusa de Documento</Typography>

          <Box
            mt={5}
            display="flex"
            gridGap={12}
            alignItems="center"
            width="100%"
            justifyContent="center"
          >
            <Typography>Selecione um motivo</Typography>
            <TextField
              label="Motivo"
              select
              fullWidth
              css={{ maxWidth: 300 }}
              variant="outlined"
              size="small"
              onChange={({ target: { value } }) => (preComment.current = value)}
            >
              <MenuItem value="dados_invalido">Dados inválidos</MenuItem>
              <MenuItem value="arquivo_invalido">Arquivo inválido</MenuItem>
              <MenuItem value="solicitacao_doc">
                Solicitação do envio de documento societário
              </MenuItem>
            </TextField>
            <Button variant="contained" onClick={handleSetPreMessages}>
              Inserir
            </Button>
          </Box>

          <Box display="flex" flexDirection="column" mt={5}>
            <TextField
              multiline
              rows={5}
              label="Justificativa"
              variant="outlined"
              onChange={({ target: { value } }) => setComment(value)}
              value={comment}
            />
          </Box>

          <Box mt={5} justifySelf="flex-end">
            <Button
              variant="contained"
              onClick={handleReprove}
              loading={loading}
              disabled={loading || comment === ''}
            >
              Salvar
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogDocumentReproveComponent;
