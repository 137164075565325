import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormikContext, Formik } from 'formik';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import CardForm from '@backoffice/private/components/Card/CardForm';
import CardTitle from '@backoffice/private/components/Card/CardTitle';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';
import toFormatAlphabetic from '@backoffice/shared/utils/toFormatAlphabetic.util';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';

import {
  Dispatchs,
  Selectors,
} from '../../../../ducks/merchants/acquirers/merchantAcquirer.duck';
import {
  Dispatchs as DispatchsAcquirers,
  Selectors as SelectorsAcquirers,
} from '../../../../ducks/domains/acquirers.duck';
import {
  Dispatchs as DispatchsAdvancements,
  Selectors as SelectorsAdvancements,
} from '../../../../ducks/domains/advancements.duck';

const validationSchema = Yup.object({
  acquirer_id: Yup.string().required().default(''),
  advancement_id: Yup.string().required().default(''),
});

const FormMerchantPartnerContainer = () => {
  const [acquirers, setAcquirers] = useState<any>([]);
  const history = useHistory();

  const dispatch = useDispatch();
  const { isAdmin } = useUserHook();

  const { values, errors, resetForm } = useFormikContext();

  const dispatchAcquirers = {
    GET: flow(DispatchsAcquirers.get, dispatch),
  };

  const dispatchRedux = {
    CREATE: flow(Dispatchs.create, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
  };

  const dispatchAdvancements = {
    GET: flow(DispatchsAdvancements.get, dispatch),
  };

  const selectorRedux = {
    LOADING: useSelector(Selectors.loading),
    DATA: useSelector(Selectors.data),
  };

  const selectorAcquirersRedux = {
    DATA: useSelector(SelectorsAcquirers.data),
  };

  const selectorAdvancementsRedux = {
    DATA: useSelector(SelectorsAdvancements.data),
  };

  const handleBack = () => history.goBack();

  const handleAcquirers = (data) => {
    const acquirersOptions = data?.map((b) => ({
      label: b?.acquirer_name,
      value: b?.id,
    }));

    const acquirersFormated = toFormatAlphabetic(acquirersOptions);

    setAcquirers(acquirersFormated);
  };

  useEffect(() => {
    const { successAction } = selectorRedux.DATA || {};

    if (successAction === true) {
      resetForm();

      dispatchRedux.RESET();
    }
  }, [selectorRedux.DATA]);

  useEffect(() => {
    if (selectorAcquirersRedux.DATA) {
      handleAcquirers(selectorAcquirersRedux.DATA);
    }
  }, [selectorAcquirersRedux.DATA]);

  useEffect(() => {
    dispatchAcquirers.GET();
    dispatchAdvancements.GET();
  }, []);

  return (
    <FormMerchantAcquirerComponent
      loading={selectorRedux.LOADING}
      errorsForm={errors}
      handleBack={handleBack}
      handleSubmit={() =>
        dispatchRedux.CREATE({
          acquirers: [
            {
              acquirer_id: (values as any)?.acquirer_id,
              advancement_id: (values as any)?.advancement_id,
            },
          ],
        })
      }
      acquirers={acquirers}
      advancements={selectorAdvancementsRedux.DATA}
      isAdmin={isAdmin}
    />
  );
};

const FormMerchantAcquirerComponent = ({
  loading,
  handleSubmit,
  errorsForm,
  handleBack,
  acquirers,
  advancements,
  isAdmin,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_estabelecimento_credenciadoras',
  });

  const advancementsOptions = advancements?.map((b) => ({
    label: b?.description,
    value: b?.id,
  }));

  const credentialsSchema = [
    [
      {
        label: 'Credenciadora',
        name: 'acquirer_id',
        type: 'select',
        options: acquirers,
      },
      {
        label: 'Antecipação',
        name: 'advancement_id',
        type: 'select',
        options: advancementsOptions,
      },
    ],
  ];

  if (!isAdmin) {
    return <></>;
  }

  return (
    <>
      {permissions?.some((item) => item == 'create') && (
        <Box gridGap={8} display="flex" flexDirection="column">
          <CardTitle>Cadastrar nova credenciadora</CardTitle>

          <Box display="flex" flexDirection="column" gridGap={24}>
            <CardForm formik fields={credentialsSchema} />

            <CardFormFooter
              hasError={Object.keys(errorsForm).length > 0}
              onSubmit={handleSubmit}
              onCancel={handleBack}
              loading={loading}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default function FormMerchantAcquirerFormik() {
  return (
    <Formik
      initialValues={{ ...validationSchema.cast() }}
      onSubmit={() => {}}
      validateOnMount
      validationSchema={validationSchema}
    >
      <FormMerchantPartnerContainer />
    </Formik>
  );
}
