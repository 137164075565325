import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import * as listCreditorsDuck from '@backoffice/private/ducks/creditors/listCreditors.duck';

import { Creditor } from '../@types/models/Creditor';

export default function useCreditors() {
  const [creditors, setCreditors] = useState<Creditor[]>([]);

  const dispatch = useDispatch();

  const dispatchRedux = {
    GET: flow(listCreditorsDuck.Dispatchs.get, dispatch),
  };

  useEffect(() => {
    handleResults();
  }, []);

  const handleResults = async () => {
    const result: any = await dispatchRedux.GET({ isExport: true });

    if (result) {
      const data = result?.results;
      handleAlphabetic(data);
    }
  };

  const handleAlphabetic = (data) => {
    const arrayFiltered: Creditor[] = data.sort((a: Creditor, b: Creditor) => {
      const aLabel = a.trading_name;
      const bLabel = b.trading_name;

      const aLabelFiltered = aLabel[0].toUpperCase() + aLabel.substring(1);
      const bLabelFiltered = bLabel[0].toUpperCase() + bLabel.substring(1);

      if (aLabelFiltered > bLabelFiltered) {
        return 1;
      }

      if (aLabelFiltered < bLabelFiltered) {
        return -1;
      }

      return 0;
    });

    setCreditors(arrayFiltered);
  };

  return creditors;
}
