import React, { useState } from 'react';
import { v4 } from 'uuid';
import { Field, useFormikContext } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import NumberFormat from 'react-number-format';
import { TextField as TextFieldMaterial } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import toFormatAlphabetic from '@backoffice/shared/utils/toFormatAlphabetic.util';

import CardSearch from '@backoffice/private/components/Card/CardSearch';

import STATUS_DEAL from '../../../constants/statusDeal.constant';

export const SearchDealComponent = ({
  isAdmin,
  merchants,
  creditors,
  handleSearchReset,
  loading,
}) => {
  const [key, setKey] = useState(v4());

  const { values, submitForm, resetForm }: any = useFormikContext();

  const arrToFormat =
    values?.consulta_por === 'fornecedor' ? creditors : merchants;

  const arr = arrToFormat.reduce(
    (acc, curr) => [
      ...acc,
      {
        label: curr.trading_name,
        value: curr.id,
      },
    ],
    []
  );

  const arrFiltered = toFormatAlphabetic(arr);

  const schemaSearch = [
    [
      {
        label: 'Consulta por',
        name: 'consulta_por',
        disabled: !isAdmin ? true : false,
        type: 'select',
        options: [
          {
            label: 'Fornecedor',
            value: 'fornecedor',
          },
          {
            label: 'Estabelecimento',
            value: 'estabelecimento',
          },
        ],
      },
      arrFiltered.length > 0 && {
        type: 'custom',
        component: (
          <Field
            component={Autocomplete}
            name="id"
            css={{ minWidth: '250px' }}
            options={arrFiltered.map((a: any) => a.value)}
            getOptionLabel={(option: any) => {
              const currentBank = arrFiltered?.find((b) => b.value === option);

              if (!currentBank?.label) {
                return '';
              }

              return currentBank?.label;
            }}
            renderInput={(params) => (
              <TextFieldMaterial
                {...params}
                label="Fornecedor/Estabelecimento"
                variant="outlined"
              />
            )}
          />
        ),
      },
      !(!isAdmin && values?.consulta_por === 'fornecedor') && {
        type: 'custom',
        component: (
          <Field name="document_number">
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }: any) => (
              <>
                <NumberFormat
                  customInput={TextFieldMaterial}
                  format="##.###.###/####-##"
                  fullWidth
                  label="CNPJ"
                  variant="outlined"
                  value={field.value}
                  error={touched && !!error}
                  helperText={touched && !!error && error}
                  onBlur={async ({ target: { value } }) => {
                    setFieldTouched(field.name, true);
                  }}
                  onValueChange={(values: any) =>
                    setFieldValue(field.name, values.value)
                  }
                />
              </>
            )}
          </Field>
        ),
      },
      {
        label: 'Código externo',
        name: 'codes',
      },

      {
        label: 'ID (Grupo/Parcela)',
        name: 'external_reference',
      },
      {
        label: 'Status',
        name: 'status',
        type: 'select',
        multiple: true,
        options: STATUS_DEAL,
      },
      {
        label: 'Data Execução Inicial',
        name: 'startDate',
        type: 'date',
      },
      {
        label: 'Data Execução Final',
        name: 'endDate',
        type: 'date',
      },
      {
        label: 'Data Compromisso',
        name: 'createdAt',
        type: 'date',
      },
      {
        label: 'Modalidade',
        name: 'strategy',
        type: 'select',
        minWidth: '200px',
        multiple: false,
        options: [
          {
            label: 'Contrato Performado',
            value: 'default',
          },
          {
            label: 'Contrato Fumaça',
            value: 'smoke',
          },
        ],
      },
      {
        label: 'Agrupar parcelas',
        name: 'searchByGroup',
        type: 'custom',
        component: (
          <Field name="searchByGroup">
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }: any) => (
              <FormControlLabel
                key={field.value}
                disabled={values?.codes?.length > 0}
                label={'Agrupar parcelas'}
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={({ target: { checked } }) =>
                      setFieldValue(field.name, checked)
                    }
                  />
                }
              />
            )}
          </Field>
        ),
      },
      {
        label: 'Gestão de colateral',
        name: 'searchByGcap',
        type: 'custom',
        component: (
          <Field name="searchByGcap">
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }: any) => (
              <FormControlLabel
                key={field.value}
                label={'Gestão de colateral'}
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={({ target: { checked } }) => {
                      setFieldValue(field.name, checked);
                    }}
                  />
                }
              />
            )}
          </Field>
        ),
      },
    ],
  ];

  return (
    <CardSearch
      formik
      key={key}
      fields={schemaSearch as any}
      disabled={loading}
      onSubmit={submitForm}
      onReset={() =>
        handleSearchReset()(() => {
          setKey(v4());

          resetForm();
        })
      }
    />
  );
};
