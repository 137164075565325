import React from 'react';

import CreateEditProfile from './CreateEditProfile.screen';
import CreateEditUser from './CreateEditUser.screen';
import CreateEditUserCreditor from './CreateEditUserCreditor.screen';

import useDynamicCreate from '@backoffice/shared/hooks/useDynamicCreate.hook';
import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';

const CeateEditDynamic = () => {
  const { typeCreate } = useDynamicCreate();
  const { type } = useIsEdit();
  return (
    <div>
      {(type == 'user' || typeCreate == 'user') && <CreateEditUser />}
      {(type == 'user-creditor' || typeCreate == 'user-creditor') && (
        <CreateEditUserCreditor />
      )}
      {(type == 'profile' || type == 'visual' || typeCreate == 'profile') && (
        <CreateEditProfile />
      )}
    </div>
  );
};

export default CeateEditDynamic;
