import fetch from '@payhop/shared-utils/fetch.util';

export default async function getExportCreditorsService(account_id: string) {
  await fetch(`creditor?account_id=${account_id}`, {
    method: 'GET',
    ms: 'EXPORT',
    auth: true,
  });

  return true;
}
