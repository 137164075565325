import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core';

import Button from '@backoffice/shared/components/Button.component';
import Typography from '@backoffice/shared/components/Typography.component';
import ProfilePermissionsItems from './ProfilePermissionsItems.component';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/profiles/permissions.duck';
import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';

interface PermissionProfileProps {
  name: string;
  resources: PostArrayPermissions[];
  onlyVisual: boolean;
  accountType: string;
}
interface ModulePermissions {
  active: boolean;
  created_at: Date;
  description: string;
  id: string;
  is_payhop: boolean;
  module: string;
  permition_delete: boolean;
  slug: string;
  sub_module?: string;
  updated_at?: Date;
}
interface PostArrayPermissions {
  permition: string;
  user_resource_id: string;
}

const PermissionsProfile = ({
  name,
  resources,
  onlyVisual,
  accountType,
}: PermissionProfileProps) => {
  const Component = ({ field, form: { setFieldValue } }: any) => {
    const { isEdit } = useIsEdit();
    const [modulePermissions, setModulePermissions] = useState<
      ModulePermissions[]
    >([]);
    const [postArrayPermissions, setPostArrayPermissions] = useState<
      PostArrayPermissions[]
    >([]);
    const [selectedItems, setSelectedItems] = useState<Array<string>>([]);

    const dispatch = useDispatch();

    const dispatchRedux = {
      GET: flow(Dispatchs.get, dispatch),
      RESET: flow(Dispatchs.reset, dispatch),
    };

    const selectorRedux = {
      DATA: useSelector(Selectors.data),
    };

    useEffect(() => {
      handleselectedItems(resources);
    }, []);

    useEffect(() => {
      dispatchRedux.GET({ type: accountType ? '1' : '2' });
      setPostArrayPermissions(resources);
      if (!isEdit) {
        setSelectedItems([]);
        setPostArrayPermissions([]);
      }
    }, [accountType]);

    useEffect(() => {
      if (selectorRedux.DATA) {
        const { results } = selectorRedux.DATA;
        setModulePermissions(results);
      }
    }, [selectorRedux.DATA]);

    useEffect(() => {
      setFieldValue(field.name, postArrayPermissions);
    }, [postArrayPermissions]);

    const handleSetPermission = (value) => {
      const valueArray = value.split(',');
      const permissionObject = {
        user_resource_id: valueArray[1],
        permition: valueArray[0],
      };

      if (
        postArrayPermissions.some(
          (item) =>
            item.user_resource_id === permissionObject.user_resource_id &&
            item.permition === permissionObject.permition
        )
      ) {
        setPostArrayPermissions(
          postArrayPermissions.filter(function (item) {
            if (
              item.user_resource_id == permissionObject.user_resource_id &&
              item.permition == permissionObject.permition
            ) {
              return false;
            } else {
              return true;
            }
          })
        );
        setSelectedItems(selectedItems.filter((item) => item !== value));
      } else {
        setPostArrayPermissions([...postArrayPermissions, permissionObject]);
        setSelectedItems([...selectedItems, value]);
      }
    };

    const handleselectedItems = (resources) => {
      const resourcesArray: any = [];

      resources.forEach((item) => {
        resourcesArray.push(`${item.permition},${item.user_resource_id}`);
      });

      setSelectedItems(resourcesArray);
    };

    const handleSelectRow = (value) => {
      const permitions = ['create', 'read', 'edit', 'delete'];
      const valueArray = value.split(',');
      const id = valueArray[1];

      const permissionObject: PostArrayPermissions[] = [];
      const selectedItemsArray: Array<string> = [];

      if (selectedItems.find((item) => item == value)) {
        let newPostArrayPermissions = postArrayPermissions;
        let newselectedItems = selectedItems;
        permitions.forEach((itemPermition) => {
          newPostArrayPermissions = newPostArrayPermissions.filter(function (
            item
          ) {
            if (
              item.user_resource_id == id &&
              item.permition == itemPermition
            ) {
              return false;
            } else {
              return true;
            }
          });

          newselectedItems = newselectedItems.filter(
            (item) => item !== `${itemPermition},${id}`
          );
        });
        newselectedItems = newselectedItems.filter((item) => item !== value);

        setPostArrayPermissions(newPostArrayPermissions);
        setSelectedItems(newselectedItems);
      } else {
        selectedItemsArray.push(value);

        permitions.forEach((itemPermition) => {
          if (
            !postArrayPermissions.some(
              (item) =>
                item.user_resource_id === id && item.permition === itemPermition
            )
          ) {
            permissionObject.push({
              user_resource_id: id,
              permition: itemPermition,
            });
            selectedItemsArray.push(`${itemPermition},${id}`);
          }
        });

        setPostArrayPermissions([...postArrayPermissions, ...permissionObject]);
        setSelectedItems([...selectedItems, ...selectedItemsArray]);
      }
    };

    const handleSetAllPermissions = () => {
      const permissions = ['create', 'read', 'edit', 'delete'];

      const permissionObject: PostArrayPermissions[] = [];
      const selectedItemsArray: Array<string> = [];

      modulePermissions.forEach((module) => {
        permissions.forEach((itemPermission) => {
          if (
            !postArrayPermissions.some(
              (item) =>
                item.user_resource_id === module.id &&
                item.permition === itemPermission
            )
          ) {
            permissionObject.push({
              user_resource_id: module.id,
              permition: itemPermission,
            });
            selectedItemsArray.push(`${itemPermission},${module.id}`);
          }
        });
      });
      setPostArrayPermissions([...postArrayPermissions, ...permissionObject]);
      setSelectedItems([...selectedItems, ...selectedItemsArray]);
    };

    return (
      <>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            aria-label="show document"
            disabled={onlyVisual}
            css={{
              margin: '10px',
              marginBottom: '20px',
              textAlign: 'right',
            }}
            onClick={() => handleSetAllPermissions()}
          >
            Selecionar Todos
          </Button>
        </Box>
        <Box display="flex" gridColumnGap="35px">
          <ProfilePermissionsItems
            title={'Módulo'}
            items={modulePermissions}
            itemType={'module'}
          />
          <ProfilePermissionsItems
            title={'Sub Módulo'}
            items={modulePermissions}
            itemType={'sub_module'}
          />
          <ProfilePermissionsItems
            title={'Descrição'}
            items={modulePermissions}
            itemType={'description'}
          />
          <div>
            <Typography
              customVariant="text__body--md"
              customColor="info-500"
              css={{ marginBottom: '15px' }}
            >
              Todos
            </Typography>
            {modulePermissions.map((item: any) => {
              return (
                <>
                  <Checkbox
                    key={`all,${item.id}`}
                    css={{
                      display: 'block',
                      padding: '7.3px',
                    }}
                    checked={selectedItems.includes(`all,${item.id}`)}
                    disabled={onlyVisual}
                    onChange={() => handleSelectRow(`all,${item.id}`)}
                  />
                </>
              );
            })}
          </div>
          <div>
            <Typography
              customVariant="text__body--md"
              customColor="info-500"
              css={{ marginBottom: '15px' }}
            >
              Criar
            </Typography>
            {modulePermissions.map((item: any) => {
              return (
                <>
                  <Box display="flex">
                    {/* <p>{item.id}</p> */}
                    <Checkbox
                      key={`create,${item.id}`}
                      css={{
                        display: 'block',
                        padding: '7.3px',
                      }}
                      checked={selectedItems.includes(`create,${item.id}`)}
                      disabled={onlyVisual}
                      onChange={() => handleSetPermission(`create,${item.id}`)}
                    />
                  </Box>
                </>
              );
            })}
          </div>
          <div>
            <Typography
              customVariant="text__body--md"
              customColor="info-500"
              css={{ marginBottom: '15px' }}
            >
              Ler
            </Typography>
            {modulePermissions.map((item: any) => {
              return (
                <>
                  <Checkbox
                    key={`read,${item.id}`}
                    css={{ display: 'block', padding: '7.3px' }}
                    checked={selectedItems.includes(`read,${item.id}`)}
                    disabled={onlyVisual}
                    onChange={() => handleSetPermission(`read,${item.id}`)}
                  />
                </>
              );
            })}
          </div>
          <div>
            <Typography
              customVariant="text__body--md"
              customColor="info-500"
              css={{ marginBottom: '15px' }}
            >
              Editar
            </Typography>
            {modulePermissions.map((item: any) => {
              return (
                <>
                  <Checkbox
                    key={`edit,${item.id}`}
                    css={{ display: 'block', padding: '7.3px' }}
                    checked={selectedItems.includes(`edit,${item.id}`)}
                    disabled={onlyVisual}
                    onChange={() => handleSetPermission(`edit,${item.id}`)}
                  />
                </>
              );
            })}
          </div>
          <div>
            <Typography
              customVariant="text__body--md"
              customColor="info-500"
              css={{ marginBottom: '15px' }}
            >
              Deletar
            </Typography>
            {modulePermissions.map((item: any) => {
              return (
                <>
                  <Checkbox
                    key={`delete,${item.id}`}
                    css={{ display: 'block', padding: '7.3px' }}
                    checked={selectedItems.includes(`delete,${item.id}`)}
                    disabled={onlyVisual || !item.permition_delete}
                    onChange={() => handleSetPermission(`delete,${item.id}`)}
                  />
                </>
              );
            })}
          </div>
        </Box>
      </>
    );
  };

  return <Field name={name}>{Component}</Field>;
};

export default PermissionsProfile;
