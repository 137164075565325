import React, { useState, useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';

import getMerchants from '@backoffice/private/services/merchant/getMerchants.service';
import getCreditors from '@backoffice/private/services/creditor/getCreditors.service';

import * as listReportsDuck from '@backoffice/private/ducks/reports/listReports.duck';
import { SearchContractComponent } from './SearchContract.component';
import { v4 } from 'uuid';
import moment from 'moment';

const SearchContractContainer = () => {
  const { isAdmin, user } = useUserHook();

  const [creditors, setCreditors] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [key, setKey] = useState('');

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(listReportsDuck.Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(listReportsDuck.Dispatchs.resetFilters, dispatch),
    GET: flow(listReportsDuck.Dispatchs.get, dispatch),
    RESET: flow(listReportsDuck.Dispatchs.reset, dispatch),
  };

  const selectorRedux = {
    LOADING: useSelector(listReportsDuck.Selectors.loading),
    FILTERS: useSelector(listReportsDuck.Selectors.filters),
  };

  const { handleSearch, handleSearchReset } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: false,
    },
  });

  const handleGetCreditors = useCallback(async () => {
    const response = await getCreditors();
    const result = await response.json();

    const { results } = result || {};

    setCreditors(results);
  }, []);

  const handleGetMerchants = useCallback(async () => {
    const response = await getMerchants();
    const result = await response.json();

    const { results } = result || {};

    setMerchants(results);
  }, []);

  useEffect(() => {
    if (isAdmin) {
      handleGetCreditors();
    }

    handleGetMerchants();
  }, [handleGetCreditors, handleGetMerchants]);

  useEffect(() => {
    setKey(v4());
  }, [selectorRedux.FILTERS]);

  const handleSubmit = async (values) => {
    const newValues = {
      ...values,
      offset: 0,
    };

    if (newValues?.codes) {
      newValues.searchByGroup = undefined;
    }

    if (newValues?.searchByGroup) {
      newValues.codes = undefined;
    }

    if (isAdmin) {
      if (newValues?.searchByCreditor === 'fornecedor') {
        newValues.creditorId = newValues.id;
        newValues.searchByCreditor = true;
        newValues.merchantId = undefined;
      } else {
        newValues.creditorId = undefined;
        newValues.searchByCreditor = false;
        newValues.merchantId = newValues.id;
      }
    }

    if (!isAdmin) {
      const { personId } = user || {};

      if (newValues?.searchByCreditor === 'fornecedor') {
        newValues.creditorId = personId;
        newValues.merchantId = undefined;
      } else {
        newValues.creditorId = personId;
        newValues.merchantId = newValues.id;
      }
    }

    if (newValues?.executionDateInitial) {
      newValues.executionDateInitial = moment(newValues.executionDateInitial)
        .toISOString()
        .split('T')[0];
    } else {
      newValues.executionDateInitial = undefined;
    }
    if (newValues?.executionDateEnd) {
      newValues.executionDateEnd = moment(newValues.executionDateEnd)
        .toISOString()
        .split('T')[0];
    } else {
      newValues.executionDateEnd = undefined;
    }

    if (newValues?.createdAtInitial) {
      newValues.createdAtInitial = moment(newValues.createdAtInitial)
        .toISOString()
        .split('T')[0];
    } else {
      newValues.createdAtInitial = undefined;
    }
    if (newValues?.createdAtEnd) {
      newValues.createdAtEnd = moment(newValues.createdAtEnd)
        .toISOString()
        .split('T')[0];
    } else {
      newValues.createdAtEnd = undefined;
    }

    await handleSearch(newValues);

    return true;
  };

  return (
    <Formik
      initialValues={{
        searchByCreditor: selectorRedux.FILTERS.searchByCreditor
          ? 'fornecedor'
          : 'estabelecimento',
        createdAtInitial: selectorRedux.FILTERS.createdAtInitial ?? null,
        createdAtEnd: selectorRedux.FILTERS.createdAtEnd ?? null,
        executionDateInitial:
          selectorRedux.FILTERS.executionDateInitial ?? null,
        executionDateEnd: selectorRedux.FILTERS.executionDateEnd ?? null,
        documentNumber: selectorRedux.FILTERS.documentNumber ?? '',
        codes: selectorRedux.FILTERS?.codes,
        id: selectorRedux.FILTERS.searchByCreditor
          ? selectorRedux.FILTERS.creditorId
          : selectorRedux.FILTERS.merchantId,
        strategy: selectorRedux.FILTERS.strategy,
        externalReference: selectorRedux.FILTERS.externalReference,
        searchByGroup: selectorRedux.FILTERS.searchByGroup ?? false,
        status: selectorRedux.FILTERS.status,
        contractType: selectorRedux.FILTERS.contractType,
        searchByGcap: selectorRedux.FILTERS.searchByGcap,
      }}
      onSubmit={handleSubmit}
      key={key}
    >
      <SearchContractComponent
        isAdmin={isAdmin}
        creditors={creditors}
        merchants={merchants}
        loading={selectorRedux.LOADING}
        handleSearchReset={handleSearchReset}
      />
    </Formik>
  );
};

export default SearchContractContainer;
