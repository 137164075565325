import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import FormAcquirer from '@backoffice/private/modules/parameters/components/Form/FormAcquirers.component';
import FormArrangements from '@backoffice/private/modules/parameters/components/Form/FormArrangement.component';
import FormBank from '@backoffice/private/modules/parameters/components/Form/FormBanks.component';
import SearchAcquirer from '@backoffice/private/modules/parameters/components/Search/SearchAcquirer.component';
import SearchArrangement from '@backoffice/private/modules/parameters/components/Search/SearchArrangement.component';
import SearchBank from '@backoffice/private/modules/parameters/components/Search/SearchBank.component';
import TableAcquirerContainer from '../components/Table/TableAcquirer.component';
import TableArrangementContainer from '../components/Table/TableArrangement.component';
import TableBankContainer from '../components/Table/TableBank.component';
import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import CardTab from '@backoffice/private/components/Card/CardTab';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';
import useHandleTabRoute from '@backoffice/shared/hooks/useHandleTabRoute.hook';

const texts = {
  titleEdit: 'Parâmetros',
  titleCreate: 'Parâmetros',
};

const tabs = {
  geral: {
    label: 'Geral',
  },
  credenciadoras: {
    label: 'Credenciadoras',
  },
  arranjos: {
    label: 'Arranjos',
  },
  bancos: {
    label: 'Bancos',
  },
};

const tabsFormatted: any = Object.keys(tabs).reduce(
  (acc, curr, index) => ({
    ...acc,
    [curr]: {
      label: tabs[curr].label,
      name: curr,
      index,
    },
  }),
  {}
);

const tabsLabelsArr = Object.keys(tabsFormatted).map(
  (val) => tabsFormatted[val].label
);

const ParametersScreen = () => {
  const permissionsAcquirers = useAccountPermission({
    permission_slug: 'cad_parametro_payhop_credenciadoras',
  });
  const permissionsArrangements = useAccountPermission({
    permission_slug: 'cad_parametro_payhop_arranjos',
  });
  const permissionsBanks = useAccountPermission({
    permission_slug: 'cad_parametro_payhop_bancos',
  });
  const { handleTabRoute } = useHandleTabRoute();
  const [tabActive, setTabActive] = useState(0);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { isEdit } = useIsEdit();

  const titlePage = useMemo(
    () => (isEdit ? texts.titleEdit : texts.titleCreate),
    [isEdit]
  );

  return (
    <PageLayout headerTitle header sidebar title={titlePage} ctaBack>
      <Box display="flex" gridGap={24} flexDirection="column">
        <CardTab
          onChange={(_, value) => handleTabRoute(value)}
          tabs={tabsLabelsArr}
          tabActive={Number(params.get('tab'))}
        />

        {params.get('tab') == null && <h1>Geral</h1>}

        {params.get('tab') == '1' && (
          <Box display="flex" flexDirection="column" gridGap={24}>
            {permissionsAcquirers.permissions?.some(
              (item) => item == 'create'
            ) && <FormAcquirer />}
            {permissionsAcquirers.permissions?.some(
              (item) => item == 'read'
            ) && (
              <>
                <SearchAcquirer />
                <TableAcquirerContainer />
              </>
            )}
            {!permissionsAcquirers.permissions ||
              (!permissionsAcquirers.permissions?.some(
                (item) => item == 'read'
              ) && <ModuleNotPermited />)}
          </Box>
        )}
        {params.get('tab') == '2' && (
          <Box display="flex" flexDirection="column" gridGap={24}>
            {permissionsArrangements.permissions?.some(
              (item) => item == 'create'
            ) && <FormArrangements />}

            {permissionsArrangements.permissions?.some(
              (item) => item == 'read'
            ) && (
              <>
                <SearchArrangement />
                <TableArrangementContainer />
              </>
            )}

            {!permissionsArrangements.permissions ||
              (!permissionsArrangements.permissions?.some(
                (item) => item == 'read'
              ) && <ModuleNotPermited />)}
          </Box>
        )}

        {params.get('tab') == '3' && (
          <Box display="flex" flexDirection="column" gridGap={24}>
            {permissionsBanks.permissions?.some((item) => item == 'create') && (
              <FormBank />
            )}

            {permissionsBanks.permissions?.some((item) => item == 'read') && (
              <>
                <SearchBank />
                <TableBankContainer />
              </>
            )}

            {!permissionsBanks.permissions ||
              (!permissionsBanks.permissions?.some(
                (item) => item == 'read'
              ) && <ModuleNotPermited />)}
          </Box>
        )}
      </Box>
    </PageLayout>
  );
};

export default ParametersScreen;
