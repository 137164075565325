import fetch from '@payhop/shared-utils/fetch.util';

export default function patchAccountsByIdService(id, obj) {
  return fetch(`accounts/${id}`, {
    method: 'PATCH',
    ms: 'ACCOUNT',
    auth: true,
    body: JSON.stringify(obj),
  });
}
