import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import { Dispatchs } from '@backoffice/private/ducks/creditors/listCreditors.duck';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import CreditorsScreen from './screens/Creditors.screen';
import CreateEditCreditorScreen from './screens/CreateEditCreditor.screen';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';

const CreditorModule: React.FC<any> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { permissions } = useAccountPermission({
    permission_slug: 'cad_fornecedor',
  });

  const dispatchRedux = {
    RESET: flow(Dispatchs.reset, dispatch),
  };

  useEffect(() => {
    return () => {
      const location = history.location.pathname;

      if (!location.includes('creditors')) dispatchRedux.RESET();
    };
  }, []);

  return (
    <>
      {permissions && (
        <Switch>
          <Route
            exact
            path={ROUTES.PRIVATE.CREDITORS.ROOT}
            component={CreditorsScreen}
          />
          {permissions?.some((item) => item == 'create') && (
            <Route
              exact
              path={ROUTES.PRIVATE.CREDITORS.CREATE}
              component={CreateEditCreditorScreen}
            />
          )}

          {permissions?.some((item) => item == 'edit') && (
            <Route
              exact
              path={ROUTES.PRIVATE.CREDITORS.EDIT()}
              component={CreateEditCreditorScreen}
            />
          )}

          <Redirect to={ROUTES.PRIVATE.HOME} />
        </Switch>
      )}
    </>
  );
};

export default CreditorModule;
