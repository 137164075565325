import React from 'react';
import {
  Dialog,
  IconButton,
  Grid,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';

import { componentPropTypes } from './ModalFullScreen.type';

import { appBarCss, titleCss, containerCss } from './ModalFullScreen.css';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModallFullScreenComponent: React.FC<any> = ({
  onClose,
  visible,
  children,
  title,
  fullScreen = true,
  className,
}) => (
  <Dialog
    fullScreen={fullScreen}
    open={visible}
    TransitionComponent={Transition}
    onClose={onClose}
    className={className}
  >
    <Grid container>
      <Grid item xs={12}>
        <AppBar css={appBarCss}>
          <Box display="flex" alignItems={'center'} gridColumnGap={4}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" css={titleCss} align="center">
              {title}
            </Typography>
          </Box>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth={false} css={containerCss(fullScreen)}>
          {children}
        </Container>
      </Grid>
    </Grid>
  </Dialog>
);

export default ModallFullScreenComponent;
