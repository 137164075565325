import React, { useEffect, useMemo, useRef, useState } from 'react';
import { flow } from 'lodash';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Box, IconButton, Button, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';

import ButtonComponent from '@backoffice/shared/components/Button.component';

import CardTable from '@backoffice/private/components/Card/CardTable';

import ROUTES from '@backoffice/shared/utils/routes.util';

import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';

import postDealsReversedService from '@backoffice/private/services/deal/postDealsReversed.service';
import postDealsResendApprove from '@backoffice/private/services/deal/postDealsResendApprove.service';
import putDealsChangeStatusService from '@backoffice/private/services/deal/putDealsChangeStatus.service';

import * as deals from '@backoffice/private/ducks/deals/listDeals.duck';
import { STRATEGY_LABELS } from '@backoffice/private/constants/contract/strategy.constant';

const TableDealComponent = () => {
  const { isAdmin, user } = useUserHook();
  const handleNotification = useNotification();

  const [loadingLocal, setLoadingLocal] = useState(false);
  const [orderFilter, setOrderFilter] = useState({});

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(deals.Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(deals.Dispatchs.resetFilters, dispatch),
    GET: flow(deals.Dispatchs.get, dispatch),
    RESET: flow(deals.Dispatchs.reset, dispatch),
    DELETE: flow(deals.Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(deals.Selectors.data),
    LOADING: useSelector(deals.Selectors.loading),
    DATA_RESULTS: useSelector(deals.Selectors.data_results),
    FILTERS: useSelector(deals.Selectors.filters),
    RESULTSET: useSelector(deals.Selectors.resultset),
    FETCHED: useSelector(deals.Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: false,
    },
  });

  useEffect(() => {
    if (!isAdmin) {
      const { personId } = user || {};

      dispatchRedux.UPDATE_FILTERS({
        creditor_id: personId,
        searchByGroup: false,
        searchByGcap: false,
      });
    } else {
      dispatchRedux.GET();
    }
  }, []);

  const handleOnRefresh = () => {
    dispatchRedux.GET();
  };

  const handleOnExport = async () => {
    const result = await dispatchRedux.GET({ isExport: true });

    return (result as any)?.data;
  };

  const handleOnBaixa = async ({ dealId }) => {
    setLoadingLocal(true);

    const response = await postDealsReversedService(dealId);
    const result = await response.json();

    if (response.ok) {
      handleNotification('Baixado com sucesso', 'success');

      handleOnRefresh();
    }

    if (!response.ok) {
      handleNotification(
        result?.errors?.message?.[0] || 'Aconteceu um erro',
        'error'
      );
    }

    setLoadingLocal(false);

    return response.ok;
  };

  const handleOnLiquida = async ({ dealId }) => {
    const creditorId = selectorRedux.DATA_RESULTS.find(
      (d) => d.id === dealId
    )?.creditor_id;

    setLoadingLocal(true);

    const response = await putDealsChangeStatusService({
      deal_id: dealId,
      deal_status: 4,
      creditor_id: creditorId,
    });
    const result = await response.json();

    if (response.ok) {
      const response = await postDealsReversedService(dealId);

      if (response.ok) {
        handleNotification('Líquidado com sucesso', 'success');

        handleOnRefresh();
      } else {
        handleNotification(
          result?.errors?.message?.[0] || 'Aconteceu um erro',
          'error'
        );
      }
    }

    if (!response.ok) {
      handleNotification(
        result?.errors?.message?.[0] || 'Aconteceu um erro',
        'error'
      );
    }

    setLoadingLocal(false);

    return response.ok;
  };

  const handleOnAprova = async ({ dealId }) => {
    setLoadingLocal(true);

    const response = await postDealsResendApprove({
      deal_id: dealId,
    });
    const result = await response.json();

    if (response.ok) {
      handleNotification('A solictação de aprovação foi enviada', 'success');

      handleOnRefresh();
    }

    if (!response.ok) {
      handleNotification(
        result?.errors?.message?.[0] || 'Aconteceu um erro',
        'error'
      );
    }

    setLoadingLocal(false);

    return response.ok;
  };

  const handleOnDelete = async ({ dealId }) => {
    const creditorId = selectorRedux.DATA_RESULTS.find(
      (d) => d.id === dealId
    )?.creditor_id;

    setLoadingLocal(true);

    const response = await putDealsChangeStatusService({
      deal_id: dealId,
      deal_status: 3,
      creditor_id: creditorId,
    });
    const result = await response.json();

    if (response.ok) {
      handleNotification('Excluído com sucesso', 'success');

      handleOnRefresh();
    }

    if (!response.ok) {
      handleNotification(
        result?.errors?.message?.[0] || 'Aconteceu um erro',
        'error'
      );
    }

    setLoadingLocal(false);

    return response.ok;
  };

  const handleOnContesta = async ({ dealId }) => {
    const creditorId = selectorRedux.DATA_RESULTS.find(
      (d) => d.id === dealId
    )?.creditor_id;

    setLoadingLocal(true);

    const response = await putDealsChangeStatusService({
      deal_id: dealId,
      deal_status: 10,
      creditor_id: creditorId,
    });

    const result = await response.json();

    if (response.ok) {
      handleNotification('Contestado com sucesso', 'success');

      handleOnRefresh();
    }

    if (!response.ok) {
      handleNotification(
        result?.errors?.message?.[0] || 'Aconteceu um erro',
        'error'
      );
    }

    setLoadingLocal(false);

    return response.ok;
  };

  const handleOrder = async (filter) => {
    setLoadingLocal(true);
    await setOrderFilter((oldValue) => {
      if (!oldValue[filter]) {
        return { ...oldValue, [filter]: 'asc' };
      }
      if (oldValue[filter] === 'asc') {
        return { ...oldValue, [filter]: 'desc' };
      }
      if (oldValue[filter] === 'desc') {
        const orders = { ...oldValue };
        delete orders[filter];
        return { ...oldValue, [filter]: '' };
      }
    });
    setLoadingLocal(false);
  };

  useEffect(() => {
    dispatchRedux.UPDATE_FILTERS({
      ...orderFilter,
    });
  }, [orderFilter]);

  return (
    <TableDeal
      loading={selectorRedux.LOADING || loadingLocal}
      results={selectorRedux.DATA_RESULTS}
      handleOnRefresh={handleOnRefresh}
      handleOnExport={handleOnExport}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleOnBaixa={handleOnBaixa}
      handleOnContesta={handleOnContesta}
      handleOnLiquida={handleOnLiquida}
      handleOnAprova={handleOnAprova}
      handleOnDelete={handleOnDelete}
      handleOrder={handleOrder}
      isAdmin={isAdmin}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
      orderFilter={orderFilter}
    />
  );
};

const TableDeal = ({
  results,
  filters,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOnRefresh,
  handleOnExport,
  handleOnBaixa,
  handleOnContesta,
  handleOnLiquida,
  handleOnAprova,
  handleOnDelete,
  handleOrder,
  isAdmin,
  orderFilter,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_compromisso_dados_compromissos',
  });
  const history = useHistory();

  const action = useRef({
    type: '',
    dealId: '',
  });

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');

  const { limit, count, offset } = filters || {};

  const columns = useMemo(
    () => [
      {
        render: ({ id, status }) => (
          <Box display="flex" alignItems="center" gridGap={8}>
            <Tooltip title="Visualizar">
              <IconButton
                id="view-item-button"
                size="small"
                onClick={() =>
                  history.push(ROUTES.PRIVATE.APPOINTMENTS.EDIT(id))
                }
                disabled={!permissions?.some((item) => item == 'read')}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancelar">
              <IconButton
                id="delete-item-button"
                size="small"
                disabled={!permissions?.some((item) => item == 'delete')}
                onClick={() => {
                  action.current = {
                    type: 'excluir',
                    dealId: id,
                  };

                  setConfirmDialog(true);
                  setDialogText(
                    'O cancelamento do compromisso é irreversível!'
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Baixar contrato">
              <Button
                css={{ minWidth: 30 }}
                variant="contained"
                disabled={!permissions?.some((item) => item == 'edit')}
                size="small"
                onClick={() => {
                  action.current = {
                    type: 'baixa',
                    dealId: id,
                  };

                  setConfirmDialog(true);
                  setDialogText(
                    'O pagamento do seu boleto foi realizado pelo estabelecimento? A baixa do compromisso é irreversível.'
                  );
                }}
              >
                B
              </Button>
            </Tooltip>

            <Tooltip title="Liquidar contrato">
              <Button
                css={{ minWidth: 30 }}
                variant="contained"
                disabled={!permissions?.some((item) => item == 'edit')}
                size="small"
                onClick={() => {
                  action.current = {
                    type: 'liquida',
                    dealId: id,
                  };

                  setConfirmDialog(true);
                  setDialogText(
                    'Todas as Unidades de Recebíveis foram liquidadas? A Liquidação do compromisso é irreversível.'
                  );
                }}
              >
                L
              </Button>
            </Tooltip>
            {isAdmin && (
              <Tooltip title="Contestar contrato">
                <Button
                  css={{ minWidth: 30 }}
                  variant="contained"
                  disabled={
                    !permissions?.some((item) => item == 'edit') ||
                    status !== 'Aprovado'
                  }
                  size="small"
                  onClick={() => {
                    action.current = {
                      type: 'contesta',
                      dealId: id,
                    };

                    setConfirmDialog(true);
                    setDialogText('Deseja contestar esse compromisso?');
                  }}
                >
                  C
                </Button>
              </Tooltip>
            )}
            <Tooltip title="Reenviar contrato">
              <Button
                css={{ minWidth: 30 }}
                variant="contained"
                disabled={!permissions?.some((item) => item == 'edit')}
                size="small"
                onClick={() => {
                  action.current = {
                    type: 'aprova',
                    dealId: id,
                  };

                  setConfirmDialog(true);
                  setDialogText(
                    'Todas as informações enviadas anteriormente no compromisso estão corretas? O reenvio do compromisso é irreversível!'
                  );
                }}
              >
                R
              </Button>
            </Tooltip>
          </Box>
        ),
      },
      {
        title: 'Fornecedor',
        dataIndex: 'trading_name',
        key: 'trading_name',
      },
      {
        title: 'Estabelecimento',
        dataIndex: 'merchant_trading_name',
        key: 'merchant_trading_name',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('merchantOrder');
        },
        orderType: orderFilter.merchantOrder,
      },
      {
        title: 'CNPJ/CPF',
        dataIndex: 'merchant_document_number',
        key: 'merchant_document_number',
        render: ({ merchant_document_number }) => (
          <NumberFormat
            displayType="text"
            value={merchant_document_number}
            isNumericString
            format={
              merchant_document_number.length == 14
                ? '##.###.###/####-##'
                : '###.###.###-##'
            }
          />
        ),
      },
      {
        title: 'Modalidade',
        dataIndex: 'strategy',
        key: 'strategy',
        hasOrdenation: true,
        render: ({ strategy }) => (strategy ? STRATEGY_LABELS[strategy] : '-'),
        handleOrder: () => {
          handleOrder('strategyOrder');
        },
        orderType: orderFilter.strategyOrder,
      },
      {
        title: 'Parcelas',
        dataIndex: 'installment',
        key: 'installment',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('installmentOrder');
        },
        orderType: orderFilter.installmentOrder,
      },
      {
        title: 'Valor Total',
        dataIndex: 'amount',
        key: 'amount',
        editable: true,
        render: ({ amount }) =>
          Number(amount).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('amountOrder');
        },
        orderType: orderFilter.amountOrder,
      },
      {
        title: 'Data Compromisso',
        dataIndex: 'created_at',
        key: 'created_at',
        render: ({ created_at }) => moment(created_at).format('DD/MM/YYYY'),
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('createdAtOrder');
        },
        orderType: orderFilter.createdAtOrder,
      },
      {
        title: 'Aprovar até',
        dataIndex: 'expiration_date',
        key: 'expiration_date',
        render: ({ expiration_date }) =>
          expiration_date ? moment(expiration_date).format('DD/MM/YYYY') : '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('expirationDateOrder');
        },
        orderType: orderFilter.expirationDateOrder,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('statusOrder');
        },
        orderType: orderFilter.statusOrder,
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('idOrder');
        },
        orderType: orderFilter.idOrder,
      },
      {
        title: 'Data Execução Inicial',
        dataIndex: 'execution_date',
        key: 'execution_date',
        render: ({ execution_date }) =>
          execution_date ? moment(execution_date).format('DD/MM/YYYY') : '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('executionInitialOrder');
        },
        orderType: orderFilter.executionInitialOrder,
      },
      {
        title: 'Data Execução Final',
        dataIndex: 'execution_date_end',
        key: 'execution_date_end',
        render: ({ execution_date_end }) =>
          execution_date_end
            ? moment(execution_date_end).format('DD/MM/YYYY')
            : '-',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('executionEndOrder');
        },
        orderType: orderFilter.executionEndOrder,
      },
      {
        title: 'Descrição',
        dataIndex: 'description',
        key: 'description',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('descriptionOrder');
        },
        orderType: orderFilter.descriptionOrder,
      },
      {
        title: 'Código Externo',
        dataIndex: 'code',
        key: 'code',
        hasOrdenation: true,
        handleOrder: () => {
          handleOrder('codeOrder');
        },
        orderType: orderFilter.codeOrder,
      },
      {
        title: 'Gestão de colateral',
        dataIndex: 'gcap',
        key: 'gcap',
        render: ({ gcap }) => (gcap ? 'Sim' : 'Não'),
      },
    ],
    [permissions, orderFilter]
  );

  const onCloseDialog = () => {
    setConfirmDialog(false);
    setDialogText('');
  };

  const handleConfirmModal = async () => {
    let success;

    if (action.current.type === 'baixa') {
      success = await handleOnBaixa({ dealId: action.current.dealId });
    }

    if (action.current.type === 'contesta') {
      success = await handleOnContesta({ dealId: action.current.dealId });
    }

    if (action.current.type === 'liquida') {
      success = await handleOnLiquida({ dealId: action.current.dealId });
    }

    if (action.current.type === 'aprova') {
      success = await handleOnAprova({ dealId: action.current.dealId });
    }

    if (action.current.type === 'excluir') {
      success = await handleOnDelete({ dealId: action.current.dealId });
    }

    if (success) {
      onCloseDialog();
    }

    return true;
  };

  return (
    <>
      <CardTable
        count={count || 0}
        loading={loading}
        dataSource={results}
        rowsPerPage={limit}
        columns={columns}
        page={offset / limit}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onRefresh={handleOnRefresh}
        onExport={handleOnExport}
        exporttype="xls"
        exportname="deals"
        tableProps={{
          style: {
            minWidth: 1700,
          },
        }}
      />

      {confirmDialog && (
        <Dialog
          open={confirmDialog}
          onClose={() => setConfirmDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Você tem certeza?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonComponent
              onClick={onCloseDialog}
              color="primary"
              disabled={loading}
            >
              Não
            </ButtonComponent>
            <ButtonComponent
              onClick={handleConfirmModal}
              color="primary"
              autoFocus
              loading={loading}
              loadingSize="20px"
            >
              Sim
            </ButtonComponent>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default TableDealComponent;
