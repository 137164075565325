import React from 'react';

import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Props = {
  data: Array<string | number>;
};

const SchedulesExport = ({ data }: Props) => {
  function handleFilterSchedulesData(data) {
    const filteredData: any = [];
    data.forEach((item) => {
      const newData = {
        creditor_company_name: item.creditor_company_name,
        partnership: item.partnership,
        merchant_company_name: item.merchant_company_name,
        merchant_document_number: item.merchant_document_number,
        created_at: moment(item.created_at).format('DD/MM/YYYY'),
        execution_begin_date: moment(item.execution_begin_date).format(
          'DD/MM/YYYY'
        ),
        execution_end_date: moment(item.execution_end_date).format(
          'DD/MM/YYYY'
        ),
        status: item.status,
      };
      filteredData.push(newData);
    });
    return filteredData;
  }
  return (
    <ExcelFile
      filename={'Agendas'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleFilterSchedulesData(data)} name="Agendas">
        <ExcelColumn label="NOME DO FORNECEDOR" value="creditor_company_name" />
        <ExcelColumn label="PARCEIRO" value="partnership" />
        <ExcelColumn
          label="NOME DO ESTABELECIMENTO"
          value="merchant_company_name"
        />
        <ExcelColumn label="CNPJ" value="merchant_document_number" />
        <ExcelColumn label="DATA CONSULTA" value="created_at" />
        <ExcelColumn
          label="DATA EXECUÇÃO INICIAL"
          value="execution_begin_date"
        />
        <ExcelColumn label="DATA EXECUÇÃO FINAL" value="execution_end_date" />
        <ExcelColumn label="STATUS" value="status" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default SchedulesExport;
