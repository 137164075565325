import flow from 'lodash/flow';
import get from 'lodash/get';

import fetch from '@payhop/shared-utils/fetch.util';

const context = `private/creditors/access/user`;
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
  error?: any;
};

export const Types = {
  INIT: `${context}/INIT`,
  SUCCESS: `${context}/SUCCESS`,
  RESET: `${context}/RESET`,
  RESET_NOTIFICATION: `${context}/RESET/NOTIFICATION`,
  ERROR: `${context}/ERROR`,
};

const initialState: any = {
  data: null,
  loading: false,
  error: null,
  fetched: false,
  notification: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload } = action;

  const reducers = {
    [Types.RESET]: {
      ...initialState,
    },
    [Types.INIT]: {
      ...state,
      error: null,
      notification: null,
      loading: true,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
      error: false,
      data: payload,
      fetched: true,
      notification: true,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error: true,
      data: payload,
      notification: true,
    },
    [Types.RESET_NOTIFICATION]: {
      ...state,
      notification: initialState.notification,
    },
  };

  return reducers[type] || state;
};

export const createDispatch =
  (data, idCreditor) => async (dispatch, getState) => {
    try {
      dispatch({
        type: Types.INIT,
      });

      const response = await fetch(`users`, {
        method: 'POST',
        ms: 'ACCOUNT',
        auth: true,
        body: JSON.stringify({
          ...data,
          id: idCreditor,
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw result;
      }

      const { account_id, user_id } = result;

      const responseSecondary = await fetch(`creditors/secundary-account`, {
        method: 'POST',
        ms: 'CREDITOR',
        auth: true,
        body: JSON.stringify({
          creditor_id: idCreditor,
          account_id,
        }),
      });

      dispatch({
        type: Types.SUCCESS,
        payload: {
          successAction: true,
        },
      });

      return response.ok;
    } catch (result: any) {
      dispatch({
        type: Types.ERROR,
        payload: {
          successAction: false,
          error: result?.errors?.message?.[0],
        },
      });

      return false;
    }
  };

export const resetDispatch = () => ({
  type: Types.RESET,
});

export const resetNotificationDispatch = () => ({
  type: Types.RESET_NOTIFICATION,
});

const selectRoot = (state: any) => get(state, rootPathState);
const selectData = (state: any) => get(state, 'data');
const selectLoading = (state: any) => get(state, 'loading');
const selectFetched = (state: any) => get(state, 'fetched');
const selectError = (state: any) => get(state, 'error');
const selectNotification = (state: any) => get(state, 'notification');

export const Selectors = {
  data: flow(selectRoot, selectData),
  loading: flow(selectRoot, selectLoading),
  fetched: flow(selectRoot, selectFetched),
  error: flow(selectRoot, selectError),
  notification: flow(selectRoot, selectNotification),
};

export const Dispatchs = {
  reset: resetDispatch,
  create: createDispatch,
  reset_notification: resetNotificationDispatch,
};
