import { Dispatch } from 'redux';
import qs from 'qs';
import flow from 'lodash/flow';
import get from 'lodash/get';

import { setError } from '@payhop/shared-ducks/error.duck';

import fetch from '@payhop/shared-utils/fetch.util';

import { Selectors as SelectorsCreditor } from '../creditor.duck';

const context = `private/creditors/parameters/list`;
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
  error?: any;
  filters?: any;
};

export const Types = {
  INIT: `${context}/INIT`,
  SUCCESS: `${context}/SUCCESS`,
  UPDATE_FILTERS: `${context}/UPDATE_FILTERS`,
  RESET: `${context}/RESET`,
  RESET_FILTERS: `${context}/RESET_FILTERS`,
  ERROR: `${context}/ERROR`,
};

const initialFilters = {
  offset: 0,
  limit: 10,
  name: undefined,
};

const initialState: any = {
  data: null,
  loading: false,
  error: null,
  filters: initialFilters,
  fetched: false,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, filters } = action;

  const reducers = {
    [Types.RESET]: {
      ...initialState,
    },
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
      error: false,
      data: payload,
      fetched: true,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error: true,
    },
    [Types.UPDATE_FILTERS]: {
      ...state,
      filters: Object.assign({}, state.filters, filters),
    },
    [Types.RESET_FILTERS]: {
      ...state,
      filters: initialFilters,
    },
  };

  return reducers[type] || state;
};

export const getDispatch = () => async (dispatch: Dispatch<any>, getState) => {
  const idCreditor = flow(getState, SelectorsCreditor.data)()?.id;
  const filters = get(getState(), `${rootPathState}.filters`);

  const filtersSerialize = qs.stringify(filters);

  const url = [
    `creditors/${idCreditor}/pricing`,
    filtersSerialize && `?${filtersSerialize}`,
  ].join('');

  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(url, {
      method: 'GET',
      ms: 'CREDITOR',
      auth: true,
    });

    const result = await response.json();

    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: result,
    });

    return result;
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
    });

    dispatch(
      setError({
        messages: result?.errors?.message,
        data: result,
        visible: false,
        context,
      })
    );

    return false;
  }
};

export const deleteDispatch = (id) => async (dispatch: Dispatch<any>) => {
  try {
    const response = await fetch(`merchants/${id}`, {
      method: 'DELETE',
      ms: 'MERCHANT',
      auth: true,
    });

    const result = await response.json();

    if (!response.ok) {
      throw result;
    }

    return result;
  } catch (result: any) {
    dispatch(
      setError({
        messages: result?.errors?.message,
        data: result,
        visible: false,
        context,
      })
    );

    return false;
  }
};

export const updateFiltersDispatch = (filters) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: Types.UPDATE_FILTERS,
    filters,
  });

  return dispatch(getDispatch());
};

export const resetFiltersDispatch = () => ({
  type: Types.RESET_FILTERS,
});

export const resetDispatch = () => ({
  type: Types.RESET,
});

const selectRoot = (state: any) => get(state, rootPathState);
const selectData = (state: any) => get(state, 'data');
const selectDataResults = (state: any) => get(state, 'results');
const selectDataMetadata = (state: any) => get(state, 'metadata');
const selectDataMetadataResultset = (state: any) => get(state, 'resultset');
const selectLoading = (state: any) => get(state, 'loading');
const selectFilters = (state: any) => get(state, 'filters');
const selectFetched = (state: any) => get(state, 'fetched');

export const Selectors = {
  data: flow(selectRoot, selectData),
  data_results: flow(selectRoot, selectData, selectDataResults),
  loading: flow(selectRoot, selectLoading),
  filters: flow(selectRoot, selectFilters),
  fetched: flow(selectRoot, selectFetched),
  resultset: flow(
    selectRoot,
    selectData,
    selectDataMetadata,
    selectDataMetadataResultset
  ),
};

export const Dispatchs = {
  get: getDispatch,
  delete: deleteDispatch,
  updateFilters: updateFiltersDispatch,
  resetFilters: resetFiltersDispatch,
  reset: resetDispatch,
};
