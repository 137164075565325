import { Fragment, useRef, useState, useContext } from 'react';
import { css } from '@emotion/react';
import {
  Grid,
  Drawer,
  IconButton,
  Typography,
  Divider,
  CircularProgress,
  Box,
} from '@material-ui/core';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

import ButtonPrimary from '@payhop/shared-components/Button/ButtonPrimary';

import ModalFullScreen from '@payhop/shared-components/Modal/ModalFullScreen';

import { newBrand } from '@payhop/shared-configs/styles.config';
import { Link, useHistory } from 'react-router-dom';
import ROUTES from '@backoffice/shared/utils/routes.util';
import TypographyComponent from '@backoffice/shared/components/Typography.component';

const drawerContainerCss = css({
  width: 300,
  padding: 24,
  position: 'relative',
  overflowY: 'scroll',
  height: '100%',
});

const drawerIconClose = css({
  position: 'absolute',
  top: 0,
  right: 0,
});

const testeCss = css({
  overflowY: 'scroll',
});

const NotificationDrawerComponent: React.FC<any> = ({
  onClose,
  visible,
  results,
  loadingNotification,
  resultset,
  handleGetNotification,
}) => {
  const history = useHistory();
  const [modal, setModal] = useState<any>({
    visible: false,
    notification: null,
  });

  const handleOpenModal = (notification: any) =>
    setModal({
      notification,
      visible: true,
    });

  const handleCloseModal = () =>
    setModal({
      notification: null,
      visible: false,
    });

  const containerButtonMore: any = useRef(null);
  const { offset, limit, count } = resultset || {};

  const currentPage = (offset + limit) / limit;

  const morePages = currentPage < Math.ceil(count / limit);

  const inifinityScroll = () => {
    if (!containerButtonMore.current) {
      return null;
    }

    const rect = containerButtonMore.current.getBoundingClientRect();

    const getNewsNotificationByScroll =
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) + 200 &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth);

    if (getNewsNotificationByScroll && !loadingNotification) {
      handleGetNotification({
        offset: currentPage * limit,
        limit,
      });
    }

    return null;
  };

  return (
    <Fragment>
      <div>
        <Drawer
          onScroll={inifinityScroll}
          anchor="right"
          open={visible}
          onClose={onClose}
        >
          <div onScroll={inifinityScroll} css={drawerContainerCss}>
            <div css={drawerIconClose}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <Grid container alignItems="stretch" direction="column" spacing={2}>
              {results.map((r: any) => (
                <Fragment key={r.notification.id}>
                  <Grid item container spacing={1}>
                    <Grid item xs="auto">
                      {r.status === 1 ? (
                        <NotificationsIcon />
                      ) : (
                        <NotificationsNoneIcon />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs
                      container
                      alignItems="stretch"
                      direction="column"
                    >
                      <Grid item>
                        <Typography
                          css={{
                            fontSize: 18,
                            fontWeight: 'bold',
                          }}
                        >
                          {r.notification.title}
                        </Typography>
                      </Grid>
                      <Grid item css={{ marginTop: 2 }}>
                        <Typography
                          css={{ fontSize: 14, color: newBrand.gray }}
                        >
                          {r.notification.description}
                        </Typography>
                      </Grid>
                      <Grid item css={{ marginTop: 4 }}>
                        <Typography css={{ fontSize: 12 }}>
                          {moment(r.notification.created_at).calendar()}
                        </Typography>
                      </Grid>

                      <Grid item css={{ marginTop: 8 }}>
                        <Typography
                          onClick={() => handleOpenModal(r)}
                          css={{
                            fontSize: 12,
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                        >
                          Mais informações
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>
                </Fragment>
              ))}

              {morePages && (
                <Grid item ref={containerButtonMore}>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </Drawer>
      </div>

      <ModalFullScreen
        fullScreen={false}
        visible={modal.visible}
        onClose={handleCloseModal}
        title={modal?.notification?.notification?.description}
        css={{
          '.MuiDialog-paper': {
            overflowY: 'unset',
          },
        }}
      >
        {modal?.notification?.notification?.type === 1 && (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div
                  css={{
                    p: {
                      marginBottom: 20,
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      modal?.notification?.notification?.metadata
                        ?.notification_info,
                  }}
                ></div>
              </Grid>
              {modal?.notification?.notification?.metadata
                ?.show_deal_info_button && (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={'100%'}
                    margin="0 auto"
                  >
                    <ButtonPrimary
                      onClick={() => {
                        handleCloseModal();

                        onClose();
                        history.push(
                          `appointments/edit?id=${modal?.notification?.notification?.metadata?.id}`
                        );
                      }}
                    >
                      Detalhes do compromisso
                    </ButtonPrimary>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        {modal?.notification?.notification?.type !== 1 && (
          <Grid item xs={12}>
            <div
              css={{
                p: {
                  marginBottom: 20,
                },
              }}
              dangerouslySetInnerHTML={{
                __html:
                  modal?.notification?.notification?.metadata
                    ?.notification_info,
              }}
            />
          </Grid>
        )}
        {modal?.notification?.notification?.type == 3 && (
          <div>
            <p>
              A solicitação de consulta de agenda foi processada com sucesso!
              Para saber mais detalhes,
              <TypographyComponent
                customColor="secondary-darker"
                css={{
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  history.push(
                    ROUTES.PRIVATE.SCHEDULES.EDIT(
                      modal?.notification?.notification?.metadata?.id
                    )
                  );
                  handleCloseModal();
                }}
              >
                {' '}
                clique aqui.
              </TypographyComponent>
            </p>
          </div>
        )}
      </ModalFullScreen>
    </Fragment>
  );
};

export default NotificationDrawerComponent;
