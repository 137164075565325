import fetch from '@payhop/shared-utils/fetch.util';

export default async function getMerchantNew(
  documentNumber: string,
  creditor_document?: string
) {
  const response = await fetch(
    `merchants/status?document=${documentNumber}&creditor_document=${creditor_document}`,
    {
      method: 'GET',
      ms: 'MERCHANT',
      auth: true,
    }
  );

  const result = await response.json();

  return result;
}
