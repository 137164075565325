import { Route, Switch, Redirect } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import ImportSmokeAppintmentsScreen from './screens/ImportSmokeAppointments.screen';

const ImportSmokeAppointmentsModule: React.FC<any> = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.PRIVATE.IMPORT_SMOKE_APPOINTMENTS.ROOT}
      component={ImportSmokeAppintmentsScreen}
    />

    <Redirect to={ROUTES.PRIVATE.HOME} />
  </Switch>
);

export default ImportSmokeAppointmentsModule;
