import fetch from '@payhop/shared-utils/fetch.util';

type filters = {
  name?: string;
  document?: string;
  creditor_id?: string;
  id?: string;
};

export default async function getExportReportService(
  account_id: string,
  filters: filters
) {
  delete filters.id;

  const queryString = Object.keys(filters)
    .map((key) => {
      if (filters[key] !== '' && filters[key]) {
        return `${key}=${filters[key]}`;
      }
    })
    .filter((item) => item !== undefined)
    .join('&');

  await fetch(
    `deals/receivable-reached?account_id=${account_id}&${queryString}`,
    {
      method: 'GET',
      ms: 'EXPORT',
      auth: true,
    }
  );

  return true;
}
