import React, { useState, useEffect, useMemo } from 'react';
import { flow } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import * as dialogCreditorCreateDomicile from '@backoffice/private/ducks/creditors/dialogs/dialogCreditorCreateDomicile.duck';
import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/originators/creditorOriginators.duck';

import {
  Dispatchs as DispatchsOriginators,
  Selectors as SelectorsOriginators,
} from '@backoffice/private/ducks/creditors/originators/listCreditorOriginators.duck';

import {
  Dialog,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FormCreditorDomicile from '../Form/CreditorDomicile/FormCreditorDomicile.component';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

type DialogCreateDomicileProps = {
  creditorId?: string;
};

const DialogCreateDomicile = ({ creditorId }: DialogCreateDomicileProps) => {
  const handleNotification = useNotification();
  const [originatorObject, setOriginatorObject] = useState([]);
  const dispatch = useDispatch();

  const dispatchRedux = {
    CLOSE: flow(dialogCreditorCreateDomicile.Dispatchs.close, dispatch),
  };

  const dispatchListOriginators = {
    GET: flow(DispatchsOriginators.get, dispatch),
  };

  const dispatchReduxOriginators = {
    CREATE: flow(Dispatchs.create, dispatch),
  };

  const selectorRedux = {
    visible: useSelector(dialogCreditorCreateDomicile.Selectors.visible),
    payload: useSelector(dialogCreditorCreateDomicile.Selectors.payload),
  };

  useEffect(() => {
    if (selectorRedux.payload) {
      setOriginatorObject(selectorRedux.payload);
    }
  }, [selectorRedux.payload]);

  const handleCreateDomicile = (data, i) => {
    let teste: any = originatorObject;

    delete teste.status;
    delete teste.id;
    delete teste.data_cadastro;
    delete teste.data_atualizacao;

    teste = { originadores: [{ ...teste, domicilios: [data] }] };

    fetchData(teste);
  };

  const fetchData = async (obj) => {
    try {
      const response: any = await dispatchReduxOriginators.CREATE(
        obj,
        creditorId
      );
      if (response[0].domicilios[0].status == 'Erro') {
        handleNotification(response[0].domicilios[0].mensagem, 'error');
      } else {
        handleNotification(
          'Domicílio bancário cadastrado com sucesso!',
          'success'
        );
      }
      dispatchRedux.CLOSE(dispatchListOriginators.GET);
      // Faça o que precisa com a resposta
    } catch (error) {
      handleNotification(`Erro ao cadastrar domicílio: ${error}`, 'error');
      console.error('Erro na requisição:', error);
    }
  };

  return (
    <Dialog
      open={selectorRedux.visible}
      onClose={() => dispatchRedux.CLOSE(dispatchListOriginators.GET)}
      aria-labelledby="form-dialog-title"
      maxWidth={'lg'}
      fullWidth={true}
    >
      <DialogTitle id="customized-dialog-title">
        Cadastrar domicílio
        <IconButton
          css={{ position: 'absolute', right: 0, top: '5px' }}
          aria-label="close"
          onClick={() => dispatchRedux.CLOSE(dispatchListOriginators.GET)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormCreditorDomicile
          handleSubmitForm={handleCreateDomicile}
          handleResetFormSubmitted={() => {}}
          data={[]}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DialogCreateDomicile;
