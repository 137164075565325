import React, { useState } from 'react';

import { Tooltip } from '@material-ui/core';

const CopyToClipboard = ({ dataToCopy }) => {
  const [tooltipText, setTooltipText] = useState<string>('Clique para copiar');

  const handleCopyText = (id) => {
    navigator.clipboard.writeText(id);
    setTooltipText('Copiado!');
  };

  return (
    <Tooltip title={tooltipText} arrow>
      <p
        onClick={() => {
          handleCopyText(dataToCopy);
        }}
        onMouseLeave={() => setTooltipText('Clique para copiar')}
        css={{ cursor: 'pointer' }}
      >
        {dataToCopy}
      </p>
    </Tooltip>
  );
};

export default CopyToClipboard;
