import React, { useState, useEffect } from 'react';
import { flow } from 'lodash';
import { Box, Typography, Card, TextField } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import CardTab from '@backoffice/private/components/Card/CardTab';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import TableDealDetailComponent from '../components/TableDealDetail.component';
import TableDealInstamentComponent from '../components/TableDealInstallment.component';
import TableDealHistoryComponent from '../components/TableDealHistoryComponent.component';

import * as dealDuck from '@backoffice/private/ducks/deals/deal.duck';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import BankData from '../components/Tab/BankData/BankData.component';
import {
  TRANSACTION_LABELS,
  TRANSACTION_VALUES,
} from '@backoffice/private/constants/contract/transaction.constant';
import {
  CONTRACT_TYPES,
  CONTRACT_TYPES_LABELS,
} from '@backoffice/private/constants/contract/type.constant';
import { MODALITY_LABELS } from '@backoffice/private/constants/contract/modality.constant';
import DebtAknowledgment from '../components/Tab/DebtAcknowledgment/DebtAcknowledgment.component';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

const ViewAppointmentsScreen = () => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_compromisso_dados_bancarios',
  });
  const { isAdmin } = useUserHook();

  const { id } = useIsEdit();

  const dispatch = useDispatch();

  const [tabActive, setTabActive] = useState(0);
  const [installment, setInstallment] = useState(0);
  const [totalAmountCommitted, setTotalAmountCommitted] = useState(0);
  const [totalAmountEffect, setTotalAmountEffect] = useState(0);
  const [haveDomicile, setHaveDomicile] = useState(false);

  const dispatchRedux = {
    GET: flow(dealDuck.Dispatchs.get, dispatch),
  };

  const selectorRedux = {
    data: useSelector(dealDuck.Selectors.data),
    loading: useSelector(dealDuck.Selectors.loading),
  };

  const handleChangeDeal = () => {
    dispatchRedux.GET(id);
  };

  useEffect(() => {
    setTotalAmountCommitted(0);
    setTotalAmountEffect(0);
    dispatchRedux.GET(id);
  }, []);

  useEffect(() => {
    if (selectorRedux.data) {
      let valueEffect = 0;
      let valueCommitted = 0;
      selectorRedux?.data?.installments.forEach((item) => {
        valueEffect += item.amount_total_effect;
        valueCommitted += item.amount_total_committed;
      });

      setTotalAmountCommitted(valueCommitted);
      setTotalAmountEffect(valueEffect);
      setHaveDomicile(selectorRedux.data.bank_account ? true : false);
    }
  }, [selectorRedux.data]);

  const objs = [
    {
      label: 'Título',
      value: selectorRedux?.data?.title,
    },
    {
      label: 'Descrição',
      value: selectorRedux?.data?.description,
    },
    {
      label: 'Fornecedor',
      value: selectorRedux?.data?.creditor?.company_name,
      grid: undefined,
    },
    {
      label: 'Estabelecimento',
      value: selectorRedux?.data?.merchant?.company_name,
      grid: undefined,
    },
    {
      label: 'Emissão',
      value: moment(selectorRedux?.data?.issue_date).format('DD/MM/YYYY'),
      grid: undefined,
    },
    {
      label: 'Tipo de Transação',
      value: TRANSACTION_LABELS[selectorRedux?.data?.type],
      grid: undefined,
    },
    {
      label: 'Tipo de contrato',
      value: CONTRACT_TYPES_LABELS[selectorRedux?.data?.contract_type],
      grid: undefined,
    },
    {
      label: 'Modalidade',
      value: MODALITY_LABELS[selectorRedux?.data?.strategy],
      grid: undefined,
    },
    {
      label: 'Arranjos',
      value:
        selectorRedux?.data?.arrangements.length > 0
          ? selectorRedux?.data?.arrangements.join(',')
          : '-',
      grid: undefined,
    },
    {
      label: 'Credenciadoras',
      value:
        selectorRedux?.data?.acquirers_name.length > 0
          ? selectorRedux?.data?.acquirers_name.join(',')
          : '-',
      grid: undefined,
    },
    {
      label: 'Gestão de colateral',
      value: selectorRedux?.data?.gcap ? 'Sim' : 'Não',
      grid: undefined,
    },
  ];

  const isDealIF =
    selectorRedux.data?.creditor?.creditor_parameters[0]?.parameter_value ==
    'Fornecedor';

  const isDealCharge = selectorRedux.data?.type == '4';

  const tabs = {
    detalhes_do_compromisso: {
      label: 'Detalhes do Compromisso',
      show: true,
    },
    lista_de_recebiveis: {
      label: 'Lista de Recebíveis',
      show: true,
    },
    dados_bancarios: {
      label: 'Dados Bancários',
      show: haveDomicile && permissions?.some((item) => item == 'read'),
    },
    confissao_divida: {
      label: 'Confissão de dívida',
      show: isDealIF && isDealCharge,
    },
  };

  // Filtrando os elementos com a propriedade 'show' igual a true
  const filteredTabs = {};
  Object.entries(tabs).forEach(([key, value]) => {
    if (value.show === true) {
      filteredTabs[key] = value;
    }
  });

  const tabsFormatted: any = Object.keys(filteredTabs).reduce(
    (acc, curr, index) => ({
      ...acc,
      [curr]: {
        label: filteredTabs[curr].label,
        name: curr,
        index,
      },
    }),
    {}
  );

  const tabsLabelsArr = Object.keys(tabsFormatted).map(
    (val) => tabsFormatted[val].label
  );

  const newTabsLabelArr = isAdmin ? tabsLabelsArr : tabsLabelsArr.slice(0, 3);

  return (
    <PageLayout headerTitle header sidebar title={'Compromisso'} ctaBack>
      <Box display="flex" gridGap={24} flexDirection="column">
        <CardTab
          onChange={(_, value) => setTabActive(value)}
          tabs={newTabsLabelArr}
          tabActive={tabActive}
        />
        {tabActive === tabsFormatted?.detalhes_do_compromisso?.index && (
          <Card>
            <Box display="grid" gridTemplateColumns="100%" p={3} gridGap={64}>
              <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={16}>
                {objs.map((o, index) => (
                  <Box
                    gridColumn={o.grid}
                    display="flex"
                    flexDirection="column"
                    key={`deal-${index}`}
                  >
                    <Typography css={{ fontWeight: 600 }}>{o.label}</Typography>
                    {selectorRedux.loading && (
                      <Skeleton variant="text" width={200} height={24} />
                    )}

                    {!selectorRedux.loading && (
                      <Typography>{o.value}</Typography>
                    )}
                  </Box>
                ))}
              </Box>
              <Box>
                <TableDealDetailComponent
                  results={selectorRedux?.data?.installments || []}
                  loading={selectorRedux.loading}
                />
              </Box>
              <Box>
                <Typography
                  css={{ fontSize: 16, color: '#0D1421', fontWeight: 600 }}
                >
                  Histórico
                </Typography>
                <TableDealHistoryComponent
                  deal={selectorRedux?.data?.id}
                  loading={selectorRedux.loading}
                />
              </Box>
            </Box>
          </Card>
        )}

        {tabActive === tabsFormatted?.lista_de_recebiveis?.index && (
          <Card>
            <Box p={3} display="grid" gridTemplateColumns="1fr" gridGap={16}>
              <TextField
                select
                SelectProps={{ native: true }}
                label="Parcela"
                variant="outlined"
                defaultValue={installment}
                onChange={({ target: { value } }) =>
                  setInstallment(Number(value))
                }
              >
                {selectorRedux.data?.installments.map((i, index) => (
                  <option value={index} key={`installment-${index}`}>
                    {i.title}
                  </option>
                ))}
              </TextField>
            </Box>

            <Box mt={2} p={3}>
              <TableDealInstamentComponent
                loading={selectorRedux.loading}
                results={
                  selectorRedux.data?.installments[installment]?.receivables ||
                  []
                }
              />
            </Box>
          </Card>
        )}

        {tabActive === tabsFormatted?.dados_bancarios?.index && (
          <BankData
            dealData={selectorRedux?.data}
            handleChangeDeal={handleChangeDeal}
          />
        )}

        {tabActive === tabsFormatted?.confissao_divida?.index && (
          <DebtAknowledgment dealData={selectorRedux?.data} />
        )}
      </Box>
    </PageLayout>
  );
};

export default ViewAppointmentsScreen;
