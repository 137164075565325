const CONSULTA_AGENDA = 'consulta_agenda';
const TIPO_TRANSACAO = 'tipo_transacao';
const TIPO_CONTRATO = 'tipo_contrato';
const CONTA_LIQUIDACAO_URS = 'conta_liquidacao_urs';
const QUANTIDADE_PARCELAS_COMPROMISSO = 'quantidade_parcelas_compromisso';
const PERFIL_PARCEIRO = 'perfil_parceiro';
const GCAP = 'gcap';

export const PARAMETERS = {
  CONSULTA_AGENDA,
  CONTA_LIQUIDACAO_URS,
  QUANTIDADE_PARCELAS_COMPROMISSO,
  PERFIL_PARCEIRO,
  TIPO_TRANSACAO,
  TIPO_CONTRATO,
  GCAP,
};
