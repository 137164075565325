const TODOS = 'todos';
const TROCA_TITULARIDADE = 'troca_titularidade';
const CESSAO = 'cessao';

const TYPE_TODOS = [
  {
    label: 'Troca de Titularidade',
    value: '1',
  },
  {
    label: 'Cessão',
    value: '2',
  },
];

const TYPE_TROCA_TITULARIDADE = [
  {
    label: 'Troca de Titularidade',
    value: '1',
  },
];

const TYPE_CESSAO = [
  {
    label: 'Cessão',
    value: '2',
  },
];

export const TYPE_WITH_ALL = [
  {
    label: 'Todos',
    value: '0',
  },
  {
    label: 'Troca de Titularidade',
    value: '1',
  },
  {
    label: 'Cessão',
    value: '2',
  },
];

export const CONTRACT_TYPES = {
  [TODOS]: TYPE_TODOS,
  [TROCA_TITULARIDADE]: TYPE_TROCA_TITULARIDADE,
  [CESSAO]: TYPE_CESSAO,
};

export const CONTRACT_TYPES_VALUES = {
  [TROCA_TITULARIDADE]: '1',
  [CESSAO]: '2',
};

export const CONTRACT_TYPES_LABELS = {
  [1]: 'Troca de Titularidade',
  [2]: 'Cessão',
};
