import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useDropzone } from 'react-dropzone';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Button from '../../../shared/components/Button.component';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import { Field } from 'formik';

const messages = {
  title: 'Arraste para cá',
  subTitle: 'Ou se preferir',
  buttonText: 'Clique para selecionar',
};

type UploadProps = any;

const FieldUploadImage: React.FC<UploadProps> = ({
  onDrop: onDropProp,
  dropzoneProps,
  buttonProps,
  name,
  labelTitle,
  hasImage,
  ...restProps
}) => {
  const Component = ({ field, form: { setFieldValue } }: any) => {
    const dropzone = useDropzone({
      onDrop: onDropProp,
      ...dropzoneProps,
      accept: 'image/jpeg,image/png,image/jpeg',
    });

    const handleNotification = useNotification();

    const [file, setFile] = useState('');

    useEffect(() => {
      if (dropzone.acceptedFiles.length > 0) {
        handleFiles(dropzone.acceptedFiles[0]);
        setFieldValue(field.name, dropzone.acceptedFiles[0]);
      }
    }, [dropzone.acceptedFiles]);

    useEffect(() => {
      if (dropzone.fileRejections.length > 0) {
        handleNotification(
          'Arquivo inválido. Arquivos suportados: .png, .jpg, .jpeg',
          'error'
        );
      }
    }, [dropzone.fileRejections]);

    const handleFiles = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
          const base64 = reader.result;
          setFile(base64 as string);
        };
        reader.onerror = (error) => reject(error);
      });

    return (
      <>
        {restProps.variant == 'minify' ? (
          <Box
            {...dropzone.getRootProps()}
            display="flex"
            border="1px solid #A8A8A8"
            justifyContent={'center'}
            borderRadius={4}
            css={{ cursor: 'pointer' }}
            height={55}
            {...restProps}
          >
            {(!file || !hasImage) && (
              <Box
                height={55}
                display="flex"
                alignItems="center"
                justifyContent="center"
                gridColumnGap={12}
              >
                <Typography css={{ color: '#A8A8A8' }} align="center">
                  LOGO
                </Typography>
                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={0.5}
                  >
                    <CloudUploadIcon
                      css={{ width: 30, height: 40, color: '#A8A8A8' }}
                    />
                  </Box>
                </Box>
              </Box>
            )}

            {file && hasImage && (
              <Box
                height={55}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography css={{ color: '#A8A8A8' }} align="center">
                  UPLOAD COMPLETO
                </Typography>
              </Box>
            )}

            <input
              {...dropzone.getInputProps()}
              type="file"
              style={{ display: 'none' }}
            />
          </Box>
        ) : (
          <>
            <p>{labelTitle}</p>
            <Box
              {...dropzone.getRootProps()}
              display="flex"
              flexDirection="column"
              border="1px solid #A8A8A8"
              borderRadius={4}
              padding={3}
              gridRowGap={24}
              {...restProps}
            >
              {!file && !hasImage && (
                <Box
                  height={200}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box display="flex" flexDirection="column">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mb={0.5}
                    >
                      <CloudUploadIcon
                        css={{ width: 40, height: 40, color: '#A8A8A8' }}
                      />
                    </Box>
                    <Typography css={{ color: '#A8A8A8' }} align="center">
                      {messages.title}
                    </Typography>
                    <Typography
                      css={{ color: '#A8A8A8' }}
                      align="center"
                      variant="caption"
                    >
                      {messages.subTitle}
                    </Typography>
                  </Box>
                  <Button css={{ color: '#A8A8A8' }} {...(buttonProps as any)}>
                    {messages.buttonText}
                  </Button>
                </Box>
              )}

              {file && (
                <img
                  css={{
                    maxWidth: '100%',
                    height: '200px',
                    objectFit: 'contain',
                  }}
                  src={file}
                ></img>
              )}
              {!file && hasImage && (
                <img
                  css={{
                    maxWidth: '100%',
                    height: '200px',
                    objectFit: 'contain',
                  }}
                  src={hasImage}
                ></img>
              )}

              <input
                {...dropzone.getInputProps()}
                type="file"
                style={{ display: 'none' }}
              />
            </Box>
          </>
        )}
      </>
    );
  };

  return <Field name={name}>{Component}</Field>;
};

export default FieldUploadImage;
