import React from 'react';
import flow from 'lodash/flow';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

import { Box } from '@material-ui/core';

import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/merchants/partners/listMerchantPartners.duck';

import { Selectors as SelectorMerchant } from '@backoffice/private/ducks/merchants/merchant.duck';

const TableMerchantPartnerExternalContainer = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };
  const selectorMerchantRedux = {
    DATA: useSelector(SelectorMerchant.data),
  };

  const { handleChangePage, handleChangeRowsPerPage, handleDelete } =
    useCrudRead({
      dispatchRedux,
      selectorRedux,
    });

  return (
    <TableMerchantPartnerExternalComponent
      loading={selectorRedux.LOADING}
      results={selectorMerchantRedux.DATA?.partners_external_data}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
      handleDelete={handleDelete}
    />
  );
};

const TableMerchantPartnerExternalComponent = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  handleDelete,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_estabelecimento_dados_socios',
  });

  const { limit, count, offset } = filters || {};

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Cargo',
      dataIndex: 'qualification',
      key: 'qualification',
    },
    {
      title: 'Documento',
      dataIndex: 'cpf',
      key: 'cpf',
      render: ({ cpf }) =>
        cpf ? (
          <NumberFormat
            displayType="text"
            value={cpf}
            isNumericString
            format="###.###.###-##"
          />
        ) : (
          '-'
        ),
    },
  ];

  return (
    <>
      {permissions?.some((item) => item == 'read') && (
        <Box gridGap={8} display="flex" flexDirection="column">
          <CardTitle>Dados Consulta externa</CardTitle>

          <CardTable
            count={count || 0}
            loading={loading}
            dataSource={results}
            rowsPerPage={limit}
            columns={columns as any}
            page={offset / limit}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      )}
      {(!permissions || !permissions?.some((item) => item == 'read')) && (
        <ModuleNotPermited />
      )}
    </>
  );
};

export default TableMerchantPartnerExternalContainer;
