import React, { useState, useEffect } from 'react';
import { Box, Card, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import formatCNPJ from '@payhop/shared-utils/formatCNPJ.util';

import * as contract from '@backoffice/private/ducks/contracts/contract.duck';

import Typography from '@backoffice/shared/components/Typography.component';
import TableReceivablesComponent from './TableReceivables.component';
import ExportXLS from '../../../components/Export/Export.component';

const ViewAppointmentsScreen = () => {
  const dispatch = useDispatch();

  const [filteredReceivables, setFilteredReceivables] = useState([]);
  const [exportData, setExportData] = useState({} as any);

  const selectorRedux = {
    data: useSelector(contract.Selectors.data),
    loading: useSelector(contract.Selectors.loading),
  };

  useEffect(() => {
    handleReceivables(
      selectorRedux.data?.receivables,
      selectorRedux.data?.receivables_pending
    );
  }, [selectorRedux.data]);

  const handleReceivables = (receivables, receivables_pending) => {
    setFilteredReceivables([]);
    const newReceivables = receivables;
    const newReceivablesFiltered: any = [];

    if (receivables_pending?.length > 0) {
      receivables_pending.forEach((item) => {
        const newObj = {
          ...item,
          receivable_type: 'pending',
        };

        newReceivables.push(newObj);
      });
    }

    if (newReceivables?.length > 0) {
      newReceivables.forEach((item) => {
        const receivableItem = {
          external_reference: selectorRedux?.data?.external_reference,
          reference_id: selectorRedux?.data?.reference_id,
          wallet: 'CART-TBD',
          bank_account_document_holder:
            selectorRedux?.data?.bank_account.document_holder,
          bank_account_type: selectorRedux?.data?.bank_account.type,
          bank_account_ispb: selectorRedux?.data?.bank_account.isbp,
          bank_account_agency: selectorRedux?.data?.bank_account.agency,
          bank_account_account: selectorRedux?.data?.bank_account.number,
          document_acquirer: item.document_acquirer,
          arrangement_code: item.arrangement_code,
          due_date: item.due_date,
          total_constituted_value: item.amount_total,
          effect_constituted_value: item.amount_effect,
          division_rules: 'Valor Fixo',
          encumbrance_indicator: item.receivable_type == 'pending' ? '0' : '1',
          document_type_final_user_receiver:
            selectorRedux?.data?.requestor_document.length == 14 ? 'PJ' : 'PF',
          document_final_user_receiver: item.asset_holder_document,
          holder_document: item.asset_holder_document,
          constituition:
            item.receivable_type == 'pending' ? 'A constituir' : 'Constituida',
          amount_blocked: item.amount_blocked,
          amount_encumbrance: item.amount_total,
        };

        newReceivablesFiltered.push(receivableItem);
      });

      newReceivablesFiltered.sort(function (a, b) {
        return new Date(a.due_date).getTime() - new Date(b.due_date).getTime();
      });

      setFilteredReceivables(newReceivablesFiltered);
    }
  };

  const handleOnExport = async () => {
    setExportData(filteredReceivables);

    setTimeout(() => {
      const exportButton = document.querySelector(
        '.button-xls-export'
      ) as HTMLElement;

      if (exportButton) {
        exportButton.click();
      }
    });
  };

  const generalInfos = [
    {
      label: 'Referência Externa',
      value: selectorRedux?.data?.external_reference,
    },
    {
      label: 'Identificador Contrato',
      value: selectorRedux?.data?.reference_id,
    },
    {
      label: 'Saldo Devedor',
      value: Number(selectorRedux?.data?.amount).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Limite Operação',
      value: Number(selectorRedux?.data?.amount).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Valor Mantido',
      value: Number(selectorRedux?.data?.amount).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Data de vencimento',
      value: moment.utc(selectorRedux?.data?.due_date).format('DD/MM/YYYY'),
    },
    {
      label: 'Contratante',
      value: selectorRedux?.data?.asset_holder_document
        ? formatCNPJ(selectorRedux?.data?.asset_holder_document)
        : '-',
    },
    {
      label: 'Participante',
      value: '35.830.425/0001-02',
    },
    {
      label: 'Detentor',
      value: selectorRedux?.data?.requestor_document
        ? formatCNPJ(selectorRedux?.data?.requestor_document)
        : '-',
    },
  ];

  const domicilePayment = [
    {
      label: 'Titular',
      value: selectorRedux?.data?.bank_account?.document_holder
        ? formatCNPJ(selectorRedux?.data?.bank_account?.document_holder)
        : '-',
    },
    {
      label: 'Tipo de conta',
      value: selectorRedux?.data?.bank_account?.type,
    },
    {
      label: 'ISPB',
      value: selectorRedux?.data?.bank_account?.isbp,
    },
    {
      label: 'Agência',
      value: selectorRedux?.data?.bank_account?.agency,
    },
    {
      label: 'Conta',
      value: selectorRedux?.data?.bank_account?.number,
    },
  ];

  const receivables = [
    {
      label: 'Quantidade de URs alcançadas',
      value: filteredReceivables.length,
    },
    {
      label: 'Valor Constituído',
      value: Number(
        selectorRedux?.data?.receivables_amount_effect
      ).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
  ];

  return (
    <Box display="flex" gridGap={24} flexDirection="column">
      {selectorRedux.loading && (
        <Box
          display="flex"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          width="100%"
          height="100%"
          zIndex={9999}
          alignItems="center"
          justifyContent="center"
          bgcolor="#ffffffd1"
        >
          <CircularProgress />
        </Box>
      )}
      {selectorRedux.data !== null && !selectorRedux.loading && (
        <Card>
          <Box display="grid" gridTemplateColumns="100%" p={3} gridGap={20}>
            <Typography customVariant="text__body--lg" customColor="info-500">
              Informações Gerais
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              gridGap={16}
            >
              {generalInfos.map((o, index) => (
                <Box key={`contract-${index}`}>
                  <Typography css={{ fontWeight: 600 }}>{o.label}</Typography>
                  {selectorRedux.loading && (
                    <Skeleton variant="text" width={200} height={24} />
                  )}

                  {!selectorRedux.loading && <Typography>{o.value}</Typography>}
                </Box>
              ))}
            </Box>
          </Box>
          <Box display="grid" gridTemplateColumns="100%" p={3} gridGap={20}>
            <Typography customVariant="text__body--lg" customColor="info-500">
              Domicilio Pagamento
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              gridGap={16}
            >
              {domicilePayment.map((o, index) => (
                <Box key={`contract-${index}`}>
                  <Typography css={{ fontWeight: 600 }}>{o.label}</Typography>
                  {selectorRedux.loading && (
                    <Skeleton variant="text" width={200} height={24} />
                  )}

                  {!selectorRedux.loading && <Typography>{o.value}</Typography>}
                </Box>
              ))}
            </Box>
          </Box>
          <Box display="grid" gridTemplateColumns="100%" p={3} gridGap={20}>
            <Typography customVariant="text__body--lg" customColor="info-500">
              Garantias Alcançadas
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              gridGap={16}
            >
              {receivables.map((o, index) => (
                <Box key={`contract-${index}`}>
                  <Typography css={{ fontWeight: 600 }}>{o.label}</Typography>
                  {selectorRedux.loading && (
                    <Skeleton variant="text" width={200} height={24} />
                  )}

                  {!selectorRedux.loading && <Typography>{o.value}</Typography>}
                </Box>
              ))}
            </Box>

            <Box display="flex" justifyContent="space-between">
              <h2>Tabela de URs</h2>

              <Tooltip title="Baixar tabela">
                <IconButton onClick={handleOnExport} size="small">
                  <GetAppIcon css={{ width: 18, height: 18 }} />
                </IconButton>
              </Tooltip>
            </Box>
            <TableReceivablesComponent
              receivablesArray={filteredReceivables}
              loading={selectorRedux.loading}
            />
            {exportData.length > 0 && (
              <ExportXLS filename={'receivables'} data={exportData} />
            )}
          </Box>
        </Card>
      )}
    </Box>
  );
};

export default ViewAppointmentsScreen;
