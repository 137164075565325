import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';

import SearchScheduleComponent from '../components/SearchSchedule.component';
import TableScheduleComponent from '../components/TableSchedule.component';
import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';
import Loading from '@backoffice/private/components/Loading/Loading.component';

import ROUTES from '@backoffice/shared/utils/routes.util';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

const texts = {
  title: 'Agenda de recebíveis',
  buttonAdd: 'Nova consulta',
};

const SchedulesScreen = () => {
  const history = useHistory();
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_agenda_consulta_agenda',
  });

  return (
    <Fragment>
      <PageLayout
        headerTitle
        header
        sidebar
        title={texts.title}
        cta={
          <Button
            variant="outlined"
            color="primary"
            css={{ minWidth: 240 }}
            onClick={() => history.push(ROUTES.PRIVATE.SCHEDULES.CREATE)}
            disabled={!permissions?.some((item) => item == 'create')}
          >
            {texts.buttonAdd}
          </Button>
        }
      >
        {permissions?.some((item) => item == 'read') && (
          <Box display="flex" flexDirection="column" gridGap={32}>
            <Fragment>
              <SearchScheduleComponent />

              <TableScheduleComponent />
            </Fragment>
          </Box>
        )}
        {!permissions ||
          (!permissions?.some((item) => item == 'read') && (
            <ModuleNotPermited />
          ))}
      </PageLayout>
    </Fragment>
  );
};

export default SchedulesScreen;
