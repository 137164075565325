import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormikContext, Formik, Field } from 'formik';
import { flow, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import TextFieldMaterial from '@material-ui/core/TextField';
import { v4 as uuidv4 } from 'uuid';
import NumberFormat from 'react-number-format';

import validateCNPJ from '@payhop/shared-utils/validateCNPJ.util';

import CardForm from '@backoffice/private/components/Card/CardForm';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';
import useCrudNotification from '@backoffice/shared/hooks/useCrudNotification.hook';

import {
  Dispatchs as DispatchsAccounts,
  Selectors as SelectorsAccounts,
} from '@backoffice/private/ducks/accounts/account.duck';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/creditor.duck';

import ROUTES from '@backoffice/shared/utils/routes.util';

import STATES from '@backoffice/private/constants/estados.constant';

import getAddressByZip from '@backoffice/private/services/account/getAddressByZip.service';
import getDocument from '@backoffice/private/services/account/getDocument.service';
import getValidationDocument from '@backoffice/private/services/account/getValidationDocument.service';
import postAccountsSendTokenService from '@backoffice/private/services/account/postAccountsSendToken.service';

const validationSchema = Yup.object({
  origin_account: Yup.string().default('2'),
  type_account: Yup.string().default('2'),
  type_person: Yup.string().default('1'),
  document_number: Yup.string()
    .required()
    .default('')
    .test('document_number', 'CNPJ inválido', (value) => {
      const documentLength: any = !!value && String(value).length;

      if (documentLength < 14 || !validateCNPJ(value)) {
        return false;
      }

      return true;
    }),
  trading_name: Yup.string().required().default(''),
  company_name: Yup.string().required().default(''),
  contact_document_number: Yup.string().notRequired(),
  address_zip_code: Yup.string().required().default(''),
  address: Yup.string().required().default(''),
  address_number: Yup.string().required().default(''),
  address_complement: Yup.string().notRequired(),
  address_neighborhood: Yup.string().required().default(''),
  address_city: Yup.string().required().default(''),
  address_state: Yup.string().required().default(''),
  active: Yup.string().required().default('true'),
});

const validationCreateSchema = Yup.object({
  contact_name: Yup.string().required().default(''),
  contact_email: Yup.string().required().default(''),
  phone_number: Yup.string().required().default(''),
}).concat(validationSchema);

const validationUpdateSchema = Yup.object({
  contact_name: Yup.string().notRequired().default(''),
  contact_email: Yup.string().notRequired().default(''),
  phone_number: Yup.string().notRequired().default(''),
}).concat(validationSchema);

const FormCreditorContainer = () => {
  const initial = useRef({});

  const [key, setKey] = useState(uuidv4());
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [exisistCnpj, setExisistCnpj] = useState(false);

  const { isEdit, id } = useIsEdit();

  const history = useHistory();

  const { values, setValues, errors, validateForm } = useFormikContext();

  const dispatch = useDispatch();

  const dispatchRedux = {
    GET: flow(Dispatchs.get, dispatch),
    UPDATE: flow(Dispatchs.update, dispatch),
    RESET_NOTIFICATION: flow(Dispatchs.reset_notification, dispatch),
  };

  const dispatchAccountsRedux = {
    CREATE: flow(DispatchsAccounts.create, dispatch),
    RESET_ERROR: flow(DispatchsAccounts.resetError, dispatch),
  };

  const selectorAccountsRedux = {
    DATA: useSelector(SelectorsAccounts.data),
    LOADING: useSelector(SelectorsAccounts.loading),
    ERROR: useSelector(SelectorsAccounts.error),
  };

  const selectorRedux = {
    FETCHED: useSelector(Selectors.fetched),
    LOADING: useSelector(Selectors.loading),
    DATA: useSelector(Selectors.data),
    NOTIFICATION: useSelector(Selectors.notification),
  };

  const { handleNotification } = useCrudNotification({
    selectorRedux,
    dispatchRedux,
  });

  useEffect(() => {
    if (selectorAccountsRedux.ERROR) {
      handleNotification(selectorAccountsRedux.DATA?.error, 'error');

      dispatchAccountsRedux.RESET_ERROR();
    }
  }, [selectorAccountsRedux.ERROR]);

  useEffect(() => {
    const { successAction } = selectorAccountsRedux.DATA || {};

    if (successAction === true && !isEdit) {
      history.push(
        ROUTES.PRIVATE.CREDITORS.EDIT(selectorAccountsRedux.DATA?.creditor?.id)
      );
    }
  }, [selectorAccountsRedux.DATA]);

  useEffect(() => {
    if (isEdit) {
      dispatchRedux.GET(id);
    }
  }, [isEdit]);

  useEffect(() => {
    if (selectorRedux.FETCHED && isEdit) {
      const obj = {
        document_number: selectorRedux.DATA.document_number,
        trading_name: selectorRedux.DATA.trading_name,
        company_name: selectorRedux.DATA.company_name,
        address: selectorRedux.DATA.address,
        address_number: selectorRedux.DATA.address_number,
        address_complement: selectorRedux.DATA.address_complement,
        address_neighborhood: selectorRedux.DATA.address_neighborhood,
        address_city: selectorRedux.DATA.address_city,
        address_state: selectorRedux.DATA.address_state,
        address_zip_code: selectorRedux.DATA.address_zip_code,
        contact_phone_number: selectorRedux.DATA.account?.phone_number,
        contact_name: selectorRedux.DATA.account?.name,
        contact_email: selectorRedux.DATA.account?.email,
        active: String(selectorRedux.DATA.active),
      };

      setValues(obj);

      initial.current = obj;

      setKey(uuidv4());
    }
  }, [selectorRedux.FETCHED, isEdit]);

  const handleBack = () => history.goBack();

  const handleGetAddressByZip = useCallback(
    async (zip) => {
      const digitsZip = zip.replace(/\D/g, '');

      setLoadingLocal(true);

      const response = await getAddressByZip(digitsZip);
      const { endereco } = await response.json();

      setLoadingLocal(false);

      setValues({
        ...(values as any),
        address: endereco?.logradouro || '',
        address_neighborhood: endereco?.bairro || '',
        address_city: endereco?.localidade || '',
        address_state: endereco?.uf || '',
      });

      setTimeout(() => setKey(uuidv4()));
    },
    [values, setValues]
  );

  const handleGetDocument = useCallback(
    async (document) => {
      const documentDigits = document.replace(/\D/g, '');

      if (documentDigits.length < 14) {
        return false;
      }

      setLoadingLocal(true);

      const responseExisistCnpj = await getValidationDocument(documentDigits);
      const resultExisistCnpj = await responseExisistCnpj.json();

      if (resultExisistCnpj) {
        handleNotification('CNPJ já cadastrado', 'error');
      }

      setExisistCnpj(resultExisistCnpj);

      const response = await getDocument(documentDigits);
      const result = await response.json();

      setLoadingLocal(false);

      setValues({
        ...(values as any),
        company_name: result?.empresa?.nome || '',
        trading_name: result?.empresa?.fantasia || '',
        address_zip_code: result?.empresa?.cep || '',
        address: result?.empresa?.logradouro || '',
        address_number: result?.empresa?.numero || '',
        address_complement: result?.empresa?.complemento || '',
        address_neighborhood: result?.empresa?.bairro || '',
        address_city: result?.empresa?.municipio || '',
        address_state: result?.empresa?.uf || '',
      });

      setTimeout(() => setKey(uuidv4()));
    },
    [values, setValues]
  );

  const handleResendEmail = async () => {
    setLoadingLocal(true);

    const { email } = selectorRedux.DATA?.account || {};

    const response = await postAccountsSendTokenService({ email });

    setLoadingLocal(false);

    if (response.ok) {
      return handleNotification('Email reenviado com sucesso', 'success');
    }

    const result = await response.json();

    return handleNotification(result?.errors.message[0], 'error');
  };

  const isEqualsValues = isEdit && isEqual(values, initial.current);

  return (
    <FormCreditorComponent
      key={key}
      isEdit={isEdit}
      loading={selectorRedux.LOADING || selectorAccountsRedux.LOADING}
      loadingLocal={loadingLocal}
      fetched={selectorRedux.FETCHED}
      errorsForm={errors}
      exisistCnpj={exisistCnpj}
      handleBack={handleBack}
      handleGetAddressByZip={handleGetAddressByZip}
      handleGetDocument={handleGetDocument}
      isEqualsValues={isEqualsValues}
      handleResendEmail={handleResendEmail}
      handleSubmit={() =>
        isEdit
          ? dispatchRedux.UPDATE(id, values)
          : dispatchAccountsRedux.CREATE(values)
      }
    />
  );
};

const FormCreditorComponent = ({
  isEdit,
  loading,
  loadingLocal,
  fetched,
  handleSubmit,
  errorsForm,
  handleBack,
  handleGetAddressByZip,
  handleGetDocument,
  exisistCnpj,
  isEqualsValues,
  handleResendEmail,
}) => {
  return (
    <>
      <Box display="flex" flexDirection="column" gridGap={24}>
        <Box gridGap={8} display="flex" flexDirection="column">
          <CardTitle>Dados da empresa</CardTitle>

          <CardForm
            formik
            fields={[
              [
                {
                  type: 'custom',
                  component: (
                    <Field name="document_number">
                      {({
                        field,
                        form: { setFieldValue, setFieldTouched },
                        meta: { error, touched },
                      }: any) => (
                        <NumberFormat
                          id="document_number"
                          customInput={TextFieldMaterial}
                          format="##.###.###/####-##"
                          fullWidth
                          label="CNPJ"
                          required
                          disabled={isEdit}
                          variant="outlined"
                          value={field.value}
                          error={touched && !!error}
                          helperText={touched && !!error && error}
                          onBlur={async ({ target: { value } }) => {
                            setFieldTouched(field.name, true);

                            handleGetDocument(value);
                          }}
                          onValueChange={(values: any) =>
                            setFieldValue(field.name, values.value)
                          }
                        />
                      )}
                    </Field>
                  ),
                },
              ],
              [
                {
                  name: 'company_name',
                  label: 'Razão social',
                  required: true,
                },
                {
                  name: 'trading_name',
                  label: 'Nome Fantasia',
                  required: true,
                },
              ],
              [
                {
                  type: 'custom',
                  component: (
                    <>
                      <Field name="address_zip_code">
                        {({
                          field,
                          form: { setFieldValue, setFieldTouched },
                          meta: { error, touched },
                        }: any) => (
                          <NumberFormat
                            id="cep"
                            customInput={TextFieldMaterial}
                            format="#####-###"
                            fullWidth
                            label="CEP"
                            required
                            variant="outlined"
                            value={field.value}
                            error={touched && !!error}
                            helperText={touched && !!error && error}
                            onBlur={async ({ target: { value } }) => {
                              setFieldTouched(field.name, true);

                              handleGetAddressByZip(value);
                            }}
                            onValueChange={(values: any) =>
                              setFieldValue(field.name, values.value)
                            }
                          />
                        )}
                      </Field>
                    </>
                  ),
                },
                {
                  name: 'address',
                  label: 'Endereço',
                  required: true,
                },
                {
                  name: 'address_number',
                  label: 'Número',
                  required: true,
                },
              ],
              [
                {
                  name: 'address_complement',
                  label: 'Complemento',
                },
                {
                  name: 'address_neighborhood',
                  label: 'Bairro',
                  required: true,
                },
                {
                  name: 'address_city',
                  label: 'Cidade',
                  required: true,
                },
                {
                  name: 'address_state',
                  label: 'Estado',
                  required: true,
                  type: 'select',
                  options: STATES,
                },
              ],
            ]}
          />
        </Box>

        {!isEdit && (
          <Box gridGap={8} display="flex" flexDirection="column">
            <CardTitle>Usuário Master</CardTitle>

            <CardForm
              formik
              fields={[
                [
                  {
                    name: 'contact_name',
                    label: 'Nome Completo',
                    required: true,
                  },
                  {
                    name: 'contact_email',
                    label: 'Email',
                    required: true,
                  },
                  {
                    name: 'phone_number',
                    label: 'Telefone Celular',
                    required: true,
                    mask: 'mobile',
                  },
                ],
              ]}
            />
          </Box>
        )}

        <CardFormFooter
          hasError={
            isEqualsValues ||
            Object.keys(errorsForm).length > 0 ||
            (isEdit ? false : exisistCnpj)
          }
          onSubmit={handleSubmit}
          onCancel={handleBack}
          loading={isEdit ? loading && fetched : loading}
        />
      </Box>

      {((isEdit && loading && !fetched) || loadingLocal) && (
        <Box
          display="flex"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          width="100%"
          height="100%"
          zIndex={9999}
          alignItems="center"
          justifyContent="center"
          bgcolor="#ffffffd1"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default function FormCreditorFormik() {
  const { isEdit } = useIsEdit();

  return (
    <Formik
      initialValues={{ ...validationSchema.cast() }}
      onSubmit={() => {}}
      validateOnMount
      validationSchema={
        isEdit ? validationUpdateSchema : validationCreateSchema
      }
    >
      <FormCreditorContainer />
    </Formik>
  );
}
