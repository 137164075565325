import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'lodash';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import patchAccountsByIdService from '@backoffice/private/services/account/patchAccountsById.service';
import getCheckidsProcessService from '@backoffice/private/services/checkid/getCheckidsProcess.service';
import postCheckidsEmailAccountService from '@backoffice/private/services/checkid/postCheckidsEmailAccount.serive';
import postCheckidsEmailDocsService from '@backoffice/private/services/checkid/postCheckidsEmailDocs.serive';

import * as merchantDuck from '@backoffice/private/ducks/merchants/merchant.duck';
import * as checkidsDuck from '@backoffice/private/ducks/checkids/listCheckids.duck';

import ActionsDocumentComponent from '../components/ActionsDocument.component';

const messages = {
  ativar: 'Ativado com sucesso!',
  inativar: 'Inativado com sucesso!',
  processar_documentos: 'Solicitação enviada com sucesso!',
  email_reprovacao_conta: 'Conta reprovada. E-mail enviado com sucesso',
  email_reprovacao_docs: 'Email enviado com sucesso!',
};

const ActionsDocumentContainer = () => {
  const currentAction = useRef('');

  const dispatch = useDispatch();
  const handleNotification = useNotification();

  const dispatchCheckidRedux = {
    GET: flow(checkidsDuck.Dispatchs.get, dispatch),
  };

  const selectorRedux = {
    data: useSelector(merchantDuck.Selectors.data),
  };

  const selectorCheckIdRedux = {
    data_results: useSelector(checkidsDuck.Selectors.data_results),
  };

  const [loadingActive, setLoadingActive] = useState(false);
  const [loadingInactive, setLoadingInactive] = useState(false);
  const [loadingProcessDocuments, setLoadingProcessDocuments] = useState(false);
  const [loadingReproveAccount, setLoadingReproveAccount] = useState(false);
  const [loadingReproveDocs, setLoadingReproveDocs] = useState(false);

  const handlePostRequest = async (response) => {
    if (response.ok) {
      handleNotification(messages[currentAction.current], 'success');

      dispatchCheckidRedux.GET();

      return false;
    }

    const result = await response.json();

    const message = result?.errors?.message?.[0];

    handleNotification(message, 'error');

    return true;
  };

  const handleSetActive = async () => {
    setLoadingActive(true);

    const response = await patchAccountsByIdService(
      selectorRedux.data?.account_id,
      {
        status: 'ativo',
      }
    );

    currentAction.current = 'ativar';

    await handlePostRequest(response);

    setLoadingActive(false);
  };

  const handleSetInactive = async () => {
    setLoadingInactive(true);

    const response = await patchAccountsByIdService(
      selectorRedux.data?.account_id,
      {
        status: 'inativo',
      }
    );

    currentAction.current = 'inativar';

    await handlePostRequest(response);

    setLoadingInactive(false);
  };

  const handleSetProcessDocument = async () => {
    setLoadingProcessDocuments(true);

    const response = await getCheckidsProcessService(
      selectorRedux.data?.account_id
    );

    currentAction.current = 'processar_documentos';

    await handlePostRequest(response);

    setLoadingProcessDocuments(false);
  };

  const handleReproveAccount = async () => {
    setLoadingReproveAccount(true);

    const response = await postCheckidsEmailAccountService(
      selectorRedux.data?.account_id
    );

    currentAction.current = 'email_reprovacao_conta';

    await handlePostRequest(response);

    setLoadingReproveAccount(false);
  };

  const handleReproveDocs = async () => {
    setLoadingReproveDocs(true);

    const response = await postCheckidsEmailDocsService(
      selectorRedux.data?.account_id
    );

    currentAction.current = 'email_reprovacao_docs';

    await handlePostRequest(response);

    setLoadingReproveDocs(false);
  };

  const hasReprovedDocument = selectorCheckIdRedux.data_results?.find(
    (r) => r.status_description === 'Reprovado'
  );

  return (
    <ActionsDocumentComponent
      onActive={handleSetActive}
      onInactive={handleSetInactive}
      onProcessDocument={handleSetProcessDocument}
      onReproveAccount={handleReproveAccount}
      onReproveDocs={handleReproveDocs}
      hasReprovedDocument={hasReprovedDocument}
      loading={
        loadingActive ||
        loadingInactive ||
        loadingProcessDocuments ||
        loadingReproveAccount ||
        loadingReproveDocs
      }
    />
  );
};

export default ActionsDocumentContainer;
