import React from 'react';

type StepsProps = {
  steps: {
    pass: ISteps;
    name: string;
    isActive: boolean;
    isDone: boolean;
    isClickable: boolean;
  }[];
  handleChangeStep: (step: ISteps) => void;
};

import { Step, StepsContainer, StepsContent } from './Steps.styled';
import { ISteps } from '@backoffice/private/modules/creditor/components/Dialogs/DialogCreateOriginator.component';

const Steps = ({ steps, handleChangeStep }: StepsProps) => {
  return (
    <StepsContainer>
      <StepsContent>
        {steps.map((step) => {
          return (
            <Step
              onClick={() =>
                step.isClickable ? handleChangeStep(step.pass) : undefined
              }
              active={step.isActive}
              done={step.isDone}
              clickable={step.isClickable}
              key={step.pass}
            >
              <span>{step.pass}</span>
              <p>{step.name}</p>
              <div></div>
            </Step>
          );
        })}
      </StepsContent>
    </StepsContainer>
  );
};

export default Steps;
