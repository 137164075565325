import React from 'react';

import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const normalizeOrigin = {
  1: 'Portal',
  2: 'Backoffice',
  3: 'Api',
  4: 'HotSite',
};

const normalizeOnboardingType = {
  1: 'Completo',
  2: 'Simplificado',
  3: 'Integrado',
};

type Props = {
  data: Array<string | number>;
};

const MerchantsImportFunc = ({ data }: Props) => {
  function handleFilterActive(value) {
    if (value) {
      return 'Sim';
    } else {
      return 'Não';
    }
  }

  function handleFilterMerchantData(data) {
    const filteredData: any = [];
    data.forEach((item) => {
      const newData = {
        cnpj___cpf: item.cnpj___cpf,
        razao_social: item.razao_social,
        nome_fantasia: item.nome_fantasia,
        e_mail_do_contato: item.e_mail_do_contato,
        nome_do_contato: item.nome_do_contato,
        telefone_com_ddd_do_contato: item.telefone_com_ddd_do_contato,
        endereco: item.endereco,
        numero: item.numero,
        complemento: item.complemento,
        bairro: item.bairro,
        cidade: item.cidade,
        estado: item.estado,
        cep: item.cep,
        error: item?.error?.length > 0 ? item?.error[0] : '',
      };

      filteredData.push(newData);
    });

    return filteredData;
  }
  return (
    <ExcelFile
      filename={'estabelecimentos'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleFilterMerchantData(data)} name="Estabelecimentos">
        <ExcelColumn label="CNPJ / CPF" value="cnpj___cpf" />
        <ExcelColumn label="Razão Social" value="razao_social" />
        <ExcelColumn label="Nome Fantasia	" value="nome_fantasia" />
        <ExcelColumn label="E-mail do Contato	" value="e_mail_do_contato" />
        <ExcelColumn label="Nome do Contato	" value="nome_do_contato" />
        <ExcelColumn
          label="Telefone com DDD do Contato	"
          value="telefone_com_ddd_do_contato"
        />
        <ExcelColumn label="Endereço" value="endereco" />
        <ExcelColumn label="Número" value="numero" />
        <ExcelColumn label="Complemento" value="complemento" />
        <ExcelColumn label="Bairro" value="bairro" />
        <ExcelColumn label="Cidade" value="cidade" />
        <ExcelColumn label="Estado" value="estado" />
        <ExcelColumn label="CEP" value="cep" />
        <ExcelColumn label="" value="error" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default MerchantsImportFunc;
