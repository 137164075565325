import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  Dispatchs,
  Selectors,
} from '../../../../ducks/parameters/banks/bank.duck';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

import messages from '@backoffice/shared/utils/messagesSchema.util';

import CardTitle from '@backoffice/private/components/Card/CardTitle';
import CardForm from '@backoffice/private/components/Card/CardForm';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';

const bankSchema = [
  [
    {
      label: 'Nome',
      name: 'name',
      required: true,
    },
    {
      label: 'Código do banco',
      name: 'code',
      required: true,
    },
    {
      label: 'ISPB',
      name: 'ispb',
    },
  ],
];

const validationSchema = Yup.object({
  name: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .min(3, 'O campo deve ter mais de 3 caracteres')
    .max(50, 'Número de caracteres excedido'),
  code: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .min(3, 'O código deve possuir 3 digitos')
    .max(3, 'O código deve possuir 3 digitos'),
  ispb: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .min(8, 'O código deve possuir 8 digitos')
    .max(8, 'O código deve possuir 8 digitos'),
});

const FormBankContainer = () => {
  const initial = useRef({});
  const history = useHistory();
  const dispatch = useDispatch();
  const handleNotification = useNotification();
  const [key, setKey] = useState(uuidv4());
  const { values, errors, setValues, resetForm } = useFormikContext();

  const { isEdit, id } = useIsEdit();

  const handleBack = () => history.goBack();

  const dispatchBankRedux = {
    GET: flow(Dispatchs.get, dispatch),
    CREATE: flow(Dispatchs.create, dispatch),
    UPDATE: flow(Dispatchs.update, dispatch),
    RESET_NOTIFICATION: flow(Dispatchs.reset_notification, dispatch),
  };

  const selectorBankRedux = {
    FETCHED: useSelector(Selectors.fetched),
    LOADING: useSelector(Selectors.loading),
    DATA: useSelector(Selectors.data),
    NOTIFICATION: useSelector(Selectors.notification),
    ERROR: useSelector(Selectors.error),
  };

  useEffect(() => {
    if (isEdit) {
      dispatchBankRedux.GET(id);
    }
  }, [isEdit]);

  useEffect(() => {
    const { successActionUpdate, error, successAction, errors } =
      selectorBankRedux.DATA || {};

    if (isEdit) {
      if (
        successActionUpdate === true &&
        selectorBankRedux.NOTIFICATION === true
      ) {
        handleNotification('Atualizado com sucesso!', 'success');

        dispatchBankRedux.RESET_NOTIFICATION();
      }

      if (
        successActionUpdate === false &&
        selectorBankRedux.NOTIFICATION === true
      ) {
        handleNotification(error, 'error');

        dispatchBankRedux.RESET_NOTIFICATION();
      }
    } else {
      if (successAction === true) {
        handleNotification('Banco cadastrado com sucesso!', 'success');
      } else if (
        successAction === false &&
        selectorBankRedux.NOTIFICATION === true
      ) {
        handleNotification(errors?.message[0], 'error');
        dispatchBankRedux.RESET_NOTIFICATION();
      }
    }
  }, [selectorBankRedux.DATA, isEdit]);

  useEffect(() => {
    if (selectorBankRedux.DATA?.bank_name) {
      if (selectorBankRedux.FETCHED && isEdit) {
        const obj = {
          ...(values as any),
          name: selectorBankRedux.DATA.bank_name,
          code: selectorBankRedux.DATA.bank_code,
          ispb: selectorBankRedux.DATA.bank_ispb,
        };

        setValues(obj);

        initial.current = obj;

        setKey(uuidv4());
      }
    }
  }, [selectorBankRedux.FETCHED, selectorBankRedux.DATA, isEdit]);

  return (
    <FormBankComponent
      isEdit={isEdit}
      loading={selectorBankRedux.LOADING}
      fetched={selectorBankRedux.FETCHED}
      errorsForm={errors}
      values={values}
      handleBack={handleBack}
      handleSubmit={() => {
        if (isEdit) {
          dispatchBankRedux.UPDATE(id, { ...(values as any) });
        } else {
          dispatchBankRedux.CREATE({ ...(values as any) });
          resetForm();
        }
      }}
    />
  );
};

const FormBankComponent = ({
  isEdit,
  loading,
  handleSubmit,
  errorsForm,
  handleBack,
  fetched,
  values,
}) => {
  return (
    <Box gridGap={8} display="flex" flexDirection="column">
      <CardTitle>{isEdit ? 'Editar Banco' : 'Cadastrar novo banco'}</CardTitle>

      <Box display="flex" flexDirection="column" gridGap={24}>
        <CardForm formik fields={bankSchema as any} />

        <CardFormFooter
          hasError={Object.keys(errorsForm).length > 0}
          onSubmit={handleSubmit}
          onCancel={handleBack}
          loading={loading}
        />
      </Box>

      {isEdit && loading && !fetched && (
        <Box
          display="flex"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          width="100%"
          height="100%"
          zIndex={9999}
          alignItems="center"
          justifyContent="center"
          bgcolor="#ffffffd1"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default function FormBankFormik() {
  return (
    <Formik
      initialValues={{ ...validationSchema.cast() }}
      onSubmit={() => {}}
      validateOnMount
      validationSchema={validationSchema}
    >
      <FormBankContainer />
    </Formik>
  );
}
