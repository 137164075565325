import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import flow from 'lodash/flow';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  Dispatchs,
  Selectors,
} from '../../../../ducks/parameters/acquirers/acquirer.duck';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

import messages from '@backoffice/shared/utils/messagesSchema.util';
import validateCNPJ from '@payhop/shared-utils/validateCNPJ.util';

import CardTitle from '@backoffice/private/components/Card/CardTitle';
import CardForm from '@backoffice/private/components/Card/CardForm';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';
import FieldUploadImage from '@backoffice/private/components/Fields/FieldUploadImage.component';

const validationSchema = Yup.object({
  name: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .min(3, 'O campo deve ter mais de 3 caracteres')
    .max(30, 'Número de caracteres excedido'),
  document_number: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .test('document_number', 'CNPJ inválido', (value) => {
      const documentLength: any = !!value && String(value).length;

      if (documentLength < 14 || !validateCNPJ(value)) {
        return false;
      }

      return true;
    }),
  registradora: Yup.string().required(messages.REQUIRED).default(''),
  file: Yup.mixed(),
});

const FormAcquirerContainer = () => {
  const initial = useRef({});
  const history = useHistory();
  const dispatch = useDispatch();
  const handleNotification = useNotification();
  const [key, setKey] = useState(uuidv4());
  const [logoImage, setlogoImage] = useState('');
  const { values, errors, setValues, resetForm } = useFormikContext();

  const { isEdit, id } = useIsEdit();

  const handleBack = () => history.goBack();

  const dispatchAcquirerRedux = {
    GET: flow(Dispatchs.get, dispatch),
    CREATE: flow(Dispatchs.create, dispatch),
    UPDATE: flow(Dispatchs.update, dispatch),
    RESET_NOTIFICATION: flow(Dispatchs.reset_notification, dispatch),
  };

  const selectorAcquirerRedux = {
    FETCHED: useSelector(Selectors.fetched),
    LOADING: useSelector(Selectors.loading),
    DATA: useSelector(Selectors.data),
    NOTIFICATION: useSelector(Selectors.notification),
    ERROR: useSelector(Selectors.error),
  };

  useEffect(() => {
    if (isEdit) {
      dispatchAcquirerRedux.GET(id);
    }
  }, [isEdit]);

  useEffect(() => {
    const { successActionUpdate, error, successAction, errors } =
      selectorAcquirerRedux.DATA || {};

    if (isEdit) {
      if (
        successActionUpdate === true &&
        selectorAcquirerRedux.NOTIFICATION === true
      ) {
        handleNotification('Atualizado com sucesso!', 'success');

        dispatchAcquirerRedux.RESET_NOTIFICATION();
      }

      if (
        successActionUpdate === false &&
        selectorAcquirerRedux.NOTIFICATION === true
      ) {
        handleNotification(error, 'error');

        dispatchAcquirerRedux.RESET_NOTIFICATION();
      }
    } else {
      if (
        successAction === true &&
        selectorAcquirerRedux.NOTIFICATION === true
      ) {
        handleNotification('Credenciadora cadastrada com sucesso!', 'success');
        dispatchAcquirerRedux.RESET_NOTIFICATION();
      } else if (
        successAction === false &&
        selectorAcquirerRedux.NOTIFICATION === true
      ) {
        handleNotification(errors?.message[0], 'error');
        dispatchAcquirerRedux.RESET_NOTIFICATION();
      }
    }
  }, [selectorAcquirerRedux.DATA, isEdit]);

  useEffect(() => {
    if (selectorAcquirerRedux.DATA?.acquirer_name) {
      if (selectorAcquirerRedux.FETCHED && isEdit) {
        const obj = {
          ...(values as any),
          name: selectorAcquirerRedux.DATA.acquirer_name,
          document_number: selectorAcquirerRedux.DATA.document_number,
          registradora: selectorAcquirerRedux.DATA.register_name
            ? selectorAcquirerRedux.DATA.register_name.toLowerCase()
            : null,
        };

        setlogoImage(selectorAcquirerRedux.DATA.file);
        setValues(obj);

        initial.current = obj;

        setKey(uuidv4());
      }
    }
  }, [selectorAcquirerRedux.FETCHED, selectorAcquirerRedux.DATA, isEdit]);

  return (
    <FormAcquirerComponent
      isEdit={isEdit}
      loading={selectorAcquirerRedux.LOADING}
      fetched={selectorAcquirerRedux.FETCHED}
      errorsForm={errors}
      values={values}
      handleBack={handleBack}
      logoImage={logoImage}
      handleSubmit={() => {
        if (isEdit) {
          dispatchAcquirerRedux.UPDATE(id, {
            ...(values as any),
            active: selectorAcquirerRedux.DATA.active,
          });
        } else {
          dispatchAcquirerRedux.CREATE({ ...(values as any) });
          resetForm();
        }
      }}
    />
  );
};

const FormAcquirerComponent = ({
  isEdit,
  loading,
  handleSubmit,
  errorsForm,
  handleBack,
  logoImage,
  fetched,
  values,
}) => {
  const acquirerSchema = [
    [
      {
        label: 'Nome',
        name: 'name',
        required: true,
      },
      !isEdit && {
        name: 'document_number',
        label: 'CNPJ',
        required: true,
        mask: 'cnpj',
      },
      {
        label: 'Registradora',
        name: 'registradora',
        type: 'select',
        options: [
          {
            label: 'B3',
            value: 'b3',
          },
          {
            label: 'CERC',
            value: 'cerc',
          },
          {
            label: 'CIP',
            value: 'cip',
          },
          {
            label: 'TAG',
            value: 'tag',
          },
        ],
      },
      !isEdit && {
        type: 'custom',
        label: 'Logo',
        component: (
          <FieldUploadImage
            name="file"
            labelTitle="Logo"
            hasImage={logoImage}
            variant={'minify'}
          />
        ),
      },
    ],
  ];
  return (
    <Box gridGap={8} display="flex" flexDirection="column">
      <CardTitle>
        {isEdit ? 'Editar Credenciadora' : 'Cadastrar nova credenciadora'}
      </CardTitle>

      <Box display="flex" flexDirection="column" gridGap={24}>
        <CardForm formik fields={acquirerSchema as any} />

        <CardFormFooter
          hasError={Object.keys(errorsForm).length > 0}
          onSubmit={handleSubmit}
          onCancel={handleBack}
          loading={loading}
        />
      </Box>

      {isEdit && loading && !fetched && (
        <Box
          display="flex"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          width="100%"
          height="100%"
          zIndex={9999}
          alignItems="center"
          justifyContent="center"
          bgcolor="#ffffffd1"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default function FormAcquirerFormik() {
  return (
    <Formik
      initialValues={{ ...validationSchema.cast() }}
      onSubmit={() => {}}
      validateOnMount
      validationSchema={validationSchema}
    >
      <FormAcquirerContainer />
    </Formik>
  );
}
