import fetch from '@payhop/shared-utils/fetch.util';

export default function postCheckidsEmailAccountService(id) {
  return fetch(`checkids/email-account`, {
    method: 'POST',
    ms: 'CHECKID',
    auth: true,
    body: JSON.stringify({
      account_id: id,
    }),
  });
}
