import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import CSVReader from 'react-csv-reader';
import { v4 } from 'uuid';
import { CSVLink } from 'react-csv';
import NumberFormat from 'react-number-format';

import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';

import { PARAMETERS_VALUES } from '@backoffice/private/constants/parameters/parameters_values.contant';
import { PARAMETERS } from '@backoffice/private/constants/parameters/parameters.constant';
import {
  TRANSACTION_TYPES,
  TRANSACTION_VALUES,
} from '@backoffice/private/constants/contract/transaction.constant';
import {
  CONTRACT_TYPES,
  CONTRACT_TYPES_VALUES,
} from '@backoffice/private/constants/contract/type.constant';

import { withStyles } from '@material-ui/core/styles';

import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';
import Button from '@backoffice/shared/components/Button.component';

import CardTable from '@backoffice/private/components/Card/CardTable';

import fetch from '@payhop/shared-utils/fetch.util';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useListCreditorsHook from '../hooks/useListCreditors.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

import postDealsUploadService from '@backoffice/private/services/deal/postDealsUpload.service';
import getCreditorsByIdService from '@backoffice/private/services/creditor/getCreditorsById.service';
import { Formik } from 'formik';
import useCreditorParameters from '@backoffice/private/hooks/useCreditorParameters.hook';
import InputSelectNew from '@backoffice/private/components/Form/Inputs/InputSelectNew.component';
import InputText from '@backoffice/private/components/Form/Inputs/InputText.component';

import ModalHelpTable from '@backoffice/private/components/Modal/ModalHelpTable.component';
import { getColumnsData, getColumnsTable } from '../hooks/useGetHelpTable.hook';
import InfoIcon from '@material-ui/icons/Info';
const papaparseOptions = {
  header: true,
  dynamicTyping: false,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const AppointmentsScreen = () => {
  const csvLinkEl = useRef(null);
  const { isAdmin, user } = useUserHook();
  const [key, setKey] = useState(v4());
  const handleNotification = useNotification();

  const [showHelpModal, setShowHelpModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creditorId, setCreditorId] = useState('');
  const [dataFile, setDataFile] = useState([]);
  const [resultFile, setResultFile] = useState({});
  const [file, setFile] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  const [selectedTransactionType, setSelectedTransactionType] =
    useState<string>('');
  const [selectedContractType, setSelectedContractType] = useState<string>('');

  const creditors = useListCreditorsHook();

  const [creditorParameter, setCreditorParameter] = useState('');
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [referenceId, setReferenceId] = useState('');

  const { parameters, handleSetParameters } = useCreditorParameters();
  const handleShowHelpModal = () => setShowHelpModal(!showHelpModal);

  useEffect(() => {
    if (!isAdmin) {
      const { personId } = user || {};

      handleGetCreditorParameters(personId);
    }
  }, []);

  const handleForce = (data, fileInfo, originalFile) => {
    setDataFile(
      data.map((d) => ({
        ...d,
        cnpj_estabelecimento: d.cnpj_estabelecimento.toString(),
        valor: Number(d.valor),
      }))
    );
    setFile(originalFile);
  };

  const handleClickSelectFile = () => {
    const obj = document.querySelector('.csv-input');

    (obj as any).click();
  };

  const handleReset = () => {
    setDataFile([]);
    setFile(null);
    setKey(v4());
    setResultFile({});
    if (isAdmin) {
      setCreditorParameter('');
    }
  };

  const handleScrollTable = () => {
    const table = document.querySelector('.cardTable .MuiTableContainer-root');

    if (table) {
      (table as any).scrollLeft = 999999;
    }
  };

  const handleProcess = async () => {
    setLoading(true);

    const [, resultCreditor] = await getCreditorsByIdService(creditorId);
    const { document_number } = resultCreditor || {};

    const [success, result] = await postDealsUploadService({
      creditor_id: creditorId,
      deal_type: selectedTransactionType,
      deal_contract_type: selectedContractType,
      file,
    });

    setLoading(false);

    if (success) {
      const { status } = result || {};
      const statusArr = status?.status || [];
      setCreditorId('');

      const shouldHaveErrors = statusArr.length > 0;

      setReferenceId(result?.process_id);

      if (!shouldHaveErrors) {
        handleNotification('Arquivo importado com sucesso!', 'success');
        if (creditorParameter === 'pre_compromisso') {
          setConfirmDialog(true);
        }
        handleReset();
      }

      if (shouldHaveErrors) {
        let newDataFileArr: any = [];

        statusArr.map((s) => {
          const { item1, item2 } = s;
          const item1Split = item1.split(';');

          const newDataFile: any = columns.reduce(
            (acc, curr, idx) => ({
              ...acc,
              [curr.dataIndex]: item1Split[idx],
            }),
            {}
          );

          newDataFile.error = item2;

          newDataFileArr = [...newDataFileArr, newDataFile];
        });

        handleNotification(
          'Ocorreu um erro ao importar um ou mais registros. Verifique a coluna de erro.',
          'error'
        );
        if (
          creditorParameter === 'pre_compromisso' &&
          status.totalError != status.totalLines
        ) {
          setConfirmDialog(true);
        }
        setDataFile(newDataFileArr);
        setFile(null);
        setKey(v4());
        handleScrollTable();
      }

      setResultFile(result);
    } else {
      handleNotification(result.errors.message[0], 'error');
    }
  };

  const handleGetCreditorParameters = useCallback(async (id) => {
    setCreditorId(id);

    handleSetParameters(id, [
      PARAMETERS.TIPO_TRANSACAO,
      PARAMETERS.PERFIL_PARCEIRO,
      PARAMETERS.TIPO_CONTRATO,
    ]);
  }, []);

  const handleCNPJMask = (cnpj) => {
    const cnpjWithoutMask = cnpj.replace(/\D/g, '');
    const cnpjWithMask = cnpjWithoutMask.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
    return cnpjWithMask;
  };

  const handleFormatCnpj = (value) => {
    const cnpj = String(value);

    if (cnpj.includes(',')) {
      const arrayCNPJs = cnpj.split(',');
      const formatedCNPJ = arrayCNPJs.map((cnpj) =>
        handleCNPJMask(cnpj.trim())
      );

      return formatedCNPJ.join(', ');
    } else {
      const formatedCNPJ = handleCNPJMask(cnpj);

      return formatedCNPJ;
    }
  };

  const handleOnExport = () => {
    setData([
      {
        'cnpj estabelecimento': '13916156000169',
        título: 'Pedido Parcelado',
        descrição: 'Teste Parcelado - lote A',
        código: '28072215121',
        emissão: '05/08/2022',
        expiração: '05/08/2022',
        valor: '150',
        vencimento: '05/08/2022',
        'execução início': '05/08/2022',
        'execução fim': '15/08/2022',
        'id domicilio': '00000000-0000-0000-0000-000000000000',
        cnpj_credenciadora: '01027058000191,10440482000154',
        arranjos: 'MCC,VCC',
      },
    ]);

    setTimeout(() => {
      (csvLinkEl as any).current.link.click();
    });
  };

  const columns = [
    {
      dataIndex: 'cnpj_estabelecimento',
      key: 'cnpj_estabelecimento',
      title: 'CNPJ/CPF Estabelecimento',
      render: ({ cnpj_estabelecimento }) => {
        const isNull = String(cnpj_estabelecimento).includes('null');
        let valueMask = '';
        if (cnpj_estabelecimento.length === 11) {
          valueMask = '###.###.###-##';
        }

        if (cnpj_estabelecimento.length === 14) {
          valueMask = '##.###.###/####-##';
        }

        return !isNull ? (
          <NumberFormat
            value={cnpj_estabelecimento}
            displayType="text"
            format={valueMask}
          />
        ) : (
          <Fragment />
        );
      },
    },
    {
      dataIndex: 't_tulo',
      key: 't_tulo',
      title: 'Título',
    },
    {
      dataIndex: 'descri__o',
      key: 'descri__o',
      title: 'Descrição',
    },
    {
      dataIndex: 'c_digo',
      key: 'c_digo',
      title: 'Código',
    },
    {
      dataIndex: 'emiss_o',
      key: 'emiss_o',
      title: 'Emissão',
    },
    {
      dataIndex: 'expira__o',
      key: 'expira__o',
      title: 'Expiração',
    },
    {
      dataIndex: 'valor',
      key: 'valor',
      title: 'Valor',
      render: ({ valor }) => {
        const valueFormatted = String(valor).replace(',', '.');
        return valor ? (
          Number(valueFormatted).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })
        ) : (
          <Fragment />
        );
      },
    },
    {
      dataIndex: 'vencimento',
      key: 'vencimento',
      title: 'Vencimento',
    },

    {
      dataIndex: 'execu__o_in_cio',
      key: 'execu__o_in_cio',
      title: 'Execução Início',
    },
    {
      dataIndex: 'execu__o_fim',
      key: 'execu__o_fim',
      title: 'Execução Fim',
    },
    {
      dataIndex: 'id_domicilio',
      key: 'id_domicilio',
      title: 'ID Domicílio',
    },
    {
      dataIndex: 'cnpj_credenciadora',
      key: 'cnpj_credenciadora',
      title: 'CNPJ credenciadora',
      render: ({ cnpj_credenciadora }) => {
        const cnpjObj = handleFormatCnpj(cnpj_credenciadora);
        return cnpjObj;
      },
    },
    {
      dataIndex: 'arranjos',
      key: 'arranjos',
      title: 'Arranjos',
    },
    {
      dataIndex: 'error',
      key: 'error',
      title: 'Erro',
      render: ({ error }) => (
        <HtmlTooltip
          title={
            <Fragment>
              <Typography
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {error}
              </Typography>
            </Fragment>
          }
        >
          <div>
            {error && error.slice(0, 35) + (error.length > 35 ? '...' : '')}
          </div>
        </HtmlTooltip>
      ),
    },
  ];

  const { permissions } = useAccountPermission({
    permission_slug: 'importacao_compromisso',
  });

  const hasFile = !!file;

  useEffect(() => {
    if (parameters && creditorId) {
      if (parameters.tipo_transacao) {
        const { tipo_transacao, tipo_contrato } = parameters;
        if (tipo_transacao == 'ambos') {
          setSelectedTransactionType('1');
        } else {
          setSelectedTransactionType(TRANSACTION_VALUES[tipo_transacao]);
        }
        if (tipo_contrato == 'todos') {
          setSelectedContractType('1');
        } else {
          setSelectedContractType(CONTRACT_TYPES_VALUES[tipo_contrato]);
        }
      }

      if (!parameters.tipo_transacao || !parameters.tipo_contrato) {
        handleNotification('Parceiro não tem parametros cadastrados', 'error');
        setSelectedTransactionType('');
        setSelectedContractType('');
      }
    }
  }, [parameters]);
  const helpColumns = useMemo(() => getColumnsTable(), []);
  const helpData = useMemo(() => getColumnsData(), []);

  //Parameters Values
  const tipo_parceiro = PARAMETERS_VALUES[parameters.perfil_parceiro];
  const tipo_transacao: string = parameters.tipo_transacao;
  const tipo_contrato: string = parameters.tipo_contrato;

  useEffect(() => {
    if (parameters && creditorId) {
      if (parameters.tipo_transacao) {
        const { tipo_transacao, tipo_contrato } = parameters;
        if (tipo_transacao == 'ambos') {
          setSelectedTransactionType('1');
        } else {
          setSelectedTransactionType(TRANSACTION_VALUES[tipo_transacao]);
        }
        if (tipo_contrato == 'todos') {
          setSelectedContractType('1');
        } else {
          setSelectedContractType(CONTRACT_TYPES_VALUES[tipo_contrato]);
        }
      }

      if (!parameters.tipo_transacao || !parameters.tipo_contrato) {
        handleNotification('Parceiro não tem parametros cadastrados', 'error');
        setSelectedTransactionType('');
        setSelectedContractType('');
      }
    }
  }, [parameters]);

  return (
    <Fragment key={key}>
      <PageLayout headerTitle header sidebar title="Compromissos">
        {permissions?.some((item) => item == 'create') && (
          <Box display="flex" flexDirection="column" gridGap={32}>
            <Card>
              <Formik
                initialValues={{
                  type: '1',
                  contract_type: '1',
                }}
                onSubmit={() => {}}
                key={key}
              >
                {({ values, setFieldValue, errors }) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    paddingY="16px"
                    paddingX="8px"
                  >
                    <Box display="flex" gridGap={16}>
                      <InputSelectNew
                        inputWidth="50%"
                        name="creditor_id"
                        label="Fornecedor"
                        labelId="fornecedor"
                        options={creditors}
                        disabled={!isAdmin}
                        keyName="id"
                        optionName="trading_name"
                        value={creditorId || ''}
                        onChange={(e, option) => {
                          const value = option.props.value;

                          handleGetCreditorParameters(value);
                          setFieldValue('creditor_id', value);
                        }}
                        required
                      />
                      <InputText
                        inputWidth="50%"
                        name="tipo_parceiro"
                        label="Tipo de parceiro"
                        disabled
                        value={tipo_parceiro}
                      />
                    </Box>
                    <Box display="flex" gridGap={16} marginTop="16px">
                      {tipo_transacao && (
                        <>
                          <InputSelectNew
                            name="type"
                            label="Tipo de transação"
                            labelId="type"
                            disabled={tipo_transacao !== 'ambos'}
                            options={TRANSACTION_TYPES[tipo_transacao]}
                            keyName="value"
                            optionName="label"
                            value={
                              tipo_transacao == 'ambos'
                                ? values.type
                                : TRANSACTION_VALUES[tipo_transacao]
                            }
                            onChange={(_, option) => {
                              setFieldValue('type', option.props.value);
                              setSelectedTransactionType(option.props.value);
                            }}
                            required
                          />
                          <InputSelectNew
                            name="contract_type"
                            label="Tipo de contrato"
                            labelId="contract_type"
                            disabled={tipo_contrato !== 'todos'}
                            options={CONTRACT_TYPES[tipo_contrato]}
                            keyName="value"
                            optionName="label"
                            value={
                              tipo_contrato !== 'todos'
                                ? CONTRACT_TYPES_VALUES[tipo_contrato]
                                : values.contract_type
                            }
                            onChange={(_, option) => {
                              setFieldValue(
                                'contract_type',
                                option.props.value
                              );
                              setSelectedContractType(option.props.value);
                            }}
                            required
                          />
                        </>
                      )}
                    </Box>
                  </Box>
                )}
              </Formik>
            </Card>
            {!isAdmin && (
              <Card>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <CardContent>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          fullWidth
                          variant="outlined"
                          disabled={true}
                          name="consulta_agenda"
                          value={
                            creditorParameter === 'pre_compromisso'
                              ? 'Consulta de Agenda Pré Aprovação do Compromisso'
                              : creditorParameter === 'pos_compromisso'
                              ? 'Consulta de Agenda Pós Aprovação do Compromisso'
                              : ''
                          }
                        />
                      </FormControl>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            )}
            <Card>
              <CardContent>
                <Box display="flex" gridGap={24} alignItems="center">
                  <Box flex={1} display="flex" gridColumnGap={10}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClickSelectFile}
                    >
                      Selecionar arquivo
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleOnExport}
                    >
                      Baixar Modelo
                    </Button>
                    <IconButton onClick={handleShowHelpModal}>
                      <InfoIcon color="primary" />
                    </IconButton>
                    <Box display="none">
                      <CSVReader
                        cssClass="react-csv-input"
                        label="Selecionar arquivo"
                        onFileLoaded={handleForce}
                        parserOptions={papaparseOptions}
                      />
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            <div className="cardTable">
              <CardTable
                dataSource={dataFile}
                columns={columns.map((c: any) => ({
                  ...c,
                  cnpj_estabelecimento: c?.cnpj_estabelecimento || c?.cnpj,
                }))}
                footer={false}
                totallines={(resultFile as any)?.status?.totalLines}
                totalerror={(resultFile as any)?.status?.totalError}
                onExport={async () => dataFile}
                tableProps={{
                  style: {
                    minWidth: 1600,
                  },
                }}
              />
            </div>
            <Card>
              <Box component={CardActions} justifyContent="flex-end" mx={1}>
                <Button color="default" variant="text" onClick={handleReset}>
                  Limpar
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  aria-label="show more"
                  onClick={handleProcess}
                  loading={loading}
                  disabled={loading || !hasFile || !creditorId}
                >
                  Processar dados
                </Button>
              </Box>
            </Card>
          </Box>
        )}
        {!permissions ||
          (!permissions?.some((item) => item == 'create') && (
            <ModuleNotPermited />
          ))}

        <Dialog
          open={confirmDialog}
          onClose={() => setConfirmDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Pesquisa da agenda em processamento...
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ID da solicitação: {`${referenceId}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmDialog(false)}
              color="primary"
              disabled={loading}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <CSVLink
          filename="modelo_compromissos.csv"
          data={data}
          ref={csvLinkEl}
          separator={';'}
        />
        <ModalHelpTable
          isVisible={showHelpModal}
          handleClose={handleShowHelpModal}
          columns={helpColumns}
          data={helpData}
        />
      </PageLayout>
    </Fragment>
  );
};

export default AppointmentsScreen;
