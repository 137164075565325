import React, { useEffect, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import { flow } from 'lodash';
import { useDispatch } from 'react-redux';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import CardTab from '@backoffice/private/components/Card/CardTab';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';

import FormCreditor from '@backoffice/private/modules/creditor/components/Form/FormCreditor.component';

import TableCreditorUsers from '../../access/components/usersCreditors/TableUsersCreditors.component';
import TableCreditorTax from '../components/Table/TableCreditorTax.component';

import FormCreditorTax from '../components/Form/FormCreditorTax.component';
import FormCreditorParam from '../components/Form/FormCreditorParam.component';
import FormCreditorVisual from '../components/Form/FormCreditorVisual.component';

import DialogsUpdate from '../components/Dialogs/DialogCreditorUpdate.component';
import DialogChangePassword from '../components/Dialogs/DialogChangePassword.component';

import { Dispatchs as DispatchsAccounts } from '@backoffice/private/ducks/accounts/account.duck';
import { Dispatchs as DispatchsVisuals } from '@backoffice/private/ducks/creditors/visuals/creditorVisual.duck';

import { Dispatchs } from '@backoffice/private/ducks/creditors/creditor.duck';

import * as dialogCreditorChangePassword from '@backoffice/private/ducks/creditors/dialogs/dialogCreditorChangePassword.duck';
import TableCreditorOriginatorContainer from '../components/Table/Originator/TableOriginator.container';
import TableCreditorDomicileContainer from '../components/Table/Domicile/TableDomicile.container';

const texts = {
  titleEdit: 'Editar fornecedor',
  titleCreate: 'Cadastro de fornecedor',
};

const tabs = {
  dados_cadastrais: {
    label: 'Dados Cadastrais',
  },
  originadores: {
    label: 'originadores',
  },
  // usuarios: {
  //   label: 'Usuários',
  // },
  parametros: {
    label: 'Parâmetros',
  },
  taxas: {
    label: 'Taxas',
  },
  identidade_visual: {
    label: 'Identidade Visual',
  },
};

const tabsFormatted: any = Object.keys(tabs).reduce(
  (acc, curr, index) => ({
    ...acc,
    [curr]: {
      label: tabs[curr].label,
      name: curr,
      index,
    },
  }),
  {}
);

const tabsLabelsArr = Object.keys(tabsFormatted).map(
  (val) => tabsFormatted[val].label
);

const CreateProviderScreen = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    RESET: flow(Dispatchs.reset, dispatch),
  };

  const dispatchAccountsRedux = {
    RESET: flow(DispatchsAccounts.reset, dispatch),
  };
  const dispatchVisualsRedux = {
    RESET: flow(DispatchsVisuals.reset, dispatch),
  };

  const [tabActive, setTabActive] = useState(0);

  const { isEdit } = useIsEdit();

  const titlePage = useMemo(
    () => (isEdit ? texts.titleEdit : texts.titleCreate),
    [isEdit]
  );

  useEffect(() => {
    return () => {
      dispatchAccountsRedux.RESET();
      dispatchRedux.RESET();
      dispatchVisualsRedux.RESET();
    };
  }, []);

  return (
    <PageLayout headerTitle header sidebar title={titlePage} ctaBack>
      <Box display="flex" gridGap={24} flexDirection="column">
        {isEdit && (
          <CardTab
            onChange={(_, value) => setTabActive(value)}
            tabs={tabsLabelsArr}
            tabActive={tabActive}
          />
        )}

        {tabActive === tabsFormatted.dados_cadastrais.index && <FormCreditor />}

        {tabActive === tabsFormatted.originadores.index && (
          <Box display="flex" flexDirection="column" gridGap={24}>
            <TableCreditorOriginatorContainer />
          </Box>
        )}

        {tabActive === tabsFormatted?.usuarios?.index && (
          <Box display="flex" flexDirection="column" gridGap={24}>
            <TableCreditorUsers />
          </Box>
        )}

        {tabActive === tabsFormatted.parametros.index && <FormCreditorParam />}

        {tabActive === tabsFormatted.taxas.index && (
          <Box display="flex" flexDirection="column" gridGap={24}>
            <TableCreditorTax />

            <FormCreditorTax />
          </Box>
        )}

        {tabActive === tabsFormatted.identidade_visual.index && (
          <FormCreditorVisual />
        )}
      </Box>

      <DialogsUpdate
        currentTab={Object.keys(tabsFormatted).find(
          (t) => tabsFormatted[t].index === tabActive
        )}
      />

      <DialogChangePassword duck={dialogCreditorChangePassword} />
    </PageLayout>
  );
};

export default CreateProviderScreen;
