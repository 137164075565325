import fetch from '@payhop/shared-utils/fetch.util';

export default async function getCreditorsByIdService(id) {
  const response = await fetch(`creditors/${id}`, {
    method: 'GET',
    ms: 'CREDITOR',
    auth: true,
  });

  const result = await response.json();

  return [response.ok, result];
}
