export default function crudRoutes(rootUrl) {
  return {
    ROOT: rootUrl,
    CREATE: `${rootUrl}/create`,
    EDIT: (id = '', type = '') =>
      [`${rootUrl}/edit`, id && `?id=${id}`, type && `&type=${type}`].join(''),
    DYNAMIC_CREATE: (type = '') =>
      [`${rootUrl}/create`, type && `?type=${type}`].join(''),
  };
}
