import React, { useEffect, useMemo, useCallback, useState } from 'react';
import moment from 'moment';

import getDealHistoryService from '@backoffice/private/services/deal/getDealHistory.service';

import CardTable from '@backoffice/private/components/Card/CardTable';

interface HistoryDeal {
  id: string;
  account_id: string;
  created_at: string;
  deal_status_description: string;
  deal_status_id: string;
  email?: string;
  name?: string;
}

const TableDealHistoryComponent = ({ deal, loading }) => {
  const [historyDeal, setHistoryDeal] = useState<HistoryDeal[]>([]);
  const [historyLoading, setHistoryLoading] = useState(true);

  useEffect(() => {
    handleGetDealHistory(deal);
  }, [deal]);

  const handleGetDealHistory = useCallback(async (id) => {
    if (id) {
      const [response, result] = await getDealHistoryService(id);
      setHistoryDeal(result.results);
      setHistoryLoading(false);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'Usuário',
        dataIndex: 'name',
        key: 'name',
        render: ({ name }) => (name ? name : '-'),
      },
      {
        title: 'Status',
        dataIndex: 'deal_status_description',
        key: 'deal_status_description',
      },
      {
        title: 'Data e hora',
        dataIndex: 'created_at',
        key: 'created_at',
        render: ({ created_at }) =>
          moment(created_at).format('DD/MM/YYYY HH:mm'),
      },
    ],
    []
  );

  return (
    <>
      <CardTable
        loading={loading || historyLoading}
        dataSource={historyDeal}
        columns={columns}
        footer={false}
      />
    </>
  );
};

export default TableDealHistoryComponent;
