import { useState } from 'react';

import { Originator } from '../@types/models/Originator';
import { Result } from '../@types/models/Result';
import getOriginators from '../services/originator/getOriginators.service';

type ResultOriginators = Result & {
  results: Originator[];
};

export default function useCreditorOriginators() {
  const [originators, setOriginators] = useState<Originator[]>([]);

  const handleSetOriginators = async (id: string) => {
    const response = await getOriginators(id);
    const { results }: { results: Originator[] } = await response.json();

    setOriginators(results);
  };

  return { originators, handleSetOriginators };
}
