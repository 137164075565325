import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import moment from 'moment';

interface InputDataProps {
  valueDate: Date;
  handleDate: (value: any, dateString: string | undefined) => void;
  dateParam?: string;
  hasError?: boolean;
  helperText?: string;
  name?: string;
}

const InputData = ({
  valueDate,
  handleDate,
  dateParam,
  hasError,
  helperText,
  name,
}: InputDataProps) => {
  const [haveError, setHaveError] = useState(false);

  const handleInputError = () => {
    if (hasError) {
      setHaveError(true);
    } else {
      setHaveError(false);
    }
  };
  return (
    <LocalizationProvider
      dateLibInstance={moment}
      dateAdapter={MomentAdapter}
      locale="pt-BR"
    >
      <DatePicker
        css={{ width: '100%' }}
        inputFormat="DD/MM/YYYY"
        value={valueDate}
        onChange={(value) => {
          handleDate(value, dateParam);
        }}
        renderInput={(props) => (
          <TextField
            id={name}
            fullWidth
            variant="outlined"
            {...props}
            error={haveError}
            helperText={haveError && helperText}
            onBlur={handleInputError}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default InputData;
