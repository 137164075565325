import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  Fragment,
} from 'react';

import CardTable from '@backoffice/private/components/Card/CardTable';

interface ScheduleSummary {
  request_date: string;
  period: string;
  merchant_name: string;
  merchant_document_number: string;
  acquirer_name: string;
  value_type: string;
  amount_total: string;
  amount_available: string;
}

const TableScheduleSummaryComponent = ({ summary, loading, isOld }) => {
  const [scheduleSummary, setScheduleSummary] = useState<ScheduleSummary[]>([]);
  const [summaryLoading, setSummaryLoading] = useState(false);

  useEffect(() => {
    setScheduleSummary(summary);
  }, [summary]);

  const handleMaskDocument = (document) => {
    const stringDocument = String(document);

    if (stringDocument.length === 11) {
      return stringDocument.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
        '$1.$2.$3-$4'
      );
    }
    if (stringDocument.length === 14) {
      return stringDocument.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
        '$1.$2.$3/$4-$5'
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        title: 'Data de consulta',
        dataIndex: 'request_date',
        key: 'request_date',
      },
      {
        title: 'Período consultado',
        dataIndex: 'period',
        key: 'period',
      },
      {
        title: 'Estabelecimento',
        dataIndex: 'merchant_name',
        key: 'merchant_name',
      },
      {
        title: 'CNPJ',
        render: ({ merchant_document_number }) =>
          handleMaskDocument(merchant_document_number),
      },
      {
        title: 'Credenciadora',
        dataIndex: 'acquirer_name',
        key: 'acquirer_name',
      },
      {
        title: 'Arranjo / Tipo',
        dataIndex: 'arrangement_name',
        key: 'arrangement_name',
      },
      {
        title: 'Valor Total',
        dataIndex: 'amount_total',
        key: 'amount_total',
        render: ({ amount_total }) =>
          amount_total || amount_total > 0 ? (
            Number(amount_total).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          ) : (
            <p>-</p>
          ),
      },
      {
        title: 'Valor Disponivel',
        dataIndex: 'amount_available',
        key: 'amount_available',
        render: ({ amount_available }) =>
          amount_available || amount_available > 0 ? (
            Number(amount_available).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          ) : (
            <p>-</p>
          ),
      },
    ],
    []
  );
  const columnsOld = useMemo(
    () => [
      {
        title: 'Estabelecimento',
        dataIndex: 'merchant_name',
        key: 'merchant_name',
      },
      {
        title: 'CNPJ',
        render: ({ merchant_document_number }) =>
          handleMaskDocument(merchant_document_number),
      },
      {
        title: 'Credenciadora',
        dataIndex: 'acquirer_name',
        key: 'acquirer_name',
      },
      {
        title: 'Tipo',
        dataIndex: 'value_type',
        key: 'value_type',
      },
      {
        title: 'Valor Total',
        dataIndex: 'amount_total',
        key: 'amount_total',
        render: ({ amount_total }) =>
          amount_total || amount_total > 0 ? (
            Number(amount_total).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          ) : (
            <p>-</p>
          ),
      },
      {
        title: 'Valor Disponivel',
        dataIndex: 'amount_available',
        key: 'amount_available',
        render: ({ amount_available }) =>
          amount_available || amount_available > 0 ? (
            Number(amount_available).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          ) : (
            <p>-</p>
          ),
      },
    ],
    []
  );

  return (
    <>
      <CardTable
        loading={loading}
        dataSource={scheduleSummary}
        columns={isOld ? columnsOld : columns}
        footer={false}
      />
    </>
  );
};

export default TableScheduleSummaryComponent;
