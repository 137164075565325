import flow from 'lodash/flow';
import get from 'lodash/get';

import fetch from '@payhop/shared-utils/fetch.util';

import { Selectors as SelectorsCreditor } from '../creditor.duck';

const context = `private/creditors/visuals/visuals`;
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
  error?: any;
};

export const Types = {
  INIT: `${context}/INIT`,
  SUCCESS: `${context}/SUCCESS`,
  RESET: `${context}/RESET`,
  RESET_NOTIFICATION: `${context}/RESET/NOTIFICATION`,
  ERROR: `${context}/ERROR`,
  RESET_ERROR: `${context}/RESET_ERROR`,
};

const initialState: any = {
  data: null,
  loading: null,
  error: null,
  fetched: null,
  notification: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload } = action;

  const reducers = {
    [Types.RESET]: {
      ...initialState,
    },
    [Types.RESET_ERROR]: {
      ...state,
      error: initialState.error,
    },
    [Types.INIT]: {
      ...state,
      error: null,
      notification: null,
      loading: true,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
      error: false,
      notification: true,
      data: payload,
      fetched: true,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error: true,
      notification: true,
      data: payload,
    },
    [Types.RESET_NOTIFICATION]: {
      ...state,
      notification: initialState.notification,
    },
  };

  return reducers[type] || state;
};

export const getDispatch = () => async (dispatch, getState) => {
  const idCreditor = flow(getState, SelectorsCreditor.data)()?.id;

  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(`creditors/${idCreditor}/visual-identity`, {
      method: 'GET',
      ms: 'CREDITOR',
      auth: true,
    });

    const result = await response.json();

    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: { successGet: true, ...result },
    });

    return result;
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
      payload: {
        successAction: false,
        error: result?.errors?.message?.[0],
      },
    });

    return false;
  }
};

export const createDispatch = (data) => async (dispatch, getState) => {
  const idCreditor = flow(getState, SelectorsCreditor.data)()?.id;

  const formData = new FormData();

  const {
    color_hex,
    path_logo,
    path_image,
    path_url,
    article,
    product_service,
  } = data;

  formData.append('color_hex', color_hex);
  if (typeof path_logo !== 'string' || path_logo !== undefined) {
    formData.append('file_logo', path_logo);
  }
  if (typeof path_image !== 'string' || path_image !== undefined) {
    formData.append('file_image', path_image);
  }
  formData.append('path_url', path_url);
  formData.append('article', article);
  formData.append('product_service', product_service);

  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(`creditors/${idCreditor}/visual-identity`, {
      method: 'POST',
      ms: 'CREDITOR',
      auth: true,
      defaultHeaders: false,
      body: formData,
    });

    if (!response.ok) {
      throw {};
    }

    dispatch({
      type: Types.SUCCESS,
      payload: {
        successCreate: true,
      },
    });

    return response.ok;
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
      payload: {
        successAction: false,
        error: result?.errors?.message?.[0],
      },
    });

    return false;
  }
};

export const updateDispatch = (data) => async (dispatch, getState) => {
  const { id } = data;
  const idCreditor = flow(getState, SelectorsCreditor.data)()?.id;

  const formData = new FormData();

  const {
    color_hex,
    path_logo,
    path_image,
    path_url,
    article,
    product_service,
  } = data;

  formData.append('color_hex', color_hex);
  if (typeof path_logo !== 'string' && path_logo !== undefined) {
    formData.append('file_logo', path_logo);
  }
  if (typeof path_image !== 'string' && path_image !== undefined) {
    formData.append('file_image', path_image);
  }
  formData.append('path_url', path_url);
  formData.append('article', article);
  formData.append('product_service', product_service);

  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(
      `creditors/${idCreditor}/visual-identity/${id}`,
      {
        method: 'PATCH',
        ms: 'CREDITOR',
        auth: true,
        defaultHeaders: false,
        body: formData,
      }
    );

    if (!response.ok) {
      throw {};
    }

    dispatch({
      type: Types.SUCCESS,
      payload: {
        successAction: true,
        successEdit: true,
      },
    });

    return response.ok;
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
      payload: {
        successAction: false,
        error: result?.errors?.message?.[0],
      },
    });

    return false;
  }
};

export const resetDispatch = () => ({
  type: Types.RESET,
});

export const resetNotificationDispatch = () => ({
  type: Types.RESET_NOTIFICATION,
});

export const resetErrorDispatch = () => ({
  type: Types.RESET_ERROR,
});

const selectRoot = (state: any) => get(state, rootPathState);
const selectData = (state: any) => get(state, 'data');
const selectLoading = (state: any) => get(state, 'loading');
const selectFetched = (state: any) => get(state, 'fetched');
const selectError = (state: any) => get(state, 'error');
const selectNotification = (state: any) => get(state, 'notification');

export const Selectors = {
  data: flow(selectRoot, selectData),
  loading: flow(selectRoot, selectLoading),
  fetched: flow(selectRoot, selectFetched),
  error: flow(selectRoot, selectError),
  notification: flow(selectRoot, selectNotification),
};

export const Dispatchs = {
  reset: resetDispatch,
  create: createDispatch,
  update: updateDispatch,
  get: getDispatch,
  reset_notification: resetNotificationDispatch,
  reset_error: resetErrorDispatch,
};
