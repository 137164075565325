import React from 'react';

import { TextField } from 'formik-material-ui';
import { Box } from '@material-ui/core';

import { FieldDefault } from './styled';
import { useFormikContext } from 'formik';

interface InputTextProps {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  value?: string | null;
  type?: 'password' | 'text' | 'number' | string;
  inputWidth?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputText = ({
  name,
  label,
  required,
  disabled,
  value,
  type,
  inputWidth,
  onChange,
}: InputTextProps) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Box width={inputWidth ? inputWidth : '100%'}>
      <FieldDefault
        component={TextField}
        id={name}
        name={name}
        label={`${label}${required ? ` *` : ''}`}
        fullWidth
        variant="outlined"
        disabled={disabled}
        value={value || ''}
        type={type ? type : undefined}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      />
    </Box>
  );
};

export default InputText;
