import React from 'react';

import MerchantsExport from './ExportDatas/MerchantsExport.component';
import DealsExport from './ExportDatas/DealsExport.component';
import DealUrsExport from './ExportDatas/DealUrsExport.component';
import AcquirersExport from './ExportDatas/AcquirersExport.component';
import BanksExport from './ExportDatas/BanksExport.component';
import SchedulesExport from './ExportDatas/SchedulesExport.component';
import ScheduleExport from './ExportDatas/ScheduleExport.component';
import ReceivablesExport from './ExportDatas/ReceivablesExport.component';
import ProfilesExport from './ExportDatas/ProfilesExport.component';
import ArrangementsExport from './ExportDatas/ArangementsExport.component';
import OriginatorsExport from './ExportDatas/OriginatorsExport.component';
import ParametersExport from './ExportDatas/ParametersExport.component';
import MerchantsImportMain from './ExportDatas/MerchantsImportMain.component';
import MerchantsImportIf from './ExportDatas/MerchantsImportIf.component';
import MerchantsImportFunc from './ExportDatas/MerchantsImportFunc.component';

type Props = {
  filename: string;
  data: Array<string | number>;
};

const ExportXLS = ({ data, filename }: Props) => {
  return (
    <>
      {filename == 'merchants-import-if' && <MerchantsImportIf data={data} />}
      {filename == 'merchants-import-main' && (
        <MerchantsImportMain data={data} />
      )}
      {filename == 'merchants-import-func' && (
        <MerchantsImportFunc data={data} />
      )}
      {filename == 'merchants' && <MerchantsExport data={data} />}
      {filename == 'deals' && <DealsExport data={data} />}
      {filename == 'deals-urs' && <DealUrsExport data={data} />}
      {filename == 'acquirers' && <AcquirersExport data={data} />}
      {filename == 'banks' && <BanksExport data={data} />}
      {filename == 'schedules' && <SchedulesExport data={data} />}
      {filename == 'schedule' && <ScheduleExport data={data} />}
      {filename == 'receivables' && <ReceivablesExport data={data} />}
      {filename == 'profiles' && <ProfilesExport data={data} />}
      {filename == 'arrangements' && <ArrangementsExport data={data} />}
      {filename == 'originators' && <OriginatorsExport data={data} />}
      {filename == 'parameters' && <ParametersExport data={data} />}
    </>
  );
};

export default ExportXLS;
