import React, { useMemo } from 'react';
import moment from 'moment';

import CardTable from '@backoffice/private/components/Card/CardTable';

const TableDealInstamentComponent = ({ results, loading }) => {
  const columns = useMemo(
    () => [
      {
        render: ({ acquirer_path }) => <img src={acquirer_path} width="16" />,
      },
      {
        title: 'Adquirente',
        tooltip: 'Credenciadora vinculada a UR',
        dataIndex: 'acquirer_name',
        key: 'acquirer_name',
      },
      {
        title: 'Arranjo',
        tooltip: 'Bandeira + Modalidade de Operação',
        dataIndex: 'payment_arrangement',
        key: 'payment_arrangement',
        render: ({ payment_arrangement, brand_name }) =>
          `${brand_name} ${payment_arrangement}`,
      },
      {
        title: 'Prioridade',
        tooltip: 'Ordem de oneração da UR solicitada',
        dataIndex: 'effect_order',
        key: 'effect_order',
      },
      {
        title: 'Liquidação',
        tooltip: 'Data de pagamento da UR ao titular',
        dataIndex: 'settlement_date',
        key: 'settlement_date',
        render: ({ settlement_date }) =>
          moment(settlement_date).format('DD/MM/YYYY'),
      },
      {
        title: 'Valor comprometido',
        tooltip: 'Valor reservado, na qual será o valor liquidado',
        dataIndex: 'committed_amount_payhop',
        key: 'committed_amount_payhop',
        render: ({ committed_amount_payhop }) =>
          Number(committed_amount_payhop).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
      },
      {
        title: 'Valor do Efeito Inicial',
        tooltip: 'Valor alcançado no dia do registro do compromisso',
        dataIndex: 'effect_amount_initial',
        key: 'effect_amount_initial',
        render: ({ effect_amount_initial }) =>
          Number(effect_amount_initial ?? '0.00').toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
      },
      {
        title: 'Valor do efeito',
        tooltip:
          'Confirmação do valor alcançado no dia seguinte ao registro do compromisso',
        dataIndex: 'effect_amount',
        key: 'effect_amount',
        render: ({ effect_amount }) =>
          Number(effect_amount ?? '0.00').toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
      },
      {
        title: 'Valor de efeito atualizado',
        tooltip:
          'Valor efetivamente alcançado diariamente a partir do dia seguinte ao registro do compromisso',
        dataIndex: 'effect_amount_updated',
        key: 'effect_amount_updated',
        render: ({ effect_amount_updated }) =>
          Number(effect_amount_updated ?? '0.00').toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
      },
      {
        title: 'Valor De Liquidação Informado',
        tooltip:
          'Valor final pago pela instituição credenciadora ou subcredenciadora',
        dataIndex: 'effective_settlement_amount',
        key: 'effective_settlement_amount',
        render: ({ effective_settlement_amount }) =>
          Number(effective_settlement_amount ?? '0.00').toLocaleString(
            'pt-br',
            {
              style: 'currency',
              currency: 'BRL',
            }
          ),
      },
      {
        title: 'Data De Liquidação Efetiva',
        tooltip: 'Data do efetivo pagamento',
        dataIndex: 'effective_settlement_date',
        key: 'effective_settlement_date',
        render: ({ effective_settlement_date }) =>
          effective_settlement_date
            ? moment(effective_settlement_date).format('DD/MM/YYYY')
            : '-',
      },
      {
        title: 'Data de Atualização',
        tooltip:
          'Data na qual houve a atualização do Valor de efeito atualizado',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: ({ updated_at }) =>
          updated_at ? moment(updated_at).format('DD/MM/YYYY') : '-',
      },
    ],
    []
  );

  return (
    <>
      <CardTable
        loading={loading}
        dataSource={results}
        columns={columns}
        footer={false}
        onExport={() => {
          return results;
        }}
        exporttype="xls"
        exportname="deals-urs"
      />
    </>
  );
};

export default TableDealInstamentComponent;
