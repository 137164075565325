import fetch from '@payhop/shared-utils/fetch.util';

export default function getOriginators(id: string) {
  return fetch(`parceiro/originador?creditor_id=${id}&backoffice=true`, {
    method: 'GET',
    ms: 'API',
    auth: true,
    bearer: false,
  });
}
