import { useSelector } from 'react-redux';

export default function useUserHook() {
  const currentUser = useSelector(
    (state: any) => state?.shared?.authentication?.data
  );

  return {
    user: currentUser,
    isAdmin: currentUser?.accountType === 'Backoffice',
    accountId: currentUser?.accountId,
    isCreditor: currentUser?.accountType === 'Creditor',
    isPrimaryAccount: currentUser?.isSecundaryAccount === 'False',
  };
}
