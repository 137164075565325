import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Router } from 'react-router-dom';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider as ThemeProviderEmotion } from '@emotion/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { SnackbarProvider } from 'notistack';
import { ptBR } from '@material-ui/core/locale';
import configureStore from './store';
import { Provider } from 'react-redux';
import { pdfjs } from 'react-pdf';
import momentTimezone from 'moment-timezone';

import getHistory from './shared/utils/getHistory.util';

import themeObj from './shared/utils/theme.util';

import reportWebVitals from './reportWebVitals';

import 'moment/locale/pt-br';

import Routes from './routes';

import 'moment/locale/pt-br';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

momentTimezone.tz.setDefault('America/Sao_Paulo');

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const { store } = configureStore();

const App: React.FC = () => {
  const theme = createMuiTheme(themeObj, ptBR);

  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ThemeProviderEmotion
          theme={{
            mui: theme,
          }}
        >
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <CssBaseline />

              <Router history={getHistory()}>
                <Switch>
                  <Route path="/" component={Routes} />
                </Switch>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </ThemeProviderEmotion>
      </StylesProvider>
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
