import fetch from '@payhop/shared-utils/fetch.util';

export default function postAccountsSendTokenService(email) {
  return fetch(`accounts/send-token`, {
    method: 'POST',
    ms: 'ACCOUNT',
    auth: true,
    body: JSON.stringify(email),
  });
}
