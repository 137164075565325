import React from 'react';

import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';

import Button from '@backoffice/shared/components/Button.component';

type CardFormProps = {
  hasError?: any;
  disabled?: boolean;
  loading?: boolean;
  onSubmit?: any;
  onCancel?: any;
  right?: any;
};

const CardFormFooterComponent: React.FC<CardFormProps> = ({
  hasError,
  onSubmit,
  onCancel,
  disabled,
  loading,
  right,
}) => {
  return (
    <Card>
      <Box
        component={CardActions}
        justifyContent={right ? 'space-between' : 'flex-end'}
        mx={1}
      >
        {right && right}
        <Button
          color="primary"
          variant="contained"
          aria-label="show more"
          disabled={hasError || disabled || loading}
          onClick={onSubmit}
          loading={loading}
        >
          Gravar dados
        </Button>
      </Box>
    </Card>
  );
};

export default CardFormFooterComponent;
