import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';

import InputText from '@backoffice/private/components/Form/Inputs/InputText.component';
import { validationSchema } from './validationSchema';
import Button from '@backoffice/shared/components/Button.component';
import InputDocument from '@backoffice/private/components/Form/Inputs/InputDocument.component';
import Fade from '@material-ui/core/Fade';
import { Box } from '@material-ui/core';
import { ISteps } from '../../Dialogs/DialogCreateOriginator.component';

const FormCreditorOriginator = ({
  handleSubmitForm,
  data,
  handleResetFormSubmitted,
}) => {
  const formData = {
    cnpj_originador: data?.cnpj_originador,
    razao_social: data?.razao_social,
  };
  const [valueRazaoSocial, setValueRazaoSocial] = useState<string>(
    formData.razao_social
  );

  useEffect(() => {
    handleResetFormSubmitted();
  }, []);

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      validateOnChange
      onSubmit={(values) => {
        handleSubmitForm(values, ISteps.Originator);
      }}
    >
      {({ handleSubmit, errors, values }) => (
        <Fade in={true}>
          <form onSubmit={handleSubmit}>
            <Box css={{ marginBottom: '15px' }}>
              <InputDocument name="cnpj_originador" label="CNPJ/CPF" required />
            </Box>
            <Box css={{ marginBottom: '15px' }}>
              <InputText
                label="Razão Social"
                name="razao_social"
                value={values.razao_social}
                required
              />
            </Box>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              aria-label="show more"
              disabled={Object.keys(errors).length > 0}
              fullWidth
            >
              Próximo
            </Button>
          </form>
        </Fade>
      )}
    </Formik>
  );
};

export default FormCreditorOriginator;
