import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import LogRocket from 'logrocket';

import authentication from '@payhop/shared-ducks/authentication.duck';
import error from '@payhop/shared-ducks/error.duck';
import toaster from '@payhop/shared-ducks/toaster.duck';

import privateDuck from '@backoffice/private/ducks';

const shared = combineReducers({
  authentication,
  error,
  toaster,
});

const appReducer = combineReducers({
  shared,
  private: privateDuck,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

const composeWithReduxDevTools =
  (window as any).window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk, LogRocket.reduxMiddleware()];

const composedEnhancers = composeWithReduxDevTools(
  applyMiddleware(...middlewares)
);

const store = createStore(rootReducer, composedEnhancers);

const persistor = persistStore(store);

export default () => ({
  store,
  persistor,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
