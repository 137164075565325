import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Header from '@backoffice/private/components/Header';
import Sidebar from '@backoffice/private/components/Sidebar';
import TitlePage from '@backoffice/private/components/TitlePage';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/accounts/accountResources.duck';

const PageLayout: React.FC<any> = ({
  breadcrumbs = true,
  children,
  cta,
  ctaBack,
  header,
  sidebar,
  headerTitle,
  title,
}) => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    GET: flow(Dispatchs.get, dispatch),
  };
  const selectorRedux = {
    DATA: useSelector(Selectors.data),
  };

  useEffect(() => {
    if (!selectorRedux.DATA) {
      dispatchRedux.GET();
    }
  }, []);

  const history = useHistory();

  const backUrl = history.location.pathname.split('/').slice(0, -1).join('/');

  return (
    <Box display="flex" component="main" flexDirection="column">
      {header && <Header />}

      <Box component="section" display="flex">
        {sidebar && selectorRedux.DATA && <Sidebar />}

        <Box
          width="100%"
          height="calc(100vh - 66px)"
          paddingTop={8}
          px={3}
          overflow="auto"
          id="container-box"
        >
          {headerTitle && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <TitlePage breadcrumbs={breadcrumbs} title={title} />

              {cta && cta}

              {ctaBack && (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  css={{ width: 230 }}
                  onClick={() => history.goBack()}
                >
                  Voltar para a listagem
                </Button>
              )}
            </Box>
          )}

          <Box my={6}>
            <Container maxWidth={false} disableGutters>
              {children}
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PageLayout;
