import AppsIcon from '@material-ui/icons/Apps';

import ROUTES from '@backoffice/shared/utils/routes.util';

const CADASTRO_MENU = [
  'Cadastro e Consulta',
  'all',
  [
    {
      id: 'access-management',
      label: 'Gestão de acessos',
      profileModule: true,
      profileModuleSlug: [
        'cad_acesso_usuario_payhop',
        'cad_acesso_usuario_fornecedor',
        'cad_acesso_perfil',
      ],
      link: ROUTES.PRIVATE.ACCESS.ROOT,
      icon: AppsIcon,
      module: 'access',
    },
    {
      id: 'creditors',
      label: 'Fornecedor',
      profileModule: true,
      profileModuleSlug: ['cad_fornecedor'],
      link: ROUTES.PRIVATE.CREDITORS.ROOT,
      icon: AppsIcon,
      module: 'creditors',
      admin: true,
    },
    {
      id: 'schedules',
      label: 'Agenda de recebiveis',
      link: ROUTES.PRIVATE.SCHEDULES.ROOT,
      profileModule: true,
      profileModuleSlug: ['cad_agenda_consulta_agenda'],
      module: 'schedules',
      icon: AppsIcon,
    },
    {
      id: 'registered-contracts',
      label: 'Contratos Registrados',
      link: ROUTES.PRIVATE.REGISTERED_CONTRACTS.ROOT,
      profileModule: true,
      profileModuleSlug: ['cad_contratos_registrados'],
      module: 'registered-contracts',
      icon: AppsIcon,
      admin: true,
    },
    {
      id: 'appointments',
      label: 'Compromisso',
      profileModule: true,
      profileModuleSlug: [
        'cad_compromisso_dados_solicitacoes',
        'cad_compromisso_dados_compromissos',
      ],
      link: ROUTES.PRIVATE.APPOINTMENTS.ROOT,
      module: 'appointments',
      icon: AppsIcon,
    },
    {
      id: 'merchants',
      label: 'Estabelecimento',
      profileModule: true,
      profileModuleSlug: ['cad_estabelecimento'],
      link: ROUTES.PRIVATE.MERCHANTS.ROOT,
      icon: AppsIcon,
      module: 'merchants',
    },
    {
      id: 'partners',
      label: 'Parceiros',
      profileModule: true,
      profileModuleSlug: ['cad_parceiros'],
      link: ROUTES.PRIVATE.PARTNERS.ROOT,
      icon: AppsIcon,
      module: 'partners',
      admin: true,
    },
    {
      id: 'docs-validation',
      label: 'Validação docs',
      profileModule: true,
      profileModuleSlug: ['cad_validacao_docs'],
      link: ROUTES.PRIVATE.DOCS.ROOT,
      icon: AppsIcon,
      module: 'docs',
      admin: true,
    },
  ],
];

const IMPORTACAO_MENU = [
  'Importação',
  'all',
  [
    {
      label: 'Compromisso Fumaça',
      profileModule: true,
      profileModuleSlug: ['importacao_compromisso_fumaca'],
      link: ROUTES.PRIVATE.IMPORT_SMOKE_APPOINTMENTS.ROOT,
      module: 'import-smoke-appointments',
      icon: AppsIcon,
      admin: true,
    },
    {
      label: 'Compromisso',
      profileModule: true,
      profileModuleSlug: ['importacao_compromisso'],
      link: ROUTES.PRIVATE.IMPORT_APPOINTMENTS.ROOT,
      module: 'import-appointments',
      icon: AppsIcon,
    },
    {
      label: 'Estabelecimento',
      profileModule: true,
      profileModuleSlug: ['importacao_estabelecimento'],
      link: ROUTES.PRIVATE.IMPORT_MERCHANT.ROOT,
      module: 'import-merchants',
      icon: AppsIcon,
    },
    {
      label: 'Alterar Contrato',
      profileModule: true,
      profileModuleSlug: ['importacao_alterar_contrato'],
      link: ROUTES.PRIVATE.CHANGE_DEALS.ROOT,
      module: 'change-deals',
      icon: AppsIcon,
    },
  ],
];

const PARAMETROS_MENU = [
  'Parâmetros',
  'all',
  [
    {
      label: 'Payhop',
      profileModule: true,
      profileModuleSlug: [
        'cad_parametro_payhop_geral',
        'cad_parametro_payhop_bancos',
        'cad_parametro_payhop_credenciadoras',
      ],
      link: ROUTES.PRIVATE.PARAMETERS.ROOT,
      module: 'parameters',
      icon: AppsIcon,
    },
  ],
];

const REPORTS_MENU = [
  'Relatórios',
  'all',
  [
    {
      label: 'Relatórios',
      profileModule: true,
      profileModuleSlug: ['relatorios_liquidacao'],
      link: ROUTES.PRIVATE.MONITORING_REPORT.ROOT,
      module: 'monitoring-report',
      icon: AppsIcon,
    },
  ],
];

export default [CADASTRO_MENU, IMPORTACAO_MENU, PARAMETROS_MENU, REPORTS_MENU];
