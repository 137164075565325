import { createMuiTheme } from '@material-ui/core/styles';

import type {} from '@material-ui/lab/themeAugmentation';

import {
  BRAND_COLORS,
  SYSTEM_COLORS,
} from '@backoffice/shared/configs/colors.config';
import {
  FONT_FAMILY,
  FONT_STYLES,
} from '@backoffice/shared/configs/typography.config';

export default createMuiTheme({
  // palette: {
  //   primary: {
  //     light: BRAND_COLORS.primary[300],
  //     main: BRAND_COLORS.primary.main,
  //     dark: BRAND_COLORS.primary[400],
  //   },
  //   secondary: {
  //     light: '#FF91A8',
  //     main: BRAND_COLORS.secondary.main,
  //     dark: BRAND_COLORS.secondary[400],
  //   },
  //   warning: {
  //     light: SYSTEM_COLORS.warning[300],
  //     main: SYSTEM_COLORS.warning.main,
  //     dark: SYSTEM_COLORS.warning[400],
  //   },
  //   success: {
  //     light: SYSTEM_COLORS.success[300],
  //     main: SYSTEM_COLORS.success.main,
  //     dark: SYSTEM_COLORS.success[400],
  //   },
  //   error: {
  //     light: SYSTEM_COLORS.error[300],
  //     main: SYSTEM_COLORS.error.main,
  //     dark: SYSTEM_COLORS.error[400],
  //   },
  //   info: {
  //     light: SYSTEM_COLORS.info[300],
  //     main: SYSTEM_COLORS.info.main,
  //     dark: SYSTEM_COLORS.info[400],
  //     dark2: SYSTEM_COLORS.info[500],
  //   } as any,
  //   grey: {
  //     100: BRAND_COLORS.gray[100],
  //     200: BRAND_COLORS.gray[200],
  //     300: BRAND_COLORS.gray[300],
  //     400: BRAND_COLORS.gray[400],
  //     500: BRAND_COLORS.gray[500],
  //   },
  // },
  // typography: {
  //   fontFamily: FONT_FAMILY.OpenSans,
  //   body1: FONT_STYLES.TEXT_BODY_LG,
  //   body2: FONT_STYLES.TEXT_BODY_MD,
  //   subtitle1: FONT_STYLES.TEXT_BODY,
  //   subtitle2: FONT_STYLES.TEXT_BODY_SM,
  //   h1: FONT_STYLES.TEXT_HEADER_LG,
  //   h2: FONT_STYLES.TEXT_HEADER,
  //   h3: FONT_STYLES.TEXT_HEADER_SM,
  //   button: {
  //     fontSize: FONT_STYLES.TEXT_BODY_SM.fontSize,
  //     fontStyle: 'normal',
  //     fontWeight: 600,
  //     lineHeight: '18px',
  //     textTransform: 'none',
  //   },
  // },
  // overrides: {
  //   MuiChip: {
  //     root: {
  //       backgroundColor: BRAND_COLORS.gray[100],
  //       color: SYSTEM_COLORS.info[500],
  //       ...FONT_STYLES.TEXT_BODY,
  //     },
  //   },
  //   MuiStepLabel: {
  //     label: {
  //       ...FONT_STYLES.TEXT_BODY_SM,
  //       '&$active, &$completed': {
  //         fontWeight: FONT_STYLES.TEXT_BODY_SM.fontWeight,
  //         color: SYSTEM_COLORS.info[500],
  //       },
  //     },
  //   },
  //   MuiStepIcon: {
  //     root: {
  //       '&$completed': {
  //         fill: SYSTEM_COLORS.success.main,
  //       },
  //     },
  //     active: {
  //       fill: SYSTEM_COLORS.info.main,
  //     },
  //   },
  //   MuiStepConnector: {
  //     line: {
  //       borderColor: BRAND_COLORS.gray[200],
  //     },
  //   },
  //   MuiPaginationItem: {
  //     root: {
  //       ...FONT_STYLES.TEXT_BODY_SM,
  //     },
  //     outlinedPrimary: {
  //       backgroundColor: 'white',
  //       color: SYSTEM_COLORS.info[500],
  //       '&$selected': {
  //         backgroundColor: SYSTEM_COLORS.info.main,
  //         borderColor: SYSTEM_COLORS.info.main,
  //         color: 'white',
  //         '&:hover': {
  //           backgroundColor: `${BRAND_COLORS.secondary[400]} !important`,
  //           borderColor: `${BRAND_COLORS.secondary[400]} !important`,
  //         },
  //       },
  //     },
  //   },
  //   MuiInputLabel: {
  //     root: FONT_STYLES.TEXT_BODY,
  //   },
  // },
});
