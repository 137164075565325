import fetch from '@payhop/shared-utils/fetch.util';

export default function patchAccountsUnlockout(email) {
  return fetch(`accounts/unlockout`, {
    method: 'POST',
    ms: 'ACCOUNT',
    auth: true,
    body: JSON.stringify({ email }),
  });
}
