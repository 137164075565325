import React, { useEffect, useState } from 'react';
import { flow } from 'lodash';
import { useDispatch } from 'react-redux';

import { Box, Card } from '@material-ui/core';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import * as dialogCreditorCreateDomicile from '@backoffice/private/ducks/creditors/dialogs/dialogCreditorCreateDomicile.duck';

import Button from '@backoffice/shared/components/Button.component';
import CardDetail from '../../CardDetail/CardDetail.component';
import InputSelectNew from '@backoffice/private/components/Form/Inputs/InputSelectNew.component';
import TypographyComponent from '@backoffice/shared/components/Typography.component';

import useCreditorOriginators from '@backoffice/private/hooks/useCreditorOriginator.hook';

import { Originator } from '@backoffice/private/@types/models/Originator';

import patchDealDomicile from '@backoffice/private/services/deal/patchDealDomicile.service';
import DialogCreateDomicile from '@backoffice/private/modules/creditor/components/Dialogs/DialogCreateDomicile.component';
import Loading from '@backoffice/private/components/Loading/Loading.component';
import getDealOriginatorHistoryService from '@backoffice/private/services/deal/getDealOriginatorHistory.service';
import TableDealHistoryOriginatorComponent from '../../TableDealHistoryOriginatorComponent.component';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

const BankData = ({ dealData, handleChangeDeal }) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_compromisso_dados_bancarios',
  });
  const [domicileOptions, setDomicileOptions] = useState([]);
  const [domicileSelected, setDomicileSelected] = useState('');

  const [originatorInfo, setOriginatorInfo] = useState({});
  const [originator, setOriginator] = useState<Originator[]>([]);
  const [domicileInfo, setDomicileInfo] = useState({});
  const [newDomicileInfo, setNewDomicileInfo] = useState({});

  const [dealDomicile, setDealDomicile] = useState('');

  const [infosLoading, setInfosLoading] = useState<boolean>(false);

  const [historyOriginator, setHistoryOriginator] = useState([]);

  const { originators, handleSetOriginators } = useCreditorOriginators();

  const dispatch = useDispatch();

  const dispatchRedux = {
    OPEN: flow(dialogCreditorCreateDomicile.Dispatchs.open, dispatch),
  };

  const handleParseData = () => {
    const { bank_account } = dealData;
    const originator = {
      cnpj_originador: bank_account.originator.document_number,
      razao_social: bank_account.originator.company_name,
    };

    const domicile = {
      cnpj_domicilio: bank_account.document_number,
      titulo_domicilio: bank_account.title_domicile,
      agencia: bank_account.branch_number,
      conta: bank_account.account_number,
      tipo_conta: bank_account.account_type,
      banco: `${bank_account.domain_bank.bank_code} - ${bank_account.domain_bank.bank_name}`,
    };

    setOriginatorInfo(originator);
    setDomicileInfo(domicile);
  };

  const handleSetDomicileOptions = (domiciles) => {
    const domicileOptionsArray = domiciles?.map(({ id, titulo_domicilio }) => ({
      id,
      title_domicile: titulo_domicilio,
    }));

    setDomicileOptions(domicileOptionsArray);
  };

  const handleSetNewDomicile = (id) => {
    setDomicileSelected(id);
    const domiciles = originator[0]?.domicilios;

    const domicile = domiciles.filter((item) => item.id == id);

    setNewDomicileInfo(domicile[0]);
  };

  const handlePatchDomicile = async () => {
    setInfosLoading(true);
    const data = {
      domicilios: [
        {
          id_compromisso: dealData.id,
          id_domicilio: domicileSelected,
        },
      ],
    };

    await patchDealDomicile(data);

    handleChangeDeal();
    setDomicileSelected('');
    setInfosLoading(false);
  };

  const handleGetHistoryOriginator = async (dealId) => {
    const response: any = await getDealOriginatorHistoryService(dealId);

    setHistoryOriginator(response);
  };

  useEffect(() => {
    if (dealData) {
      const creditor_id = dealData.creditor_id;
      const deal_domicile = dealData?.bank_account?.id;

      handleParseData();
      handleSetOriginators(creditor_id);
      setDealDomicile(deal_domicile);

      handleGetHistoryOriginator(dealData.id);
    }
  }, [dealData]);

  useEffect(() => {
    if (originators) {
      const { bank_account } = dealData;
      const cnpj_originator = bank_account.originator.document_number;

      const originator = originators.filter(
        (item) => item.cnpj_originador == cnpj_originator
      );

      setOriginator(originator);

      const domiciles = originator[0]?.domicilios;

      const activeDomiciles = domiciles?.filter(
        (item) => item.status === 'Ativo'
      );

      if (activeDomiciles?.length == 1) {
        setDomicileSelected(activeDomiciles[0]?.id);
        handleSetDomicileOptions(activeDomiciles);
      } else {
        //Remove Deal Domicile of Select
        const newDomicilesArray = activeDomiciles?.filter(
          (item) => item.id !== dealDomicile
        );

        handleSetDomicileOptions(newDomicilesArray);
      }
    }
  }, [originators]);

  return (
    <>
      <Box
        gridGap={8}
        display="flex"
        flexDirection="column"
        position={'relative'}
      >
        <Box display={'flex'} justifyContent="space-between">
          <CardTitle>Domicílio Atual</CardTitle>
        </Box>
        <Card>
          <Box display={'flex'} bgcolor={'#FFFFFF'}>
            <CardDetail data={originatorInfo} title="Originador" />
            <CardDetail data={domicileInfo} title="Domicílio" />
          </Box>
        </Card>
        {infosLoading && <Loading />}
      </Box>

      {permissions?.some((item) => item == 'edit') && (
        <Box gridGap={8} display="flex" flexDirection="column">
          <Box display={'flex'} justifyContent="space-between">
            <CardTitle>Alterar Domicílio</CardTitle>
            <Button
              color="primary"
              variant="contained"
              aria-label="show more"
              onClick={() => dispatchRedux.OPEN(originator[0])}
            >
              Adicionar domicílio
            </Button>
          </Box>
          <Card>
            <Box padding={'12px'}>
              {domicileOptions && (
                <InputSelectNew
                  inputWidth="50%"
                  name="domicile"
                  label="Alterar Domicílio"
                  labelId="Alterar Domicílio"
                  options={domicileOptions}
                  keyName="id"
                  disabled={
                    domicileOptions?.length == 1 &&
                    dealDomicile == domicileSelected
                  }
                  optionName="title_domicile"
                  value={domicileSelected || ''}
                  onChange={(e, option) => {
                    handleSetNewDomicile(option.props.value);
                  }}
                  required
                />
              )}

              {domicileOptions?.length == 1 &&
                dealDomicile == domicileSelected && (
                  <TypographyComponent
                    css={{ marginTop: '16px' }}
                    customColor="gray-400"
                  >
                    O Originador só possui um domicílio cadastrado ativo
                  </TypographyComponent>
                )}

              {domicileSelected && dealDomicile !== domicileSelected && (
                <>
                  <CardDetail data={newDomicileInfo} title="Domicílio" />
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      color="primary"
                      variant="contained"
                      aria-label="show more"
                      onClick={() => handlePatchDomicile()}
                    >
                      Confirmar Alteração
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Card>
        </Box>
      )}

      <Box gridGap={8} display="flex" flexDirection="column">
        <Box display={'flex'} justifyContent="space-between">
          <CardTitle>Histórico</CardTitle>
        </Box>
        <Card>
          {historyOriginator.length > 0 && (
            <TableDealHistoryOriginatorComponent
              historyOriginator={historyOriginator}
              loading={false}
            />
          )}

          {historyOriginator.length == 0 && (
            <TypographyComponent
              css={{ marginTop: '16px', marginBottom: '8px' }}
              customColor="gray-400"
            >
              Não ocorreu nenhuma alteração nos domicílios desse compromisso
            </TypographyComponent>
          )}
        </Card>
      </Box>

      <DialogCreateDomicile creditorId={dealData?.creditor_id} />
    </>
  );
};

export default BankData;
