import {
  CircularProgress,
  Box,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ReplayIcon from '@material-ui/icons/Replay';
import CancelIcon from '@material-ui/icons/Cancel';

import { Upload } from '@backoffice/private/components/Upload';

type Props = {
  file?: Array<any>;
  onDrop: any;
  onRetry: any;
  onDelete: any;
};

const typeOfFile = (file) => {
  if (file?.fileUrl) {
    const isPdf = file?.fileUrl.split('/').pop().split('.').pop() === 'pdf';

    return isPdf ? 'pdf' : 'image';
  }

  const isPdf = file?.file?.type === 'application/pdf';

  return isPdf ? 'pdf' : 'image';
};

const PreviewComponent = ({ file = [], onDrop, onRetry, onDelete }: Props) => {
  const [currentPhotoSelected, setCurrentPhotoSelected] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);

  const hasFile = file.length > 0;
  const containMultipleFile = file.length > 1;

  const isFilePedingUpload = (file) =>
    file?.upload === 'pending' || file?.upload === 'uploading';

  const isFileFailUpload = (file) => file?.upload === 'fail';

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleRenderSelectedFile = (file) => {
    const isTypeFile = typeOfFile(file);

    if (isTypeFile === 'pdf' && file?.file) {
      return (
        <Box display="flex" flexDirection="column">
          <Document
            file={URL.createObjectURL(file.file)}
            onLoadSuccess={onDocumentLoadSuccess}
            css={{
              canvas: {
                width: '100% !important',
                height: '600px !important',
              },
            }}
          >
            <Page key={pageNumber} pageNumber={pageNumber + 1} />
          </Document>

          <Box
            display="flex"
            alignItems="center"
            alignSelf="center"
            gridGap={4}
          >
            <Button
              size="small"
              onClick={() => setPageNumber((prev) => prev - 1)}
              disabled={pageNumber === 0}
            >
              {'<'}
            </Button>
            <p>
              Página {pageNumber + 1} de {numPages}
            </p>
            <Button
              size="small"
              onClick={() => setPageNumber((prev) => prev + 1)}
              disabled={numPages === pageNumber + 1}
            >
              {'>'}
            </Button>
          </Box>
        </Box>
      );
    }

    if (isTypeFile === 'image') {
      return (
        <img
          css={{ width: '100%' }}
          src={file?.file ? URL.createObjectURL(file.file) : file?.fileUrl}
        />
      );
    }

    return <></>;
  };

  const handleRenderThumbFile = (file, index) => {
    const isTypeFile = typeOfFile(file);

    return (
      <Box
        height={100}
        key={`${index}-preview`}
        onClick={() => setCurrentPhotoSelected(index)}
        position="relative"
        css={{
          cursor: 'pointer',
        }}
      >
        {isTypeFile === 'image' && (
          <img
            css={{ height: 100, display: 'block', width: '100%' }}
            src={file?.file ? URL.createObjectURL(file.file) : file?.fileUrl}
          />
        )}

        {isTypeFile === 'pdf' && (
          <Box
            width={100}
            height={100}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gridGap={8}
          >
            <PictureAsPdfIcon />
            <Typography align="center">{file?.file?.name}</Typography>
          </Box>
        )}

        {isFilePedingUpload(file) && (
          <Box
            position="absolute"
            width="100%"
            height="100%"
            top={0}
            left={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="#ffffffb3"
          >
            <CircularProgress />
          </Box>
        )}

        {isFileFailUpload(file) && (
          <Box
            position="absolute"
            width="100%"
            height="100%"
            top={0}
            left={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="#ffffffb3"
          >
            <IconButton onClick={onRetry(file)}>
              <ReplayIcon />
            </IconButton>
            <IconButton onClick={() => onDelete(file.index)}>
              <CancelIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gridGap={16} width={450}>
        <Box
          width={450}
          minHeight={600}
          position="relative"
          className="selectedDocument"
        >
          <Box display="flex" flexDirection="column" gridGap={16}>
            {handleRenderSelectedFile(file[currentPhotoSelected])}

            {file[currentPhotoSelected]?.file && (
              <Typography align="center">
                {file[currentPhotoSelected]?.file?.name || ''}
              </Typography>
            )}
          </Box>

          {isFilePedingUpload(file[currentPhotoSelected]) && (
            <Box
              position="absolute"
              width="100%"
              height="100%"
              top={0}
              left={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="#ffffffb3"
            >
              <CircularProgress />
            </Box>
          )}

          {isFileFailUpload(file[currentPhotoSelected]) && (
            <Box
              position="absolute"
              width="100%"
              height="100%"
              top={0}
              left={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="#ffffffb3"
            >
              <IconButton onClick={onRetry(file[currentPhotoSelected])}>
                <ReplayIcon />
              </IconButton>
              <IconButton onClick={() => onDelete(0)}>
                <CancelIcon />
              </IconButton>
            </Box>
          )}
        </Box>

        {containMultipleFile && (
          <Box display="grid" gridTemplateColumns="100px 100px" gridGap={12}>
            {file.map(handleRenderThumbFile)}
          </Box>
        )}
      </Box>

      <Box id="upload-document" css={{ display: 'none' }}>
        <Upload
          buttonProps={{ color: 'primary', variant: 'outlined' }}
          onDrop={onDrop}
        />
      </Box>
    </>
  );
};

export default PreviewComponent;
