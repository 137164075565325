import React, { useState } from 'react';
import flow from 'lodash/flow';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { Box, Button, Typography } from '@material-ui/core';

import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';

import postAccountsUnlockoutService from '@backoffice/private/services/account/postAccountsUnlockout.service';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/merchants/access/listMerchantAccess.duck';
import * as dialogMerchantChangePasswordDuck from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantChangePassword.duck';

const GroupedInfos = ({ date, ip }) => {
  const hasDate = !!date;

  const dateFormatted = hasDate ? moment(date).format('DD/MM/YYYY') : '-';
  const hourFormatted = hasDate ? moment(date).format('HH:mm') : '-';

  return (
    <Box display="flex" flexDirection="column" css={{ lineHeight: '0px' }}>
      <Typography variant="body2">Data: {dateFormatted}</Typography>
      <br />
      <Typography variant="body2">Hora: {hourFormatted}</Typography>
      <br />
      <Typography variant="body2">IP: {ip}</Typography>
    </Box>
  );
};

const TableMerchantAccessContainer = () => {
  const handleNotification = useNotification();

  const [loadingLocal, setLoadingLocal] = useState(false);

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const dispatchDialogCreditorUpdate = {
    OPEN: flow(dialogMerchantChangePasswordDuck.Dispatchs.open, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage } = useCrudRead({
    dispatchRedux,
    selectorRedux,
  });

  const handleUnlock = async ({ email }) => {
    setLoadingLocal(true);

    const response = await postAccountsUnlockoutService(email);
    const result = await response.json();

    if (response.ok) {
      handleNotification('Desbloqueado com sucesso!', 'success');
    }

    if (!response.ok) {
      handleNotification(result?.errors?.message[0], 'error');
    }

    setLoadingLocal(false);
  };

  const handleGeneratePDF = () => {
    const doc: any = new jsPDF('l');

    autoTable(doc, {
      html: '#tableExport table',
      showFoot: false,
    });

    const name = `dados-de-acesso-${+Date.now()}`;

    doc.save(name);
  };

  return (
    <TableMerchantAccessComponent
      loading={selectorRedux.LOADING || loadingLocal}
      results={selectorRedux.DATA_RESULTS}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePassword={dispatchDialogCreditorUpdate.OPEN}
      handleUnlock={handleUnlock}
      handleGeneratePDF={handleGeneratePDF}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const TableMerchantAccessComponent = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  handleChangePassword,
  handleUnlock,
  handleGeneratePDF,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_estabelecimento_dados_acesso',
  });
  const { isAdmin } = useUserHook();

  const { limit, count, offset } = filters || {};

  const columns = [
    isAdmin && {
      title: '',
      width: 300,
      render: (props) => (
        <Box display="flex" alignItems="center" gridGap={12} width="100%">
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => {
              handleChangePassword(props);
            }}
            disabled={!permissions?.some((item) => item == 'edit')}
          >
            Trocar senha
          </Button>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => handleUnlock(props)}
            disabled={!permissions?.some((item) => item == 'edit')}
          >
            Desbloquear
          </Button>
        </Box>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone_number',
      key: 'phone_number',
      width: 150,
      render: ({ phone_number }) => (
        <NumberFormat
          displayType="text"
          value={phone_number}
          isNumericString
          format={
            phone_number.length === 11 ? '(##) #####-####' : '(##) ####-####'
          }
        />
      ),
    },
    {
      title: 'Validação E-mail',
      dataIndex: 'checked_email_date',
      key: 'checked_email_date',
      render: ({ checked_email_date, checked_email_address }) => (
        <GroupedInfos date={checked_email_date} ip={checked_email_address} />
      ),
    },
    {
      title: 'Validação Telefone',
      dataIndex: 'checked_phone_number_date',
      key: 'checked_phone_number_date',
      render: ({ checked_phone_number_date, checked_phone_address }) => (
        <GroupedInfos
          date={checked_phone_number_date}
          ip={checked_phone_address}
        />
      ),
    },
    {
      title: 'Aceite Compart. Agenda com Serasa',
      dataIndex: 'checked_term_partnership',
      key: 'checked_term_partnership',
      render: ({
        checked_term_partnership,
        checked_term_partnership_address,
      }) => (
        <GroupedInfos
          date={checked_term_partnership}
          ip={checked_term_partnership_address}
        />
      ),
    },
    {
      title: 'Aceite do termo',
      dataIndex: 'checked_term_policy_date',
      key: 'checked_term_policy_date',
      render: ({ checked_term_policy_date, checked_term_policy_address }) => (
        <GroupedInfos
          date={checked_term_policy_date}
          ip={checked_term_policy_address}
        />
      ),
    },
    {
      title: 'Selfie',
      dataIndex: 'checked_selfie_date',
      key: 'checked_selfie_date',
      render: ({ checked_selfie_date, checked_selfie_address }) => (
        <GroupedInfos date={checked_selfie_date} ip={checked_selfie_address} />
      ),
    },
  ];

  return (
    <Box gridGap={8} display="flex" flexDirection="column">
      {permissions?.some((item) => item == 'read') && (
        <>
          <CardTitle>Listagem de contas de acesso</CardTitle>

          <Box alignSelf="flex-end">
            <Button
              onClick={handleGeneratePDF}
              variant="outlined"
              color="primary"
            >
              Gerar PDF
            </Button>
          </Box>

          <CardTable
            count={count || 0}
            loading={loading}
            dataSource={results}
            rowsPerPage={limit}
            columns={columns as any}
            page={offset / limit}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            tableProps={{
              style: {
                minWidth: 1800,
              },
            }}
          />
        </>
      )}

      {(!permissions || !permissions?.some((item) => item == 'read')) && (
        <ModuleNotPermited />
      )}

      <Box id="tableExport" css={{ display: 'none' }}>
        <CardTable
          count={count || 0}
          loading={loading}
          dataSource={results}
          rowsPerPage={limit}
          columns={(columns as any).slice(1)}
          page={offset / limit}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          tableProps={{
            style: {
              minWidth: 1800,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default TableMerchantAccessContainer;
