import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useDropzone } from 'react-dropzone';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Button from '../../../shared/components/Button.component';

import { UploadProps } from './Upload.type';

const messages = {
  title: 'Arraste para cá',
  subTitle: 'Ou se preferir',
  buttonText: 'Clique para selecionar',
};

export const Upload: React.FC<UploadProps> = ({
  onDrop: onDropProp,
  dropzoneProps,
  buttonProps,
  ...restProps
}) => {
  const dropzone = useDropzone({ onDrop: onDropProp, ...dropzoneProps });

  return (
    <Box
      {...dropzone.getRootProps()}
      display="flex"
      flexDirection="column"
      border="1px dashed black"
      padding={4}
      gridRowGap={24}
      {...restProps}
    >
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={0.5}
        >
          <CloudUploadIcon css={{ width: 40, height: 40 }} />
        </Box>
        <Typography align="center">{messages.title}</Typography>
        <Typography align="center" variant="caption">
          {messages.subTitle}
        </Typography>
      </Box>

      <Button {...(buttonProps as any)}>{messages.buttonText}</Button>

      <input
        {...dropzone.getInputProps()}
        type="file"
        style={{ display: 'none' }}
      />
    </Box>
  );
};
