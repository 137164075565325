import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { Field } from 'formik';
import { TextField } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import Box from '@material-ui/core/Box';

const FieldColor = ({ name, inputDisabled, ...restProps }) => {
  const Component = ({
    field,
    form: { setFieldValue, setFieldTouched },
  }: any) => {
    const [color, setColor] = useState('');
    const [colorPicker, setColorPicker] = useState(false);

    useEffect(() => {
      setColor(field.value);
    }, []);

    function handleChangeColorComplete(color) {
      setColor(color.hex);
      handleInputChange(color.hex);
    }

    function handleInputChange(hex) {
      setFieldValue(field.name, hex);
    }

    function handleOpenColorPicker() {
      setColorPicker(true);
    }
    function handleCloseColorPicker() {
      setColorPicker(false);
    }

    return (
      <Box
        css={{
          display: 'flex',
          justifyContent: 'flex-start',
          columnGap: 10,
        }}
      >
        <TextField
          required
          id="outlined-required"
          value={field.value}
          label="Cor Principal"
          variant="outlined"
          disabled={inputDisabled}
          onBlur={async ({ target: { value } }) => {
            setFieldTouched(field.name, true);
          }}
          onChange={(values: any) => {
            handleInputChange(values.target.value);
          }}
        />
        {!inputDisabled && (
          <div
            css={css`
              position: relative;
            `}
          >
            <div
              css={{
                border: '1px solid #A8A8A8',
                width: 32,
                height: 55,
                borderRadius: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleOpenColorPicker();
              }}
            >
              <div
                css={{
                  backgroundColor: field.value,
                  width: 24,
                  height: 45,
                  borderRadius: 4,
                }}
              ></div>
            </div>

            {colorPicker && (
              <SketchPicker
                css={{ position: 'absolute', top: 0, right: -220, zIndex: 5 }}
                color={color}
                onChangeComplete={handleChangeColorComplete}
              />
            )}
          </div>
        )}

        {colorPicker && (
          <div
            onClick={() => {
              handleCloseColorPicker();
            }}
            css={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: 4,
            }}
          ></div>
        )}
      </Box>
    );
  };

  return <Field name={name}>{Component}</Field>;
};

export default FieldColor;
