import React from 'react';
import CreateSchedule from '../../../components/Forms/CreateSchedule/CreateSchedule';
import PageLayout from '@backoffice/private/layouts/Page.layout';

const CreateScheduleScreen = () => {
  return (
    <PageLayout
      headerTitle
      header
      sidebar
      title={'Consultar agenda de recebiveis'}
    >
      <CreateSchedule />
    </PageLayout>
  );
};

export default CreateScheduleScreen;
