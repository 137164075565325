import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';

import Accordion from '@backoffice/private/components/Accordion/Accordion.component';
import InputSelectNew from '@backoffice/private/components/Form/Inputs/InputSelectNew.component';

import useCreditors from '@backoffice/private/hooks/useCreditors.hook';

import { Creditor } from '@backoffice/private/@types/models/Creditor';
import InputText from '@backoffice/private/components/Form/Inputs/InputText.component';
import useCreditorOriginators from '@backoffice/private/hooks/useCreditorOriginators.hook';
import useCreditorOriginatorDomiciles from '@backoffice/private/hooks/useCreditorOriginatorDomiciles.hook';
import { Originator } from '@backoffice/private/@types/models/Originator';
import { Domicile } from '@backoffice/private/@types/models/Domicile';
import CardDetail from '../../../CardDetail/CardDetail.component';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';

type PartnerStepProps = {
  creditorId: string | null;
  handleGetCreditorInfos: (creditorId: string) => void;
  parameters: {
    tipo_parceiro: string;
  };
  setFieldValue: any;
};

const PartnerStep = ({
  handleGetCreditorInfos,
  creditorId,
  parameters,
  setFieldValue,
}: PartnerStepProps) => {
  const [originatorId, setOriginatorId] = useState<string | null>(null);
  const [selectedOriginator, setSelectedOriginator] =
    useState<Originator | null>(null);

  const [domicileId, setDomicileId] = useState<string | null>(null);
  const [selectedDomicile, setSelectedDomicile] = useState<Domicile | null>(
    null
  );

  const { isAdmin, user } = useUserHook();

  const { tipo_parceiro } = parameters;

  const creditors: Creditor[] = useCreditors();
  const { originators, handleSetOriginators, handleResetOriginators } =
    useCreditorOriginators();
  const { domiciles, handleSetDomiciles } = useCreditorOriginatorDomiciles();

  const hasOriginatorSelected = selectedOriginator;

  const handleGetDomiciles = (id: string) => {
    const originator = originators.filter((item) => item.cnpj_originador == id);

    setOriginatorId(id);
    handleSetDomiciles(id, originators);

    setSelectedOriginator(originator[0]);
  };

  const handleSetDomicile = (id) => {
    const domicile = domiciles.filter((item) => item.id == id);

    setDomicileId(id);
    setSelectedDomicile(domicile[0]);
  };

  const handleResetOriginatorDomicile = (onlyDomicile: boolean) => {
    setFieldValue('creditor_originator_bank_account_id', null);
    setDomicileId(null);
    setSelectedDomicile(null);
    handleSetDomicile(null);

    if (!onlyDomicile) {
      setFieldValue('originator', null);
      setOriginatorId(null);
      setSelectedOriginator(null);
    }
  };

  useEffect(() => {
    if (!isAdmin) {
      const { personId } = user || {};

      handleGetCreditorInfos(personId);
      setFieldValue('creditor_id', personId);
    }
  }, []);

  //Get originators only when conta_liquidacao_urs is 'Parceiro'
  useEffect(() => {
    if (creditorId) {
      handleSetOriginators(creditorId);
    }
  }, [creditorId]);

  //Automatically select originator when only has one
  useEffect(() => {
    if (originators && originators.length == 1) {
      const originatorToAutoSelect = originators[0];
      setOriginatorId(originatorToAutoSelect.cnpj_originador);
      setSelectedOriginator(originatorToAutoSelect);
      handleGetDomiciles(originatorToAutoSelect.cnpj_originador);
    }
  }, [originators]);

  useEffect(() => {
    if (domiciles && domiciles.length == 1) {
      const domicileToAutoSelect = domiciles[0];
      setDomicileId(domicileToAutoSelect.id);
      setSelectedDomicile(domicileToAutoSelect);
      setFieldValue(
        'creditor_originator_bank_account_id',
        domicileToAutoSelect.id
      );
    }
  }, [domiciles]);

  useEffect(() => {
    return () => {
      handleResetOriginators();
    };
  }, []);

  return (
    <Accordion title="Parceiro">
      <Box display="flex" gridGap={16}>
        <InputSelectNew
          inputWidth="50%"
          name="creditor_id"
          label="Fornecedor"
          labelId="fornecedor"
          options={creditors}
          disabled={!isAdmin}
          keyName="id"
          optionName="trading_name"
          value={creditorId || ''}
          onChange={(e, option) => {
            const value = option.props.value;

            handleGetCreditorInfos(value);
            setFieldValue('creditor_id', value);

            handleResetOriginatorDomicile(false);
          }}
          required
        />
        <InputText
          inputWidth="50%"
          name="tipo_parceiro"
          label="Tipo de parceiro"
          disabled
          value={tipo_parceiro}
        />
      </Box>

      <Box>
        <Box display="flex" gridGap={24} marginTop={'16px'}>
          <InputSelectNew
            name="originator"
            label="Originador"
            labelId="originador"
            keyName="cnpj_originador"
            optionName="razao_social"
            value={originatorId || ''}
            disabled={originators.length <= 1}
            options={originators}
            onChange={(e, option) => {
              handleResetOriginatorDomicile(true);
              handleGetDomiciles(option.props.value);
            }}
            required
          />
          <InputSelectNew
            name="creditor_originator_bank_account_id"
            label="Domicílio"
            labelId="domicilio"
            keyName="id"
            optionName="titulo_domicilio"
            value={domicileId || ''}
            options={domiciles}
            disabled={domiciles.length <= 1 || !hasOriginatorSelected}
            onChange={(e, option) => {
              const value = option.props.value;

              handleSetDomicile(value);
              setFieldValue('creditor_originator_bank_account_id', value);
            }}
            required
          />
        </Box>
        <Box display={'flex'}>
          {selectedOriginator && (
            <Grid item xs={6}>
              <CardDetail data={selectedOriginator} title="Originador" />
            </Grid>
          )}
          {selectedDomicile && (
            <Grid item xs={6}>
              <CardDetail data={selectedDomicile} title="Domicílio" />
            </Grid>
          )}
        </Box>
      </Box>
    </Accordion>
  );
};

export default PartnerStep;
