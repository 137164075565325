import flow from 'lodash/flow';
import get from 'lodash/get';

import { setError } from '@payhop/shared-ducks/error.duck';

import fetch from '@payhop/shared-utils/fetch.util';

import { Selectors as SelectorsCreditor } from '../creditor.duck';
import { Originator } from '@backoffice/private/@types/models/Originator';
import { Result } from '@backoffice/private/@types/models/Result';
import { RootState } from '@backoffice/store';

const context = `private/creditors/originators/originator`;
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
  error?: any;
};

type ResultOriginators = Result & {
  results: Originator[];
};

export const Types = {
  INIT: `${context}/INIT`,
  SUCCESS: `${context}/SUCCESS`,
  RESET: `${context}/RESET`,
  RESET_NOTIFICATION: `${context}/RESET/NOTIFICATION`,
  ERROR: `${context}/ERROR`,
};

const initialState: any = {
  data: null,
  loading: null,
  error: null,
  fetched: null,
  notification: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload } = action;

  const reducers = {
    [Types.RESET]: {
      ...initialState,
    },
    [Types.INIT]: {
      ...state,
      error: null,
      notification: null,
      loading: true,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
      error: false,
      data: payload,
      fetched: true,
      notification: false,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error: true,
      data: payload,
      notification: true,
    },
    [Types.RESET_NOTIFICATION]: {
      ...state,
      notification: initialState.notification,
    },
  };

  return reducers[type] || state;
};

export const fetchData = (id?: string) => async (dispatch, getState) => {
  const idCreditor: string = flow(getState, SelectorsCreditor.data)()?.id;

  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(
      `parceiro/originador?creditor_id=${id ? id : idCreditor}&backoffice=true`,
      {
        method: 'GET',
        ms: 'API',
        auth: true,
        bearer: false,
      }
    );

    const result: ResultOriginators = await response.json();

    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: result,
    });
  } catch (result: any) {
    dispatch(
      setError({
        messages: result?.errors?.message,
        data: result,
        visible: false,
        context,
      })
    );

    return false;
  }
};

export const createDispatch = (data, id) => async (dispatch, getState) => {
  const idCreditor = flow(getState, SelectorsCreditor.data)()?.id || id;

  const newData = { ...data };

  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(
      `parceiro/originador?creditor_id=${idCreditor}`,
      {
        method: 'POST',
        ms: 'API',
        auth: true,
        bearer: false,
        body: JSON.stringify(newData),
      }
    );

    const result = await response.json();

    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: {
        successAction: true,
      },
    });

    return result.originadores;
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
      payload: {
        successAction: false,
        error: result?.errors?.message?.[0],
      },
    });

    return false;
  }
};

export const updateData = (data) => async (dispatch, getState) => {
  const idCreditor = flow(getState, SelectorsCreditor.data)()?.id;

  const newData = { ...data };

  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(
      `parceiro/originador?creditor_id=${idCreditor}&backoffice=true`,
      {
        method: 'PUT',
        ms: 'API',
        auth: true,
        bearer: false,
        body: JSON.stringify(newData),
      }
    );

    const result = await response.json();

    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: {
        successAction: true,
      },
    });

    return result.originadores;
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
      payload: {
        successAction: false,
        error: result?.errors?.message?.[0],
      },
    });

    return false;
  }
};

export const resetData = () => ({
  type: Types.RESET,
});

export const resetNotification = () => ({
  type: Types.RESET_NOTIFICATION,
});

const selectRoot = (state: RootState) => get(state, rootPathState);
export const selectData = (state: RootState) =>
  get(state, `${rootPathState}.data`);
const selectLoading = (state: RootState) => get(state, 'loading');
const selectFetched = (state: RootState) => get(state, 'fetched');
const selectError = (state: RootState) => get(state, 'error');
const selectNotification = (state: RootState) => get(state, 'notification');

export const Selectors = {
  data: flow(selectRoot, selectData),
  loading: flow(selectRoot, selectLoading),
  fetched: flow(selectRoot, selectFetched),
  error: flow(selectRoot, selectError),
  notification: flow(selectRoot, selectNotification),
};

export const Dispatchs = {
  get: fetchData,
  reset: resetData,
  create: createDispatch,
  update: updateData,
  reset_notification: resetNotification,
};
