import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  Fragment,
} from 'react';
import moment from 'moment';

import CardTable from '@backoffice/private/components/Card/CardTable';

interface ScheduleAnalytical {
  settlement_date: string;
  merchant_name: string;
  merchant_document_number: string;
  acquirer_name: string;
  value_type: string;
  amount_total: string;
  amount_available: string;
}

const TableScheduleAnalyticalComponent = ({ analytical, loading }) => {
  const [scheduleAnalytical, setScheduleAnalytical] = useState<
    ScheduleAnalytical[]
  >([]);
  const [analyticalLoading, setAnalyticalLoading] = useState(false);

  useEffect(() => {
    setScheduleAnalytical(analytical);
  }, [analytical]);

  const handleMaskDocument = (document) => {
    const stringDocument = String(document);

    if (stringDocument.length === 11) {
      return stringDocument.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
        '$1.$2.$3-$4'
      );
    }
    if (stringDocument.length === 14) {
      return stringDocument.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
        '$1.$2.$3/$4-$5'
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        title: 'Data Liquidação',
        dataIndex: 'settlement_date',
        render: ({ settlement_date }) =>
          moment.utc(settlement_date).format('DD/MM/YYYY'),
      },
      {
        title: 'Estabelecimento',
        dataIndex: 'merchant_name',
        key: 'merchant_name',
      },
      {
        title: 'CNPJ',
        render: ({ merchant_document_number }) =>
          handleMaskDocument(merchant_document_number),
      },
      {
        title: 'Credenciadora',
        dataIndex: 'acquirer_name',
        key: 'acquirer_name',
      },
      {
        title: 'Arranjo / Tipo',
        dataIndex: 'value_type',
        key: 'value_type',
      },
      {
        title: 'Valor Total',
        dataIndex: 'amount_total',
        key: 'amount_total',
        render: ({ amount_total }) =>
          amount_total ? (
            Number(amount_total).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          ) : (
            <Fragment />
          ),
      },
      {
        title: 'Valor Disponivel',
        dataIndex: 'amount_available',
        key: 'amount_available',
        render: ({ amount_available }) =>
          amount_available ? (
            Number(amount_available).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          ) : (
            <Fragment />
          ),
      },
    ],
    []
  );

  return (
    <>
      <CardTable
        loading={loading}
        dataSource={scheduleAnalytical}
        columns={columns}
        footer={false}
      />
    </>
  );
};

export default TableScheduleAnalyticalComponent;
