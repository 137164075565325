import fetch from '@payhop/shared-utils/fetch.util';

export default async function getMerchantAcquirers(documentNumber: string) {
  const response = await fetch(
    `merchants/relations?documentNumber=${documentNumber}&limit=9999`,
    {
      method: 'GET',
      ms: 'MERCHANT',
      auth: true,
    }
  );

  const result = await response.json();

  return result;
}
