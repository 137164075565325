import * as Yup from 'yup';

import messages from '@backoffice/shared/utils/messagesSchema.util';

const loginSchema = Yup.object({
  email: Yup.string()
    .email(messages.VALIDATION_EMAIL)
    .required(messages.REQUIRED),
  password: Yup.string().required(messages.REQUIRED),
});

export default loginSchema;
