import React, { useState, useEffect } from 'react';
import { flow } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import * as dialogCreditorCreateOriginatorDuck from '@backoffice/private/ducks/creditors/dialogs/dialogCreditorCreateOriginator.duck';
import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/originators/creditorOriginators.duck';

import {
  Dispatchs as DispatchsOriginators,
  Selectors as SelectorsOriginators,
} from '@backoffice/private/ducks/creditors/originators/listCreditorOriginators.duck';

import { Dialog, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Steps from '@backoffice/private/components/Form/Steps/Steps.component';
import FormCreditorOriginator from '../Form/CreditorOriginator/FormCreditorOriginator.component';
import FormCreditorDomicile from '../Form/CreditorDomicile/FormCreditorDomicile.component';
import ResumeCreateOriginator from '../ResumeCreateOriginator.component';
import { Originator } from '@backoffice/private/@types/models/Originator';

export enum ISteps {
  Originator = 1,
  Domicile = 2,
  Resume = 3,
}

const DialogCreateOriginator = ({ ...props }) => {
  const steps = [
    {
      pass: ISteps.Originator,
      name: 'Originador',
      isActive: true,
      isClickable: true,
      isDone: false,
    },
    {
      pass: ISteps.Domicile,
      name: 'Domicílio',
      isActive: false,
      isClickable: false,
      isDone: false,
    },
    {
      pass: ISteps.Resume,
      name: 'Resumo',
      isActive: false,
      isClickable: false,
      isDone: false,
    },
  ];

  const [stepsInfo, setStepsInfo] = useState(steps);
  const [data, setData] = useState({});
  const [responseData, setResponseData] = useState<Originator | null>(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const dispatch = useDispatch();

  const dispatchRedux = {
    CLOSE: flow(dialogCreditorCreateOriginatorDuck.Dispatchs.close, dispatch),
    CONTROL_STEP: flow(
      dialogCreditorCreateOriginatorDuck.Dispatchs.controlStep,
      dispatch
    ),
  };

  const dispatchListOriginators = {
    GET: flow(DispatchsOriginators.get, dispatch),
  };

  const dispatchReduxOriginators = {
    CREATE: flow(Dispatchs.create, dispatch),
  };

  const selectorRedux = {
    visible: useSelector(dialogCreditorCreateOriginatorDuck.Selectors.visible),
    payload: useSelector(dialogCreditorCreateOriginatorDuck.Selectors.payload),
    step: useSelector(dialogCreditorCreateOriginatorDuck.Selectors.step),
  };

  useEffect(() => {
    if (!selectorRedux.visible) {
      setStepsInfo(steps);
      setData({});
      setFormSubmitted(false);
    }
  }, [selectorRedux.visible]);

  useEffect(() => {
    if (formSubmitted) {
      const fetchData = async () => {
        try {
          const response = await dispatchReduxOriginators.CREATE(
            {
              originadores: [data],
            },
            null
          );
          setResponseData(response[0]);
        } catch (error) {
          console.error('Erro na requisição:', error);
        }
      };

      fetchData();
    }
  }, [formSubmitted]);

  useEffect(() => {
    if (responseData && formSubmitted) {
      if (
        responseData?.status === 'Ativo' &&
        responseData?.domicilios &&
        responseData?.domicilios[0]?.status === 'Ativo'
      ) {
        setStepsInfo((prevState) =>
          prevState.map((item) => {
            item.isClickable = false;
            return item;
          })
        );
      }
    }
  }, [responseData, formSubmitted]);

  const handleChangeStep = (step) => {
    dispatchRedux.CONTROL_STEP(step);

    setStepsInfo((prevState) =>
      prevState.map((item) => {
        if (item.pass == step) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }

        return item;
      })
    );
  };

  const handleSubmitStep = (values, step) => {
    setData((prevState) => ({
      ...prevState,
      ...(step === ISteps.Domicile ? { domicilios: [{ ...values }] } : values),
    }));
    setFormSubmitted(true);

    setStepsInfo((prevState) =>
      prevState.map((item) => {
        if (item.pass === step) {
          item.isDone = true;
        }

        const nextStep = step + 1;

        if (item.pass === nextStep) {
          item.isClickable = true;
        }

        return item;
      })
    );

    handleChangeStep(step + 1);
  };

  const handleSubmitForm = (values, step) => {
    handleSubmitStep(values, step);
  };

  const handleResetFormSubmitted = () => {
    setFormSubmitted(false);
  };

  const handleCloseModal = (reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      dispatchRedux.CLOSE(dispatchListOriginators.GET);
    }
  };

  return (
    <Dialog
      open={selectorRedux.visible}
      onClose={(_, reason) => handleCloseModal(reason)}
      aria-labelledby="form-dialog-title"
      maxWidth={'lg'}
      {...props}
    >
      <Box p={3} position="relative" pt={6}>
        <Box position="absolute" top={8} right={8}>
          <IconButton
            onClick={() => dispatchRedux.CLOSE(dispatchListOriginators.GET)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Steps steps={stepsInfo} handleChangeStep={handleChangeStep} />

        <Box padding={2}>
          {selectorRedux.step === ISteps.Originator && (
            <FormCreditorOriginator
              handleSubmitForm={handleSubmitForm}
              handleResetFormSubmitted={handleResetFormSubmitted}
              data={data}
            />
          )}
          {selectorRedux.step === ISteps.Domicile && (
            <FormCreditorDomicile
              handleSubmitForm={handleSubmitForm}
              handleResetFormSubmitted={handleResetFormSubmitted}
              data={data}
            />
          )}
          {selectorRedux.step === ISteps.Resume && (
            <ResumeCreateOriginator data={responseData} />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogCreateOriginator;
