import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import TitlePage from '@backoffice/private/components/TitlePage';

const PageLayout: React.FC<any> = ({
  breadcrumbs = true,
  cta,
  ctaBack,
  title,
}) => {
  const history = useHistory();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <TitlePage breadcrumbs={breadcrumbs} title={title} />

      {cta && cta}

      {ctaBack && (
        <Button
          color="primary"
          variant="contained"
          size="small"
          css={{ width: 230 }}
          onClick={() => history.goBack()}
        >
          Voltar para a listagem
        </Button>
      )}
    </Box>
  );
};

export default PageLayout;
