import React from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Button from '@backoffice/shared/components/Button.component';

import CloseIcon from '@material-ui/icons/Close';

import * as listMerchantDocumentDetailDuck from '@backoffice/private/ducks/merchants/documentDetail/listMerchantDocumentDetail.duck';

import TableMerchant from '../containers/TableMerchant.container';

import Preview from './PreviewDocument.component';

import DialogDocumentReproveContainer from '../containers/DialogDocumentReprove.container';

const DialogDocumentComponent = ({
  open,
  onClose,
  onAprove,
  onReprove,
  documentType,
  loading,
  document,
  onDrop,
  onRetry,
  onDelete,
  documentNumberType,
  urlContract,
}) => {
  const selectorRedux = {
    results: useSelector(listMerchantDocumentDetailDuck.Selectors.data_results),
  };

  const handleClick = () => {
    const btn = window.document.querySelector('#upload-document button');

    (btn as any).click();
  };

  const fatceMatch = selectorRedux.results?.find(
    (r) => r.campo === 'Grau Face Match'
  );

  return (
    <Dialog
      fullScreen
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box display="flex" pt={12} pb={5} px={2} flexDirection="column">
        <Box
          display="flex"
          justifyContent="center"
          alignSelf="center"
          gridGap={48}
          width="100%"
          maxWidth={1200}
        >
          <Box flex={1} display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" flexDirection="column" gridGap={12}>
                <Typography variant="h6" css={{ fontWeight: 700 }}>
                  {documentType}
                </Typography>
                <Typography>Informações preenchidas no cadastro</Typography>
              </Box>
              <Box display="flex" flexDirection="column" gridGap={8}>
                {urlContract && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => window.open(urlContract)}
                  >
                    {documentNumberType === 1 ? 'Ver contrato' : 'Visualizar'}
                  </Button>
                )}
                <Button variant="contained" size="small" onClick={handleClick}>
                  Upload
                </Button>
              </Box>
            </Box>
            <Box mt={4}>
              <TableMerchant type="documentDetail" />
            </Box>
            <Box
              mt={4}
              display="flex"
              gridGap={8}
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onAprove}
                disabled={loading}
              >
                Aprovar
              </Button>
              <Button variant="outlined" onClick={onReprove} disabled={loading}>
                Reprovar
              </Button>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gridGap={8}>
            {fatceMatch?.ocr?.[0] && (
              <Typography variant="h5">
                Grau Face Match:{' '}
                {Number(fatceMatch?.ocr?.[0]).toLocaleString('pt-br')}%
              </Typography>
            )}
            <Preview
              file={document}
              onDrop={onDrop}
              onRetry={onRetry}
              onDelete={onDelete}
            />
          </Box>
        </Box>
      </Box>

      <DialogDocumentReproveContainer />
    </Dialog>
  );
};

export default DialogDocumentComponent;
