import { useMemo, Fragment, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardSearch from '@backoffice/private/components/Card/CardSearch';

import ROUTES from '@backoffice/shared/utils/routes.util';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/listCreditors.duck';
import { v4 } from 'uuid';
import getExportCreditorsService from '@backoffice/private/services/export/getExportCreditors.service';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
const texts = {
  title: 'Fornecedores',
  buttonAdd: 'Adicionar fornecedor',
};

const schemaSearch = [
  [
    {
      name: 'name',
      label: 'Nome Fantasia ou Razão Social',
    },
    {
      name: 'document',
      label: 'CNPJ/CPF',
    },
  ],
];

const ProvidersContainer = () => {
  const dispatch = useDispatch();
  const handleNotification = useNotification();

  const { accountId } = useUserHook();

  const [key, setKey] = useState(v4());

  const dispatchRedux = {
    UPDATE_FILTERS: flow(Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(Dispatchs.resetFilters, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    DELETE: flow(Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    DATA_RESULTS: useSelector(Selectors.data_results),
    FILTERS: useSelector(Selectors.filters),
    RESULTSET: useSelector(Selectors.resultset),
    FETCHED: useSelector(Selectors.fetched),
  };

  const handleOnRefresh = () => {
    dispatchRedux.GET();
  };

  const handleOnExport = async () => {
    await getExportCreditorsService(accountId);

    handleNotification(
      'Sua solicitação foi enviada, em alguns minutos você receberá uma notificação',
      'success'
    );
  };

  const {
    handleDelete,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearch,
    handleSearchReset,
  } = useCrudRead({
    dispatchRedux,
    selectorRedux,
  });

  useEffect(() => {
    setKey(v4());
  }, [selectorRedux.FILTERS]);

  return (
    <ProvidersScreen
      loading={selectorRedux.LOADING}
      results={selectorRedux.DATA_RESULTS}
      key={key}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleSearch={handleSearch}
      handleSearchReset={handleSearchReset}
      handleOnRefresh={handleOnRefresh}
      handleOnExport={handleOnExport}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const ProvidersScreen = ({
  loading,
  results,
  key,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleSearchReset,
  handleOnRefresh,
  handleOnExport,
}) => {
  const history = useHistory();
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_fornecedor',
  });

  const columns = useMemo(
    () => [
      {
        width: 100,
        render: ({ id }) => (
          <Box display="flex">
            <IconButton
              onClick={() => history.push(ROUTES.PRIVATE.CREDITORS.EDIT(id))}
              disabled={!permissions?.some((item) => item == 'edit')}
            >
              <EditIcon />
            </IconButton>
          </Box>
        ),
      },
      {
        title: 'CNPJ',
        dataIndex: 'document_number',
        key: 'document_number',
        render: ({ document_number }) => (
          <NumberFormat
            displayType="text"
            value={document_number}
            isNumericString
            format="##.###.###/####-##"
          />
        ),
      },
      {
        title: 'Nome Fantasia',
        dataIndex: 'trading_name',
        key: 'trading_name',
      },
      {
        title: 'Razão Social',
        dataIndex: 'company_name',
        key: 'company_name',
      },
      {
        title: 'Ativo?',
        dataIndex: 'active',
        key: 'active',
        render: ({ active }) => (active ? 'Sim' : 'Não'),
      },
      {
        title: 'Data de Cadastro',
        dataIndex: 'created_at',
        key: 'created_at',
        render: ({ created_at }) =>
          moment(created_at).format('DD/MM/YYYY HH:mm'),
      },
    ],
    [permissions]
  );

  const { limit, count, offset } = filters || {};

  return (
    <Fragment>
      <PageLayout
        headerTitle
        header
        sidebar
        title={texts.title}
        cta={
          <Button
            variant="outlined"
            color="primary"
            css={{ minWidth: 230 }}
            disabled={!permissions?.some((item) => item == 'create')}
            onClick={() => history.push(ROUTES.PRIVATE.CREDITORS.CREATE)}
          >
            {texts.buttonAdd}
          </Button>
        }
      >
        {permissions?.some((item) => item == 'read') && (
          <Box display="flex" flexDirection="column" gridGap={32}>
            <Formik
              initialValues={{
                name: filters.name ?? '',
                document: filters.document ?? '',
              }}
              onSubmit={handleSearch}
              key={key}
            >
              {({ submitForm, resetForm }) => (
                <CardSearch
                  fields={schemaSearch}
                  disabled={loading}
                  formik
                  onSubmit={submitForm}
                  onReset={() => handleSearchReset()(resetForm)}
                />
              )}
            </Formik>

            <CardTable
              count={count || 0}
              loading={loading}
              dataSource={results}
              rowsPerPage={limit}
              columns={columns}
              page={offset / limit}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onExport={handleOnExport}
              onRefresh={handleOnRefresh}
              exporttype="api"
              exportname="creditors"
            />
          </Box>
        )}
        {!permissions ||
          (!permissions?.some((item) => item == 'read') && (
            <ModuleNotPermited />
          ))}
      </PageLayout>
    </Fragment>
  );
};

export default ProvidersContainer;
