import React, { useMemo } from 'react';
import moment from 'moment';

import CardTable from '@backoffice/private/components/Card/CardTable';
import { DIVISON_RULE_LABELS } from '@backoffice/private/constants/contract/divison_rule.constant';

const TableDealDetailComponent = ({ results, loading }) => {
  const columns = useMemo(
    () => [
      {
        title: 'Parcela',
        dataIndex: 'installment',
        key: 'installment',
      },
      {
        title: 'Vencimento',
        dataIndex: 'due_date',
        key: 'due_date',
        render: ({ due_date }) => moment(due_date).format('DD/MM/YYYY'),
      },
      {
        title: 'Execução Inicio',
        dataIndex: 'execution_date',
        key: 'execution_date',
        render: ({ execution_date }) =>
          moment(execution_date).format('DD/MM/YYYY'),
      },
      {
        title: 'Execução Fim',
        dataIndex: 'execution_date_end',
        key: 'execution_date_end',
        render: ({ execution_date_end }) =>
          execution_date_end
            ? moment(execution_date_end).format('DD/MM/YYYY')
            : '-',
      },
      {
        title: 'Regra de Divisão',
        dataIndex: 'division_rule',
        key: 'division_rule',
        render: ({ strategy, division_rule }) =>
          strategy == 1 ? DIVISON_RULE_LABELS[division_rule] : '-',
      },
      {
        title: 'Valor a onerar',
        dataIndex: 'amount_onerate',
        key: 'amount_onerate',
        render: ({ strategy, amount_onerate, division_rule }) => {
          if (strategy == 1) {
            if (division_rule == '1') {
              Number(amount_onerate).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            } else {
              const formatoPorcentagem = new Intl.NumberFormat('pt-BR', {
                style: 'percent',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });

              const numberFiltered = Number(amount_onerate) / 100;

              const numberPercent = formatoPorcentagem.format(
                Number(numberFiltered)
              );

              return numberPercent;
            }
          } else {
            return '-';
          }
        },
      },
      {
        title: 'Valor Total',
        dataIndex: 'amount',
        key: 'amount',
        render: ({ amount }) =>
          Number(amount).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
      },
      {
        title: 'Valor comprometido',
        dataIndex: 'amount_total_committed',
        key: 'amount_total_committed',
        render: ({ amount_total_committed }) =>
          Number(amount_total_committed).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
      },
      {
        title: 'Valor do Efeito Inicial',
        dataIndex: 'effect_amount_initial',
        key: 'effect_amount_initial',
        render: ({ effect_amount_initial }) =>
          Number(effect_amount_initial ?? '0.00').toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }) ?? '-',
      },
      {
        title: 'Valor do efeito',
        dataIndex: 'effect_amount',
        key: 'effect_amount',
        render: ({ effect_amount }) =>
          Number(effect_amount ?? '0.00').toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
      },
      {
        title: 'Valor de Efeito Atualizado',
        dataIndex: 'effect_amount_updated',
        key: 'effect_amount_updated',
        render: ({ effect_amount_updated }) =>
          Number(effect_amount_updated ?? '0.00').toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
      },
      {
        title: 'Data de Atualização',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: ({ updated_at }) => moment(updated_at).format('DD/MM/YYYY'),
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Porcentagem',
        dataIndex: 'min_percentage',
        key: 'min_percentage',
        render: ({ min_percentage }) =>
          `${Number(min_percentage).toLocaleString('pt-br', {
            style: 'decimal',
          })}%`,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
    ],
    []
  );

  return (
    <>
      <CardTable
        loading={loading}
        dataSource={results}
        columns={columns}
        footer={false}
      />
    </>
  );
};

export default TableDealDetailComponent;
