import React from 'react';
import { useHistory } from 'react-router-dom';

const useHandleTabRoute = () => {
  const history = useHistory();

  const handleTabRoute = (value) => {
    const parameters = new URLSearchParams(history.location.search);
    if (value !== 0) {
      parameters.set('tab', value);
    } else {
      parameters.delete('tab');
    }
    history.replace({ search: parameters.toString() });
  };

  return {
    handleTabRoute,
  };
};

export default useHandleTabRoute;
