import { useState } from 'react';

import { Originator } from '../@types/models/Originator';
import { Domicile } from '../@types/models/Domicile';

export default function useCreditorOriginatorDomiciles() {
  const [domiciles, setDomiciles] = useState<Domicile[]>([]);

  const handleSetDomiciles = async (
    cnpj_originator: string,
    originators: Originator[]
  ) => {
    const originator: Originator[] = originators.filter(
      (item) => item.cnpj_originador == cnpj_originator
    );

    const originatorObj: Originator = originator[0];
    const domiciles: Domicile[] = originatorObj.domicilios;

    const activeDomiciles = domiciles.filter((item) => item.status === 'Ativo');

    setDomiciles(activeDomiciles);
  };

  return { domiciles, handleSetDomiciles };
}
