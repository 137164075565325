import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import { Dispatchs } from '@backoffice/private/ducks/merchants/listMerchants.duck';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import MerchantsScreen from './screens/Merchants.screen';
import CreateEditMerchantscreen from './screens/CreateEditMerchant.screen';

const MerchantsModule: React.FC<any> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { permissions } = useAccountPermission({
    permission_slug: 'cad_estabelecimento',
  });

  const dispatchRedux = {
    RESET: flow(Dispatchs.reset, dispatch),
  };

  useEffect(() => {
    return () => {
      const location = history.location.pathname;

      if (!location.includes('merchants')) dispatchRedux.RESET();
    };
  }, []);

  return (
    <>
      {permissions && (
        <Switch>
          <Route
            exact
            path={ROUTES.PRIVATE.MERCHANTS.ROOT}
            component={MerchantsScreen}
          />
          {permissions?.some((item) => item == 'create') && (
            <Route
              exact
              path={ROUTES.PRIVATE.MERCHANTS.CREATE}
              component={CreateEditMerchantscreen}
            />
          )}

          {permissions?.some((item) => item == 'edit') && (
            <Route
              exact
              path={ROUTES.PRIVATE.MERCHANTS.EDIT()}
              component={CreateEditMerchantscreen}
            />
          )}

          <Redirect to={ROUTES.PRIVATE.HOME} />
        </Switch>
      )}
    </>
  );
};

export default MerchantsModule;
