import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { flow } from 'lodash';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import NotiIcon from '@material-ui/icons/Notifications';
import Logo from '@payhop/shared-components/Logo';

import { logoff } from '@payhop/shared-ducks/authentication.duck';
import { Dispatchs } from '@backoffice/private/ducks/accounts/accountResources.duck';
import * as AuthenticationDuck from '@payhop/shared-ducks/authentication.duck';

import { useHistory } from 'react-router-dom';
import { Typography, Divider, Badge } from '@material-ui/core';
import { useNotificationDrawer } from '../NotificationDrawer/NotificationDrawer.context';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

interface HeaderProps {
  history: any;
}

const HeaderComponent: React.FC<HeaderProps> = ({ history }) => {
  const { toggleVisible: toggleVisibleDrawer, unreads }: any =
    useNotificationDrawer();

  const dispatch = useDispatch();

  const dark = false;

  const classes = useStyles();

  const dispatchAccountRedux = {
    RESET: flow(Dispatchs.reset, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(AuthenticationDuck.Selectors.data),
  };

  return (
    <AppBar position="static" elevation={1} component="header" color="inherit">
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push('/app');
          }}
        >
          <Logo css={{ maxWidth: 100 }} />
        </IconButton>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems={'center'}
          flex={1}
        >
          <Box>
            <IconButton onClick={toggleVisibleDrawer}>
              <Badge
                badgeContent={unreads}
                color="primary"
                css={{
                  '.MuiBadge-badge': {
                    color: 'white !important',
                    fontSize: 12,
                    height: 18,
                    width: 18,
                    minWidth: 18,
                  },
                }}
              >
                <NotiIcon />
              </Badge>
            </IconButton>
          </Box>
          <Divider orientation="vertical" flexItem css={{ margin: '0 12px' }} />
          <Box css={{ textTransform: 'uppercase' }}>
            <Typography
              css={{
                fontSize: '10px',
                color: '#757575',
              }}
            >
              Logado como
            </Typography>
            <Typography
              css={{
                fontSize: '12px',
              }}
            >
              {selectorRedux.DATA?.name}
            </Typography>
            <Typography css={{ fontSize: '10px' }}>
              {selectorRedux.DATA?.companyName}
            </Typography>
          </Box>
          <IconButton color="inherit">
            <PowerSettingsNewIcon
              onClick={() => {
                dispatch(logoff());
                dispatchAccountRedux.RESET();
              }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const HeaderContainer = () => {
  const history = useHistory();

  return <HeaderComponent history={history} />;
};

export default HeaderContainer;
