import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import { Dispatchs as DispatchAcquirers } from '@backoffice/private/ducks/parameters/acquirers/listAcquirers.duck';
import { Dispatchs as DispatchArrangements } from '@backoffice/private/ducks/parameters/arrangements/listArrangements.duck';
import { Dispatchs as DispatchBanks } from '@backoffice/private/ducks/parameters/banks/listBanks.duck';

import ParametersScreen from './screens/Parameters.screen';
import EditParameterScreen from './screens/EditParameter.screen';

const ParametersModule: React.FC<any> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const dispatchReduxAcquirers = {
    RESET: flow(DispatchAcquirers.reset, dispatch),
  };
  const dispatchReduxArrangements = {
    RESET: flow(DispatchArrangements.reset, dispatch),
  };
  const dispatchReduxBanks = {
    RESET: flow(DispatchBanks.reset, dispatch),
  };

  useEffect(() => {
    return () => {
      const location = history.location.pathname;

      if (!location.includes('parameters')) {
        dispatchReduxAcquirers.RESET();
        dispatchReduxArrangements.RESET();
        dispatchReduxBanks.RESET();
      }
    };
  }, []);
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.PRIVATE.PARAMETERS.ROOT}
        component={ParametersScreen}
      />

      <Route
        exact
        path={ROUTES.PRIVATE.PARAMETERS.EDIT()}
        component={EditParameterScreen}
      />

      <Redirect to={ROUTES.PRIVATE.HOME} />
    </Switch>
  );
};

export default ParametersModule;
