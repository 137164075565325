import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import CardForm from '@backoffice/private/components/Card/CardForm';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';

const CreateDocScreen = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const isEdit = useMemo(
    () => pathname.split('/').splice(-1)[0] === 'edit',
    [pathname]
  );

  const titlePage = useMemo(
    () => (isEdit ? 'Editar vendedor' : 'Cadastro de vendedor'),
    [isEdit]
  );

  const handleBack = () => history.goBack();

  return (
    <PageLayout headerTitle header sidebar title={titlePage}>
      <Box display="flex" gridGap={24} flexDirection="column">
        <CardForm
          fields={[
            [
              {
                name: 'profile_id',
                label: 'Fornecedor',
                type: 'select',
                options: [],
              },
            ],
            [
              {
                name: 'name',
                label: 'Nome completo',
                required: true,
              },
              {
                name: 'email',
                label: 'E-mail',
                required: true,
              },
            ],
          ]}
        />

        <CardFormFooter
          hasError={false}
          onSubmit={() => {}}
          onCancel={handleBack}
        />
      </Box>
    </PageLayout>
  );
};

export default CreateDocScreen;
