import React from 'react';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';

import CardTable from '@backoffice/private/components/Card/CardTable';

import * as requests from '@backoffice/private/ducks/requests/listRequests.duck';

const formatDate = (date) => moment(date).format('DD/MM/YYYY');
const formatDateHour = (date) => moment(date).format('DD/MM/YYYY HH:mm:SS');

const normalizeType = (type) => {
  const texts = {
    1: 'Garantia',
    2: 'Pagamento',
  };

  return texts[type];
};

const maskDocument = (document) => {
  const stringDocument = String(document);

  if (stringDocument.length === 11) {
    return stringDocument.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
      '$1.$2.$3-$4'
    );
  }
  if (stringDocument.length === 14) {
    return stringDocument.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
      '$1.$2.$3/$4-$5'
    );
  }
};

const TableSolicitationComponent = () => {
  const dispatch = useDispatch();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'reference_id',
    },
    {
      title: 'Fornecedor',
      render: ({ creditor_trading_name }) => creditor_trading_name,
    },
    {
      title: 'Parceiro',
      render: ({ partnership }) => partnership,
    },
    {
      title: 'Estabelecimento',
      render: ({ merchant_trading_name }) => merchant_trading_name,
    },
    {
      title: 'CNPJ (EC)',
      render: ({ merchant_document_number }) =>
        maskDocument(merchant_document_number),
    },
    {
      title: 'Título',
      render: ({ deal }) => deal.title,
    },
    {
      title: 'Parcelas',
      render: ({ deal }) => deal.installment,
    },
    {
      title: 'Cod. Externo',
      render: ({ deal }) => deal.code,
    },
    {
      title: 'Valor total',
      dataIndex: 'amount',
    },
    {
      title: '%',
      render: ({ deal }) =>
        Number(deal.min_percentage / 100).toLocaleString('pt-br', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: 'Data de Solicitação',
      render: ({ deal }) => formatDateHour(deal.created_at),
    },
    {
      title: 'Expiração',
      render: ({ deal }) => formatDate(deal.expiration_date),
    },
    {
      title: 'Vencimento',
      render: ({ deal }) => formatDate(deal.due_date),
    },
    {
      title: 'Execução',
      render: ({ deal }) => formatDate(deal.execution_date),
    },
    {
      title: 'Tipo',
      render: ({ deal }) => normalizeType(deal.type),
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];

  const dispatchRedux = {
    UPDATE_FILTERS: flow(requests.Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(requests.Dispatchs.resetFilters, dispatch),
    GET: flow(requests.Dispatchs.get, dispatch),
    RESET: flow(requests.Dispatchs.reset, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(requests.Selectors.data),
    LOADING: useSelector(requests.Selectors.loading),
    DATA_RESULTS: useSelector(requests.Selectors.data_results),
    FILTERS: useSelector(requests.Selectors.filters),
    RESULTSET: useSelector(requests.Selectors.resultset),
    FETCHED: useSelector(requests.Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage } = useCrudRead({
    dispatchRedux,
    selectorRedux,
  } as any);

  const handleOnRefresh = () => {
    dispatchRedux.GET();
  };

  const handleOnExport = async () => {
    const result = await dispatchRedux.GET({ isExport: true });

    return (result as any)?.results;
  };

  const filters = {
    ...selectorRedux.FILTERS,
    ...selectorRedux.RESULTSET,
  };

  const { limit, count, offset }: any = filters || {};

  const loading = selectorRedux.LOADING;
  const results = selectorRedux.DATA_RESULTS;

  return (
    <CardTable
      count={count || 0}
      loading={loading}
      dataSource={results}
      rowsPerPage={limit}
      columns={columns}
      page={offset / limit}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onRefresh={handleOnRefresh}
      onExport={handleOnExport}
      tableProps={{
        style: {
          minWidth: 2300,
        },
      }}
    />
  );
};

export default TableSolicitationComponent;
