import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import { Dispatchs } from '@backoffice/private/ducks/partners/listPartners.duck';

import PartnersScreen from './screens/Partners.screen';
import CreateEditPartner from './screens/CreateEditPartner.screen';

const CreditorModule: React.FC<any> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const dispatchRedux = {
    RESET: flow(Dispatchs.reset, dispatch),
  };

  useEffect(() => {
    return () => {
      const location = history.location.pathname;

      if (!location.includes('partners')) dispatchRedux.RESET();
    };
  }, []);
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.PRIVATE.PARTNERS.ROOT}
        component={PartnersScreen}
      />
      <Route
        exact
        path={ROUTES.PRIVATE.PARTNERS.CREATE}
        component={CreateEditPartner}
      />
      <Route
        exact
        path={ROUTES.PRIVATE.PARTNERS.EDIT()}
        component={CreateEditPartner}
      />

      <Redirect to={ROUTES.PRIVATE.HOME} />
    </Switch>
  );
};

export default CreditorModule;
