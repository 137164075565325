export const DIVISON_RULE_TYPES = [
  {
    label: 'R$',
    value: '1',
  },
  {
    label: '%',
    value: '2',
  },
];

export const DIVISON_GCAP_RULE_TYPES = [
  {
    label: '%',
    value: '2',
  },
];

export const DIVISON_RULE_LABELS = {
  [1]: 'R$',
  [2]: '%',
};
