import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Box } from '@material-ui/core';

import CardTab from '@backoffice/private/components/Card/CardTab/CardTab.component';
import SearchContract from '../components/Search/Contract/index';
import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';
import TableContract from '../components/TableContract.component';

const texts = {
  title: 'Relatórios',
};

const MonitoringReportScreen = () => {
  const permissionsData = useAccountPermission({
    permission_slug: 'relatorios_liquidacao',
  });

  const [tabActive, setTabActive] = useState(0);

  return (
    <Fragment>
      <PageLayout headerTitle header sidebar title={texts.title}>
        <Box display="flex" flexDirection="column" gridGap={32}>
          <CardTab
            onChange={(_, value) => setTabActive(value)}
            tabs={['PREVISÃO DE LIQUIDAÇÃO']}
            tabActive={tabActive}
          />

          {tabActive === 0 && (
            <Fragment>
              {permissionsData.permissions?.some((item) => item == 'read') && (
                <>
                  <SearchContract />
                  <TableContract />
                </>
              )}
              {!permissionsData.permissions ||
                (!permissionsData.permissions?.some(
                  (item) => item == 'read'
                ) && <ModuleNotPermited />)}
            </Fragment>
          )}
        </Box>
      </PageLayout>
    </Fragment>
  );
};

export default MonitoringReportScreen;
