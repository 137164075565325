import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import { Dispatchs as DispatchUsers } from '@backoffice/private/ducks/users/listUsers.duck';
import { Dispatchs as DispatchProfiles } from '@backoffice/private/ducks/profiles/listProfiles.duck';

import AccessScreen from './screens/Access.screen';
import CreateEditDynamic from './screens/CeateEditDynamic.screen';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import { useEffect } from 'react';

const RegisterModule: React.FC<any> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const dispatchUsersRedux = {
    RESET: flow(DispatchUsers.reset, dispatch),
  };

  const dispatchProfilesRedux = {
    RESET: flow(DispatchProfiles.reset, dispatch),
  };

  useEffect(() => {
    return () => {
      const location = history.location.pathname;

      if (!location.includes('access')) {
        dispatchUsersRedux.RESET();
        dispatchProfilesRedux.RESET();
      }
    };
  }, []);

  return (
    <Switch>
      <Route exact path={ROUTES.PRIVATE.ACCESS.ROOT} component={AccessScreen} />

      <Route
        exact
        path={ROUTES.PRIVATE.ACCESS.DYNAMIC_CREATE()}
        component={CreateEditDynamic}
      />
      <Route
        exact
        path={ROUTES.PRIVATE.ACCESS.EDIT()}
        component={CreateEditDynamic}
      />

      <Redirect to={ROUTES.PRIVATE.HOME} />
    </Switch>
  );
};

export default RegisterModule;
