import { Grid, CircularProgress } from '@material-ui/core';

import ButtonPrimaryStyled from './ButtonPrimary.styled';

const ButtonPrimaryComponent: React.FC<any> = ({
  color = 'primary',
  variant = 'contained',
  loading,
  disabled,
  ...restProps
}) => (
  <Grid container>
    <Grid item xs={12} css={{ position: 'relative' }}>
      <ButtonPrimaryStyled
        {...restProps}
        color={color}
        variant={variant}
        disabled={loading || disabled}
      />
      {loading && (
        <CircularProgress
          size={32}
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            margin: 'auto',
          }}
        />
      )}
    </Grid>
  </Grid>
);

export default ButtonPrimaryComponent;
