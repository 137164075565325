import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import { Dispatchs } from '@backoffice/private/ducks/deals/listDeals.duck';

import MonitoringScreen from './screens/MonitoringReport.screen';

const MonitoringReportModule: React.FC<any> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const dispatchRedux = {
    RESET: flow(Dispatchs.reset, dispatch),
  };

  useEffect(() => {
    return () => {
      const location = history.location.pathname;

      if (!location.includes('monitoring-report')) dispatchRedux.RESET();
    };
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={ROUTES.PRIVATE.MONITORING_REPORT.ROOT}
        component={MonitoringScreen}
      />

      <Redirect to={ROUTES.PRIVATE.HOME} />
    </Switch>
  );
};

export default MonitoringReportModule;
