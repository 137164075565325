import { useState } from 'react';
import flow from 'lodash/flow';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Box, IconButton, TextField, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import fetch from '@payhop/shared-utils/fetch.util';

const service = (email, senha) =>
  fetch(`accounts/change-password`, {
    method: 'POST',
    ms: 'ACCOUNT',
    auth: true,
    body: JSON.stringify({
      newPassword: senha,
      email,
    }),
  });

const ModalChangePasswordComponent: React.FC<any> = ({ duck }) => {
  const handleNotification = useNotification();

  const [loading, setLoading] = useState(false);
  const [senha, setSenha] = useState('');

  const dispatch = useDispatch();

  const dispatchRedux = {
    CLOSE: flow(duck.Dispatchs.close, dispatch),
  };

  const selectorRedux: any = {
    visible: useSelector(duck.Selectors.visible),
    payload: useSelector(duck.Selectors.payload),
  };

  const handleSubmit = async () => {
    setLoading(true);
    const response: any = await service(selectorRedux.payload?.email, senha);
    setLoading(false);

    if (response.ok) {
      handleNotification('Senha alterada com sucesso!', 'success');

      dispatchRedux.CLOSE();
    }

    if (!response.ok) {
      const result = await response.json();

      handleNotification(
        result?.errors?.message?.[0] ??
          'Aconteceu um erro, tente novamente mais tarde',
        'error'
      );
    }
  };

  return (
    <Dialog
      open={selectorRedux.visible as any}
      onClose={() => dispatchRedux.CLOSE()}
      aria-labelledby="form-dialog-title"
      maxWidth={false}
    >
      <Box p={3} position="relative" pt={6}>
        <Box position="absolute" top={8} right={8}>
          <IconButton onClick={() => dispatchRedux.CLOSE()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="1fr"
          minWidth={300}
          gridGap={12}
          mt={3}
        >
          <TextField
            label="Nova senha"
            required
            fullWidth
            variant="outlined"
            type="password"
            onChange={(ev) => setSenha(ev?.target?.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Atualizar
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ModalChangePasswordComponent;
