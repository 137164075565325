import React from 'react';

import { Autocomplete } from 'formik-material-ui-lab';
import { Field } from 'formik';
import { Box, TextField } from '@material-ui/core';

type InputSelectProps = {
  options: string[];
  name: string;
  label: string;
  required: boolean;
  inputWidth?: string;
  value: string | null;
  disabled?: boolean;
  handleGetOptionLabel: (option?: string) => string;
  onChange?: (e, value) => void;
};

const InputSelect = ({
  options,
  name,
  label,
  required,
  inputWidth,
  value,
  disabled,
  onChange,
  handleGetOptionLabel,
}: InputSelectProps) => {
  return (
    <Box width={inputWidth ? inputWidth : '100%'}>
      <Field
        component={Autocomplete}
        name={name}
        options={options || []}
        getOptionLabel={(option: any) => {
          return handleGetOptionLabel(option);
        }}
        value={value}
        disabled={disabled}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`${label}${required ? ` *` : ''}`}
            variant="outlined"
          />
        )}
      />
    </Box>
  );
};

export default InputSelect;
