import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import { Dispatchs } from '@backoffice/private/ducks/schedules/listSchedules.duck';

import SchedulesScreen from './screens/Schedules.screen';
import CreateScheduleFormik from './screens/create/schedule/CreateSchedule.screen';
import ViewScheduleScreen from './screens/ViewSchedule.screen';

const AppointmentsModule: React.FC<any> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const dispatchRedux = {
    RESET: flow(Dispatchs.reset, dispatch),
  };

  useEffect(() => {
    return () => {
      const location = history.location.pathname;

      if (!location.includes('schedules')) dispatchRedux.RESET();
    };
  }, []);
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.PRIVATE.SCHEDULES.ROOT}
        component={SchedulesScreen}
      />

      <Route
        exact
        path={ROUTES.PRIVATE.SCHEDULES.EDIT()}
        component={ViewScheduleScreen}
      />

      <Route
        exact
        path={ROUTES.PRIVATE.SCHEDULES.CREATE}
        component={CreateScheduleFormik}
      />
      <Redirect to={ROUTES.PRIVATE.HOME} />
    </Switch>
  );
};

export default AppointmentsModule;
