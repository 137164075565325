import flow from 'lodash/flow';
import get from 'lodash/get';

import fetch from '@payhop/shared-utils/fetch.util';

const context = 'private/accounts/account';
const rootPathState = context.replace(/\//g, '.');

const getLeadInfosByTypeAccount = (data) => {
  return {
    full_name: data?.contact_name,
    email: data?.contact_email,
    password: 'Password@123',
  };
};

type Actions = {
  type: string;
  payload?: any;
  error?: any;
};

export const Types = {
  INIT: `${context}/INIT`,
  SUCCESS: `${context}/SUCCESS`,
  RESET: `${context}/RESET`,
  RESET_ERROR: `${context}/RESET_ERROR`,
  ERROR: `${context}/ERROR`,
};

const initialState: any = {
  data: null,
  loading: false,
  error: null,
  fetched: false,
};

export default (state = initialState, action: Actions) => {
  const { type, payload } = action;

  const reducers = {
    [Types.RESET]: {
      ...initialState,
    },
    [Types.RESET_ERROR]: {
      ...state,
      error: initialState.error,
    },
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
      error: false,
      data: payload,
      fetched: true,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error: true,
      data: payload,
    },
  };

  return reducers[type] || state;
};

export const createDispatch = (data) => async (dispatch, getState) => {
  let dataObj;

  const currentUser = getState()?.shared?.authentication?.data;

  const { personId, accountType } = currentUser || {};

  try {
    dispatch({
      type: Types.INIT,
    });

    if (data.partnerType == 'if') {
      const response = await fetch(`accounts/multiple-limited`, {
        method: 'POST',
        ms: 'ACCOUNT',
        auth: true,
        body: JSON.stringify([{ ...data }]),
      });

      const result = await response.json();

      if (!response.ok) {
        throw result;
      }

      dispatch({
        type: Types.SUCCESS,
        payload: {
          successAction: true,
          ...result,
        },
      });
    } else {
      const responseLead = await fetch(`accounts/lead`, {
        method: 'POST',
        ms: 'ACCOUNT',
        auth: true,
        body: JSON.stringify(getLeadInfosByTypeAccount(data)),
      });

      const resultLead = await responseLead.json();

      if (!responseLead.ok) {
        throw resultLead;
      }

      const { id: idLead } = resultLead;

      dataObj = Object.assign(data, {
        lead_account_id: idLead,
        phone_number: data.phone_number,
        email: data.contact_email,
      });

      if (accountType === 'Creditor') {
        dataObj.creditor_id = personId;
      }

      delete dataObj.contact_email;
      delete dataObj.typePerson;

      const response = await fetch(`accounts`, {
        method: 'POST',
        ms: 'ACCOUNT',
        auth: true,
        body: JSON.stringify(dataObj),
      });

      const result = await response.json();

      if (!response.ok) {
        throw result;
      }

      dispatch({
        type: Types.SUCCESS,
        payload: {
          successAction: true,
          ...result,
        },
      });

      return result;
    }
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
      payload: {
        successAction: false,
        error: result?.errors?.message?.[0],
      },
    });

    return false;
  }
};

export const resetDispatch = () => ({
  type: Types.RESET,
});

export const resetErrorDispatch = () => ({
  type: Types.RESET_ERROR,
});

const selectRoot = (state: any) => get(state, rootPathState);
const selectData = (state: any) => get(state, 'data');
const selectLoading = (state: any) => get(state, 'loading');
const selectFetched = (state: any) => get(state, 'fetched');
const selectError = (state: any) => get(state, 'error');

export const Selectors = {
  data: flow(selectRoot, selectData),
  loading: flow(selectRoot, selectLoading),
  fetched: flow(selectRoot, selectFetched),
  error: flow(selectRoot, selectError),
};

export const Dispatchs = {
  reset: resetDispatch,
  create: createDispatch,
  resetError: resetErrorDispatch,
};
