import React from 'react';
import Typography from '@backoffice/shared/components/Typography.component';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import { Box } from '@material-ui/core';

const ResumeCreateOriginator = ({ data }) => {
  return (
    <>
      <Box display="flex" gridColumnGap={10}>
        <Typography
          css={{ fontWeight: 600 }}
          customVariant="text__body--lg"
          customColor="secondary-darker"
        >
          Cadastro de originador
        </Typography>
        {data.mensagem !== 'Originador cadastrado com sucesso' ? (
          <CancelIcon />
        ) : (
          <CheckIcon color="primary" />
        )}
      </Box>
      {data.mensagem !== 'Originador cadastrado com sucesso' ? (
        <>
          <p>Erro: {data.mensagem}</p>
        </>
      ) : (
        <p>Originador Cadastrado com sucesso</p>
      )}

      <Box display="flex" gridColumnGap={10}>
        <Typography
          css={{ fontWeight: 600 }}
          customVariant="text__body--lg"
          customColor="secondary-darker"
        >
          Cadastro de domicílio
        </Typography>
        {data.domicilios &&
        data.domicilios[0]?.mensagem !==
          'Domicílio Bancário cadastrado com sucesso' ? (
          <CancelIcon />
        ) : (
          <CheckIcon color="primary" />
        )}
      </Box>
      {data.domicilios &&
      data.domicilios[0]?.mensagem !==
        'Domicílio Bancário cadastrado com sucesso' ? (
        <>
          <p>Não Foi possivel cadastrar o domicilio</p>
          <p>Erro: {data.domicilios[0].mensagem}</p>
        </>
      ) : (
        <p>Domicílio Cadastrado com sucesso</p>
      )}
    </>
  );
};

export default ResumeCreateOriginator;
