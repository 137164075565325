import React, { useEffect, useState } from 'react';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import Fade from '@material-ui/core/Fade';
import { Box } from '@material-ui/core';

import InputText from '@backoffice/private/components/Form/Inputs/InputText.component';
import InputSelect from '@backoffice/private/components/Form/Inputs/InputSelect.component';
import Button from '@backoffice/shared/components/Button.component';
import Typography from '@backoffice/shared/components/Typography.component';

import { validationSchema } from './validationSchema';
import { Bank } from '@backoffice/private/@types/models/Bank';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/domains/bank.duck';
import InputTextWithMask from '@backoffice/private/components/Form/Inputs/InputTextWithMask.component';
import { ISteps } from '../../Dialogs/DialogCreateOriginator.component';

const accountType = [
  {
    type: 'CC',
    name: 'Conta Corrente',
  },
  {
    type: 'CD',
    name: 'Conta de Depósito',
  },
  {
    type: 'CG',
    name: 'Conta Garantia',
  },
  {
    type: 'CI',
    name: 'Conta Investimento',
  },
  {
    type: 'PG',
    name: 'Conta de Pagamento',
  },
  {
    type: 'PP',
    name: 'Conta Poupança',
  },
];

const FormCreditorDomicile = ({
  handleSubmitForm,
  data,
  handleResetFormSubmitted,
}) => {
  const dispatch = useDispatch();
  const domicilio = data?.domicilios?.[0] || {}; // Define um objeto vazio como fallback

  useEffect(() => {
    handleResetFormSubmitted();
  }, []);

  const {
    cnpj_domicilio,
    titulo_domicilio,
    banco,
    ispb,
    agencia,
    conta,
    digito,
    tipo_conta,
  } = domicilio;

  const formData = {
    cnpj_domicilio,
    titulo_domicilio,
    banco,
    ispb,
    agencia,
    conta,
    digito,
    tipo_conta,
  };

  const [banks, setBanks] = useState<Bank[]>();
  const [selectedBank, setSelectedBank] = useState<string | null>(
    formData.banco
  );
  const [selectedTypeAccount, setSelectedTypeAccount] = useState<string | null>(
    formData.tipo_conta
  );

  //Inputs Text
  const [tituloDomicilio, setTituloDomicilio] = useState<string>(
    formData.titulo_domicilio
  );
  const [ispbValue, setIspbValue] = useState(formData.ispb);
  const [agenciaValue, setAgenciaValue] = useState<string>(formData.agencia);
  const [contaValue, setContaValue] = useState<string>(formData.conta);
  const [digitoValue, setDigitoValue] = useState<string>(formData.digito);

  const dispatchBank = {
    GET: flow(Dispatchs.get, dispatch),
  };

  const selectorBankRedux = {
    DATA: useSelector(Selectors.data),
  };

  useEffect(() => {
    dispatchBank.GET();
  }, []);

  useEffect(() => {
    setBanks(selectorBankRedux.DATA);
  }, [selectorBankRedux.DATA]);

  useEffect(() => {
    const bank = banks?.find((b) => b.bank_code === selectedBank);
    const bankIspb = bank?.bank_ispb || '';
    setIspbValue(bankIspb);
  }, [selectedBank, banks]);

  const handleGetBanksLabel = (option) => {
    const currentBank = banks?.find((b) => b.bank_code === option);

    if (!currentBank?.bank_code) {
      return '';
    }

    return `${currentBank?.bank_code} - ${currentBank?.bank_name}`;
  };

  const handleGetAccountTypeLabel = (option) => {
    const currentAccountType = accountType?.find((b) => b.type === option);

    return `${currentAccountType?.name}`;
  };

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnChange
      validateOnMount
      onSubmit={(values) => {
        let newConta = values.conta;

        if (values.digito) {
          newConta = `${values.conta}-${values.digito}`;
        }
        const data = {
          ...values,
          agencia: String(values.agencia),
          conta: newConta,
        };

        delete data.digito;

        Object.keys(data).forEach((key) => {
          // Verificar se a chave começa com "mui"
          if (key.startsWith('mui')) {
            // Remover a propriedade
            delete data[key];
          }
        });

        handleSubmitForm(data, ISteps.Domicile);
      }}
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        handleChange,
        setFieldValue,
        values,
      }) => (
        <Fade in={true}>
          <form onSubmit={handleSubmit}>
            <Box css={{ marginBottom: '15px' }}>
              <InputTextWithMask
                name="cnpj_domicilio"
                label="CNPJ"
                mask="cnpj"
                required
                onChange={(value) => {
                  setFieldValue('cnpj_domicilio', value.value);
                }}
              />
            </Box>
            <Box css={{ marginBottom: '15px' }}>
              <InputText
                label="Título"
                name="titulo_domicilio"
                value={values.titulo_domicilio}
                required
              />
            </Box>

            <Box>
              <Typography customColor="gray-400" customVariant="text__body--sm">
                Dados bancários
              </Typography>
              <Box
                display={'flex'}
                gridColumnGap="15px"
                css={{ marginBottom: '15px', marginTop: '12px' }}
              >
                {banks && (
                  <InputSelect
                    inputWidth="60%"
                    name="banco"
                    required
                    label="Selecionar Banco"
                    options={banks?.map((b) => b?.bank_code)}
                    value={selectedBank}
                    handleGetOptionLabel={handleGetBanksLabel}
                    onChange={(e, option) => {
                      const bank = banks?.find((b) => b.bank_code == option);
                      setFieldTouched('banco');
                      setFieldValue('banco', option);
                      setFieldValue('ispb', bank?.bank_ispb);
                      setSelectedBank(option);
                      handleChange(e);
                    }}
                  />
                )}

                <InputText
                  label="ISPB"
                  name="ispb"
                  value={values.ispb}
                  required
                  disabled
                />
              </Box>

              <Box css={{ marginBottom: '15px' }}>
                <InputSelect
                  name="tipo_conta"
                  required
                  label="Tipo da conta"
                  options={['CC', 'CD', 'CG', 'CI', 'PG', 'PP']}
                  value={selectedTypeAccount}
                  handleGetOptionLabel={handleGetAccountTypeLabel}
                  onChange={(e, option) => {
                    setFieldValue('tipo_conta', option);
                    setSelectedTypeAccount(option);
                  }}
                />
              </Box>

              <Box
                display={'flex'}
                gridColumnGap="15px"
                css={{ marginBottom: '15px' }}
              >
                <InputText
                  inputWidth="60%"
                  label="Agência"
                  name="agencia"
                  value={values.agencia}
                  required
                />

                <InputText
                  label="Conta"
                  name="conta"
                  value={values.conta}
                  required
                />
                {selectedTypeAccount !== 'PG' && (
                  <InputText
                    inputWidth="20%"
                    name="digito"
                    label="Dígito"
                    value={values.digito}
                    required={selectedTypeAccount !== 'PG'}
                    disabled={
                      selectedTypeAccount == 'PG' || !selectedTypeAccount
                    }
                    type="text"
                  />
                )}
              </Box>
            </Box>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              aria-label="show more"
              disabled={Object.keys(errors).length > 0}
              fullWidth
            >
              Cadastrar
            </Button>
          </form>
        </Fade>
      )}
    </Formik>
  );
};

export default FormCreditorDomicile;
