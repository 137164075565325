import React from 'react';

import ReactExport from 'react-export-excel';
import moment from 'moment';
import { DIVISON_RULE_LABELS } from '@backoffice/private/constants/contract/divison_rule.constant';
import { STRATEGY_LABELS } from '@backoffice/private/constants/contract/strategy.constant';
import { CONTRACT_TYPES_LABELS } from '@backoffice/private/constants/contract/type.constant';
import { TRANSACTION_LABELS } from '@backoffice/private/constants/contract/transaction.constant';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Props = {
  data: Array<string | number>;
};

const DealsExport = ({ data }: Props) => {
  function handleFilterDealData(data) {
    const filteredData: any = [];
    data.forEach((item) => {
      const newData = {
        id: item.id,
        company_name: item.company_name,
        trading_name: item.trading_name,
        partnership: item.partnership,
        amount: item.amount,
        created_at: item.created_at
          ? moment(item.created_at).format('DD/MM/YYYY HH:mm')
          : '-',
        updated_at: item.updated_at
          ? moment(item.updated_at).format('DD/MM/YYYY HH:mm')
          : '-',
        due_date: item.due_date
          ? moment(item.due_date).format('DD/MM/YYYY HH:mm')
          : '-',
        execution_date: item.execution_date
          ? moment(item.execution_date).format('DD/MM/YYYY HH:mm')
          : '-',
        execution_date_end: item.execution_date_end
          ? moment(item.execution_date_end).format('DD/MM/YYYY HH:mm')
          : '-',
        expiration_date: item.expiration_date
          ? moment(item.expiration_date).format('DD/MM/YYYY HH:mm')
          : '-',
        type: item.type,
        status: item.status,
        info: item.info,
        description: item.description,
        code: item.code,
        merchant_document_number: item.merchant_document_number,
        merchant_company_name: item.merchant_company_name,
        merchant_trading_name: item.merchant_trading_name,
        title: item.title,
        installment: item.installment,
        min_percentage: item.min_percentage,
        division_rule: item.division_rule,
        amount_onerate: item.amount_onerate,
        strategy: item.strategy,
        contract_type: item.contract_type,
        attachment_value: item.attachment ? 'Sim' : 'Não',
        gcap_value: item.gcap ? 'Sim' : 'Não',
      };
      filteredData.push(newData);
    });
    return filteredData;
  }
  return (
    <ExcelFile
      filename={'Compromissos'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleFilterDealData(data)} name="Compromissos">
        <ExcelColumn label="FORNECEDOR" value="company_name" />
        <ExcelColumn label="NOME FANTASIA" value="trading_name" />
        <ExcelColumn label="PARCEIRO" value="partnership" />
        <ExcelColumn label="VALOR TOTAL" value="amount" />
        <ExcelColumn label="DATA DO COMPROMISSO" value="created_at" />
        <ExcelColumn
          label="DATA DE ATUALIZAÇÃO DO COMPROMISSO"
          value="updated_at"
        />
        <ExcelColumn label="VENCIMENTO" value="due_date" />
        <ExcelColumn label="DATA EXECUÇÃO INICIAL" value="execution_date" />
        <ExcelColumn label="DATA EXECUÇÃO FINAL" value="execution_date_end" />
        <ExcelColumn label="APROVAR ATÉ" value="expiration_date" />
        <ExcelColumn label="TIPO DE TRANSAÇÃO" value="type" />
        <ExcelColumn label="STATUS" value="status" />
        <ExcelColumn label="DETALHE DO STATUS" value="info" />
        <ExcelColumn label="DESCRIÇÃO" value="description" />
        <ExcelColumn label="CÓDIGO EXTERNO" value="code" />
        <ExcelColumn label="CNPJ" value="merchant_document_number" />
        <ExcelColumn label="ESTABELECIMENTO" value="merchant_company_name" />
        <ExcelColumn
          label="RAZÃO SOCIAL ESTABELECIMENTO"
          value="merchant_trading_name"
        />
        <ExcelColumn label="TÍTULO" value="title" />
        <ExcelColumn label="PARCELAS" value="installment" />
        <ExcelColumn label="PORCENTAGEM" value="min_percentage" />
        <ExcelColumn label="ID" value="id" />
        <ExcelColumn label="REGRA DIVISÃO" value="division_rule" />
        <ExcelColumn label="VALOR A ONERAR" value="amount_onerate" />
        <ExcelColumn label="MODALIDADE" value="strategy" />
        <ExcelColumn label="TIPO DE CONTRATO" value="contract_type" />
        <ExcelColumn label="ANEXO" value="attachment_value" />
        <ExcelColumn label="Gestão de colateral" value="gcap_value" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default DealsExport;
