import fetch from '@payhop/shared-utils/fetch.util';

type dataRequest = {
  creditor_id: string;
  data: any;
};

function validateRecorrence(text: string) {
  if (!text) return '';
  const textNormalized = text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  if (textNormalized === 'sim') return true;
  if (textNormalized === 'não' || textNormalized === 'nao') return false;
  return '';
}

export default async function postUsersUploadMerchantMaintenance({
  creditor_id,
  data,
}: dataRequest) {
  const dataFiltered = data.map((item) => {
    return {
      creditor_id: creditor_id,
      document_number: item.cnpj_ou_id_lote,
      is_recurrence: validateRecorrence(item.recorrente_),
      optin_optout: item.opt_in_opt_out,
    };
  });

  const response = await fetch(`accounts/maintenance`, {
    method: 'POST',
    ms: 'ACCOUNT',
    auth: true,
    body: JSON.stringify(dataFiltered),
  });

  const result = await response.json();

  return [response.ok, result];
}
