import { combineReducers } from 'redux';

type listOfReducersType = {
  name: string;
  path: string;
};

const reducers = {};

const listOfReducers: Array<listOfReducersType> = [
  {
    name: 'list',
    path: 'listMerchants.duck',
  },
  {
    name: 'listPending',
    path: 'listPendingMerchants.duck',
  },
  {
    name: 'merchant',
    path: 'merchant.duck',
  },
  {
    name: 'partners',
    path: 'partners',
  },
  {
    name: 'access',
    path: 'access',
  },
  {
    name: 'dialogs',
    path: 'dialogs',
  },
  {
    name: 'acquirers',
    path: 'acquirers',
  },
  {
    name: 'documentDetail',
    path: 'documentDetail',
  },
];

listOfReducers.map((elm) =>
  Object.assign(reducers, {
    ...reducers,
    [elm.name]: require(`./${elm.path}`).default,
  })
);

export default combineReducers({
  ...reducers,
});
