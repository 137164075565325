import React, { useState, useEffect, useMemo } from 'react';
import { flow } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import * as dialogMerchantSeeDiscoveryArrangments from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantSeeDiscoveryArrangments.duck';

import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CardTable from '@backoffice/private/components/Card/CardTable';

const DialogSeeArrangmentsDiscovery = () => {
  const [arrangements, setArrangements] = useState<any>([]);

  const dispatch = useDispatch();

  const dispatchRedux = {
    CLOSE: flow(
      dialogMerchantSeeDiscoveryArrangments.Dispatchs.close,
      dispatch
    ),
  };
  const selectorRedux = {
    visible: useSelector(
      dialogMerchantSeeDiscoveryArrangments.Selectors.visible
    ),
    payload: useSelector(
      dialogMerchantSeeDiscoveryArrangments.Selectors.payload
    ),
  };

  useEffect(() => {
    if (selectorRedux.payload) {
      setArrangements(selectorRedux?.payload?.arrangements);
    }
  }, [selectorRedux.payload]);

  const columns = useMemo(
    () => [
      {
        title: 'Nome do arranjo',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Código do arranjo',
        dataIndex: 'arrangement_code',
        key: 'arrangement_code',
      },
    ],
    [arrangements]
  );

  return (
    <Dialog
      open={selectorRedux.visible}
      onClose={() => dispatchRedux.CLOSE()}
      aria-labelledby="form-dialog-title"
      maxWidth={'lg'}
      fullWidth={true}
    >
      <DialogTitle id="customized-dialog-title">
        Arranjos
        <IconButton
          css={{ position: 'absolute', right: 0, top: '5px' }}
          aria-label="close"
          onClick={() => dispatchRedux.CLOSE()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {arrangements && arrangements.length > 0 ? (
          <CardTable
            dataSource={arrangements}
            columns={columns}
            footer={false}
          />
        ) : (
          <p>Nenhum arranjo encontrado para esse originador</p>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DialogSeeArrangmentsDiscovery;
