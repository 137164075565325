import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useUserAuthenticated from '@payhop/shared-hooks/useUserAuthenticated.hook';

import { getNotifications } from '@payhop/shared-ducks/notifications.duck';

const ListPropertiesContainer: React.FC<{
  children: any;
  initialFilters?: any;
}> = ({ children, initialFilters }) => {
  const { signedIn } = useUserAuthenticated();

  const [filters, setFilters] = useState({
    limit: 25,
    offset: 0,
    ...initialFilters,
  });

  const dispatch = useDispatch();

  const { data, loading } = useSelector(
    (state: any) => state.private.notifications
  );

  const handleGetNotification = useCallback(
    (filters: any, concat = true) =>
      signedIn &&
      dispatch(
        getNotifications(filters, {
          concat: concat && data?.results,
        })
      ),
    [dispatch, data, signedIn]
  );

  useEffect(() => {
    if (!signedIn) {
      return undefined;
    }

    handleGetNotification(filters);
  }, [filters, signedIn]);

  return children({
    data: (data || {})?.results || [],
    resultset: data?.metadata?.resultset,
    loading,
    setFilters,
    filters,
    handleGetNotification,
  });
};

export default ListPropertiesContainer;
