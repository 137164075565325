import React from 'react';

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Props = {
  data: Array<string | number>;
};

const ParametersExport = ({ data }: Props) => {
  function handleParametersData(data) {
    const paramsData = data;

    return paramsData;
  }
  return (
    <ExcelFile
      filename={'Parâmetros'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleParametersData(data)} name="Parâmetros">
        <ExcelColumn label="AGENDA CONSOLIDADA" value="agenda_consolidada" />
        <ExcelColumn
          label="APROVACAO COMPROMISSO"
          value="aprovacao_compromisso"
        />
        <ExcelColumn
          label="BASE URL REGISTRADORA"
          value="base_url_registradora"
        />
        <ExcelColumn
          label="BASE URL REGISTRADORA CONTRATO"
          value="base_url_registradora_contrato"
        />
        <ExcelColumn label="CHAVE API" value="chave_api" />
        <ExcelColumn label="COMPORTAMENTO" value="comportamento" />
        <ExcelColumn
          label="COMPROMISSO VALOR MAX"
          value="compromisso_valor_max"
        />
        <ExcelColumn
          label="COMPROMISSO VALOR MIN"
          value="compromisso_valor_min"
        />
        <ExcelColumn label="CONSULTA AGENDA" value="consulta_agenda" />
        <ExcelColumn
          label="CONTA LIQUIDACAO URS"
          value="conta_liquidacao_urs"
        />
        <ExcelColumn
          label="CUSTO CONSULTA AGENDA"
          value="custo_consulta_agenda"
        />
        <ExcelColumn label="CUSTO ONBOARDING" value="custo_onboarding" />
        <ExcelColumn label="DIA FATURAMENTO" value="dia_faturamento" />
        <ExcelColumn
          label="DIAS ANTERIORES AGENDA CONSOLIDADA"
          value="dias_anteriores_agenda_consolidada"
        />
        <ExcelColumn label="DIAS MAX" value="dias_max" />
        <ExcelColumn label="DIAS MIN" value="dias_min" />
        <ExcelColumn
          label="DIAS POSTERIORES AGENDA CONSOLIDADA"
          value="dias_posteriores_agenda_consolidada"
        />
        <ExcelColumn label="ENVIA NOTIFICACAO" value="envia_notificacao" />
        <ExcelColumn label="ESTRATEGIA" value="estrategia" />
        <ExcelColumn label="INTEGRACAO" value="integracao" />
        <ExcelColumn label="MENSALIDADE MIN" value="mensalidade_min" />
        <ExcelColumn label="MODALIDADES" value="modalidades" />
        <ExcelColumn label="PARCERIA" value="parceria" />
        <ExcelColumn label="PERFIL" value="perfil" />
        <ExcelColumn label="PERFIL PARCEIRO" value="perfil_parceiro" />
        <ExcelColumn
          label="PERMITE COMPROMISSO ACIMA LIMITE"
          value="permite_compromisso_acima_limite"
        />
        <ExcelColumn
          label="PERMITE CONTA OUTRO CNPJ"
          value="permite_conta_outro_cnpj"
        />
        <ExcelColumn
          label="QUANTIDADE PARCELAS COMPROMISSO"
          value="quantidade_parcelas_compromisso"
        />
        <ExcelColumn label="REGISTRADORA LOTE" value="registradora_lote" />
        <ExcelColumn
          label="REPASSE CUSTO REGISTRO"
          value="repasse_custo_registro"
        />
        <ExcelColumn label="SEGMENTO MERCADO" value="segmento_mercado" />
        <ExcelColumn label="SLUG" value="slug" />
        <ExcelColumn
          label="TAXA REPASSE CUSTO REGISTRO"
          value="taxa_repasse_custo_registro"
        />
        <ExcelColumn label="TIPO CONTRATO" value="tipo_contrato" />
        <ExcelColumn label="TIPO TRANSACAO" value="tipo_transacao" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ParametersExport;
