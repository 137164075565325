import { Route, Switch, Redirect } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import ImportMerchantScreen from './screens/ImportAppointments.screen';

const CreditorModule: React.FC<any> = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.PRIVATE.IMPORT_APPOINTMENTS.ROOT}
      component={ImportMerchantScreen}
    />

    <Redirect to={ROUTES.PRIVATE.HOME} />
  </Switch>
);

export default CreditorModule;
