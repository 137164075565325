import React from 'react';
import { IconButton, Box } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import CardTable from '@backoffice/private/components/Card/CardTable';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

const TableMerchantDocumentComponent = ({
  loading,
  results,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  onEdit,
  seeHistoric,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_estabelecimento_documentos',
  });
  const { limit, count, offset } = filters || {};

  const columns = [
    {
      width: 40,
      title: '',
      render: (obj) => (
        <Box display="flex">
          <IconButton
            disabled={!permissions?.some((item) => item == 'read')}
            onClick={() => seeHistoric(obj)}
          >
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      width: 40,
      title: '',
      render: (obj) => (
        <Box display="flex">
          <IconButton
            disabled={!permissions?.some((item) => item == 'edit')}
            onClick={() => onEdit(obj)}
          >
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      title: 'Documento',
      dataIndex: 'file_info',
      key: 'file_info',
    },
    {
      title: 'Status',
      dataIndex: 'status_description',
      key: 'status_description',
    },
    {
      title: 'Justificativa',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Data upload',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Data alteração status',
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
  ];

  return (
    <>
      {permissions?.some((item) => item == 'read') && (
        <Box gridGap={8} display="flex" flexDirection="column" flex={1}>
          <CardTitle>Histórico</CardTitle>

          <CardTable
            count={count || 0}
            loading={loading}
            dataSource={results}
            rowsPerPage={limit}
            columns={columns}
            page={offset / limit}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            footer={false}
          />
        </Box>
      )}
      {(!permissions || !permissions?.some((item) => item == 'read')) && (
        <ModuleNotPermited />
      )}
    </>
  );
};

export default TableMerchantDocumentComponent;
