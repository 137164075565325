import ROUTES from './routes.util';

const LABEL_ROUTES = {
  [ROUTES.PRIVATE.HOME]: 'Início',

  [ROUTES.PRIVATE.ACCESS.ROOT]: 'Acesso',
  [ROUTES.PRIVATE.ACCESS.DYNAMIC_CREATE()]: 'Criar',
  [ROUTES.PRIVATE.ACCESS.EDIT()]: 'Editar ',

  [ROUTES.PRIVATE.CREDITORS.ROOT]: 'Fornecedores',
  [ROUTES.PRIVATE.CREDITORS.CREATE]: 'Criar fornecedor',
  [ROUTES.PRIVATE.CREDITORS.EDIT()]: 'Editar fornecedor',

  [ROUTES.PRIVATE.PARAMETERS.ROOT]: 'Parâmetros',
  [ROUTES.PRIVATE.PARAMETERS.EDIT()]: 'Editar',

  [ROUTES.PRIVATE.DOCS.ROOT]: 'Documentação',
  [ROUTES.PRIVATE.DOCS.CREATE]: 'Criar documentação',
  [ROUTES.PRIVATE.DOCS.EDIT()]: 'Editar documentação',

  [ROUTES.PRIVATE.MERCHANTS.ROOT]: 'Estabelecimentos',
  [ROUTES.PRIVATE.MERCHANTS.CREATE]: 'Criar estabelecimento',
  [ROUTES.PRIVATE.MERCHANTS.EDIT()]: 'Editar estabelecimento',

  [ROUTES.PRIVATE.SCHEDULES.ROOT]: 'Agenda de recebiveis',
  [ROUTES.PRIVATE.SCHEDULES.CREATE]: 'Nova consulta',
  [ROUTES.PRIVATE.SCHEDULES.EDIT()]: 'Detalhe da agenda',

  [ROUTES.PRIVATE.REGISTERED_CONTRACTS.ROOT]: 'Consulta de contratos',

  [ROUTES.PRIVATE.APPOINTMENTS.ROOT]: 'Compromissos',
  [ROUTES.PRIVATE.APPOINTMENTS.EDIT()]: 'Compromisso',
  [ROUTES.PRIVATE.APPOINTMENTS.CREATE]: 'Criar compromisso',

  [ROUTES.PRIVATE.IMPORT_APPOINTMENTS.ROOT]: 'Compromissos',

  [ROUTES.PRIVATE.IMPORT_SMOKE_APPOINTMENTS.ROOT]: 'Compromisso Fumaça',

  [ROUTES.PRIVATE.IMPORT_MERCHANT.ROOT]: 'Estabelecimentos',

  [ROUTES.PRIVATE.CHANGE_DEALS.ROOT]: 'Alterar Contratos',

  [ROUTES.PRIVATE.PARTNERS.ROOT]: 'Parceiros',
  [ROUTES.PRIVATE.PARTNERS.CREATE]: 'Criar parceiro',
  [ROUTES.PRIVATE.PARTNERS.EDIT()]: 'Editar parceiro',

  [ROUTES.PRIVATE.CONCILIATION_SCHEDULE.ROOT]: 'Agenda',

  [ROUTES.PRIVATE.CONCILIATION_BANK.ROOT]: 'Conciliação Bancária',

  [ROUTES.PRIVATE.CONCILIATION_DIVERGENCE.ROOT]: 'Consultas',

  [ROUTES.PRIVATE.CONCILIATION_COMPANY.ROOT]: 'Empresa e contas',
  [ROUTES.PRIVATE.CONCILIATION_COMPANY.CREATE]: 'Criação de empresa',
  [ROUTES.PRIVATE.CONCILIATION_COMPANY.EDIT()]: 'Edição da empresa',

  [ROUTES.PRIVATE.CONCILIATION_MONITORING.ROOT]: 'Monitoramento dos Contratos',
  [ROUTES.PRIVATE.MONITORING_REPORT.ROOT]: 'Relatórios',
};

export default LABEL_ROUTES;
