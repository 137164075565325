import fetch from '@payhop/shared-utils/fetch.util';

export default async function getMerchantDiscovery(documentNumber, creditorId) {
  const response = await fetch(
    `accounts/search-acquirer?documentNumber=${documentNumber}&creditorid=${creditorId}`,
    {
      method: 'GET',
      ms: 'ACCOUNT',
      auth: true,
    }
  );

  return response.ok;
}
