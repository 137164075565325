import { flow } from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import Skeleton from '@material-ui/lab/Skeleton';
import ExportXLS from '../../../components/Export/Export.component';
import { v4 as uuidv4 } from 'uuid';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import CardTab from '@backoffice/private/components/Card/CardTab';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import * as scheduleDuck from '@backoffice/private/ducks/schedules/schedule.duck';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import TableScheduleSummaryComponent from '../components/TableScheduleSummary.component';
import TableScheduleAnalyticalComponent from '../components/TableScheduleAnalytical.component';
import { useNotificationDrawer } from '@backoffice/private/components/NotificationDrawer/NotificationDrawer.context';
import Loading from '@backoffice/private/components/Loading/Loading.component';

const ViewAppointmentsScreen = () => {
  const { isAdmin } = useUserHook();
  const {
    toggleVisible: toggleVisibleDrawer,
    unreads,
    visible: notificationVisible,
  }: any = useNotificationDrawer();

  const { id } = useIsEdit();

  const dispatch = useDispatch();

  const [schedule, setSchedule] = useState({} as any);
  const [scheduleSummary, setScheduleSummary] = useState([] as any);
  const [scheduleAnalytical, setScheduleAnalytical] = useState([] as any);
  const [exportData, setExportData] = useState({} as any);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [visualizationOpen, setVisualizationOpen] = useState(false);
  const [dialogRules, setDialogRules] = useState({} as any);

  const dispatchRedux = {
    GET: flow(scheduleDuck.Dispatchs.get, dispatch),
    CREATE: flow(scheduleDuck.Dispatchs.create, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(scheduleDuck.Selectors.data),
    LOADING: useSelector(scheduleDuck.Selectors.loading),
  };

  useEffect(() => {
    dispatchRedux.GET(id);

    if (notificationVisible) {
      toggleVisibleDrawer();
    }
  }, []);

  useEffect(() => {
    setSchedule(selectorRedux.DATA);
  }, [selectorRedux.DATA]);

  useEffect(() => {
    if (schedule?.is_old) {
      if (schedule?.schedule_consolidated_old) {
        handleScheduleSummaryOld(schedule?.schedule_consolidated_old?.summary);
        handleScheduleAnalyticalOld(
          schedule?.schedule_consolidated_old?.schedule_analytical
        );
      } else {
        handleScheduleSummaryOld(schedule?.schedule_expanded_old?.summary);
        handleScheduleAnalyticalOld(
          schedule?.schedule_expanded_old?.schedule_analytical
        );
      }
    } else {
      if (schedule?.schedule_consolidated) {
        handleScheduleSummary(schedule?.schedule_consolidated?.summary);
        handleScheduleAnalytical(
          schedule?.schedule_consolidated?.schedule_analytical
        );
      } else {
        handleScheduleSummary(schedule?.schedule_expanded?.summary);
        handleScheduleAnalytical(
          schedule?.schedule_expanded?.schedule_analytical
        );
      }
    }
  }, [schedule]);

  const handleScheduleSummaryOld = (data) => {
    const newArr: any = [];

    if (data) {
      data.forEach((item) => {
        const creditItem = {
          merchant_name: item.merchant_name,
          merchant_document_number: item.merchant_document_number,
          acquirer_name: item.acquirer_name,
          value_type: 'CREDITO',
          amount_total: item.credit?.amount_total
            ? item.credit?.amount_total
            : '0',
          amount_available: item.credit?.amount_available
            ? item.credit?.amount_available
            : '0',
        };

        const debitItem = {
          merchant_name: item.merchant_name,
          merchant_document_number: item.merchant_document_number,
          acquirer_name: item.acquirer_name,
          value_type: 'DEBITO',
          amount_total: item.debit?.amount_total
            ? item.debit?.amount_total
            : '0',
          amount_available: item.debit?.amount_available
            ? item.debit?.amount_available
            : '0',
        };

        newArr.push(creditItem);
        newArr.push(debitItem);
      });

      setScheduleSummary(newArr);
    }
  };

  const handleScheduleSummary = (data) => {
    const newArr: any = [];
    const solicitationDate = moment
      .utc(schedule?.created_at)
      .format('DD/MM/YYYY');

    const period = `${moment
      .utc(schedule?.execution_begin_date)
      .format('DD/MM/YYYY')}
    a ${moment.utc(schedule?.execution_end_date).format('DD/MM/YYYY')}`;

    if (data) {
      data.forEach((item) => {
        const { merchant_name, merchant_document_number, acquirer_name } = item;
        let arrItem: any = {
          request_date: solicitationDate,
          period: period,
          merchant_name,
          merchant_document_number,
          acquirer_name,
        };

        const arrangementsArray = item.arrangements;

        arrangementsArray?.forEach((arrangement) => {
          const { arrangement_name, amount_total, amount_available } =
            arrangement;
          arrItem = {
            ...arrItem,
            arrangement_name,
            amount_total,
            amount_available,
          };

          newArr.push(arrItem);
        });
      });

      setScheduleSummary(newArr);
    }
  };

  const handleScheduleAnalytical = (data) => {
    const newArr: any = [];

    if (data) {
      data.forEach((item) => {
        const urItem = {
          settlement_date: item.settlement_date,
          merchant_name: item.merchant_name,
          merchant_document_number: item.merchant_document_number,
          acquirer_name: item.acquirer_name,
          value_type: item.arrangement_name,
          amount_total: item.amount_total ? item.amount_total : '0',
          amount_available: item.amount_available ? item.amount_available : '0',
        };

        newArr.push(urItem);
      });

      setScheduleAnalytical(newArr);
    }
  };

  const handleScheduleAnalyticalOld = (data) => {
    const newArr: any = [];
    if (data) {
      data.forEach((item) => {
        item.credit.forEach((itemCredit) => {
          const creditItem = {
            settlement_date: itemCredit.settlement_date,
            merchant_name: item.merchant_name,
            merchant_document_number: item.merchant_document_number,
            acquirer_name: item.acquirer_name,
            value_type: 'CREDITO',
            amount_total: itemCredit.amount_total
              ? itemCredit.amount_total
              : '0',
            amount_available: itemCredit.amount_available
              ? itemCredit.amount_available
              : '0',
          };

          newArr.push(creditItem);
        });
        item.debit.forEach((itemDebit) => {
          const debitItem = {
            settlement_date: itemDebit.settlement_date,
            merchant_name: item.merchant_name,
            merchant_document_number: item.merchant_document_number,
            acquirer_name: item.acquirer_name,
            value_type: 'DEBITO',
            amount_total: itemDebit.amount_total ? itemDebit.amount_total : '0',
            amount_available: itemDebit.amount_available
              ? itemDebit.amount_available
              : '0',
          };

          newArr.push(debitItem);
        });
      });

      setScheduleAnalytical(newArr);
    }
  };

  const handleOnExport = async () => {
    const data = {
      document_number: schedule?.merchant?.document_number,
      analytical: [...scheduleAnalytical],
      summary: [...scheduleSummary],
    };

    setExportData(data);

    setTimeout(() => {
      const exportButton = document.querySelector(
        '.button-xls-export'
      ) as HTMLElement;

      if (exportButton) {
        exportButton.click();
      }
    });
  };

  const handleOffline = async () => {
    const data = {
      creditor_id: selectorRedux.DATA?.creditor?.id,
      merchant_id: selectorRedux.DATA?.merchant?.id,
      protocol: uuidv4(),
      changes_webhook_url: null,
      behavior: 'offline',
    };

    setLoadingLocal(true);
    const response = await dispatchRedux.CREATE(data);

    if (!response) {
      setDialogRules({
        dialogType: 'error',
        dialogTitle: 'Solicitação não executada',
      });
      setVisualizationOpen(true);
    } else {
      setDialogRules({
        dialogType: 'success',
        dialogTitle: 'Solicitação em processamento',
        dialogText:
          'Acompanhe o status da solicitação na tela "Agenda de recebíveis"',
      });
      setVisualizationOpen(true);
    }

    setLoadingLocal(false);
  };

  const handleCloseVisualization = () => {
    setVisualizationOpen(false);
    history.back();
  };

  return (
    <PageLayout
      headerTitle
      header
      sidebar
      title={'Agenda de recebíveis'}
      ctaBack
    >
      {selectorRedux.LOADING && <Loading />}
      {schedule?.request_status?.code == '02' && (
        <Box display="flex" gridGap={24} flexDirection="column">
          <Box display="flex" justifyContent="space-between">
            {schedule?.schedule_consolidated && (
              <Button
                color="primary"
                variant="contained"
                aria-label="show more"
                onClick={handleOffline}
              >
                Atualizar Agenda
              </Button>
            )}
          </Box>

          <Box display="flex" justifyContent="space-between">
            <h2>Resumo</h2>

            <Tooltip title="Baixar tabela">
              <IconButton onClick={handleOnExport} size="small">
                <GetAppIcon css={{ width: 18, height: 18 }} />
              </IconButton>
            </Tooltip>
          </Box>

          <TableScheduleSummaryComponent
            isOld={schedule?.is_old}
            summary={scheduleSummary}
            loading={selectorRedux.LOADING}
          />

          <h2>Agenda Analítica</h2>
          <TableScheduleAnalyticalComponent
            analytical={scheduleAnalytical}
            loading={selectorRedux.LOADING}
          />
        </Box>
      )}
      {schedule?.request_status?.code !== '02' && (
        <Box
          display="flex"
          gridGap={24}
          justifyContent="center"
          alignItems="center"
          height="65vh"
        >
          <Typography css={{ fontSize: '24px', color: '#9FAAB1' }}>
            Sua solicitação ainda não foi processada
          </Typography>
        </Box>
      )}
      <ExportXLS filename={'schedule'} data={exportData} />

      <Dialog
        onClose={handleCloseVisualization}
        aria-labelledby="customized-dialog-title"
        open={visualizationOpen}
        maxWidth={'xl'}
      >
        <DialogTitle
          css={{
            color: dialogRules.dialogType == 'error' ? '#F44336' : '#34BE84',
            borderTop: '2px',
            borderLeft: '2px',
            borderRight: '2px',
            borderBottom: '0',
            borderStyle: 'solid',
            borderColor:
              dialogRules.dialogType == 'error' ? '#F44336' : '#34BE84',
          }}
          id="customized-dialog-title"
        >
          {dialogRules.dialogTitle}
          <IconButton
            css={{ position: 'absolute', right: 0, top: '9px' }}
            aria-label="close"
            onClick={handleCloseVisualization}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          css={{
            color: '#0A2764',
            fontSize: '18px',
            borderTop: '0',
            borderLeft: '2px',
            borderRight: '2px',
            borderBottom: '2px',
            borderStyle: 'solid',
            borderColor:
              dialogRules.dialogType == 'error' ? '#F44336' : '#34BE84',
          }}
          dividers
        >
          {dialogRules.dialogType == 'error'
            ? 'Ocorreu um erro ao atualizar a agenda'
            : dialogRules.dialogText}
        </DialogContent>
      </Dialog>
    </PageLayout>
  );
};

export default ViewAppointmentsScreen;
