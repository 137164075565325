import CardTable from '@backoffice/private/components/Card/CardTable';
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface IDialogSmokeInfo {
  isVisible: boolean;
  handleClose: () => void;
  columns: any[];
  data: any[];
}

const ModalHelpTable = ({
  isVisible,
  handleClose,
  columns,
  data,
}: IDialogSmokeInfo) => {
  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
      fullWidth={false}
    >
      <DialogTitle id="customized-dialog-title">
        Como preencher corretamente
        <IconButton
          css={{ position: 'absolute', right: 0, top: '5px' }}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <CardTable
          onExport={async () => data}
          dataSource={data}
          columns={columns}
          footer={false}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ModalHelpTable;
