import React from 'react';

import { createDealSchema } from './CreateDeal.schema';

import PageLayout from '@backoffice/private/layouts/Page.layout';
import CreateFormDeal from '../../../components/Forms/CreateDeal/CreateFormDeal';

const CreateDeal = () => {
  const schema = createDealSchema;

  return (
    <PageLayout headerTitle header sidebar title={'Cadastro de compromisso'}>
      <CreateFormDeal />
    </PageLayout>
  );
};

export default CreateDeal;
