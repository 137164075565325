import * as Yup from 'yup';

import validateCNPJ from '@payhop/shared-utils/validateCNPJ.util';
import validateCPF from '@payhop/shared-utils/validateCPF.util';
import messages from '@payhop/shared-utils/messagesSchema.util';

const handleValidateDocument = (document) => {
  const documentLength = !!document && String(document).length;

  if (documentLength === 11 && validateCPF(document)) {
    return true;
  }

  if (documentLength === 14 && validateCNPJ(document)) {
    return true;
  }

  return false;
};

const baseCreateDealSchema = Yup.object({
  creditor_id: Yup.string().required(),
  document_number: Yup.string()
    .nullable()
    .required(messages.REQUIRED)
    .test('document_number', 'CNPJ ou CPF Inválido', (value) =>
      handleValidateDocument(value)
    ),
  title: Yup.string().required(messages.REQUIRED).default(''),
  code: Yup.string().required(messages.REQUIRED).default(''),
  description: Yup.string().required(messages.REQUIRED).default(''),
  type: Yup.string().required(messages.REQUIRED).default('1'),
  contract_type: Yup.string().required(messages.REQUIRED).default('1'),
  contract_modality: Yup.string().required(messages.REQUIRED).default(''),
  creditor_originator_bank_account_id: Yup.string().nullable().default(null),
  document_acquirers: Yup.array().of(Yup.string()),
  payment_arrangements: Yup.array().of(Yup.string()),
  min_percentage: Yup.string()
    .default('100')
    .when('type', (type, schema) =>
      schema.test(
        'min_percentage',
        'Percentual não pode ser igual a 0,00, nem maior que 100,00%',
        (val) => {
          const percentual: any = !!val && parseFloat(val);
          if (percentual <= Number(0) || percentual >= Number(100.01))
            return false;
          return true;
        }
      )
    ),
  issue_date: Yup.string().required(messages.REQUIRED).nullable().default(null),
  parcels_quantity: Yup.string().default('1'),
  total_amount: Yup.string()
    .required(messages.REQUIRED)
    .test('total_amount', 'Valor deve ser maior que 0', (value) => {
      const intValue = parseInt(value);
      if (intValue > 0) {
        return true;
      } else {
        return false;
      }
    }),
});

export const createDealSchema = baseCreateDealSchema.shape({
  expiration_date: Yup.date().when('type', {
    is: (type) => type !== '4',
    then: Yup.date().required(messages.REQUIRED).nullable().default(null),
    otherwise: Yup.date().nullable().default(null),
  }),
});

export const createDealSchemaIF = baseCreateDealSchema.shape({
  expiration_date: Yup.date().nullable().default(null),
});
