import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchData,
  selectData,
  resetData,
} from '@backoffice/private/ducks/creditors/originators/creditorOriginators.duck';

import { Originator } from '../@types/models/Originator';
import { Result } from '../@types/models/Result';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

type ResultOriginators = Result & {
  results: Originator[];
};

export default function useCreditorOriginators() {
  const handleNotification = useNotification();
  const [originators, setOriginators] = useState<Originator[]>([]);

  const dispatch = useDispatch();
  const result: ResultOriginators = useSelector(selectData);

  const handleSetOriginators = async (id?: string) => dispatch(fetchData(id));

  const handleResetOriginators = () => {
    dispatch(resetData());
  };

  useEffect(() => {
    if (result) {
      const data: Originator[] = result?.results;

      //Validates if creditor have Originators
      if (data && data?.length === 0) {
        handleNotification(
          'O fornecedor selecionado não possui nenhum originador ativo ou cadastrado',
          'error'
        );
        return;
      }

      const activeOriginatorsData = data.filter(
        (item) => item.status !== 'Inativo'
      );
      setOriginators(activeOriginatorsData);
    }
  }, [result]);

  return { originators, handleSetOriginators, handleResetOriginators };
}
