/* eslint-disable */

import fetch from '@payhop/shared-utils/fetch.util';

type dataRequest = {
  creditor_id: string;
  data: any;
};

export default async function postUsersUploadMerchantCreditorIF({
  creditor_id,
  data,
}: dataRequest) {
  const dataFiltered = data.map((item) => {
    return {
      creditor_id: creditor_id,

      origin_account: 2,
      type_account: 1,
      type_person: 2,
      document_number: item.cnpj___cpf,
      trading_name: item.cnpj___cpf,
      company_name: item.cnpj___cpf,
    };
  });

  const response = await fetch(`accounts/multiple-limited`, {
    method: 'POST',
    ms: 'ACCOUNT',
    auth: true,
    body: JSON.stringify(dataFiltered),
  });

  const result = await response.json();

  return [response.ok, result];
}
