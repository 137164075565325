import React from 'react';

import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Props = {
  data: Array<string | number>;
};

const ProfilesExport = ({ data }: Props) => {
  function handleFilterProfileData(data) {
    const filteredData: any = [];
    data.forEach((item) => {
      const newData = {
        description: item.description,
        type: item.is_payhop ? 'PAYHOP' : 'FORNECEDOR',
        active: item.active ? 'SIM' : 'NÃO',
        created_at: moment(item.created_at).format('DD/MM/YYYY'),
        updated_at: item.updated_at
          ? moment(item.updated_at).format('DD/MM/YYYY')
          : '-',
        count: item.count,
      };
      filteredData.push(newData);
    });

    return filteredData;
  }
  return (
    <ExcelFile
      filename={'Perfis'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleFilterProfileData(data)} name="Perfis">
        <ExcelColumn label="NOME" value="description" />
        <ExcelColumn label="TIPO DE PERFIL" value="type" />
        <ExcelColumn label="ATIVO" value="active" />
        <ExcelColumn label="DATA DE CRIAÇÃO" value="created_at" />
        <ExcelColumn label="DATA DA ÚLTIMA MODIFICAÇÃO" value="updated_at" />
        <ExcelColumn label="QUANTIDADE DE USUÁRIOS" value="count" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ProfilesExport;
