const CC = 'Conta Corrente';
const CD = 'Conta de Depósito';
const CG = 'Conta Garantia';
const CI = 'Conta Investimento';
const PG = 'Conta de Pagamento';
const PP = 'Conta poupança';

export const TIPO_CONTA = {
  CC,
  CD,
  CG,
  CI,
  PG,
  PP,
};
