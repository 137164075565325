import React from 'react';

import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Props = {
  data: Array<string | number>;
};

const DealUrsExport = ({ data }: Props) => {
  function handleFilterDealData(data) {
    const filteredData: any = [];
    data.forEach((item) => {
      const newData = {
        acquirer_name: item.acquirer_name,
        payment_arrangement: `${item.brand_name} ${item.payment_arrangement}`,
        effect_order: item.effect_order,
        settlement_date: item.settlement_date
          ? moment(item.settlement_date).format('DD/MM/YYYY')
          : '-',
        committed_amount_payhop: Number(
          item.committed_amount_payhop
        ).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        effect_amount_initial: Number(
          item.effect_amount_initial
        ).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        effect_amount: Number(item.effect_amount).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        effect_amount_updated: Number(
          item.effect_amount_updated
        ).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        effective_settlement_amount: Number(
          item.effective_settlement_amount
        ).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        effective_settlement_date: item.effective_settlement_date
          ? moment(item.effective_settlement_date).format('DD/MM/YYYY')
          : '-',
        updated_at: item.updated_at
          ? moment(item.updated_at).format('DD/MM/YYYY')
          : '-',
      };
      filteredData.push(newData);
    });
    return filteredData;
  }
  return (
    <ExcelFile
      filename={'Urs Compromissos'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleFilterDealData(data)} name="URs-Compromissos">
        <ExcelColumn label="ADQUIRENTE" value="acquirer_name" />
        <ExcelColumn label="ARRANJO" value="payment_arrangement" />
        <ExcelColumn label="PRIORIDADE" value="effect_order" />
        <ExcelColumn label="LIQUIDAÇÃO" value="settlement_date" />
        <ExcelColumn
          label="VALOR COMPROMETIDO"
          value="committed_amount_payhop"
        />
        <ExcelColumn
          label="VALOR DO EFEITO INICIAL"
          value="effect_amount_initial"
        />
        <ExcelColumn label="VALOR DO EFEITO" value="effect_amount" />
        <ExcelColumn
          label="VALOR DO EFEITO ATUALIZADO"
          value="effect_amount_updated"
        />
        <ExcelColumn
          label="VALOR DE LIQUIDAÇÃO INFORMADO"
          value="effective_settlement_amount"
        />
        <ExcelColumn
          label="DATA DE LIQUIDAÇÃO EFETIVA"
          value="effective_settlement_date"
        />
        <ExcelColumn label="DATA DE ATUALIZAÇÃO" value="updated_at" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default DealUrsExport;
