import * as Yup from 'yup';
import validateCNPJ from '@payhop/shared-utils/validateCNPJ.util';

export const validationSchema = Yup.object({
  cnpj_domicilio: Yup.string()
    .required('CNPJ é obrigatório')
    .test('cnpj_domicilio', 'CNPJ inválido', (value) => {
      const documentLength: any = !!value && String(value).length;

      if (documentLength < 14 || !validateCNPJ(value)) {
        return false;
      }

      return true;
    }),
  titulo_domicilio: Yup.string().required('Título é obrigatório'),
  banco: Yup.string().required('Banco é obrigatório').default(undefined),
  ispb: Yup.string().required('ISPB é obrigatório').default('-'),
  agencia: Yup.string()
    .matches(/^[0-9]*$/, 'O input aceita apenas números')
    .required('Agência é obrigatória')
    .min(4, 'Mínimo de 4 caracteres')
    .max(4, 'Máximo de 4 caracteres'),
  conta: Yup.string()
    .matches(/^[0-9]*$/, 'O input aceita apenas números')
    .required('Conta é obrigatória')
    .max(11, 'Máximo de 11 caracteres'),
  digito: Yup.string().when('tipo_conta', {
    is: (tipo_conta) => tipo_conta !== 'PG', // Defina o valor esperado para tornar o 'digito' obrigatório
    then: Yup.string()
      .required('Digito é obrigatório')
      .max(1, 'Digito só possui um caractere'),
    otherwise: Yup.string().max(1, 'Digito só possui um caractere'), // Caso contrário, ainda permitirá o 'digito' opcional
  }),
  tipo_conta: Yup.string()
    .required('Tipo da conta é obrigatório')
    .default(null),
});
