const CONSULTA_POS_COMPROMISSO = 'pos_compromisso';
const CONSULTA_PRE_COMPROMISSO = 'pre_compromisso';
const PARCEIRO_INSITUICAO_FINANCEIRA = 'if';
const PARCEIRO_FORNECEDOR = 'Fornecedor';
const LIQUIDACAO_UR_DEFAULT = 'default';
const LIQUIDACAO_UR_PARCEIRO = 'parceiro';

export const PARAMETERS_VALUES = {
  [CONSULTA_POS_COMPROMISSO]: 'Consulta de Agenda Pós Aprovação do Compromisso',
  [CONSULTA_PRE_COMPROMISSO]: 'Consulta de Agenda Pré Aprovação do Compromisso',
  [PARCEIRO_INSITUICAO_FINANCEIRA]: 'Instituição financeira',
  [PARCEIRO_FORNECEDOR]: 'Fornecedor',
  [LIQUIDACAO_UR_DEFAULT]: 'Default',
  [LIQUIDACAO_UR_PARCEIRO]: 'Parceiro',
};
