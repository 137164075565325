import useQuery from '@payhop/shared-hooks/useQuery.hook';

const useDynamicCreate = () => {
  const query = useQuery();
  const typeCreate = query.get('type');

  return {
    typeCreate,
  };
};

export default useDynamicCreate;
