import styled from '@emotion/styled';

export const CardDetailContainer = styled.div`
  background: #ffffff;
  border-radius: 5px;
  padding: 8px 12px;
`;

export const CardDetailContent = styled.div`
  margin-top: 4px;

  p {
    margin: 0;
  }
  span {
    font-weight: 600;
  }
`;
