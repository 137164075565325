import React, { useEffect, useMemo, useCallback, useState } from 'react';
import moment from 'moment';

import getDealHistoryService from '@backoffice/private/services/deal/getDealHistory.service';

import CardTable from '@backoffice/private/components/Card/CardTable';
import NumberFormat from 'react-number-format';

const TableDealHistoryOriginatorComponent = ({
  historyOriginator,
  loading,
}) => {
  const columns = useMemo(
    () => [
      {
        title: 'Usuário',
        dataIndex: 'account_name',
        key: 'account_name',
        render: ({ account_name }) => (account_name ? account_name : '-'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Data e hora',
        dataIndex: 'created_at',
        key: 'created_at',
        render: ({ created_at }) =>
          moment(created_at).format('DD/MM/YYYY HH:mm'),
      },
      {
        title: 'CNPJ Domicílio',
        dataIndex: 'document_number_domicile',
        key: 'document_number_domicile',
        render: ({ document_number_domicile }) => (
          <NumberFormat
            displayType="text"
            value={document_number_domicile}
            isNumericString
            format="##.###.###/####-##"
          />
        ),
      },
      {
        title: 'Banco',
        dataIndex: 'domain_bank',
        key: 'domain_bank',
        render: ({ domain_bank_name, domain_bank_code }) =>
          domain_bank_code + '-' + domain_bank_name,
      },
      {
        title: 'Agência',
        dataIndex: 'branch_number',
        key: 'branch_number',
      },
      {
        title: 'Conta',
        dataIndex: 'account_number',
        key: 'account_number',
      },
      {
        title: 'Tipo da conta',
        dataIndex: 'account_type',
        key: 'account_type',
      },
    ],
    []
  );

  return (
    <CardTable
      loading={loading}
      dataSource={historyOriginator}
      columns={columns}
      footer={false}
    />
  );
};

export default TableDealHistoryOriginatorComponent;
