import React from 'react';

import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@backoffice/shared/components/Typography.component';

type LoadingProps = {
  title?: string;
};

const Loading = ({ title }: LoadingProps) => {
  return (
    <Box
      display="flex"
      position="fixed"
      flexDirection={'column'}
      top={0}
      left={0}
      right={0}
      bottom={0}
      width="100%"
      height="100%"
      zIndex={9999}
      alignItems="center"
      justifyContent="center"
      bgcolor="#ffffffd1"
    >
      <CircularProgress />
      {title && (
        <Typography customVariant="text__body" customColor="info-500">
          {title}
        </Typography>
      )}
    </Box>
  );
};

export default Loading;
