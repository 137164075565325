import React from 'react';

import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Props = {
  data: Array<string | number>;
};

const ArragementsExport = ({ data }: Props) => {
  function handleFilterArrangementData(data) {
    const filteredData: any = [];
    data.forEach((item) => {
      const newData = {
        brand_code: item.brand_code,
        brand_name: item.brand_name,
        type: item.type == 'credit' ? 'Crédito' : 'Débito',
        payment_arrangement: item.payment_arrangement,
        created_at: moment(item.created_at).format('DD/MM/YYYY HH:mm'),
        updated_at: item.updated_at
          ? moment(item.updated_at).format('DD/MM/YYYY HH:mm')
          : '-',
      };
      filteredData.push(newData);
    });
    return filteredData;
  }
  return (
    <ExcelFile
      filename={'Arranjos'}
      element={
        <button
          className="button-xls-export"
          style={{ display: 'none' }}
        ></button>
      }
    >
      <ExcelSheet data={handleFilterArrangementData(data)} name="Arranjos">
        <ExcelColumn label="CÓDIGO" value="brand_code" />
        <ExcelColumn label="NOME" value="brand_name" />
        <ExcelColumn label="TIPO" value="type" />
        <ExcelColumn label="ARRANJO" value="payment_arrangement" />
        <ExcelColumn label="DATA DE CRIAÇÃO" value="created_at" />
        <ExcelColumn label="DATA DE INATIVAÇÃO" value="updated_at" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ArragementsExport;
