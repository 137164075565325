import { useMemo } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
  BRAND_COLORS,
  SYSTEM_COLORS,
} from '@backoffice/shared/configs/colors.config';
import { FONT_STYLES } from '@backoffice/shared/configs/typography.config';

const buttonSizeUseStyles = makeStyles(() =>
  createStyles({
    sm: {
      fontSize: FONT_STYLES.TEXT_BODY_SM.fontSize,
      height: 30,
    },
    md: {
      fontSize: FONT_STYLES.TEXT_BODY.fontSize,
      height: 38,
    },
    lg: {
      fontSize: FONT_STYLES.TEXT_BODY_LG.fontSize,
      height: 48,
    },
  })
);

const containedUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.info.dark,
      },
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.warning.dark,
      },
    },
    danger: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    default: {
      backgroundColor: theme.palette.grey[200],
      color: (theme as any).palette.info.dark2,
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
    },
    gradient: {
      background: BRAND_COLORS.gradient.main,
      color: theme.palette.common.white,
      '&:hover': {
        background: 'linear-gradient(180deg, #20BAF6 0%, #7450F0 100%)',
      },
    },
    white: {
      backgroundColor: 'white',
    },
  })
);

const outlinedUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: {
      borderColor: theme.palette.info.main,
      color: theme.palette.info.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.info.main, 0.1),
      },
    },
    success: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.success.main, 0.1),
      },
    },
    warning: {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.warning.main, 0.1),
      },
    },
    danger: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.1),
      },
    },
    default: {
      borderColor: (theme as any).palette.info.dark2,
      color: (theme as any).palette.info.dark2,
      '&:hover': {
        backgroundColor: BRAND_COLORS.gray[100],
      },
    },
    secondaryDarker: {
      borderColor: BRAND_COLORS.secondary[500],
      color: BRAND_COLORS.secondary[500],
      '&:hover': {
        backgroundColor: fade(BRAND_COLORS.secondary[500], 0.1),
      },
    },
    info500: {
      borderColor: SYSTEM_COLORS.info[500],
      color: SYSTEM_COLORS.info[500],
      '&:hover': {
        backgroundColor: fade(SYSTEM_COLORS.info[500], 0.1),
      },
    },
  })
);

const textUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: {
      color: theme.palette.info.main,
      '&:hover': {
        color: theme.palette.info.dark,
      },
    },
    success: {
      color: theme.palette.success.main,
      '&:hover': {
        color: theme.palette.success.dark,
      },
    },
    warning: {
      color: theme.palette.warning.main,
      '&:hover': {
        color: theme.palette.warning.dark,
      },
    },
    danger: {
      color: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error.dark,
      },
    },
    default: {
      color: (theme as any).palette.info.dark2,
      '&:hover': {
        backgroundColor: BRAND_COLORS.gray[100],
      },
    },
  })
);

const textColorsUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondary: {
      color: theme.palette.secondary.main,
    },
  })
);

type buttonProps = ButtonProps & {
  textColor?: 'secondary';
  buttonSize?: 'sm' | 'md' | 'lg';
  customColor?:
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'default'
    | 'gradient'
    | 'info-500'
    | 'secondary-darker'
    | 'white';
  loading?: boolean;
  loadingSize?: string;
};

const ButtonComponent: React.FC<buttonProps> = ({
  className,
  onClick,
  loading,
  children,
  customColor,
  buttonSize = 'md',
  textColor,
  loadingSize = 'inherit',
  ...buttonProps
}) => {
  const { variant } = buttonProps;

  const containedClasses = containedUseStyles();
  const outlinedClasses = outlinedUseStyles();
  const textClasses = textUseStyles();
  const buttonSizeClasses = buttonSizeUseStyles();
  const textColorsClasses = textColorsUseStyles();

  const buttonIs = useMemo(
    () => ({
      contained: variant === 'contained',
      outlined: variant === 'outlined',
      text: variant === 'text',
    }),
    [variant]
  );

  const objClasses = useMemo(() => {
    if (buttonIs.contained) {
      return containedClasses;
    }

    if (buttonIs.outlined) {
      return outlinedClasses;
    }

    if (buttonIs.text) {
      return textClasses;
    }

    return undefined;
  }, [variant]);

  const colorsArr = [
    customColor === 'info' && objClasses?.info,
    customColor === 'success' && objClasses?.success,
    customColor === 'warning' && objClasses?.warning,
    customColor === 'danger' && objClasses?.danger,
    customColor === 'default' && objClasses?.default,
    customColor === 'gradient' && (objClasses as any)?.gradient,
    customColor === 'secondary-darker' && (objClasses as any)?.secondaryDarker,
    customColor === 'info-500' && (objClasses as any)?.info500,
    customColor === 'white' && (objClasses as any)?.white,
  ];

  const buttonSizes = [
    buttonSize === 'sm' && buttonSizeClasses.sm,
    buttonSize === 'md' && buttonSizeClasses.md,
    buttonSize === 'lg' && buttonSizeClasses.lg,
  ];

  const textColorsArr = [
    textColor === 'secondary' && textColorsClasses.secondary,
  ];

  const classesObj = classNames([
    className,
    ...colorsArr,
    ...buttonSizes,
    ...textColorsArr,
  ]);

  return (
    <Button
      {...buttonProps}
      className={classesObj}
      onClick={!loading ? onClick : undefined}
    >
      {loading && <CircularProgress size={loadingSize} color="inherit" />}

      {!loading && children}
    </Button>
  );
};

export default ButtonComponent;
