import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import moment from 'moment';

import * as schedules from '@backoffice/private/ducks/schedules/listSchedules.duck';

import ROUTES from '@backoffice/shared/utils/routes.util';

import VisibilityIcon from '@material-ui/icons/Visibility';

import useNotification from '@payhop/shared-hooks/useNotification.hook';
import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import CardTable from '@backoffice/private/components/Card/CardTable';
import { Box, IconButton } from '@material-ui/core';

const TableScheduleComponent = () => {
  const handleNotification = useNotification();

  const [loadingLocal, setLoadingLocal] = useState(false);

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(schedules.Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(schedules.Dispatchs.resetFilters, dispatch),
    GET: flow(schedules.Dispatchs.get, dispatch),
    DELETE: flow(schedules.Dispatchs.delete, dispatch),
    RESET: flow(schedules.Dispatchs.reset, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(schedules.Selectors.data),
    LOADING: useSelector(schedules.Selectors.loading),
    DATA_RESULTS: useSelector(schedules.Selectors.data_results),
    FILTERS: useSelector(schedules.Selectors.filters),
    RESULTSET: useSelector(schedules.Selectors.resultset),
    FETCHED: useSelector(schedules.Selectors.fetched),
  };

  const { handleChangePage, handleChangeRowsPerPage } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: true,
    },
  });

  const handleOnRefresh = () => {
    dispatchRedux.GET();
  };

  const handleOnExport = async () => {
    const result = await dispatchRedux.GET({ isExport: true });

    return (result as any)?.results;
  };

  const handleMaskDocument = (document) => {
    const stringDocument = String(document);

    if (stringDocument.length === 11) {
      return stringDocument.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
        '$1.$2.$3-$4'
      );
    }
    if (stringDocument.length === 14) {
      return stringDocument.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
        '$1.$2.$3/$4-$5'
      );
    }
  };

  return (
    <TableSchedule
      loading={selectorRedux.LOADING || loadingLocal}
      results={selectorRedux.DATA_RESULTS}
      handleOnRefresh={handleOnRefresh}
      handleOnExport={handleOnExport}
      handleChangePage={handleChangePage}
      handleMaskDocument={handleMaskDocument}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      filters={{
        ...selectorRedux.FILTERS,
        ...selectorRedux.RESULTSET,
      }}
    />
  );
};

const TableSchedule = ({
  results,
  filters,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
  handleMaskDocument,
  handleOnRefresh,
  handleOnExport,
}) => {
  const history = useHistory();

  const { isAdmin } = useUserHook();

  const { limit, count, offset } = filters || {};

  const columns = [
    {
      render: ({ id, status }) => (
        <Box display="flex" alignItems="center" gridGap={8}>
          <IconButton
            size="small"
            onClick={() => history.push(ROUTES.PRIVATE.SCHEDULES.EDIT(id))}
            disabled={status == 'Consulta de Agenda Processada' ? false : true}
          >
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
    isAdmin && {
      title: 'Fornecedor',
      dataIndex: 'creditor_company_name',
      key: 'creditor_company_name',
    },
    {
      title: 'Tipo de Consulta',
      dataIndex: 'partner_type',
      key: 'partner_type',
      render: ({ partner_type }) => {
        if (partner_type === 'CONSOLIDATED') {
          return 'Agenda Consolidada';
        }
        if (partner_type === 'DEFAULT') {
          return 'Agenda API';
        }
        if (partner_type === 'IF') {
          return 'Agenda Instituição Financeira';
        }
      },
    },
    isAdmin && {
      title: 'Parceiro',
      dataIndex: 'partnership',
      key: 'partnership',
    },
    {
      title: 'Estabelecimento',
      dataIndex: 'merchant_company_name',
      key: 'merchant_company_name',
    },
    {
      title: 'CNPJ',
      render: ({ merchant_document_number }) =>
        handleMaskDocument(merchant_document_number),
    },
    {
      title: 'Data consulta',
      dataIndex: 'created_at',
      render: ({ created_at }) => moment(created_at).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Data Execução inicial',
      dataIndex: 'execution_begin_date',
      render: ({ execution_begin_date }) =>
        moment(execution_begin_date).format('DD/MM/YYYY'),
    },
    {
      title: 'Data Execução final',
      dataIndex: 'execution_end_date',
      render: ({ execution_end_date }) =>
        moment(execution_end_date).format('DD/MM/YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <>
      <CardTable
        count={count || 0}
        loading={loading}
        dataSource={results}
        rowsPerPage={limit}
        columns={columns as any}
        page={offset / limit}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onRefresh={handleOnRefresh}
        onExport={handleOnExport}
        exporttype="xls"
        exportname="schedules"
        tableProps={{
          style: {
            minWidth: 1700,
          },
        }}
      />
    </>
  );
};

export default TableScheduleComponent;
