import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import { Dispatchs } from '@backoffice/private/ducks/deals/listDeals.duck';

import AppointimentsScreen from './screens/Appointments.screen';
import ViewAppointmentsScreen from './screens/ViewAppointments.screen';
import CreateDeal from './screens/create/deal/CreateDeal';

const AppointmentsModule: React.FC<any> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const dispatchRedux = {
    RESET: flow(Dispatchs.reset, dispatch),
  };

  useEffect(() => {
    return () => {
      const location = history.location.pathname;

      if (!location.includes('appointments')) dispatchRedux.RESET();
    };
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={ROUTES.PRIVATE.APPOINTMENTS.ROOT}
        component={AppointimentsScreen}
      />

      <Route
        exact
        path={ROUTES.PRIVATE.APPOINTMENTS.EDIT()}
        component={ViewAppointmentsScreen}
      />
      <Route
        exact
        path={ROUTES.PRIVATE.APPOINTMENTS.CREATE}
        component={CreateDeal}
      />
      <Redirect to={ROUTES.PRIVATE.HOME} />
    </Switch>
  );
};

export default AppointmentsModule;
