import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormikContext, Formik, Field } from 'formik';
import { flow, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import { v4 as uuidv4 } from 'uuid';

import Loading from '@backoffice/private/components/Loading/Loading.component';
import FieldColor from '@backoffice/private/components/Fields/FieldColor.component';
import FieldUploadImage from '@backoffice/private/components/Fields/FieldUploadImage.component';
import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';

import CardForm from '@backoffice/private/components/Card/CardForm';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';
import CardTitle from '@backoffice/private/components/Card/CardTitle';

import useCrudNotification from '@backoffice/shared/hooks/useCrudNotification.hook';
import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/creditors/visuals/creditorVisual.duck';

const validationSchema = Yup.object({
  color_hex: Yup.string().required().default(''),
  path_logo: Yup.mixed().required(),
  path_image: Yup.mixed().required(),
  article: Yup.string().required().default(''),
  product_service: Yup.string().required().default(''),
});

const FormCreditorVisualContainer = () => {
  const initial = useRef({});

  const [loadingLocal, setLoadingLocal] = useState(false);
  const [logoImage, setlogoImage] = useState('');
  const [iconImage, setIconImage] = useState('');
  const [key, setKey] = useState(uuidv4());

  const [isEdit, setIsEdit] = useState(false);

  const history = useHistory();
  const handleBack = () => history.goBack();

  const { values, setValues, errors, validateForm } = useFormikContext();

  const dispatch = useDispatch();

  const dispatchRedux = {
    CREATE: flow(Dispatchs.create, dispatch),
    UPDATE: flow(Dispatchs.update, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    GET: flow(Dispatchs.get, dispatch),
    RESET_NOTIFICATION: flow(Dispatchs.reset_notification, dispatch),
    RESET_ERROR: flow(Dispatchs.reset_error, dispatch),
  };

  const selectorRedux = {
    FETCHED: useSelector(Selectors.fetched),
    LOADING: useSelector(Selectors.loading),
    DATA: useSelector(Selectors.data),
    ERROR: useSelector(Selectors.error),
    NOTIFICATION: useSelector(Selectors.notification),
  };

  const { handleNotification } = useCrudNotification({
    selectorRedux,
    dispatchRedux,
  });

  useEffect(() => {
    dispatchRedux.GET();
  }, []);

  useEffect(() => {
    const { successGet, successCreate } = selectorRedux.DATA || {};

    if (successGet) {
      setIsEdit(true);

      const obj = {
        id: selectorRedux.DATA.id,
        color_hex: selectorRedux.DATA.color_hex,
        path_logo: selectorRedux.DATA.path_logo,
        path_image: selectorRedux.DATA.path_image,
        path_url: selectorRedux.DATA.path_url,
        article: selectorRedux.DATA.article,
        product_service: selectorRedux.DATA.product_service,
      };

      setlogoImage(selectorRedux.DATA.path_logo);
      setIconImage(selectorRedux.DATA.path_image);
      setValues(obj);
      initial.current = obj;
      setKey(uuidv4());
    }

    if (successCreate) {
      history.go(0);
    }
  }, [selectorRedux.DATA]);

  const handleSubmit = async () => {
    if (isEdit) {
      dispatchRedux.UPDATE(values);
    } else {
      setLoadingLocal(true);
      await dispatchRedux.CREATE(values);
    }
  };

  const isEqualsValues = isEdit && isEqual(values, initial.current);

  return (
    <FormCreditorVisualComponent
      key={key}
      loading={selectorRedux.LOADING}
      isEdit={isEdit}
      loadingLocal={loadingLocal}
      fetched={selectorRedux.FETCHED}
      errorsForm={errors}
      handleBack={handleBack}
      isEqualsValues={isEqualsValues}
      handleSubmit={handleSubmit}
      logoImage={logoImage}
      iconImage={iconImage}
    />
  );
};

const FormCreditorVisualComponent = ({
  loading,
  loadingLocal,
  isEdit,
  fetched,
  handleSubmit,
  errorsForm,
  handleBack,
  isEqualsValues,
  logoImage,
  iconImage,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_fornecedor_identidade_visual',
  });

  const inputDisabled = !permissions?.some(
    (item) => item == ('create' || 'edit')
  );
  return (
    <>
      {permissions?.some((item) => item == 'read') && (
        <Box display="flex" flexDirection="column" gridGap={24}>
          <Box gridGap={8} display="flex" flexDirection="column">
            <CardTitle>Identidade Visual</CardTitle>

            <CardForm
              formik
              fields={[
                [
                  {
                    name: 'path_url',
                    label: 'Site Institucional',
                    required: true,
                    disabled: inputDisabled,
                  },
                ],
                [
                  {
                    name: 'color_hex',
                    type: 'custom',
                    label: 'Cor primária',
                    component: (
                      <FieldColor
                        inputDisabled={inputDisabled}
                        name="color_hex"
                      />
                    ),
                  },
                ],
                [
                  {
                    type: 'custom',
                    label: 'Logo',
                    disabled: inputDisabled,
                    component: (
                      <FieldUploadImage
                        name="path_logo"
                        labelTitle="Logo"
                        hasImage={logoImage}
                      />
                    ),
                  },
                  {
                    type: 'custom',
                    label: 'Logo',
                    disabled: inputDisabled,
                    component: (
                      <FieldUploadImage
                        name="path_image"
                        labelTitle="Imagem"
                        hasImage={iconImage}
                      />
                    ),
                  },
                ],
                [
                  {
                    name: 'article',
                    label: 'Artigo',
                    type: 'select',
                    required: true,
                    disabled: inputDisabled,
                    options: [
                      { label: 'O', value: 'O' },
                      { label: 'A', value: 'A' },
                      { label: 'E', value: 'E' },
                    ],
                  },
                  {
                    name: 'product_service',
                    label: 'Serviço/Produto',
                    required: true,
                    disabled: inputDisabled,
                  },
                ],
              ]}
            />
          </Box>

          <CardFormFooter
            hasError={isEqualsValues || Object.keys(errorsForm).length > 0}
            onSubmit={handleSubmit}
            disabled={inputDisabled}
            onCancel={handleBack}
            loading={loading}
          />
        </Box>
      )}
      {((isEdit && loading && !fetched) || loadingLocal) && <Loading />}
      {(!permissions || !permissions?.some((item) => item == 'read')) && (
        <ModuleNotPermited />
      )}
    </>
  );
};

export default function FormCreditorFormik() {
  return (
    <Formik
      initialValues={{ ...validationSchema.cast() }}
      onSubmit={() => {}}
      validateOnMount
      validationSchema={validationSchema}
    >
      <FormCreditorVisualContainer />
    </Formik>
  );
}
