import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import flow from 'lodash/flow';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  Dispatchs,
  Selectors,
} from '../../../../ducks/parameters/arrangements/arrangement.duck';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

import messages from '@backoffice/shared/utils/messagesSchema.util';

import CardTitle from '@backoffice/private/components/Card/CardTitle';
import CardForm from '@backoffice/private/components/Card/CardForm';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';
import FieldUploadImage from '@backoffice/private/components/Fields/FieldUploadImage.component';

const validationSchema = Yup.object({
  brand_name: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .min(3, 'O campo deve ter mais de 3 caracteres')
    .max(30, 'Número de caracteres excedido'),
  brand_code: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .max(30, 'Número de caracteres excedido'),
  type: Yup.string().required(messages.REQUIRED).default('credit'),
  payment_arrangement: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .length(3, 'O Arranjo deve possuir 3 caracteres'),
  file: Yup.mixed(),
});

const FormArrangementsContainer = () => {
  const initial = useRef({});
  const history = useHistory();
  const dispatch = useDispatch();
  const handleNotification = useNotification();
  const [key, setKey] = useState(uuidv4());
  const [logoImage, setlogoImage] = useState('');
  const { values, errors, setValues, resetForm } = useFormikContext();

  const { isEdit, id } = useIsEdit();

  const handleBack = () => history.goBack();

  const dispatchArrangementRedux = {
    GET: flow(Dispatchs.get, dispatch),
    CREATE: flow(Dispatchs.create, dispatch),
    UPDATE: flow(Dispatchs.update, dispatch),
    RESET_NOTIFICATION: flow(Dispatchs.reset_notification, dispatch),
  };

  const selectorArrangementRedux = {
    FETCHED: useSelector(Selectors.fetched),
    LOADING: useSelector(Selectors.loading),
    DATA: useSelector(Selectors.data),
    NOTIFICATION: useSelector(Selectors.notification),
    ERROR: useSelector(Selectors.error),
  };

  useEffect(() => {
    if (isEdit) {
      dispatchArrangementRedux.GET(id);
    }
  }, [isEdit]);

  useEffect(() => {
    const { successActionUpdate, error, successAction, errors } =
      selectorArrangementRedux.DATA || {};

    if (isEdit) {
      if (
        successActionUpdate === true &&
        selectorArrangementRedux.NOTIFICATION === true
      ) {
        handleNotification('Atualizado com sucesso!', 'success');

        dispatchArrangementRedux.RESET_NOTIFICATION();
      }

      if (
        successActionUpdate === false &&
        selectorArrangementRedux.NOTIFICATION === true
      ) {
        handleNotification(error, 'error');

        dispatchArrangementRedux.RESET_NOTIFICATION();
      }
    } else {
      if (
        successAction === true &&
        selectorArrangementRedux.NOTIFICATION === true
      ) {
        handleNotification('Credenciadora cadastrada com sucesso!', 'success');
        dispatchArrangementRedux.RESET_NOTIFICATION();
      } else if (
        successAction === false &&
        selectorArrangementRedux.NOTIFICATION === true
      ) {
        handleNotification(errors?.message[0], 'error');
        dispatchArrangementRedux.RESET_NOTIFICATION();
      }
    }
  }, [selectorArrangementRedux.DATA, isEdit]);

  useEffect(() => {
    if (selectorArrangementRedux.DATA?.brand_name) {
      if (selectorArrangementRedux.FETCHED && isEdit) {
        const obj = {
          ...(values as any),
          brand_name: selectorArrangementRedux.DATA.brand_name,
          brand_code: selectorArrangementRedux.DATA.brand_code,
          type: selectorArrangementRedux.DATA.type,
          payment_arrangement:
            selectorArrangementRedux.DATA.payment_arrangement,
        };
        setValues(obj);

        initial.current = obj;

        setKey(uuidv4());
      }
    }
  }, [selectorArrangementRedux.FETCHED, selectorArrangementRedux.DATA, isEdit]);

  const handleControlUploadImage = () => {
    setlogoImage('uploaded');
  };

  return (
    <FormArrangementsComponent
      isEdit={isEdit}
      loading={selectorArrangementRedux.LOADING}
      fetched={selectorArrangementRedux.FETCHED}
      errorsForm={errors}
      values={values}
      handleBack={handleBack}
      logoImage={logoImage}
      handleControlUploadImage={handleControlUploadImage}
      handleSubmit={() => {
        if (isEdit) {
          dispatchArrangementRedux.UPDATE(id, {
            ...(values as any),
            active: selectorArrangementRedux.DATA.active,
          });
        } else {
          dispatchArrangementRedux.CREATE({ ...(values as any) });
          resetForm();
          setlogoImage('');
        }
      }}
    />
  );
};

const FormArrangementsComponent = ({
  isEdit,
  loading,
  handleSubmit,
  errorsForm,
  handleBack,
  logoImage,
  fetched,
  handleControlUploadImage,
  values,
}) => {
  const arrangementSchema = [
    [
      !isEdit && {
        label: 'Código',
        name: 'brand_code',
        required: true,
      },
      {
        label: 'Nome',
        name: 'brand_name',
        required: true,
      },
      {
        label: 'Tipo',
        name: 'type',
        type: 'select',
        default: 'credit',
        options: [
          {
            label: 'Crédito',
            value: 'credit',
          },
          {
            label: 'Débito',
            value: 'debit',
          },
        ],
      },
      {
        label: 'Arranjo',
        name: 'payment_arrangement',
        required: true,
      },
      !isEdit && {
        type: 'custom',
        label: 'Logo',
        component: (
          <FieldUploadImage
            name="file"
            labelTitle="Logo"
            hasImage={logoImage}
            variant={'minify'}
            onChange={() => {
              handleControlUploadImage();
            }}
          />
        ),
      },
    ],
  ];
  return (
    <Box gridGap={8} display="flex" flexDirection="column">
      <CardTitle>
        {isEdit ? 'Editar Arranjo' : 'Cadastrar novo arranjo'}
      </CardTitle>

      <Box display="flex" flexDirection="column" gridGap={24}>
        <CardForm formik fields={arrangementSchema as any} />

        <CardFormFooter
          hasError={Object.keys(errorsForm).length > 0}
          onSubmit={handleSubmit}
          onCancel={handleBack}
          loading={loading}
        />
      </Box>

      {isEdit && loading && !fetched && (
        <Box
          display="flex"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          width="100%"
          height="100%"
          zIndex={9999}
          alignItems="center"
          justifyContent="center"
          bgcolor="#ffffffd1"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default function FormArrangementsFormik() {
  return (
    <Formik
      initialValues={{ ...validationSchema.cast() }}
      onSubmit={() => {}}
      validateOnMount
      validationSchema={validationSchema}
    >
      <FormArrangementsContainer />
    </Formik>
  );
}
