import React, { useCallback, useEffect, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { useFormikContext, Formik, Field } from 'formik';
import { flow, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import TextFieldMaterial from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Autocomplete } from 'formik-material-ui-lab';
import * as Yup from 'yup';

import CardForm from '@backoffice/private/components/Card/CardForm';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';

import getAddressByZip from '@backoffice/private/services/account/getAddressByZip.service';
import getDocument from '@backoffice/private/services/account/getDocument.service';
import postAccountsSendTokenService from '@backoffice/private/services/account/postAccountsSendToken.service';

import DialogChangeEmailComponent from '../DialogChangeEmail.component';
import DialogSimplifiedOnboardingLink from '../DialogSimplifiedOnboardingLink.component';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';
import useCrudNotification from '@backoffice/shared/hooks/useCrudNotification.hook';

import getCreditorParameters from '@backoffice/private/services/creditor/getCreditorParameters.service';

import {
  Dispatchs as DispatchsAccounts,
  Selectors as SelectorsAccounts,
} from '@backoffice/private/ducks/accounts/account.duck';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/merchants/merchant.duck';

import * as dialogMerchantChangeEmailDuck from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantChangeEmail.duck';

import ROUTES from '@backoffice/shared/utils/routes.util';
import messages from '@backoffice/shared/utils/messagesSchema.util';
import validateCNPJ from '@payhop/shared-utils/validateCNPJ.util';
import validateCPF from '@payhop/shared-utils/validateCPF.util';

import STATES from '@backoffice/private/constants/estados.constant';
import { Button } from '@material-ui/core';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useListCreditorsHook from '@backoffice/private/modules/importAppointments/hooks/useListCreditors.hook';
import TagsComponent from '@backoffice/private/components/Tags/Tags.component';
import { merchantValidationSchema } from '../../utils/schemas/merchantValidationSchema';

const validationSchema = Yup.object({
  lead_account_id: Yup.string(),
  origin_account: Yup.string().default('2'),
  type_account: Yup.string().default('1'),
  type_person: Yup.string().default('2'),
  document_number: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .test('document_number', 'Documento Inválido', (value) => {
      const documentLength: any = !!value && String(value).length;

      if (documentLength < 11) {
        return false;
      }

      if (documentLength === 11 && validateCPF(value)) {
        return true;
      }

      if (documentLength === 14 && validateCNPJ(value)) {
        return true;
      }

      return false;
    }),
  creditor_id: Yup.string().default(''),
  trading_name: Yup.string().required(messages.REQUIRED).default(''),
  company_name: Yup.string().required(messages.REQUIRED).default(''),
  contact_name: Yup.string().required(messages.REQUIRED).default(''),
  contact_document_number: Yup.string().notRequired(),
  phone_number: Yup.string().required(messages.REQUIRED).default(''),
  address_zip_code: Yup.string().required(messages.REQUIRED).default(''),
  address: Yup.string().required(messages.REQUIRED).default(''),
  address_number: Yup.string().default(''),
  address_complement: Yup.string().notRequired().default('').nullable(),
  address_neighborhood: Yup.string().required(messages.REQUIRED).default(''),
  address_city: Yup.string().required(messages.REQUIRED).default(''),
  address_state: Yup.string().required(messages.REQUIRED).default(''),
  contact_email: Yup.string()
    .required(messages.REQUIRED)
    .email(messages.VALIDATION_EMAIL)
    .default(''),
});

const validationSchemaPartnerIF = Yup.object({
  lead_account_id: Yup.string(),
  origin_account: Yup.string().default('2'),
  type_account: Yup.string().default('1'),
  type_person: Yup.string().default('2'),
  document_number: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .test('document_number', 'Documento Inválido', (value) => {
      const documentLength: any = !!value && String(value).length;

      if (documentLength < 11) {
        return false;
      }

      if (documentLength === 11 && validateCPF(value)) {
        return true;
      }

      if (documentLength === 14 && validateCNPJ(value)) {
        return true;
      }

      return false;
    }),
  creditor_id: Yup.string().default(''),
  trading_name: Yup.string().default(''),
  company_name: Yup.string().default(''),
  contact_name: Yup.string().default(''),
  contact_document_number: Yup.string().notRequired(),
  phone_number: Yup.string().default(''),
  address_zip_code: Yup.string().default(''),
  address: Yup.string().default(''),
  address_number: Yup.string().default(''),
  address_complement: Yup.string().notRequired().default('').nullable(),
  address_neighborhood: Yup.string().default(''),
  address_city: Yup.string().default(''),
  address_state: Yup.string().default(''),
  contact_email: Yup.string().email(messages.VALIDATION_EMAIL).default(''),
});

interface FormMerchantContainerProps {
  handlePartnerType: (string) => void;
}

const FormMerchantContainer = ({
  handlePartnerType,
}: FormMerchantContainerProps) => {
  const initial = useRef({});
  const { isAdmin, user } = useUserHook();

  const [loadingLocal, setLoadingLocal] = useState(false);
  const [exisistCnpj, setExisistCnpj] = useState(false);
  const [modalLink, setModalLink] = useState(false);
  const [creditorParameterNotify, setCreditorParameterNotify] = useState('');
  const [partnerType, setPartnerType] = useState<any>(' ');
  const [hasCreditor, setHasCreditor] = useState<boolean>(false);

  const [simplifiedOnboardingLink, setSimplifiedOnboardingLink] =
    useState(null);

  const [key, setKey] = useState(uuidv4());

  const { isEdit, id } = useIsEdit();

  const history = useHistory();

  const { values, setValues, errors } = useFormikContext();

  const dispatch = useDispatch();

  const dispatchRedux = {
    GET: flow(Dispatchs.get, dispatch),
    UPDATE: flow(Dispatchs.update, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    RESET_NOTIFICATION: flow(Dispatchs.reset_notification, dispatch),
  };

  const dispatchAccountsRedux = {
    CREATE: flow(DispatchsAccounts.create, dispatch),
    RESET_ERROR: flow(DispatchsAccounts.resetError, dispatch),
  };

  const selectorAccountsRedux = {
    DATA: useSelector(SelectorsAccounts.data),
    LOADING: useSelector(SelectorsAccounts.loading),
    ERROR: useSelector(SelectorsAccounts.error),
  };

  const selectorRedux = {
    FETCHED: useSelector(Selectors.fetched),
    LOADING: useSelector(Selectors.loading),
    DATA: useSelector(Selectors.data),
    NOTIFICATION: useSelector(Selectors.notification),
  };

  const { handleNotification } = useCrudNotification({
    selectorRedux,
    dispatchRedux,
  });

  useEffect(() => {
    if (!isAdmin) {
      const { personId } = user || {};

      setValues({ ...(values as any), creditor_id: personId });

      handleGetCreditorParameters(personId);
    }
  }, []);

  useEffect(() => {
    if (selectorAccountsRedux.ERROR) {
      handleNotification(selectorAccountsRedux.DATA?.error, 'error');

      dispatchAccountsRedux.RESET_ERROR();
    }
  }, [selectorAccountsRedux.ERROR]);

  useEffect(() => {
    const { successAction, link } = selectorAccountsRedux.DATA || {};

    if (successAction === true && !isEdit) {
      handleNotification('Cadastro Efetuado com Sucesso', 'success');
      if (partnerType == 'if') {
        history.push(ROUTES.PRIVATE.MERCHANTS.ROOT);
      } else {
        history.push(
          ROUTES.PRIVATE.MERCHANTS.EDIT(
            selectorAccountsRedux.DATA?.merchant?.id
          )
        );
      }

      if (link) {
        setSimplifiedOnboardingLink(link);
        setModalLink(true);
      }
    }
  }, [selectorAccountsRedux.DATA]);

  useEffect(() => {
    if (isEdit) {
      dispatchRedux.GET(id);
    }
  }, [isEdit]);

  useEffect(() => {
    if (selectorRedux.FETCHED && isEdit) {
      const obj = {
        ...(values as any),
        creditor_id: selectorRedux.DATA.creditor_id,
        document_number: selectorRedux.DATA.document_number,
        trading_name: selectorRedux.DATA.trading_name,
        company_name: selectorRedux.DATA.company_name,
        address: selectorRedux.DATA.address,
        address_number: selectorRedux.DATA.address_number,
        address_complement: selectorRedux.DATA.address_complement,
        address_neighborhood: selectorRedux.DATA.address_neighborhood,
        address_city: selectorRedux.DATA.address_city,
        address_state: selectorRedux.DATA.address_state,
        address_zip_code: selectorRedux.DATA.address_zip_code,
        phone_number: selectorRedux.DATA.phone_number,
        contact_name: selectorRedux.DATA.contact_name,
        contact_email: selectorRedux.DATA.email,
        active: selectorRedux.DATA.active,
        type_person: selectorRedux.DATA.type === 'PJ' ? '2' : '1',
        legal_nature_description: selectorRedux.DATA.legal_nature_description,
        partnerType: partnerType,
      };

      setValues(obj);

      initial.current = obj;
      handleGetCreditorParameters(selectorRedux.DATA.creditor_id);

      setKey(uuidv4());
    }
  }, [selectorRedux.FETCHED, isEdit]);

  const handleBack = () => history.goBack();

  const handleGetAddressByZip = useCallback(
    async (zip) => {
      const digitsZip = zip.replace(/\D/g, '');

      setLoadingLocal(true);

      const response = await getAddressByZip(digitsZip);
      const { endereco } = await response.json();

      setLoadingLocal(false);

      setValues({
        ...(values as any),
        address: endereco?.logradouro || '',
        address_neighborhood: endereco?.bairro || '',
        address_city: endereco?.localidade || '',
        address_state: endereco?.uf || '',
      });
    },
    [values, setValues]
  );

  const handleGetDocument = useCallback(
    async (document) => {
      const documentDigits = document.replace(/\D/g, '');

      if (!documentDigits) {
        return null;
      }

      setLoadingLocal(true);

      const partnerIfIF = partnerType == 'if' ? true : false;
      const response = await getDocument(documentDigits, 2, partnerIfIF);
      const result = await response.json();

      if (!response.ok) {
        handleNotification(result?.errors?.message?.[0], 'error');
      }

      setExisistCnpj(!response.ok);

      setLoadingLocal(false);

      setValues({
        ...(values as any),
        company_name: result?.empresa?.nome || '',
        trading_name: result?.empresa?.fantasia || '',
        address_zip_code: result?.empresa?.cep || '',
        address: result?.empresa?.logradouro || '',
        address_number: result?.empresa?.numero || '',
        address_complement: result?.empresa?.complemento || '',
        address_neighborhood: result?.empresa?.bairro || '',
        address_city: result?.empresa?.municipio || '',
        address_state: result?.empresa?.uf || '',
      });
    },
    [values, setValues]
  );

  const handleOnResendEmail = async () => {
    const valuesToSend = {
      email: (values as any).contact_email,
    };

    setLoadingLocal(true);

    const response = await postAccountsSendTokenService(valuesToSend);

    setLoadingLocal(false);

    if (response.ok) {
      return handleNotification('Email reenviado com sucesso', 'success');
    }

    const result = await response.json();

    return handleNotification(result?.errors.message[0], 'error');
  };

  const handleOnChangeEmail = () => {
    const handler = flow(
      dialogMerchantChangeEmailDuck.Dispatchs.open,
      dispatch
    );

    handler({
      userId: selectorRedux.DATA.account_id,
      phoneNumber: (values as any).phone_number,
    });
  };

  const handleCloseModalLink = () => {
    setModalLink(false);

    return modalLink;
  };

  const handleNewToken = async () => {
    const valuesToSend = {
      email: (values as any).contact_email,
    };

    setLoadingLocal(true);

    const response = await postAccountsSendTokenService(valuesToSend);

    setLoadingLocal(false);

    const result = await response.json();

    if (response.ok) {
      setSimplifiedOnboardingLink(result.link);
      setModalLink(true);

      return;
    }

    return handleNotification(result?.errors.message[0], 'error');
  };

  const handleGetCreditorParameters = useCallback(async (id) => {
    const [response, result] = await getCreditorParameters(id);

    result?.results?.forEach((parameter) => {
      if (parameter.parameter_key === 'envia_notificacao') {
        if (parameter.parameter_value == '1') {
          setCreditorParameterNotify('payhop_notify');
        } else {
          setCreditorParameterNotify('payhop_not_notify');
        }
      }

      if (parameter.parameter_key === 'perfil_parceiro') {
        handlePartnerType(parameter.parameter_value);
        setPartnerType(parameter.parameter_value);
      }
    });
    setHasCreditor(true);
  }, []);

  const handleCreditorSelectOptions = (data: any) => {
    const arrayOptions: any = [];

    data.forEach((item: any) => {
      const obj: any = {
        label: item.trading_name,
        value: item.id,
      };

      arrayOptions.push(obj);
    });

    return arrayOptions;
  };

  const handleCreditorIdValue = (id) => {
    setValues({ ...(values as any), creditor_id: id });
  };

  const isEqualsValues = isEdit && isEqual(values, initial.current);

  return (
    <FormMerchantComponent
      key={key}
      isEdit={isEdit}
      loading={selectorRedux.LOADING || selectorAccountsRedux.LOADING}
      fetched={selectorRedux.FETCHED}
      errorsForm={errors}
      exisistCnpj={exisistCnpj}
      handleBack={handleBack}
      handleGetAddressByZip={handleGetAddressByZip}
      handleGetDocument={handleGetDocument}
      handleCreditorSelectOptions={handleCreditorSelectOptions}
      handleCreditorIdValue={handleCreditorIdValue}
      handleGetCreditorParameters={handleGetCreditorParameters}
      values={values}
      loadingLocal={loadingLocal}
      isEqualsValues={isEqualsValues}
      handleOnChangeEmail={handleOnChangeEmail}
      handleOnResendEmail={handleOnResendEmail}
      simplifiedOnboardingLink={simplifiedOnboardingLink}
      modalLink={modalLink}
      handleCloseModalLink={handleCloseModalLink}
      handleNewToken={handleNewToken}
      creditorParameterNotify={creditorParameterNotify}
      partnerType={partnerType}
      cnaes={selectorRedux.DATA?.cnaes}
      hasCreditor={hasCreditor}
      handleSubmit={() => {
        if (isEdit) {
          dispatchRedux.UPDATE(id, { ...(values as any) });
        } else {
          dispatchAccountsRedux.CREATE({
            ...(values as any),
            partnerType: partnerType,
          });
        }
      }}
    />
  );
};

const FormMerchantComponent = ({
  isEdit,
  loading,
  fetched,
  handleSubmit,
  errorsForm,
  exisistCnpj,
  handleBack,
  values,
  handleGetAddressByZip,
  handleGetDocument,
  handleCreditorSelectOptions,
  handleCreditorIdValue,
  handleGetCreditorParameters,
  loadingLocal,
  isEqualsValues,
  handleOnChangeEmail,
  handleOnResendEmail,
  simplifiedOnboardingLink,
  modalLink,
  partnerType,
  handleCloseModalLink,
  handleNewToken,
  creditorParameterNotify,
  cnaes,
  hasCreditor,
}) => {
  const { isAdmin } = useUserHook();
  const creditors = useListCreditorsHook();

  const creditorsFiltered = creditors.sort((a: any, b: any) => {
    if (a.trading_name > b.trading_name) {
      return 1;
    }

    if (a.trading_name < b.trading_name) {
      return -1;
    }

    return 0;
  });

  const creditorSelectOptions = handleCreditorSelectOptions(creditorsFiltered);

  const isPj = values?.type_person === '2';

  return (
    <>
      <Box display="flex" flexDirection="column" gridGap={24}>
        <CardForm
          formik
          fields={[
            [
              (isEdit || !isAdmin) && {
                label: 'Fornecedor',
                name: 'creditor_id',
                required: true,
                type: 'select',
                disabled: isEdit || !isAdmin,
                options: creditorSelectOptions,
              },
              !isEdit &&
                isAdmin && {
                  type: 'custom',
                  component: (
                    <Field
                      component={Autocomplete}
                      name="creditor_id"
                      id="creditor_id"
                      disabled={isEdit || !isAdmin}
                      options={creditorSelectOptions.map((a: any) => a.value)}
                      getOptionLabel={(option: any) => {
                        const currentCreditor = creditorSelectOptions?.find(
                          (b) => b.value === option
                        );

                        if (!currentCreditor?.label) {
                          return '';
                        }

                        return currentCreditor?.label;
                      }}
                      onChange={(e, value) => {
                        handleGetCreditorParameters(value);
                        handleCreditorIdValue(value);
                      }}
                      renderInput={(params) => (
                        <>
                          <TextFieldMaterial
                            {...params}
                            label="Fornecedor"
                            variant="outlined"
                          />
                        </>
                      )}
                    />
                  ),
                },
              isAdmin &&
                partnerType && {
                  type: 'text-controlled',
                  label: 'Tipo de parceiro',
                  name: 'partnerType',
                  disabled: true,
                  value:
                    partnerType == 'if'
                      ? 'Instituição Financeira'
                      : partnerType,
                },
            ],
            [
              {
                label: 'Tipo Pessoa',
                name: 'type_person',
                required: partnerType == 'if' ? false : true,
                type: 'select',
                disabled: isEdit,
                options: [
                  {
                    label: 'Física',
                    value: '1',
                  },
                  {
                    label: 'Jurídica',
                    value: '2',
                  },
                ],
              },
              {
                label: 'Status',
                name: 'active',
                type: 'select',
                disabled: true,
                options: [
                  {
                    label: 'Ativo',
                    value: true,
                  },
                  {
                    label: 'Inativo',
                    value: false,
                  },
                ],
              },
            ],
            [
              {
                label: 'Descrição',
                name: 'legal_nature_description',
                disabled: true,
              },
            ],
            [
              {
                type: 'custom',
                component: <TagsComponent cnaes={cnaes} />,
              },
            ],
            [
              {
                type: 'custom',
                component: (
                  <Field name="document_number">
                    {({
                      field,
                      form: { setFieldValue, setFieldTouched },
                      meta: { error, touched },
                    }: any) => (
                      <NumberFormat
                        id="document_number"
                        customInput={TextFieldMaterial}
                        format={isPj ? '##.###.###/####-##' : '###.###.###-##'}
                        fullWidth
                        label={isPj ? 'CNPJ' : 'CPF'}
                        required
                        disabled={isEdit || !hasCreditor}
                        variant="outlined"
                        value={field.value}
                        error={touched && !!error}
                        helperText={touched && !!error && error}
                        onBlur={async ({ target: { value } }) => {
                          setFieldTouched(field.name, true);

                          handleGetDocument(value);
                        }}
                        onValueChange={(values: any) =>
                          setFieldValue(field.name, values.value)
                        }
                      />
                    )}
                  </Field>
                ),
              },
              {
                label: 'Razão Social / Nome',
                name: 'company_name',
                required: partnerType == 'if' ? false : true,
              },
              {
                label: 'Nome Fantasia',
                name: 'trading_name',
                required: partnerType == 'if' ? false : true,
              },
            ],
            [
              {
                label: 'Nome Contato',
                name: 'contact_name',
                required: partnerType == 'if' ? false : true,
              },
              {
                label: 'E-mail',
                name: 'contact_email',
                required: partnerType == 'if' ? false : true,
                disabled: isEdit && !isAdmin ? true : false,
              },
              {
                label: 'Telefone Celular',
                name: 'phone_number',
                required: partnerType == 'if' ? false : true,
                mask: 'mobile',
              },
            ],
            [
              {
                type: 'custom',
              },
              {
                type: 'custom',
                component: (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    css={{ columnGap: 5 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleOnChangeEmail}
                      disabled={!isAdmin}
                    >
                      Alterar email
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={
                        creditorParameterNotify == 'payhop_notify' || isAdmin
                          ? false
                          : true
                      }
                      onClick={handleOnResendEmail}
                    >
                      Reenviar email
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={
                        creditorParameterNotify == 'payhop_not_notify' &&
                        !isAdmin
                          ? false
                          : true
                      }
                      onClick={handleNewToken}
                    >
                      Gerar token
                    </Button>
                  </Box>
                ),
              },
              {
                type: 'custom',
              },
            ],
            [
              {
                type: 'custom',
                component: (
                  <>
                    <Field name="address_zip_code">
                      {({
                        field,
                        form: { setFieldValue, setFieldTouched },
                        meta: { error, touched },
                      }: any) => (
                        <NumberFormat
                          id="cep"
                          customInput={TextFieldMaterial}
                          format="#####-###"
                          fullWidth
                          label="CEP"
                          required={partnerType == 'if' ? false : true}
                          variant="outlined"
                          value={field.value}
                          error={touched && !!error}
                          helperText={touched && !!error && error}
                          onBlur={async ({ target: { value } }) => {
                            setFieldTouched(field.name, true);

                            handleGetAddressByZip(value);
                          }}
                          onValueChange={(values: any) =>
                            setFieldValue(field.name, values.value)
                          }
                        />
                      )}
                    </Field>
                  </>
                ),
              },
            ],
            [
              {
                label: 'Endereço',
                name: 'address',
                required: partnerType == 'if' ? false : true,
              },
              {
                label: 'Número',
                name: 'address_number',
              },
            ],
            [
              {
                label: 'Complemento',
                name: 'address_complement',
              },
              {
                label: 'Bairro',
                name: 'address_neighborhood',
                required: partnerType == 'if' ? false : true,
              },
              {
                label: 'Cidade',
                name: 'address_city',
                required: partnerType == 'if' ? false : true,
              },
              {
                label: 'Estado',
                name: 'address_state',
                required: partnerType == 'if' ? false : true,
                type: 'select',
                options: STATES,
              },
            ],
          ]}
        />

        {(isAdmin || !isEdit) && (
          <CardFormFooter
            hasError={
              isEqualsValues ||
              Object.keys(errorsForm).length > 0 ||
              (isEdit ? false : exisistCnpj)
            }
            onSubmit={handleSubmit}
            onCancel={handleBack}
            loading={isEdit ? loading && fetched : loading}
          />
        )}
      </Box>

      <DialogChangeEmailComponent />

      <DialogSimplifiedOnboardingLink
        open={modalLink}
        link={simplifiedOnboardingLink}
        onCancel={handleCloseModalLink}
      />

      {((isEdit && loading && !fetched) || loadingLocal) && (
        <Box
          display="flex"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          width="100%"
          height="100%"
          zIndex={9999}
          alignItems="center"
          justifyContent="center"
          bgcolor="#ffffffd1"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default function FormMerchantFormik() {
  const [partnerType, setPartnerType] = useState('');

  function handlePartnerType(partnerType) {
    setPartnerType(partnerType);
  }

  return (
    <Formik
      initialValues={{ ...merchantValidationSchema.cast() }}
      onSubmit={() => {}}
      validateOnMount
      validationSchema={
        partnerType == 'if' ? validationSchemaPartnerIF : validationSchema
      }
    >
      <FormMerchantContainer handlePartnerType={handlePartnerType} />
    </Formik>
  );
}
