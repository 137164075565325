import React from 'react';
const SidebarContext = React.createContext({});

const SidebarContextProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const toggleSideBar = () => {
    setIsOpen(!isOpen);
  };
  const value = { isOpen, toggleSideBar };
  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};

const withSidebarContext = (Component: any) => (props: any) =>
  (
    <SidebarContextProvider>
      <Component {...props} />
    </SidebarContextProvider>
  );

const useSidebar = () => React.useContext(SidebarContext);

export {
  SidebarContextProvider as default,
  SidebarContext,
  useSidebar,
  withSidebarContext,
};
