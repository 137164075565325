import { Route, Switch, Redirect } from 'react-router-dom';

import ROUTES from '@backoffice/shared/utils/routes.util';

import DocsScreen from './screens/Docs.screen';
import CreateEditDocScreen from './screens/CreateEditDoc.screen';

const DocsModule: React.FC<any> = () => (
  <Switch>
    <Route exact path={ROUTES.PRIVATE.DOCS.ROOT} component={DocsScreen} />
    <Route
      exact
      path={ROUTES.PRIVATE.DOCS.CREATE}
      component={CreateEditDocScreen}
    />
    <Route
      exact
      path={ROUTES.PRIVATE.DOCS.EDIT()}
      component={CreateEditDocScreen}
    />

    <Redirect to={ROUTES.PRIVATE.HOME} />
  </Switch>
);

export default DocsModule;
