import { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@backoffice/shared/components/Button.component';

const texts = {
  title: 'Você tem certeza?',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque id laoreet magna. Ut quis luctus dui. Aliquam augue purus, ultricies nec fermentum sit amet, consequat eget risus.',
  buttons: {
    agree: 'Sim',
    cancel: 'Não',
  },
};

type Props = {
  open: boolean;
  onConfirm: () => any;
  onCancel: () => any;
  title?: string;
  description?: string | null;
};

export default function ModalConfirmDeleteComponent({
  open,
  onCancel,
  onConfirm,
  title = texts.title,
  description = texts.description,
}: Props) {
  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {texts.buttons.cancel}
        </Button>
        <Button
          loadingSize="20px"
          onClick={async () => {
            setLoading(true);

            await onConfirm();

            setLoading(false);
          }}
          color="primary"
          autoFocus
        >
          {texts.buttons.agree}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
