import { Fragment, useEffect } from 'react';
import { flow } from 'lodash';
import { useDispatch } from 'react-redux';

import { Box } from '@material-ui/core';

import ModuleNotPermited from '@backoffice/private/components/Permissions/ModuleNotPermited.component';
import FormContracts from '@backoffice/private/modules/registeredContracts/components/FormContracts.component';
import ShowContract from '@backoffice/private/modules/registeredContracts/components/ShowContract.component';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

import { Dispatchs } from '@backoffice/private/ducks/contracts/contract.duck';

const texts = {
  title: 'Contratos Registrados',
};

const RegisteredContractsScreen = () => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_contratos_registrados',
  });
  const dispatch = useDispatch();

  const dispatchRedux = {
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
  };

  useEffect(() => {
    dispatchRedux.RESET();
  }, []);

  return (
    <Fragment>
      <PageLayout headerTitle header sidebar title={texts.title}>
        {permissions?.some((item) => item == 'read') && (
          <Box display="flex" flexDirection="column" gridGap={32}>
            <Fragment>
              <FormContracts />
              <ShowContract />
            </Fragment>
          </Box>
        )}
        {!permissions ||
          (!permissions?.some((item) => item == 'read') && (
            <ModuleNotPermited />
          ))}
      </PageLayout>
    </Fragment>
  );
};

export default RegisteredContractsScreen;
