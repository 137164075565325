import Switch from '@material-ui/core/Switch';
import React from 'react';

const SwitchActive = ({ obj, handleChangeStatus }) => {
  return (
    <Switch
      checked={obj.status == 'Ativo'}
      onChange={() => handleChangeStatus(obj)}
      color="primary"
      name="active"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  );
};

export default SwitchActive;
