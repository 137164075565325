import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Field, useFormikContext } from 'formik';
import { TextField as TextFieldMaterial } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { flow } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { v4 } from 'uuid';
import { Autocomplete } from 'formik-material-ui-lab';

import useCrudRead from '@backoffice/shared/hooks/useCrudRead.hook';

import fetch from '@payhop/shared-utils/fetch.util';
import toFormatAlphabetic from '@backoffice/shared/utils/toFormatAlphabetic.util';

import CardSearch from '@backoffice/private/components/Card/CardSearch';

import * as listScheduleDuck from '@backoffice/private/ducks/schedules/listSchedules.duck';
import { resetError } from '@payhop/shared-ducks/error.duck';
import useUserHook from '@backoffice/shared/hooks/useUser.hook';
import useNotification from '@payhop/shared-hooks/useNotification.hook';

const creditorsService = () =>
  fetch(`creditors?limit=999999`, {
    method: 'GET',
    ms: 'CREDITOR',
    auth: true,
  });

const merchantsService = () =>
  fetch(`merchants?limit=999999`, {
    method: 'GET',
    ms: 'MERCHANT',
    auth: true,
  });

const SearchSchedule = ({ merchants, creditors, handleSearchReset }) => {
  const [key, setKey] = useState(v4());

  const { isAdmin } = useUserHook();

  const { values, submitForm, resetForm }: any = useFormikContext();

  const arrToFormat =
    values?.consulta_por === 'estabelecimento' ? merchants : creditors;

  const arr = arrToFormat.reduce(
    (acc, curr) => [
      ...acc,
      {
        label: curr.trading_name,
        value: curr.id,
      },
    ],
    []
  );
  const arrFiltered = toFormatAlphabetic(arr);

  const schemaSearch = [
    [
      {
        label: 'Consulta por',
        name: 'consulta_por',
        type: 'select',
        disabled: !isAdmin ? true : false,
        options: [
          {
            label: 'Estabelecimento',
            value: 'estabelecimento',
          },
          {
            label: 'Fornecedor',
            value: 'fornecedor',
          },
        ],
      },
      arrFiltered.length > 0 && {
        type: 'custom',
        component: (
          <Field
            component={Autocomplete}
            name="id"
            options={arrFiltered.map((a: any) => a.value)}
            getOptionLabel={(option: any) => {
              const currentBank = arrFiltered?.find((b) => b.value === option);

              if (!currentBank?.label) {
                return '';
              }

              return currentBank?.label;
            }}
            renderInput={(params) => (
              <TextFieldMaterial
                {...params}
                label="Fornecedor/Estabelecimento"
                variant="outlined"
              />
            )}
          />
        ),
      },
      {
        type: 'custom',
        component: (
          <Field name="documentNumber">
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }: any) => (
              <>
                <NumberFormat
                  customInput={TextFieldMaterial}
                  format="##.###.###/####-##"
                  fullWidth
                  label="CNPJ"
                  required
                  variant="outlined"
                  value={field.value}
                  error={touched && !!error}
                  helperText={touched && !!error && error}
                  onBlur={async ({ target: { value } }) => {
                    setFieldTouched(field.name, true);
                  }}
                  onValueChange={(values: any) =>
                    setFieldValue(field.name, values.value)
                  }
                />
              </>
            )}
          </Field>
        ),
      },
    ],
    [
      {
        label: 'Status',
        name: 'status',
        type: 'select',
        options: [
          {
            label: 'Todos',
            value: 'todos',
          },
          {
            label: 'Processado',
            value: 'processado',
          },
          {
            label: 'Em processamento',
            value: 'processamento',
          },
        ],
      },
      {
        label: 'Data Consulta Inicio',
        name: 'startDate',
        type: 'date',
      },
      {
        label: 'Data Consulta Fim',
        name: 'endDate',
        type: 'date',
      },
    ],
  ];

  return (
    <CardSearch
      formik
      key={key}
      fields={schemaSearch as any}
      onSubmit={submitForm}
      onReset={() =>
        handleSearchReset()(() => {
          setKey(v4());

          resetForm();
        })
      }
    />
  );
};

export default function SearchScheduleComponent() {
  const { isAdmin, user } = useUserHook();

  const [key, setKey] = useState(v4());
  const [creditors, setCreditors] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const { error } = useSelector((state: any) => state.shared);
  const handleNotification = useNotification();
  const today = new Date();
  const priorDate = new Date(new Date().setDate(today.getDate() - 1));

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FILTERS: flow(listScheduleDuck.Dispatchs.updateFilters, dispatch),
    RESET_FILTERS: flow(listScheduleDuck.Dispatchs.resetFilters, dispatch),
    GET: flow(listScheduleDuck.Dispatchs.get, dispatch),
    RESET: flow(listScheduleDuck.Dispatchs.reset, dispatch),
    DELETE: flow(listScheduleDuck.Dispatchs.delete, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(listScheduleDuck.Selectors.data),
    LOADING: useSelector(listScheduleDuck.Selectors.loading),
    DATA_RESULTS: useSelector(listScheduleDuck.Selectors.data_results),
    FILTERS: useSelector(listScheduleDuck.Selectors.filters),
    RESULTSET: useSelector(listScheduleDuck.Selectors.resultset),
    FETCHED: useSelector(listScheduleDuck.Selectors.fetched),
  };

  const { handleSearch, handleSearchReset } = useCrudRead({
    dispatchRedux,
    selectorRedux,
    opts: {
      requestOnMount: isAdmin,
    },
  });

  useEffect(() => {
    setKey(v4());
  }, [selectorRedux.FILTERS]);

  const handleGetCreditors = useCallback(async () => {
    const response = await creditorsService();
    const result = await response.json();

    const { results } = result || {};

    setCreditors(results);
  }, []);

  const handleGetMerchants = useCallback(async () => {
    const response = await merchantsService();
    const result = await response.json();

    const { results } = result || {};

    setMerchants(results);
  }, []);

  useEffect(() => {
    if (isAdmin) {
      handleGetCreditors();
    }

    handleGetMerchants();
  }, [handleGetCreditors, handleGetMerchants]);

  useEffect(() => {
    if (error.messages?.[0] !== undefined && error.error) {
      handleNotification(error.messages?.[0], 'error');
      dispatch(resetError());
    }
  }, [error.messages]);

  const handleSubmit = async (values) => {
    const newValues = {
      ...values,
      offset: 0,
    };

    if (isAdmin) {
      if (newValues?.consulta_por === 'fornecedor') {
        newValues.creditorId = newValues.id;
        newValues.searchByCreditor = true;
        newValues.merchantId = undefined;
      } else {
        newValues.merchantId = newValues.id;
        newValues.creditorId = undefined;
        newValues.searchByCreditor = false;
      }
    }

    if (!isAdmin) {
      const { personId } = user || {};

      if (newValues?.consulta_por === 'fornecedor') {
        newValues.creditorId = personId;
        newValues.merchantId = undefined;
      } else {
        newValues.merchantId = newValues.id;
        newValues.creditorId = personId;
      }
    }

    if (newValues?.startDate) {
      newValues.startDate = moment(newValues.startDate)
        .toISOString()
        .split('T')[0];
    } else {
      newValues.startDate = undefined;
    }

    if (newValues?.endDate) {
      newValues.endDate = moment(newValues.endDate).toISOString().split('T')[0];
    } else {
      newValues.endDate = undefined;
    }

    delete newValues.id;
    delete newValues.consulta_por;

    await handleSearch(newValues);

    return true;
  };

  return (
    <Formik
      initialValues={{
        consulta_por: selectorRedux.FILTERS.searchByCreditor
          ? 'fornecedor'
          : 'estabelecimento',
        startDate: selectorRedux.FILTERS.startDate ?? priorDate,
        endDate: selectorRedux.FILTERS.endDate ?? today,
        documentNumber: selectorRedux.FILTERS.documentNumber ?? '',
        id: selectorRedux.FILTERS.searchByCreditor
          ? selectorRedux.FILTERS.creditorId
          : selectorRedux.FILTERS.merchantId,
        status: selectorRedux.FILTERS.status ?? [],
      }}
      onSubmit={handleSubmit}
      key={key}
    >
      <SearchSchedule
        creditors={creditors}
        merchants={merchants}
        handleSearchReset={handleSearchReset}
      />
    </Formik>
  );
}
