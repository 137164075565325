import React from 'react';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';

import * as dialogMerchantDocumentsDuck from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantDocuments.duck';
import * as dialogMerchantDocumentsHistoricDuck from '@backoffice/private/ducks/merchants/dialogs/dialogMerchantDocumentsHistoric.duck';

import TableMerchant from './TableMerchant.container';

const TableMerchantDocumentContainer = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    OPEN: flow(dialogMerchantDocumentsDuck.Dispatchs.open, dispatch),
  };

  const dispatchHistoricRedux = {
    OPEN: flow(dialogMerchantDocumentsHistoricDuck.Dispatchs.open, dispatch),
  };

  return (
    <TableMerchant
      type="documents"
      onEdit={(obj) => dispatchRedux.OPEN(obj)}
      seeHistoric={(obj) => dispatchHistoricRedux.OPEN(obj)}
    />
  );
};

export default TableMerchantDocumentContainer;
