import React, { useCallback } from 'react';

import moment from 'moment-timezone';
import { Field } from 'formik';
import {
  TextField as TextFieldFormik,
  Select as SelectFormik,
} from 'formik-material-ui';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import EventIcon from '@material-ui/icons/Event';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Autocomplete } from 'formik-material-ui-lab';

import { TextFieldProps } from '@backoffice/shared/components/TextField/TextField.type';
import { TextField as TextFieldF } from '@backoffice/shared/components/TextField';

export type FormSchemaProps = {
  formik?: boolean;
  fields: Array<
    Array<
      Pick<TextFieldProps, 'mask'> & {
        name?: string;
        label?: string;
        value?: string;
        GridProps?: any;
        minWidth?: string;
        type?:
          | 'select'
          | 'text'
          | 'date'
          | 'checkbox'
          | 'autocomplete'
          | 'custom'
          | string;
        component?: React.ReactNode;
        required?: boolean;
        password?: boolean;
        disabled?: boolean;
        optionsAutocomplete?: any;
        getOptionLabel?: any;
        handleSelectAll?: any;
        options?: Array<{
          value: string;
          label: string;
        }>;
        [key: string]: any;
      }
    >
  >;
};

const FormSchemaComponent: React.FC<FormSchemaProps> = ({ fields, formik }) => {
  const renderFormikComponents = useCallback((f, index) => {
    const isFieldWithMask = !!f?.mask;

    if (isFieldWithMask) {
      return (
        <Grid item xs key={`f-${index}}`}>
          <Field name={f.name}>
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }) => (
              <TextFieldF
                id={f.name}
                mask={f.mask}
                label={`${f.label}${f.required ? ` *` : ''}`}
                fullWidth
                variant="outlined"
                value={field.value}
                error={touched && !!error}
                helperText={touched && !!error && error}
                onBlur={() => setFieldTouched(field.name, true)}
                onValueChange={(values: any) =>
                  setFieldValue(field.name, values.value)
                }
              />
            )}
          </Field>
        </Grid>
      );
    }

    return (
      <Box css={{ flex: '1 1 auto' }} key={`f-${index}}`} {...f?.GridProps}>
        {f?.type === 'checkbox' && (
          <Field name={f.name}>
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(ev, checked) => setFieldValue(f.name, checked)}
                    name={f.name}
                    checked={field.value}
                  />
                }
                label={f.label}
              />
            )}
          </Field>
        )}

        {f?.type === 'date' && (
          <Field
            name={f.name}
            format={f.format || 'DD/MM/yyyy'}
            label={`${f.label}${f.required ? ` *` : ''}`}
          >
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }) => {
              return (
                <LocalizationProvider
                  dateLibInstance={moment}
                  dateAdapter={MomentAdapter}
                >
                  <DatePicker
                    css={{ width: '100%' }}
                    label={f.label}
                    inputFormat="DD/MM/YYYY"
                    value={field.value || null}
                    minDate={f?.minDate}
                    onChange={(value) => {
                      return setFieldValue(field.name, value);
                    }}
                    renderInput={(props) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        {...props}
                        helperText=""
                      />
                    )}
                  />
                </LocalizationProvider>
              );
            }}
          </Field>
        )}

        {f?.type === 'select' && (
          <FormControl fullWidth variant="outlined" disabled={f.disabled}>
            <InputLabel id={f.name}>{`${f.label}${
              f.required ? ` *` : ''
            }`}</InputLabel>
            <Field
              component={SelectFormik}
              id={f.name}
              name={f.name}
              labelId={f.name}
              css={{ minWidth: f.minWidth ? f.minWidth : 'auto' }}
              fullWidth
              multiple={f?.multiple}
              disabled={f?.disabled}
              label={`${f.label}${f.required ? ` *` : ''}`}
              MenuProps={{
                getContentAnchorEl: () => null,
              }}
            >
              {f?.options?.map((o, index) => (
                <MenuItem value={o.value} key={`${index}}`}>
                  {o.label}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        )}

        {f?.type === 'custom' && <>{f.component}</>}

        {(!f?.type || f?.type === 'text') && (
          <Field
            id={f.name}
            component={TextFieldFormik}
            name={f.name}
            label={`${f.label}${f.required ? ` *` : ''}`}
            fullWidth
            variant="outlined"
            disabled={f?.disabled}
            type={f.password ? 'password' : undefined}
            autoComplete={f.password ? 'new-password' : undefined}
          />
        )}

        {f?.type === 'text-controlled' && (
          <Field
            component={TextFieldFormik}
            name={f.name}
            label={`${f.label}${f.required ? ` *` : ''}`}
            fullWidth
            variant="outlined"
            disabled={f?.disabled}
            value={f.value}
            type={f.password ? 'password' : undefined}
            autoComplete={f.password ? 'new-password' : undefined}
          />
        )}

        {f?.type === 'autocomplete' && (
          <Field
            component={Autocomplete}
            name={f?.name}
            limitTags={f?.limitTags ?? 1}
            disabled={f?.disabled}
            multiple={f?.multiple ?? true}
            options={f?.optionsAutocomplete}
            getOptionLabel={f?.getOptionLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                label={f?.label}
                variant="outlined"
                helperText={
                  f?.handleSelectAll ? (
                    <Typography
                      variant="caption"
                      onClick={() => {
                        f?.handleSelectAll();
                      }}
                      css={{
                        '&:hover': {
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        },
                      }}
                    >
                      Selecionar todos
                    </Typography>
                  ) : undefined
                }
              />
            )}
          />
        )}
      </Box>
    );
  }, []);

  return (
    <Box gridGap={16} display="flex" flexDirection="column" width="100%">
      {fields?.map((f, index) => (
        <Box
          display={'flex'}
          flexWrap={'wrap'}
          gridRowGap={'16px'}
          gridColumnGap={'16px'}
          key={`field-${index}}`}
        >
          {!formik &&
            f?.map((f, index) => (
              <Grid item xs key={`f-${index}}`}>
                {f?.type === 'checkbox' && (
                  <FormControlLabel
                    control={<Checkbox onChange={() => {}} name={f.name} />}
                    label={f.label}
                  />
                )}

                {f?.type === 'date' && (
                  <DatePicker
                    label={f.label}
                    value={new Date()}
                    onChange={(moment: any) => {}}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                )}

                {f?.type === 'select' && (
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id={f.name}>{f.label}</InputLabel>
                    <Select
                      labelId={f.name}
                      id={f.name}
                      onChange={() => {}}
                      fullWidth
                      label={f.label}
                      disabled={f?.disabled}
                    >
                      {f?.options?.map((o, index) => (
                        <MenuItem value={o.value} key={`${index}}`}>
                          {o.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {(!f?.type || f?.type === 'text') && (
                  <TextField
                    id={f.name}
                    label={f.label}
                    fullWidth
                    variant="outlined"
                  />
                )}
              </Grid>
            ))}

          {formik && f.filter((field) => !!field).map(renderFormikComponents)}
        </Box>
      ))}
    </Box>
  );
};

export default FormSchemaComponent;
