import React, { useEffect, useMemo, useState, Fragment } from 'react';
import moment from 'moment';

import CardTable from '@backoffice/private/components/Card/CardTable';

const TableReceivablesComponent = ({ receivablesArray, loading }) => {
  const [receivables, setReceivables] = useState([]);

  useEffect(() => {
    setReceivables(receivablesArray);
  }, [receivablesArray]);

  const handleMaskDocument = (document) => {
    const stringDocument = String(document);

    if (stringDocument.length === 11) {
      return stringDocument.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
        '$1.$2.$3-$4'
      );
    }
    if (stringDocument.length === 14) {
      return stringDocument.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
        '$1.$2.$3/$4-$5'
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        title: 'Credenciadora',
        render: ({ document_acquirer }) =>
          handleMaskDocument(document_acquirer),
      },
      {
        title: 'Arranjo',
        dataIndex: 'arrangement_code',
        key: 'arrangement_code',
      },
      {
        title: 'Data liquidação',
        render: ({ due_date }) => moment(due_date).format('DD/MM/YYYY'),
      },
      {
        title: 'Constituido Total',
        dataIndex: 'total_constituted_value',
        key: 'total_constituted_value',
        render: ({ total_constituted_value }) =>
          total_constituted_value ? (
            Number(total_constituted_value).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          ) : (
            <Fragment />
          ),
      },
      {
        title: 'Constituido Efeito',
        dataIndex: 'effect_constituted_value',
        key: 'effect_constituted_value',
        render: ({ effect_constituted_value }) =>
          effect_constituted_value
            ? Number(effect_constituted_value).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })
            : '-',
      },
      {
        title: 'Divisão',
        dataIndex: 'division_rules',
        key: 'division_rules',
      },
      {
        title: 'Indicador Oneração',
        dataIndex: 'encumbrance_indicator',
        key: 'encumbrance_indicator',
      },
    ],
    []
  );

  return (
    <>
      <CardTable
        loading={loading}
        dataSource={receivables}
        columns={columns}
        footer={false}
      />
    </>
  );
};

export default TableReceivablesComponent;
