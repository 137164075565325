/* eslint-disable */

import fetch from '@payhop/shared-utils/fetch.util';

export default async (filter) => {
  const response = await fetch(`checkids/history?${filter}`, {
    method: 'GET',
    ms: 'CHECKID',
    auth: true,
  });

  return response;
};
