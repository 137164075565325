import fetch from '@payhop/shared-utils/fetch.util';

export default function getValidationDocument(cnpj: string) {
  return fetch(`validation/search-document/${cnpj}`, {
    method: 'POST',
    ms: 'ACCOUNT',
    auth: true,
    // body: JSON.stringify({
    //   document: cnpj,
    // }),
  });
}
