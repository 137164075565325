import { useState } from 'react';
import {
  Dialog,
  Box,
  IconButton,
  TextField,
  Button,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import fetch from '@payhop/shared-utils/fetch.util';
import PasswordValidation from '@payhop/shared-components/PasswordValidation/PasswordValidation.component';

const service = (email, senha) =>
  fetch(`accounts/change-password`, {
    method: 'POST',
    ms: 'ACCOUNT',
    auth: true,
    body: JSON.stringify({
      newPassword: senha,
      email,
    }),
  });

const ModalChangePasswordComponent: React.FC<any> = ({
  open,
  onClose,
  email,
}) => {
  const handleNotification = useNotification();

  const [loading, setLoading] = useState(false);
  const [senha, setSenha] = useState('');
  const [show, setShow] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const response = await service(email, senha);
    setLoading(false);

    if (response.ok) {
      handleNotification('Senha alterada com sucesso!', 'success');

      onClose();
    }

    if (!response.ok) {
      const result = await response.json();

      handleNotification(
        result?.errors?.message?.[0] ??
          'Aconteceu um erro, tente novamente mais tarde',
        'error'
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'xs'}
    >
      <Box p={3} position="relative" pt={6}>
        <Box position="absolute" top={0} left={0}>
          <DialogTitle>Redefinição de senha</DialogTitle>
        </Box>
        <Box position="absolute" top={8} right={8}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="1fr"
          minWidth={300}
          gridGap={12}
          mt={3}
        >
          <TextField
            label="Nova senha"
            required
            fullWidth
            variant="outlined"
            type={show ? 'text' : 'password'}
            onChange={(ev) => setSenha(ev?.target?.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShow(!show)}
                  >
                    {show ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <PasswordValidation senha={senha} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Redefinir senha
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ModalChangePasswordComponent;
