import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import moment from 'moment';
import { Field } from 'formik';

type InputDataProps = {
  name: string;
  label: string;
  format?: string;
  required: boolean;
  disabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  reloadError?: boolean;
};

const InputData = ({
  name,
  format,
  label,
  required,
  disabled,
  hasError,
  helperText,
  reloadError,
}: InputDataProps) => {
  const [haveError, setHaveError] = useState(false);

  const handleInputError = () => {
    if (hasError) {
      setHaveError(true);
    } else {
      setHaveError(false);
    }
  };

  useEffect(() => {
    if (reloadError) {
      if (hasError) {
        setHaveError(true);
      } else {
        setHaveError(false);
      }
    } else {
      return;
    }
  }, [hasError]);

  return (
    <Field
      name={name}
      format={format || 'DD/MM/yyyy'}
      label={`${label}${required ? ` *` : ''}`}
    >
      {({
        field,
        form: { setFieldValue, setFieldTouched },
        meta: { error, touched },
      }) => {
        return (
          <LocalizationProvider
            dateLibInstance={moment}
            dateAdapter={MomentAdapter}
          >
            <DatePicker
              css={{ width: '100%' }}
              label={`${label}${required ? ` *` : ''}`}
              inputFormat="DD/MM/YYYY"
              value={field.value || null}
              disabled={disabled}
              onChange={(value) => {
                return setFieldValue(field.name, value);
              }}
              renderInput={(props) => (
                <TextField
                  id={name}
                  fullWidth
                  variant="outlined"
                  {...props}
                  error={haveError}
                  helperText={haveError && helperText}
                  onBlur={handleInputError}
                  onTouchStart={handleInputError}
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    </Field>
  );
};

export default InputData;
