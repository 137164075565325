import React, { useState } from 'react';

import { Box, Button, CircularProgress } from '@material-ui/core';
import ModalConfirmDelete from '@backoffice/private/components/Modal/ModalConfirmDelete.component';

import useAccountPermission from '@backoffice/shared/hooks/useAccountPermission.hook';

const ActionsDocumentComponent = ({
  loading,
  onActive,
  onInactive,
  onProcessDocument,
  onReproveAccount,
  onReproveDocs,
  hasReprovedDocument,
}) => {
  const { permissions } = useAccountPermission({
    permission_slug: 'cad_estabelecimento_documentos',
  });
  const [modalConfirm, setModalConfirm] = useState(false);

  return (
    <>
      {loading && (
        <Box
          position="fixed"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="#ffffffe0"
          top={0}
          bottom={0}
          left={0}
          right={0}
          zIndex={9999}
        >
          <CircularProgress />
        </Box>
      )}

      <Box display="flex" gridGap={8} width={'100%'} justifyContent="center">
        <Box display="flex" gridGap={8}>
          <Button
            variant="outlined"
            disabled={!permissions?.some((item) => item == 'edit')}
            onClick={onActive}
          >
            Ativar conta
          </Button>
          <Button
            variant="outlined"
            disabled={!permissions?.some((item) => item == 'edit')}
            onClick={onInactive}
          >
            Reprovar conta
          </Button>
        </Box>
        <Box display="flex">
          <Button
            variant="outlined"
            disabled={!permissions?.some((item) => item == 'edit')}
            onClick={onProcessDocument}
          >
            Processar Documentos
          </Button>
        </Box>
        <Box display="flex" gridGap={8}>
          <Button
            variant="outlined"
            disabled={!permissions?.some((item) => item == 'edit')}
            onClick={() => setModalConfirm(true)}
          >
            Email reprovação conta sem justificativa
          </Button>
          <Button
            variant="outlined"
            onClick={onReproveDocs}
            disabled={
              !hasReprovedDocument ||
              !permissions?.some((item) => item == 'create')
            }
          >
            Email reprovação docs com justificativa
          </Button>
        </Box>
      </Box>

      <ModalConfirmDelete
        title="Deseja reprovar a conta?"
        description="Essa ação não poderá ser desfeita."
        open={modalConfirm || false}
        onCancel={() => setModalConfirm(false)}
        onConfirm={async () => {
          setModalConfirm(false);

          return onReproveAccount();
        }}
      />
    </>
  );
};

export default ActionsDocumentComponent;
