import { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import TextFieldMaterial from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import { useFormikContext, Formik, Field } from 'formik';
import { v4 } from 'uuid';
import NumberFormat from 'react-number-format';

import PageLayout from '@backoffice/private/layouts/Page.layout';

import CardForm from '@backoffice/private/components/Card/CardForm';
import CardFormFooter from '@backoffice/private/components/Card/CardForm/CardFormFooter';

import useIsEdit from '@backoffice/shared/hooks/useIsEdit.hook';

import {
  Dispatchs,
  Selectors,
} from '@backoffice/private/ducks/partners/partner.duck';

import useCrudCreateEdit from '@backoffice/shared/hooks/useCrudCreateEdit.hook';
import ROUTES from '@backoffice/shared/utils/routes.util';

const texts = {
  titleEdit: 'Editar parceiro',
  titleCreate: 'Cadastro de parceiro',
};

const validationSchema = Yup.object({
  document_number: Yup.string().default(''),
  trading_name: Yup.string().default(''),
  company_name: Yup.string().default(''),
  revenue_percentage: Yup.string().default(''),
});

const CreatePartnerContainer = () => {
  const [key, setKey] = useState(v4());
  const history = useHistory();

  const { values, setValues, resetForm, errors } = useFormikContext();

  const dispatch = useDispatch();

  const dispatchRedux = {
    GET: flow(Dispatchs.get, dispatch),
    RESET: flow(Dispatchs.reset, dispatch),
    CREATE: flow(Dispatchs.create, dispatch),
    UPDATE: flow(Dispatchs.update, dispatch),
  };

  const selectorRedux = {
    DATA: useSelector(Selectors.data),
    LOADING: useSelector(Selectors.loading),
    FETCHED: useSelector(Selectors.fetched),
    ERROR: useSelector(Selectors.error),
  };

  const { isEdit, handleCreate, handleUpdate } = useCrudCreateEdit({
    dispatchRedux,
    selectorRedux,
  });

  useEffect(() => {
    if (selectorRedux.FETCHED && isEdit) {
      setValues({
        company_name: selectorRedux.DATA.company_name,
        document_number: selectorRedux.DATA.document_number,
        trading_name: selectorRedux.DATA.trading_name,
        revenue_percentage: selectorRedux.DATA.revenue_percentage,
      });

      setTimeout(() => {
        setKey(v4());
      });
    }
  }, [selectorRedux.FETCHED, isEdit]);

  useEffect(() => {
    const { successAction } = selectorRedux.DATA || {};

    if (successAction === true && !isEdit) {
      resetForm();

      history.push(ROUTES.PRIVATE.PARTNERS.ROOT);
    }
  }, [selectorRedux.DATA]);

  return (
    <CreatePartnerScreen
      key={key}
      isEdit={isEdit}
      loading={selectorRedux.LOADING}
      fetched={selectorRedux.FETCHED}
      errorsForm={errors}
      handleSubmit={() =>
        isEdit
          ? handleUpdate({
              company_name: (values as any).company_name,
              document_number: (values as any).document_number,
              trading_name: (values as any).trading_name,
              revenue_percentage: (values as any).revenue_percentage,
            })
          : handleCreate(values)
      }
    />
  );
};

const CreatePartnerScreen = ({
  isEdit,
  loading,
  fetched,
  handleSubmit,
  errorsForm,
}) => {
  const history = useHistory();

  const titlePage = useMemo(
    () => (isEdit ? texts.titleEdit : texts.titleCreate),
    [isEdit]
  );

  const formViewSchema = useMemo(
    () => [
      [
        {
          type: 'custom',
          component: (
            <Field name="document_number">
              {({
                field,
                form: { setFieldValue, setFieldTouched },
                meta: { error, touched },
              }: any) => (
                <NumberFormat
                  customInput={TextFieldMaterial}
                  format="##.###.###/####-##"
                  fullWidth
                  label="CNPJ"
                  required
                  disabled={isEdit}
                  variant="outlined"
                  value={field.value}
                  error={touched && !!error}
                  helperText={touched && !!error && error}
                  onBlur={async ({ target: { value } }) => {
                    setFieldTouched(field.name, true);
                  }}
                  onValueChange={(values: any) =>
                    setFieldValue(field.name, values.value)
                  }
                />
              )}
            </Field>
          ),
        },
      ],
      [
        {
          name: 'trading_name',
          label: 'Razão social',
          required: true,
        },
        {
          name: 'company_name',
          label: 'Nome Fantasia',
          required: true,
        },
        {
          type: 'custom',
          component: (
            <Field name="revenue_percentage">
              {({
                field,
                form: { setFieldValue, setFieldTouched },
                meta: { error, touched },
              }: any) => (
                <NumberFormat
                  customInput={TextFieldMaterial}
                  prefix=""
                  suffix=" %"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  fullWidth
                  label="% Receita"
                  required
                  variant="outlined"
                  value={field.value}
                  error={touched && !!error}
                  helperText={touched && !!error && error}
                  onBlur={async ({ target: { value } }) => {
                    setFieldTouched(field.name, true);
                  }}
                  onValueChange={(values: any) =>
                    setFieldValue(field.name, values.value)
                  }
                />
              )}
            </Field>
          ),
        },
      ],
    ],
    []
  );

  const handleBack = () => history.goBack();

  return (
    <PageLayout headerTitle header sidebar title={titlePage}>
      <Box display="flex" gridGap={24} flexDirection="column">
        <CardForm formik fields={formViewSchema as any} />

        <CardFormFooter
          hasError={Object.keys(errorsForm).length > 0}
          onSubmit={handleSubmit}
          onCancel={handleBack}
          loading={isEdit ? loading && fetched : loading}
        />
      </Box>

      {isEdit && loading && !fetched && (
        <Box
          display="flex"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          width="100%"
          height="100%"
          zIndex={9999}
          alignItems="center"
          justifyContent="center"
          bgcolor="#ffffffd1"
        >
          <CircularProgress />
        </Box>
      )}
    </PageLayout>
  );
};

export default function CreateEditUserFormik() {
  const { isEdit } = useIsEdit();

  return (
    <Formik
      initialValues={{
        ...validationSchema.cast(),
      }}
      validateOnChange={false}
      validateOnBlur
      validateOnMount
      onSubmit={() => {}}
      validationSchema={validationSchema}
    >
      <CreatePartnerContainer />
    </Formik>
  );
}
