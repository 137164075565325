import { combineReducers } from 'redux';

type listOfReducersType = {
  name: string;
  path: string;
};

const reducers = {};

const listOfReducers: Array<listOfReducersType> = [
  {
    name: 'dialogChangePassword',
    path: 'dialogMerchantChangePassword.duck',
  },
  {
    name: 'dialogChangeEmail',
    path: 'dialogMerchantChangeEmail.duck',
  },
  {
    name: 'dialogDocuments',
    path: 'dialogMerchantDocuments.duck',
  },
  {
    name: 'dialogDocumentsHistoric',
    path: 'dialogMerchantDocumentsHistoric.duck',
  },
  {
    name: 'dialogDocumentsReprove',
    path: 'dialogMerchantDocumentsReprove.duck',
  },
  {
    name: 'dialogSeeDiscoveryArrangments',
    path: 'dialogMerchantSeeDiscoveryArrangments.duck',
  },
];

listOfReducers.map((elm) =>
  Object.assign(reducers, {
    ...reducers,
    [elm.name]: require(`./${elm.path}`).default,
  })
);

export default combineReducers({
  ...reducers,
});
