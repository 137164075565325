const TODOS = 'ambos';
const GARANTIA = 'garantia';
const PAGAMENTO = 'pagamento';
const ANTECIPACAO = 'antecipacao';
const COBRANCA = 'cobranca';
const COLATERAL = 'colateral';

const TRANSACAO_TODOS = [
  {
    label: 'Garantia',
    value: '1',
  },
  {
    label: 'Pagamento',
    value: '2',
  },
  {
    label: 'Antecipação',
    value: '3',
  },
  {
    label: 'Cobrança',
    value: '4',
  },
];

const TRANSACAO_COLATERAL = [
  {
    label: 'Garantia',
    value: '1',
  },
  {
    label: 'Cobrança',
    value: '4',
  },
];

const TRANSACAO_GARANTIA = [
  {
    label: 'Garantia',
    value: '1',
  },
];

const TRANSACAO_PAGAMENTO = [
  {
    label: 'Pagamento',
    value: '2',
  },
];

const TRANSACAO_ANTECIPACAO = [
  {
    label: 'Antecipação',
    value: '3',
  },
];

const TRANSACAO_COBRANCA = [
  {
    label: 'Cobrança',
    value: '4',
  },
];

export const TRANSACTION_TYPES = {
  [TODOS]: TRANSACAO_TODOS,
  [GARANTIA]: TRANSACAO_GARANTIA,
  [PAGAMENTO]: TRANSACAO_PAGAMENTO,
  [ANTECIPACAO]: TRANSACAO_ANTECIPACAO,
  [COBRANCA]: TRANSACAO_COBRANCA,
  [COLATERAL]: TRANSACAO_COLATERAL,
};

export const TRANSACTION_VALUES = {
  [GARANTIA]: '1',
  [PAGAMENTO]: '2',
  [ANTECIPACAO]: '3',
  [COBRANCA]: '4',
};

export const TRANSACTION_LABELS = {
  [1]: 'Garantia',
  [2]: 'Pagamento',
  [3]: 'Antecipação',
  [4]: 'Cobrança',
};
