import { useEffect } from 'react';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import useIsEdit from './useIsEdit.hook';

type useCrudTypes = {
  dispatchRedux: {
    GET: any;
    CREATE: any;
    UPDATE: any;
    RESET: any;
  };
  selectorRedux: {
    DATA: any;
  };
  resetOnUnmount?: boolean;
  requestIdOnMount?: boolean;
  messages?: {
    onCreate?: string;
    onEdit?: string;
  };
};

const useCrudCreateEdit = ({
  dispatchRedux,
  selectorRedux,
  resetOnUnmount = true,
  requestIdOnMount = true,
  messages,
}: useCrudTypes) => {
  const handleNotification = useNotification();

  const { isEdit, id } = useIsEdit();

  useEffect(() => {
    const { successAction, errors } = selectorRedux.DATA || {};

    if (successAction === true) {
      const messageCreate = messages?.onCreate ?? 'Criação feita com sucesso!';
      const messageEdit = messages?.onEdit ?? 'Atualização feita com sucesso!';

      handleNotification(isEdit ? messageEdit : messageCreate, 'success');
    }

    if (successAction === false) {
      const message = errors?.message?.[0];

      handleNotification(
        message ?? 'Aconteceu um erro, tente novamente mais tarde',
        'error'
      );
    }
  }, [selectorRedux.DATA]);

  useEffect(() => {
    if (isEdit && requestIdOnMount) {
      dispatchRedux.GET(id);
    }
  }, [isEdit, requestIdOnMount]);

  useEffect(() => {
    return () => {
      if (resetOnUnmount) {
        dispatchRedux.RESET();
      }
    };
  }, []);

  const handleCreate = (values) => dispatchRedux.CREATE(values);

  const handleUpdate = (values) => dispatchRedux.UPDATE(id, values);

  return {
    isEdit,
    handleCreate,
    handleUpdate,
  };
};

export default useCrudCreateEdit;
